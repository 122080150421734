import { Text, useToast } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar';
import { after15days } from '@/Core/utils/dateTime';
import { useCreateInvoiceMutation, useGetAllInvoicesQuery } from '@/Invoices/api/invoices.api';
import { makeInvoice } from '@/Invoices/api/invoices.model';
import { InvoicesList } from '@/Invoices/components/InvoicesList';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const InvoicesMasterView: React.FC = () => {
  const { isLoading } = useGetAllInvoicesQuery();
  const navigate = useNavigate();
  const toast = useToast();
  const [createInvoice, { isLoading: isCreating }] = useCreateInvoiceMutation();

  const onCreateInvoice = async () => {
    const invoice = makeInvoice({ dueDate: after15days() });
    const result = await createInvoice(invoice);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/invoices/${result.data.id}/edit?new=true`);
  };

  const onSuccessfulDelete = () => {
    return navigate(`/invoices`);
  };

  return (
    <>
      <MasterViewToolbar entity="Invoice" isDisabled={isLoading || isCreating}>
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Фактури
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          <ToolbarAction isCreator isLoading={isLoading || isCreating} label="Нова Фактура" onClick={onCreateInvoice} />
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton onClick={onCreateInvoice} />
      </ShowOnMobile>
      <InvoicesList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};

InvoicesMasterView.displayName = 'InvoicesMasterView';
