import { CashPayment } from '@/CashPayments/api/payments.types';
import { isClientCompany, isClientIndividual } from '@/Clients/api/clients.utils';
import { Box, Flex, Heading, Text } from '@/core-components';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import * as React from 'react';

interface Props {
  cashPayment: CashPayment;
  query?: string;
}

export const SpotlightCashPaymentDetail: React.FC<Props> = ({ cashPayment }) => {
  const { client } = cashPayment;

  return cashPayment ? (
    <Flex direction="column" justify="center">
      <Heading as="h6" size="md" textAlign="center">
        Каса Прими - Бр. {cashPayment.referenceNumber}
      </Heading>
      <Text>
        <strong>Датум на сметка:</strong> {cashPayment.paymentDate ? toFormattedDate(cashPayment.paymentDate) : null}
      </Text>
      <Text>
        <strong>Белешки: </strong>
        {cashPayment.notes}
      </Text>
      <Box>
        <Flex direction="column" width="100%" mt={5} mb={3} justify="flex-end">
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <Heading as="h6" size="sm">
              <strong>Износ</strong>
            </Heading>
            <Text>{formatMoney(parseFloat(cashPayment.amount), cashPayment.currency)}</Text>
          </Box>
        </Flex>
      </Box>
      <Box textAlign="left" mt={2}>
        <address>
          {isClientIndividual(client) ? (
            <>
              <Text>
                <strong>Од: </strong>
                {client?.fullName}
              </Text>
              <Text>{client?.contactAddress}</Text>
              <Text>{client?.contactEmail} </Text>
            </>
          ) : null}
          {isClientCompany(client) ? (
            <>
              <Text>
                <strong>Од: </strong>
                {client?.businessName}
              </Text>
              <Text>{client?.businessAddress}</Text>
              <Text>{client?.businessEmail} </Text>
            </>
          ) : null}
        </address>
      </Box>
    </Flex>
  ) : null;
};
SpotlightCashPaymentDetail.displayName = 'SpotlightCashPaymentDetail';
