import { getFirstObject } from '@/Core/utils/getFirstObject';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetAllClientsQuery } from '../api/clients.api';

const EmptyClients = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmptyClients" */ '../../Core/components/EmptyStates/EmptyClients'),
    'EmptyClients',
  ),
);

export const ClientsHome: React.FC = () => {
  const { data: clients } = useGetAllClientsQuery();
  const client = getFirstObject(clients);

  return client ? <Navigate to={`${client?.id}`} state={{ client }} replace /> : <EmptyClients />;
};
export default ClientsHome;
ClientsHome.displayName = 'ClientsHome';
