import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/payments.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDeleteCashPaymentMutation, useGetAllCashPaymentsQuery } from '../api/payments.api';
import { CashPayment } from '../api/payments.types';
import { notifyCashPaymentDeleted } from '../api/payments.utils';
import { CashPaymentItem } from './CashPaymentItem';

interface Props {
  onSuccessfulDelete: (cashPayment: CashPayment) => void;
}

export const CashPaymentList: React.FC<React.PropsWithChildren<Props>> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetAllCashPaymentsQuery();
  const cashPayments = getSortedEntityItems<CashPayment>(data);
  const [deleteCashPayment] = useDeleteCashPaymentMutation();
  const match = useMatch('/cash-payments/:id/edit');

  const { query, isSearching } = useSearchQuery();

  const items = React.useMemo(() => {
    if (!isSearching) {
      return cashPayments;
    }

    return cashPayments.filter((payment) => {
      const foundNumber = payment?.referenceNumber?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
      const foundReason = payment?.paymentFor?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
      const foundClient =
        payment?.client?.fullName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase()) ||
        payment?.client?.businessName?.toLocaleLowerCase().includes(query?.toLocaleLowerCase());

      return foundNumber || foundClient || foundReason;
    });
  }, [cashPayments, query, isSearching]);

  return (
    <MasterList isLoading={isLoading || isFetching} data-test-id="cash-payments-master-list">
      {isEmpty(cashPayments) ? (
        <EmptyMasterViewList
          isSearching={isSearching}
          title={isSearching ? `Немате сметки со вашето пребарување "${query}"` : 'Немате Сметки'}
        >
          <EmptyState />
        </EmptyMasterViewList>
      ) : null}
      <AnimatePresence initial={false}>
        {items.map((cashPayment) =>
          cashPayment ? (
            <CashPaymentItem
              key={cashPayment.id}
              isLoading={isLoading || isFetching}
              cashPayment={cashPayment}
              onEdit={(cashPayment) => {
                const isEditing = match != null && cashPayment.id === match?.params?.id;
                navigate(isEditing ? `/cash-payments/${cashPayment.id}` : `/cash-payments/${cashPayment.id}/edit`);
              }}
              onDelete={async (cashPayment) => {
                const result = await deleteCashPayment(cashPayment.id);
                const deleted = notifyCashPaymentDeleted(cashPayment, result);
                if (deleted) {
                  onSuccessfulDelete(cashPayment);
                }
              }}
            />
          ) : null,
        )}
      </AnimatePresence>
    </MasterList>
  );
};
CashPaymentList.displayName = 'CashPaymentList';
