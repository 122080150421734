import { extendTheme, ThemeConfig } from '@/core-components';

interface Colors {
  colors: { sky: Record<string, string> };
}
type Config = ThemeConfig & Colors;

export const config: Config = {
  colors: {
    sky: {
      900: '#0C4A6E',
      800: '#075985',
      700: '#0369A1',
      600: '#0284C7',
      500: '#0EA5E9',
      400: '#38BDF8',
      300: '#7DD3FC',
      200: '#BAE6FD',
      100: '#E0F2FE',
      50: '#F0F9FF',
    },
  },
};

export const brojkiTheme = extendTheme(config);
