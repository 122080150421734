import { Client } from '@/Clients/api/clients.types';
import { Flex } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { DashboardChartLoader } from '@/Core/components/Loaders/DashboardChartLoader';
import { ClientSelector } from '@/Core/components/Selectors/ClientSelector';
import { CurrencySelector } from '@/Core/components/Selectors/CurrencySelector';
import { ExpenseCategorySelector } from '@/Core/components/Selectors/ExpenseCategorySelector';
import { YearSelector } from '@/Core/components/Selectors/YearSelector';
import { useGetDashboardExpensesQuery } from '@/Dashboard/api/dashboard.api';
import { getDashboardSummary } from '@/Dashboard/api/dashboard.utils';
import { AreaChart } from '@/Dashboard/components/AreaChart';
import { BarChart } from '@/Dashboard/components/BarChart';
import { DashboardChartHeader } from '@/Dashboard/components/DashboardChartHeader';
import { ExpenseCategory } from '@/ExpenseCategories/api/expenseCategories.types';
import { Currency, roundAmount } from '@/Treasuries/store/treasuries.utils';
import { toFinite } from 'lodash-es';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ChartCardWrapper, ChartWrapper, DashboardFilters } from './DashboardCharts';
import { SwitchChartButton } from './SwitchChartButton';

interface SelectedFilter {
  year: number;
  client?: string;
  currency?: string;
  category?: string;
}

interface ExpensesChartProps {
  filterByClientEnabled?: boolean;
  onFiltersChanged?: ({ category, year, client, currency }: SelectedFilter) => void;
}

const calculateTotal = (total: number, next: number) => {
  const amount = total + next;
  return roundAmount(amount);
};

const currentYear = new Date().getFullYear();

export const ExpensesChart: React.FC<ExpensesChartProps> = ({ filterByClientEnabled = true }) => {
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(Currency.MKD);
  const [selectedClient, setSelectedClient] = useState<Partial<Client> | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [chartType, setChartType] = useState('AreaChart');

  const [expensesFilter, setExpensesFilter] = useState<DashboardFilters & { currency?: Currency; category?: string }>({
    year: new Date().getFullYear(),
    currency: Currency.MKD,
  });

  const {
    data: expenses,
    isLoading: isLoadingExpenses,
    isFetching: isFetchingExpenses,
  } = useGetDashboardExpensesQuery(expensesFilter);

  const isLoading = isLoadingExpenses || isFetchingExpenses;
  const summary = getDashboardSummary(expenses, toFinite(expensesFilter?.year ?? currentYear));
  const totalAmount = summary?.reduce((total, next) => calculateTotal(total, next.amount), 0);
  const { control } = useForm();

  const changeClientFilter = (client: Partial<Client> | null) => {
    setSelectedClient(client);
    setExpensesFilter({ client: client?.id, year: selectedYear, currency: selectedCurrency });
  };

  const changeYearFilter = (year: number) => {
    setSelectedYear(year);
    setExpensesFilter({ year, client: selectedClient?.id, currency: selectedCurrency });
  };

  const changeCurrencyFilter = (currency: Currency) => {
    setSelectedCurrency(currency);
    setExpensesFilter?.({ currency, year: selectedYear, client: selectedClient?.id });
  };

  const changeCategoryFilter = (category: ExpenseCategory) => {
    setSelectedCategory(category?.id);
    setExpensesFilter?.({
      category: category?.id,
      currency: selectedCurrency,
      year: selectedYear,
      client: selectedClient?.id,
    });
  };

  const toggleChart = () => {
    const type = chartType === 'AreaChart' ? 'BarChart' : 'AreaChart';
    setChartType(type);
  };

  return (
    <ChartCardWrapper>
      <DashboardChartHeader
        amount={totalAmount}
        selectedYear={selectedYear}
        selectedCurrency={selectedCurrency}
        selectedClient={selectedClient}
        selectedCategory={selectedCategory}
      >
        <Flex align="center" alignItems="center" justify="flex-end" direction="row" width="100%">
          <HideOnMobile>
            {filterByClientEnabled ? (
              <Flex width="220px" mr={2}>
                <ClientSelector placeholder="За Клиент" onClientSelected={changeClientFilter} />
              </Flex>
            ) : null}
            <Flex width="240px" mr={2}>
              <ExpenseCategorySelector control={control} onCategorySelected={changeCategoryFilter} />
            </Flex>
          </HideOnMobile>
          <Flex width="120px" mr={2}>
            <CurrencySelector defaultValue={selectedCurrency} onCurrencySelected={changeCurrencyFilter} />
          </Flex>
          <Flex width="120px">
            <YearSelector onYearSelected={changeYearFilter} />
          </Flex>
        </Flex>
      </DashboardChartHeader>
      <SwitchChartButton onClick={toggleChart} type={chartType === 'AreaChart' ? 'BarChart' : 'AreaChart'} />
      <ChartWrapper>
        {isLoading ? (
          <DashboardChartLoader />
        ) : chartType === 'AreaChart' ? (
          <AreaChart name="expenses" data={summary} color="#ff7a32" currency={selectedCurrency} />
        ) : (
          <BarChart data={summary} color="#ff7a32" currency={selectedCurrency} />
        )}
      </ChartWrapper>
    </ChartCardWrapper>
  );
};
