import { useAppSelector } from '@/Core/AppStore';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetAllProjectsQuery,
} from '@/Projects/api/projects.api';
import { makeProject } from '@/Projects/api/projects.model';
import { Project } from '@/Projects/api/projects.types';
import { notifyProjectDeleted } from '@/Projects/api/projects.utils';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { Button, useToast } from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectsTable } from './ProjectsTable';

export const Projects: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data } = useGetAllProjectsQuery();
  const projects = React.useMemo(() => getSortedEntityItems<Project>(data), [data]);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [processingProject, setProcessingProject] = React.useState<Project | null>(null);

  const [createProject, { isLoading: isCreating }] = useCreateProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();

  const onCreateProject = async () => {
    captureEvent('create_project');
    const project = makeProject();
    const result = await createProject(project);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/projects/${result.data.id}/edit`);
  };

  const onDeleteProject = React.useCallback(
    async (project: Project) => {
      captureEvent('delete_project');
      setProcessingProject(project);

      try {
        const result = await deleteProject(project.id);
        notifyProjectDeleted(project, result);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingProject(null);
      }
    },
    [deleteProject],
  );

  return (
    <GridPage
      title="Проекти"
      actions={
        <Button
          onClick={onCreateProject}
          isLoading={isCreating}
          colorScheme={colorScheme}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Нов Проект
        </Button>
      }
    >
      <GridCard title="">
        <ProjectsTable projects={projects} processingProject={processingProject} onDelete={onDeleteProject} />
      </GridCard>
    </GridPage>
  );
};
Projects.displayName = 'Projects';

export default Projects;
