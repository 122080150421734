import {
  Box,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Switch,
  Text,
  Textarea,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectDefaultThemeSetting } from './SelectDefaultThemeSetting';

export const EstimatesSettings: React.FC<React.PropsWithChildren<{ isLoading: boolean }>> = ({ isLoading }) => {
  const switchColor = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  const {
    watch,
    control,
    formState: { errors, touchedFields },
    register,
  } = useFormContext();

  const showSignaturesLine = watch('estimateShowSignaturesLine');

  return (
    <>
      <Text as="legend" mb={0} mt={0}>
        Пресметки
      </Text>
      <Text color="gray.500">Секоја нова пресметка ќе се создава со овие подесувања.</Text>
      <Box mb={4} />
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="estimateDefaultTerms">
              Правила и Услови
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Textarea
              id="estimateDefaultTerms"
              isDisabled={isLoading}
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.estimateDefaultTerms ? 'Внеси име на компанија' : ''}
              {...register('estimateDefaultTerms')}
            />
            <FormErrorMessage>{errors.estimateDefaultTerms?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="estimateDefaultTheme">
              Шаблон
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <SelectDefaultThemeSetting control={control} name="estimateDefaultTheme" isLoading={isLoading} />
            <FormErrorMessage>{errors.estimateDefaultTheme?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="estimateShowLineNumber">
              Прикажи реден број на линија
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              id="estimateShowLineNumber"
              isDisabled={isLoading}
              colorScheme={switchColor}
              {...register('estimateShowLineNumber')}
            />
            <FormErrorMessage>{errors.estimateShowLineNumber?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="estimateShowUnit">
              Прикажи мерна единица на артиклите
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              id="estimateShowUnit"
              colorScheme={switchColor}
              isDisabled={isLoading}
              {...register('estimateShowUnit')}
            />
            <FormErrorMessage>{errors.estimateShowUnit?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      {/* <FormControl className="mb-3 row align-items-end">
        <FormLabel fontSize="sm" htmlFor="estimateShowDiscountOnLineItems" className={`col-lg-4 col-form-label`}>
          Додатете рабат на артиклите
        </FormLabel>
        <div className={`col-lg-8`}>
          <Switch
            id="estimateShowDiscountOnLineItems"
            isDisabled={isLoading}
            colorScheme={switchColor}
            {...register('estimateShowDiscountOnLineItems')}
          />
          <FormErrorMessage><>{errors.estimateShowDiscountOnLineItems}</></FormErrorMessage>
        </div>
      </FormControl> */}
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="estimateShowSignaturesLine">
              Прикажи ја линијата за потписи
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              id="estimateShowSignaturesLine"
              isDisabled={isLoading}
              colorScheme={switchColor}
              {...register('estimateShowSignaturesLine')}
            />
            <FormErrorMessage>{errors.estimateShowSignaturesLine?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <Collapse in={showSignaturesLine}>
        <FormControl mb={3}>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={3}>
              <FormLabel fontSize="sm" htmlFor="estimateSignatureLabels">
                Лабели за потписи
              </FormLabel>
            </GridItem>
            <GridItem colSpan={9}>
              <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="estimateSignatureLineOneLabel">
                      Лабела за потпис 1
                    </FormLabel>
                    <Textarea
                      rows={3}
                      id="estimateSignatureLineOneLabel"
                      placeholder="Потпис 1"
                      isDisabled={isLoading}
                      focusBorderColor={inputFocusBorderColor}
                      {...register('estimateSignatureLineOneLabel')}
                    />
                    <FormErrorMessage>{errors.estimateSignatureLineOneLabel?.message?.toString()}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="estimateSignatureLineTwoLabel">
                      Лабела за потпис 2
                    </FormLabel>
                    <Textarea
                      rows={3}
                      id="estimateSignatureLineTwoLabel"
                      placeholder="Потпис 2"
                      isDisabled={isLoading}
                      focusBorderColor={inputFocusBorderColor}
                      {...register('estimateSignatureLineTwoLabel')}
                    />
                    <FormErrorMessage>{errors.estimateSignatureLineTwoLabel?.message?.toString()}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="estimateSignatureLineThreeLabel">
                      Лабела за потпис 3
                    </FormLabel>
                    <Textarea
                      rows={3}
                      id="estimateSignatureLineThreeLabel"
                      placeholder="Потпис 3"
                      isDisabled={isLoading}
                      focusBorderColor={inputFocusBorderColor}
                      {...register('estimateSignatureLineThreeLabel')}
                    />
                    <FormErrorMessage>{errors.estimateSignatureLineThreeLabel?.message?.toString()}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </FormControl>
      </Collapse>
    </>
  );
};
