import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import { useGetAllProjectsQuery } from '@/Projects/api/projects.api';
import ProjectCreatorLoader from '@/Projects/components/ProjectCreatorLoader';
import { ProjectPageLoader } from '@/Projects/components/ProjectLoader';
import { ProjectsMasterView } from '@/Projects/components/ProjectsMasterView';
import * as React from 'react';

export const Projects: React.FC = () => {
  const { isLoading } = useGetAllProjectsQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.Project}
      masterView={<ProjectsMasterView />}
      detailView={<ProjectPageLoader />}
      creatorView={<ProjectCreatorLoader />}
    />
  );
};

Projects.displayName = 'ProjectsMobile';
export default Projects;
