import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { useDeleteClientMutation, useGetAllClientsQuery } from '../api/clients.api';
import { Client } from '../api/clients.types';
import { notifyClientDeleted, redirectClientAfterDeletion } from '../api/clients.utils';
import { useClientData } from '../hooks/useClientData';

const ClientMobilePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ClientMobilePage" */ './ClientMobilePage'), 'ClientMobilePage'),
);
const ClientPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ClientPage" */ './ClientPage'), 'ClientPage'),
);

export const ClientPageLoader: React.FC = () => {
  const navigate = useNavigate();
  const { client } = useClientData();
  const { data: clients } = useGetAllClientsQuery();
  const [deleteClient] = useDeleteClientMutation();

  const onDeleteClient = async (clientToDelete: Client) => {
    const result = await deleteClient(clientToDelete.id);
    const deleted = notifyClientDeleted(clientToDelete, result);
    if (!deleted) {
      return;
    }
    const redirectTo = redirectClientAfterDeletion(clientToDelete, clients);
    if (redirectTo) {
      if (isMobile) {
        navigate(`/clients`);
        return;
      }
      navigate(`/clients/${redirectTo.id}`);
      return;
    }
  };

  return client ? (
    isMobile ? (
      <ClientMobilePage client={client} onDelete={onDeleteClient} />
    ) : (
      <ClientPage client={client} onDelete={onDeleteClient} />
    )
  ) : (
    <DocumentNotFound />
  );
};
