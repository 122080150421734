import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { getFormattedDate } from '../../Core/utils/dateTime';
import { validate } from '../../Core/utils/validate';
import { Client, ClientType } from './clients.types';

export const makeClient = (client?: Partial<Client>): Client => {
  return {
    id: v4(),
    type: ClientType.Company,
    status: DraftableStatus.Draft,
    fullName: '',
    contactAddress: '',
    contactPhone: '',
    contactEmail: '',
    website: '',
    businessName: '',
    businessAddress: '',
    businessEmail: '',
    businessBankAccount: '',
    businessVatId: '',
    businessId: '',
    invoices: [],
    estimates: [],
    payments: [],
    expenses: [],
    ...client,
    createdAt: client?.createdAt ? getFormattedDate(client.createdAt) : getFormattedDate(new Date()),
  };
};

export const clientValidationSchema = validate.object().shape({
  contactEmail: validate.string().nullable().email('Неважечка емаил адреса'),
  businessEmail: validate.string().nullable().email('Неважечка емаил адреса'),
  fullName: validate.string().nullable(),
  businessName: validate.string().nullable(),
  contactAddress: validate.string().nullable(),
  contactPhone: validate.string().nullable(),
  website: validate.string().nullable(),
  businessId: validate.string().nullable(),
  businessVatId: validate.string().nullable(),
  businessBankAccount: validate.string().nullable(),
  businessAddress: validate.string().nullable(),
});
