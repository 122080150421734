import {
  CircularProgress as ChakraCircularProgress,
  CircularProgressLabel as ChakraCircularProgressLabel,
} from '@chakra-ui/react';
import * as React from 'react';

export const CircularProgress: React.FC<React.ComponentProps<typeof ChakraCircularProgress>> = (props) => {
  return <ChakraCircularProgress {...props} />;
};

CircularProgress.displayName = 'CircularProgress';

export const CircularProgressLabel: React.FC<React.ComponentProps<typeof ChakraCircularProgressLabel>> = (props) => {
  return <ChakraCircularProgressLabel {...props} />;
};

CircularProgressLabel.displayName = 'CircularProgressLabel';
