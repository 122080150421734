import { parseISO } from 'date-fns';
import { Creatable } from './standard.model';

export const getFirstObject = <T extends Creatable>(object?: Record<string, T>): T | null => {
  if (!object) {
    return null;
  }

  const sorted = Object.values(object).sort(
    (a, b) => parseISO(b?.createdAt as string).getTime() - parseISO(a?.createdAt as string).getTime(),
  );

  return sorted[0] as T;
};
