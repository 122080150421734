import {
  Link as ChakraLink,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@/core-components';
import { faEllipsisStroke, faEnvelope, faFilePdf, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { isClientCompany } from '../../Clients/api/clients.utils';
import { useSearchParams } from '../../Core/hooks/useSearchParams';
import { toFormattedDate } from '../../Core/utils/dateTime';
import { formatMoney } from '../../Treasuries/store/treasuries.utils';
import { Expense } from '../api/expenses.types';

const columnHelper = createColumnHelper<Expense>();

interface Props {
  onDownload: (expense: Expense) => void;
  onDelete: (expense: Expense) => void;
}

export const useExpensesTableColumns = ({ onDownload, onDelete }: Props) => {
  const { setSearchParams } = useSearchParams();

  return React.useMemo(
    () => [
      columnHelper.accessor('expenseNumber', {
        header: () => 'Сметка Бр.',
        size: 10,
        cell: (info) => (
          <ChakraLink as={Link} fontWeight="semibold" to={`/expense/${info.row.original.id}`}>
            {info.getValue()}
          </ChakraLink>
        ),
      }),
      columnHelper.accessor(
        (row) => {
          const client = row.client;
          return isClientCompany(client) ? client?.businessName : client?.fullName;
        },
        {
          id: 'client',
          header: () => 'Трговец',
          size: 20,
          filterFn: 'fuzzy',
          cell: (info) => {
            return (
              <Flex flexDirection="column" gap={0.5}>
                <ChakraLink
                  as={Link}
                  to={`/clients/${info.row.original.client?.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {info.getValue()}
                </ChakraLink>
                <Text color="gray.500" fontSize="small">
                  {info.row.original.category?.name}
                </Text>
              </Flex>
            );
          },
        },
      ),
      columnHelper.accessor('expenseFor', {
        header: () => 'Опис',
        size: 25,
        cell: (info) => <Text noOfLines={1}>{info.getValue()}</Text>,
      }),
      columnHelper.accessor('expenseDate', {
        header: () => 'Издадена',
        size: 10,
        cell: (info) => (info.getValue() ? toFormattedDate(info.getValue()) : null),
      }),
      columnHelper.accessor('amount', {
        header: () => 'Износ',
        size: 25,
        cell: (info) => {
          const amount = info.getValue() ? parseFloat(info.getValue()) : null;
          return amount ? formatMoney(amount, info.row.original.currency) : null;
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: () => '',
        size: 10,
        maxSize: 10,
        cell: (info) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
              variant="ghost"
              size="sm"
              fontSize={18}
              onClick={(e) => e.stopPropagation()}
            />
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchParams((prev) => [...prev.entries(), ['id', info.row.original.id], ['type', 'expense']]);
                }}
                icon={<FontAwesomeIcon icon={faEnvelope} />}
              >
                Испрати по емаил
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload(info.row.original);
                }}
                icon={<FontAwesomeIcon icon={faFilePdf} />}
              >
                Превземи PDF
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (info.row.original) {
                    onDelete(info.row.original);
                  }
                }}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
              >
                Додади во корпа
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      }),
    ],
    [setSearchParams, onDownload, onDelete],
  );
};
