import { Box } from '@/core-components';
import { keyframes } from '@emotion/react';
import React, { FC } from 'react';

interface BrojkiLogoProps {
  isCollapsed?: boolean;
  width?: number;
  height?: number;
  sShapeColor?: string;
  pShapeColor?: string;
  showLogoText?: boolean;
  showLogoShape?: boolean;
  vertical?: boolean;
  textColor?: string;
  animate?: boolean;
}

interface LogoProps {
  vertical?: boolean;
}

const colorChange = keyframes`
  0% { fill: #ff5630; }
  15% { fill: #28abe3; }
  30% { fill: #344563; }
  60% { fill: #6554c0; }
  75% { fill: #f38630; }
  90% { fill: #a7dbdb; }
  100% { fill: #0052cc; }
`;

const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({ children, vertical }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection={vertical ? 'row' : 'column'}
    paddingLeft={vertical ? '0.5rem' : 0}
  >
    {children}
  </Box>
);

const LogoText: React.FC<React.PropsWithChildren<LogoProps>> = ({ children, vertical }) => (
  <Box paddingLeft={vertical ? '0.5rem' : ''}>{children}</Box>
);

export const BrojkiLogo: FC<BrojkiLogoProps> = ({
  isCollapsed = false,
  showLogoText = false,
  showLogoShape = true,
  width = isCollapsed ? 40 : 50,
  height = isCollapsed ? 50 : 50,
  sShapeColor = '#1d4263',
  pShapeColor = '#1d4263',
  textColor = '#fff',
  vertical = false,
  animate = false,
}) => {
  return (
    <Logo vertical={vertical}>
      {showLogoShape && (
        <Box
          as="span"
          sx={{
            '& svg': {
              transition: 'all 0.2s ease-in',
              transform: isCollapsed ? 'rotate(90deg)' : 'none',
            },
            '& .p-shape': {
              ...(animate && {
                animation: `${colorChange} 5s infinite alternate`,
              }),
              fill: pShapeColor,
            },
            '& .s-shape': {
              ...(animate && {
                animation: `${colorChange} 3s infinite alternate`,
              }),
              fill: sShapeColor,
            },
          }}
        >
          <svg width={width} height={height} viewBox="0 0 640 402">
            <g>
              <path
                className="p-shape"
                fill={sShapeColor}
                d="M0,66.0321027 L0,402 L640,402 L640,338.333868 L140.094149,338.333868 C118.359112,338.333868 100.067249,322.417335 96.8392737,300.908507 C87.1553463,235.091493 68.00269,105.608347 68.00269,105.608347 L217.565568,105.608347 L217.565568,234.016051 L281.479489,234.016051 L281.479489,66.0321027 C281.479489,66.0321027 0,66.247191 0,66.0321027 L0,66.0321027 Z"
              ></path>
            </g>
            <g>
              <path
                className="s-shape"
                fill={pShapeColor}
                d="M174.095494,131.418941 L174.095494,273.807384 L324.734364,273.807384 L324.734364,0 L640,0 L640,313.383628 L537.780767,313.383628 C537.780767,313.383628 558.870208,169.919743 570.490921,91.4125201 C572.427707,78.7223114 568.554136,66.0321027 560.376597,56.35313 C551.98386,46.6741573 539.932751,41.081862 527.020847,41.081862 L422.219233,41.081862 L382.192334,313.168539 L135.574983,313.168539 L108.890383,131.418941 L174.095494,131.418941 L174.095494,131.418941 Z"
              ></path>
            </g>
          </svg>
        </Box>
      )}
      {!isCollapsed && showLogoText ? (
        <LogoText vertical={vertical}>
          <svg width={vertical ? '50px' : '60px'} height="30px" viewBox="0 0 560 200" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g fill={textColor} fillRule="nonzero">
                <polygon
                  id="Path"
                  points="526.221034 46.9355891 558.406798 46.9355891 558.406798 158.189156 526.221034 158.189156"
                ></polygon>
                <polygon
                  id="Path"
                  points="445.940674 114.555646 430.467461 129.859764 430.467461 158.189156 398.281697 158.189156 398.281697 4.75132491 430.467461 4.75132491 430.467461 91.6021199 477.508193 46.9355891 515.884544 46.9355891 469.668681 94.0839788 520.012958 158.189156 481.015514 158.189156"
                ></polygon>
                <path
                  d="M267.433759,159.843049 C255.741604,159.843049 245.252586,157.395842 235.969553,152.503057 C226.685706,147.609458 219.429866,140.818997 214.203661,132.132898 C208.977048,123.44843 206.361708,113.591113 206.361708,102.563799 C206.361708,91.5336323 208.976641,81.6767224 214.203661,72.9902161 C219.429866,64.3065634 226.685706,57.5161027 235.969553,52.6216877 C245.252586,47.7297187 255.74201,45.2825112 267.433759,45.2825112 C279.125508,45.2825112 289.578327,47.7297187 298.793841,52.6216877 C308.010982,57.5161027 315.230622,64.3061557 320.458454,72.9902161 C325.685067,81.6763147 328.300407,91.5332246 328.300407,102.563799 C328.300407,113.591113 325.685474,123.44843 320.458454,132.132898 C315.230622,140.818997 308.010982,147.609458 298.793841,152.503057 C289.578733,157.395842 279.125508,159.843049 267.433759,159.843049 Z M267.433759,133.374643 C275.685299,133.374643 282.459558,130.58296 287.754503,124.999185 C293.052702,119.416633 295.698547,111.937627 295.698547,102.564207 C295.698547,93.1875255 293.052702,85.7093355 287.754503,80.1247452 C282.459558,74.5430086 275.685299,71.7513249 267.433759,71.7513249 C259.180186,71.7513249 252.372981,74.5430086 247.00889,80.1247452 C241.642359,85.7093355 238.961941,93.1875255 238.961941,102.564207 C238.961941,111.937627 241.642359,119.41704 247.00889,124.999185 C252.372981,130.583367 259.180186,133.374643 267.433759,133.374643 Z"
                  id="Shape"
                ></path>
                <path
                  d="M165.697443,61.6196494 C169.550494,56.2409295 174.743347,52.1744802 181.276816,49.417448 C187.809064,46.6604158 195.33864,45.2829189 203.868797,45.2829189 L203.868797,75.059519 C200.290296,74.785161 197.883207,74.6465552 196.64509,74.6465552 C187.43161,74.6465552 180.208716,77.2315532 174.982103,82.4007338 C169.758338,87.5695067 167.143405,95.3240929 167.143405,105.665308 L167.143405,158.188748 L134.957234,158.188748 L134.957234,46.9351814 L165.697443,46.9351814 L165.697443,61.6192417 L165.697443,61.6196494 Z"
                  id="Path"
                ></path>
                <path
                  d="M328.3,170.112923 L328.3,199.951896 L334.093608,199.951896 L334.093608,199.962087 C347.299651,199.962087 357.581232,196.205463 364.93957,188.690583 C372.298722,181.177334 375.978501,170.941296 375.978501,157.981247 L375.978501,46.9359967 L343.792737,46.9359967 L343.792737,154.353037 C343.792737,166.464329 339.237653,170.113331 328.3,170.113331 L328.3,170.112923 Z"
                  id="Path"
                ></path>
                <path
                  d="M359.886229,0.0199755402 C369.649622,0.0199755402 377.565195,7.95434162 377.565195,17.739095 C377.565195,27.525479 369.649622,35.4586221 359.886229,35.4586221 C350.122022,35.4586221 342.20645,27.525479 342.20645,17.739095 C342.20645,7.95434162 350.122022,0.0199755402 359.886229,0.0199755402 Z"
                  id="Path"
                ></path>
                <path
                  d="M542.31412,0.0199755402 C552.07792,0.0199755402 559.993899,7.95434162 559.993899,17.739095 C559.993899,27.525479 552.077513,35.4586221 542.31412,35.4586221 C532.550726,35.4586221 524.635154,27.525479 524.635154,17.739095 C524.635154,7.95434162 532.550726,0.0199755402 542.31412,0.0199755402 Z"
                  id="Path"
                ></path>
                <path
                  d="M65.6128995,45.2829189 C75.9278327,45.2829189 85.3186519,47.6591928 93.7764091,52.417448 C102.23742,57.1720342 108.872574,63.8589482 113.687565,72.4753363 C118.50093,81.0909091 120.908019,91.1214839 120.908019,102.563799 C120.908019,114.004077 118.50093,124.034651 113.687565,132.651855 C108.872167,141.264982 102.23742,147.953119 93.7764091,152.708113 C85.3186519,157.465552 75.9278327,159.842642 65.6128995,159.842642 C50.3463103,159.842642 38.7220802,155.01916 30.7450901,145.368528 L30.7450901,158.188748 L0.00366066241,158.188748 L0.00366066241,4.75050958 L32.1894248,4.75050958 L32.1894248,58.7219731 C40.3034863,49.7611088 51.4453225,45.2821035 65.6128995,45.2821035 L65.6128995,45.2829189 Z M60.0429983,133.374643 C68.296165,133.374643 75.0708309,130.58296 80.3661825,124.999185 C85.6607205,119.416633 88.3098199,111.937627 88.3098199,102.564207 C88.3098199,93.1875255 85.6603138,85.7093355 80.3661825,80.1247452 C75.0712377,74.5430086 68.296165,71.7513249 60.0429983,71.7513249 C51.7886113,71.7513249 45.0167926,74.5430086 39.7194073,80.1247452 C34.4240558,85.7093355 31.7778036,93.1875255 31.7778036,102.564207 C31.7778036,111.937627 34.4240558,119.41704 39.7194073,124.999185 C45.0167926,130.583367 51.7886113,133.374643 60.0429983,133.374643 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </svg>
        </LogoText>
      ) : null}
    </Logo>
  );
};
