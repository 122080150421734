import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { http } from '../../Core/http/http';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { Estimate } from '../../Estimates/api/estimates.types';
import { Invoice } from '../../Invoices/api/invoices.types';

const { MailInvoiceUrl, MailEstimateUrl } = ApiEndpoints;

// TODO: MOVE TO invoices.api
export const mailInvoice = async (
  { invoice, email, message }: { invoice: Invoice; email: string; message?: string },
  config?: AxiosRequestConfig,
): Promise<{ data: Invoice }> => {
  const { data } = await http.post<{ invoice: Invoice; email: string; message?: string }, AxiosResponse<Invoice>>(
    `${MailInvoiceUrl}/${invoice.id}`,
    { invoice, email, message },
    config,
  );
  return { data };
};

// TODO: MOVE TO estimates.api
export const mailEstimate = async (
  { estimate, email, message }: { estimate: Estimate; email: string; message?: string },
  config?: AxiosRequestConfig,
): Promise<{ data: Estimate }> => {
  const { data } = await http.post<{ estimate: Estimate; email: string; message?: string }, AxiosResponse<Estimate>>(
    `${MailEstimateUrl}/${estimate.id}`,
    { estimate, email, message },
    config,
  );

  return { data };
};
