import { AuthenticationPage } from '@/Authentication/components/AuthenticationPage/AuthenticationPage';
import { TermsConditionsDialog } from '@/Authentication/components/AuthenticationPage/TermsConditions';
import { signup } from '@/Authentication/store/signup.actions';
import { Box, Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@/core-components';
import { useAppDispatch, useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import { validate } from '@/Core/utils/validate';
import { captureEvent } from '@/setupPostHog';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

const validationSchema = validate.object().shape({
  name: validate
    .string()
    .min(2, 'Име и Презиме мора да содржи најмалку 2 карактери')
    .required('Потребно е Име и Презиме'),
  company_name: validate
    .string()
    .min(2, 'Име на Компанија мора да содржи најмалку 2 карактери')
    .required('Потребно е Име на Компанија'),
  email: validate
    .string()
    .min(6, 'Неважечка емаил адреса')
    .required('Потребна е емаил адреса')
    .email('Неважечка емаил адреса'),
  password: validate
    .string()
    .required('Потребна е лозинка')
    .min(8, 'Лозинката треба да да содржи најмалку 8 карактери'),
  password_confirmation: validate
    .string()
    .required('Потребна потврда за лозинка')
    .oneOf([validate.ref('password'), ''], 'Лозинките мора да се совпаднат'),
  termsAndConditions: validate.boolean().oneOf([true], 'Потребно е да се согласите со условите'),
});

interface FormValues {
  name: string;
  company_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  termsAndConditions?: boolean;
}

const resolver = yupResolver(validationSchema);

export const SignupPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(({ authenticated }) => authenticated.isLoading);
  const buttonColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  const form = useForm<FormValues>({
    resolver,
    defaultValues: {
      name: '',
      company_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      termsAndConditions: false,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = form;

  const termsAndConditionsAccepted = form.watch('termsAndConditions');

  const isCompanyNameInvalid = !!(errors.company_name && touchedFields.company_name);
  const isEmailInvalid = !!(errors.email && touchedFields.email);
  const isPasswordInvalid = !!(errors.password && touchedFields.password);
  const isFullNameInvalid = !!(errors.name && touchedFields.name);

  const isPasswordConfirmationInvalid = !!(errors.password_confirmation && touchedFields.password_confirmation);

  const onSubmit = async ({ name, company_name, email, password, password_confirmation }: TODO) => {
    captureEvent('user_signed_up');
    const user: TODO = await dispatch(signup({ name, company_name, email, password, password_confirmation }));
    if (user?.id) {
      navigate('/company/profile');
    }
  };

  return (
    <AuthenticationPage showProgress={isLoading}>
      <Box mt={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb={3} isInvalid={isFullNameInvalid}>
            <FormLabel htmlFor="name">Име и Презиме</FormLabel>
            <Input
              type="text"
              id="name"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.name ? 'Внесете Име и Презиме' : ''}
              {...register('name')}
            />
            <FormErrorMessage>
              <>{errors.name?.message}</>
            </FormErrorMessage>
          </FormControl>

          <FormControl mb={3} isInvalid={isCompanyNameInvalid}>
            <FormLabel htmlFor="name">Име на Компанија</FormLabel>
            <Input
              type="text"
              id="company_name"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.company_name ? 'Внесете Име на Компанија' : ''}
              {...register('company_name')}
            />
            <FormErrorMessage>
              <>{errors.company_name?.message}</>
            </FormErrorMessage>
          </FormControl>

          <FormControl mb={3} isInvalid={isEmailInvalid}>
            <FormLabel htmlFor="email">Емаил</FormLabel>
            <Input
              type="email"
              id="email"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.email ? 'Внеси ја твојата емаил адреса' : ''}
              {...register('email')}
            />
            <FormErrorMessage>
              <>{errors.email?.message}</>
            </FormErrorMessage>
          </FormControl>

          <FormControl mb={3} isInvalid={isPasswordInvalid}>
            <FormLabel htmlFor="password">Лозинка</FormLabel>
            <Input
              type="password"
              id="password"
              autoComplete="on"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.password ? 'Внеси ја твојата лозинка' : ''}
              {...register('password')}
            />
            <FormErrorMessage>
              <>{errors.password?.message}</>
            </FormErrorMessage>
          </FormControl>

          <FormControl mb={3} isInvalid={isPasswordConfirmationInvalid}>
            <FormLabel htmlFor="password_confirmation">Повтори Лозинка</FormLabel>
            <Input
              type="password"
              id="password_confirmation"
              autoComplete="on"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.password_confirmation ? 'Повтори ја твојата лозинка' : ''}
              {...register('password_confirmation')}
            />
            <FormErrorMessage>
              <>{errors.password_confirmation?.message}</>
            </FormErrorMessage>
          </FormControl>
          <FormControl id="termsAndConditions">
            <Box display="flex" alignItems="center" flexDirection="row" gap="4px">
              <Checkbox {...register('termsAndConditions')} colorScheme="sky">
                Се согласувам со
              </Checkbox>
              <TermsConditionsDialog label="условите и правилата" type="link" />
            </Box>
          </FormControl>

          <Box width="100%">
            <Button
              width="100%"
              mt={4}
              isDisabled={isLoading || !termsAndConditionsAccepted}
              colorScheme={buttonColorScheme}
              isLoading={isLoading}
              type="submit"
            >
              Регистрирај се
            </Button>
            <Box mt={3}>
              <Link to="/login">Веќе сте корисник? Најави се</Link>
            </Box>
          </Box>

          <Box mt={4} mb={4}>
            <Box display="flex" alignItems="center">
              <Text textAlign="start">
                Со креирање на сметка за користење на{' '}
                <strong>
                  <a href="https://brojki.com">brojki.com</a>
                </strong>
                , вие се согласувате со нашите <TermsConditionsDialog type="link" />
              </Text>
            </Box>
          </Box>
        </form>
      </Box>
    </AuthenticationPage>
  );
};

export default SignupPage;
