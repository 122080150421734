import { SerializedError } from '@reduxjs/toolkit';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { Estimate } from './estimates.types';

type Error =
  | {
      status: number;
      data: {
        message?: string | undefined;
        error?: string | undefined;
        errorCode?: string | undefined;
      };
    }
  | SerializedError;

type Result =
  | {
      data: Estimate;
    }
  | {
      error: Error;
    };

export const notifyEstimateDeleted = (estimate: Estimate, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${estimate.id}-error`,
      title: 'Failed to delete estimate',
    });
    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === estimate.id) {
    toast = createInfoToast({
      id: `${estimate.id}-success`,
      title: 'Успешно',
      description: `Пресметката "${estimate?.estimateNumber ?? ''}" е додадена во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyEstimateUpdated = (estimate: Estimate, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${estimate.id}-error`,
      title: 'Failed to update estimate',
    });

    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === estimate.id) {
    toast = createInfoToast({
      id: `${estimate.id}-success`,
      title: 'Успешно',
      description: `Пресметката "${estimate?.estimateNumber ?? ''}" e зачувана!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyEstimateSettingsUpdated = (estimate: Estimate, result: Result) => {
  if ('error' in result) {
    const toast = createErrorToast({
      id: `${estimate.id}-error`,
      title: 'Failed to update estimate',
    });

    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === estimate.id) {
    return result.data;
  }

  return false;
};

export const notifyEstimateCopied = (estimate: Estimate, result: Result) => {
  if ('error' in result) {
    const toast = createErrorToast({
      id: `${estimate.id}-error`,
      title: 'Failed to copy estimate',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result) {
    const toast = createInfoToast({
      id: `${estimate.id}-success`,
      title: 'Успешно',
      description: `Пресметката "${estimate?.estimateNumber ?? ''}" е копирана!`,
    });

    toaster(toast);
    return result.data;
  }

  return false;
};

export const redirectEstimateAfterDeletion = (estimate: Estimate, estimates?: Record<string, Estimate>) => {
  if (!estimates) {
    return null;
  }
  const { [estimate.id]: _, ...withoutRemoved } = estimates;
  const deletedIndex = Object.keys(estimates).findIndex((i) => estimates[i]?.id === estimate.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
