import { Client } from '@/Clients/api/clients.types';
import { Box, Text } from '@/core-components';
import { Currency, formatMoney } from '@/Treasuries/store/treasuries.utils';
import * as React from 'react';

interface Props {
  amount: number;
  selectedClient?: Partial<Client> | null;
  selectedYear: number;
  selectedCurrency?: Currency;
  selectedCategory?: string | null;
}

export const DashboardChartHeader: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  amount,
  selectedYear,
  selectedClient,
  selectedCurrency,
}) => {
  const client = selectedClient ? ` ◦ ${selectedClient?.fullName}, ${selectedClient?.businessName}` : '';

  return (
    <Box
      width="100%"
      height="60px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      borderBottom="1px solid #e2e8f0"
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="45%">
        <Text fontSize="lg" color="green.500">
          {formatMoney(amount, selectedCurrency).trim()}
        </Text>
        <Text fontSize="xs">
          вкупно за г. {selectedYear} {client}
        </Text>
      </Box>
      <Box display="flex" width="65%" alignItems="flex-start">
        {children}
      </Box>
    </Box>
  );
};

DashboardChartHeader.displayName = 'DashboardChartHeader';
