import { Badge } from '@/core-components';
import { Invoice } from '@/Invoices/api/invoices.types';
import { PaymentStatus } from '@/Treasuries/store/treasuries.types';
import * as React from 'react';

export const PaymentStatusLabel: React.FC<{ paymentStatus: Invoice['paymentStatus'] }> = ({ paymentStatus }) => {
  const status = React.useMemo(() => {
    try {
      if (paymentStatus === PaymentStatus.Paid) {
        return { label: 'Платенa', color: 'green' };
      }
      if (paymentStatus === PaymentStatus.Pending) {
        return { label: 'Во тек', color: 'gray' };
      }
      if (paymentStatus === PaymentStatus.PartiallyPaid) {
        return { label: 'Делумно платенa', color: 'gray' };
      }
      if (paymentStatus === PaymentStatus.Overdue) {
        return { label: 'Неплатена', color: 'red' };
      }
      return { label: '', color: '' };
    } catch {
      return { label: '', color: '' };
    }
  }, [paymentStatus]) as { label: string; color: string };

  return (
    <Badge textTransform="capitalize" colorScheme={status.color}>
      {status.label}
    </Badge>
  );
};
