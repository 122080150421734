import { StylesConfig } from 'react-select';

export const customStyle: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#0EA5E9' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #0EA5E9' : provided.boxShadow,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? '#0EA5E9' : provided.backgroundColor,
    };
  },
};
