import { getFirstObject } from '@/Core/utils/getFirstObject';
import { useGetAllEstimatesQuery } from '@/Estimates/api/estimates.api';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

const EmptyEstimates = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmptyEstimates" */ '../../Core/components/EmptyStates/EmptyEstimates'),
    'EmptyEstimates',
  ),
);

export const EstimatesHome: React.FC = () => {
  const { data: estimates } = useGetAllEstimatesQuery();
  const estimate = getFirstObject(estimates);

  return estimate ? <Navigate to={`${estimate?.id}`} state={{ estimate }} replace /> : <EmptyEstimates />;
};
export default EstimatesHome;
EstimatesHome.displayName = 'EstimateHome';
