import { FormControl, FormLabel, Radio, RadioGroup, Stack } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import * as React from 'react';
import { useController } from 'react-hook-form';
import { ClientType } from '../api/clients.types';
interface Props {
  type?: ClientType;
  control: TODO;
}
export const ClientTypeControl: React.FC<React.PropsWithChildren<Props>> = ({ control, type = ClientType.Company }) => {
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ defaultValue: type, control, name: 'type' });

  return (
    <RadioGroup
      name="type"
      ref={ref}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      display="flex"
      alignItems="center"
      size="lg"
      colorScheme={colorScheme}
    >
      <FormControl className="row" display="flex" alignItems="center">
        <FormLabel htmlFor="type" className="col-sm-12">
          Изберете вид на комитент
        </FormLabel>
        <Stack direction="row" className="col-sm-12">
          <Radio name="type" value={ClientType.Individual}>
            Физичко Лице
          </Radio>
          <Radio name="type" value={ClientType.Company}>
            Компанија
          </Radio>
        </Stack>
      </FormControl>
    </RadioGroup>
  );
};
