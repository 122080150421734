import { ClientType } from '@/Clients/api/clients.types';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { getTranslatedTreasuryType } from '@/Core/utils/translatableTypes';
import { validate } from '@/Core/utils/validate';
import { useGetEstimateQuery } from '@/Estimates/api/estimates.api';
import { useGetInvoiceQuery } from '@/Invoices/api/invoices.api';
import { createSuccessToast } from '@/Toaster/store/toaster.utils';
import { mailEstimate, mailInvoice } from '@/Treasuries/store/treasuries.service';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMatch } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  treasuryType?: 'invoice' | 'estimate';
  onClose: () => void;
  modalSize?: 'sm' | 'lg' | 'xl' | 'xs' | 'md' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
}

const validationSchema = validate.object().shape({
  email: validate.string().required('Потребна е емаил адреса').email('Неважечка емаил адреса'),
  message: validate.string(),
});

interface FormValues {
  email: string;
  message?: string;
}

const resolver = yupResolver(validationSchema);
export const MailTreasuryModal: React.FC<React.PropsWithChildren<Props>> = ({
  treasuryType,
  isOpen,
  onClose,
  modalSize = 'xl',
}) => {
  const toast = useToast();
  const { searchParams, setSearchParams } = useSearchParams();
  const documentType = treasuryType ?? searchParams.get('type');

  const match = useMatch(documentType === 'invoice' ? '/invoices/:id' : '/estimates/:id');
  const id = match?.params.id ?? searchParams.get('id');

  const { data: invoice, isLoading: isFetchingInvoice } = useGetInvoiceQuery(id ?? '', {
    skip: documentType !== 'invoice',
  });
  const { data: estimate, isLoading: isFetchingEstimate } = useGetEstimateQuery(id ?? '', {
    skip: documentType !== 'estimate',
  });

  const [isLoading, setIsLoading] = useState(false);
  const buttonColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  const successToastMessage = createSuccessToast({
    title: 'Успешно',
    description:
      documentType === 'invoice'
        ? 'Фактурата е испратена по е-мејл'
        : documentType === 'estimate'
          ? 'Пресметката е испратена по е-мејл'
          : 'Документот е испратено по е-мејл',
  });

  const client = documentType === 'invoice' ? invoice?.client : estimate?.client;
  const isClientCompany = client?.type === ClientType.Company;
  const sendToEmail = isClientCompany && client.businessEmail ? client.businessEmail : client?.contactEmail;

  const form = useForm<FormValues>({
    resolver,
    defaultValues: { email: sendToEmail ?? '', message: '' },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { touchedFields },
  } = form;

  useEffect(() => {
    reset({ email: sendToEmail ?? '', message: '' });
  }, [reset, sendToEmail]);

  const onModalClose = () => {
    reset({ email: sendToEmail ?? '', message: '' });
    onClose?.();
    setSearchParams((params) => {
      searchParams.delete('id');
      searchParams.delete('type');
      return params;
    });
  };

  const onSubmit = async ({ email, message }: FormValues) => {
    setIsLoading(true);
    if (!email) {
      return;
    }
    if (documentType === 'invoice' && invoice) {
      return mailInvoice({ invoice, email, message })
        .then(() => {
          setIsLoading(false);
          onModalClose();
          toast(successToastMessage);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    if (documentType === 'estimate' && estimate) {
      return mailEstimate({ estimate, email, message })
        .then(() => {
          setIsLoading(false);
          onModalClose();
          toast(successToastMessage);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const translatedType = React.useMemo(() => {
    if (isFetchingInvoice || isFetchingEstimate) {
      return '';
    }
    if (invoice) {
      return getTranslatedTreasuryType(invoice.type!);
    }
    if (estimate) {
      return getTranslatedTreasuryType(estimate.type!);
    }
    return '';
  }, [invoice, estimate, isFetchingInvoice, isFetchingEstimate]);

  return (
    <Modal
      motionPreset="slideInBottom"
      size={modalSize}
      isOpen={isOpen}
      onClose={onModalClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent as="div" borderRadius={modalSize === 'full' ? 'none' : 'md'}>
        <ModalHeader>
          <Text fontWeight="normal" fontSize="lg">
            Испрати преку емаил - {translatedType} Бр:{' '}
            <Text as="b">{documentType === 'invoice' ? invoice?.invoiceNumber : estimate?.estimateNumber}</Text>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Box as="form" height="100%" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {client ? (
              <Box display="flex" width="100%" mb={2} flexDirection="column">
                <Text color="gray.500" mb={1}>
                  Клиент
                </Text>
                {client?.businessName && (
                  <Text fontSize="xl" mb={1}>
                    {client?.businessName}
                  </Text>
                )}
                {client?.fullName && (
                  <Text mb={1} fontWeight="light">
                    {client?.fullName}
                  </Text>
                )}
                {client?.contactPhone && <Text fontWeight="light">{client?.contactPhone}</Text>}
              </Box>
            ) : null}
            <FormControl mb={2}>
              <FormLabel fontSize="sm" htmlFor="email">
                Емаил на Kлиент
              </FormLabel>

              <Input
                isDisabled={isLoading}
                id="email"
                type="text"
                focusBorderColor={inputFocusBorderColor}
                placeholder={touchedFields.email ? 'Внеси емаил на клиент' : ''}
                {...register('email')}
              />
            </FormControl>

            <FormControl mb={3}>
              <FormLabel fontSize="sm" htmlFor="message">
                Порака (опционално)
              </FormLabel>

              <Textarea
                isDisabled={isLoading}
                height={180}
                fontSize="sm"
                id="message"
                focusBorderColor={inputFocusBorderColor}
                placeholder={touchedFields.message ? 'Внеси порака' : ''}
                {...register('message')}
              />
            </FormControl>

            <Box display="flex" alignItems="center" mt={2}>
              <Box display="inline-flex" fontSize="xl" mr={2} color="sky.400">
                <FontAwesomeIcon icon={faCircleInfo} />
              </Box>
              <Text color="gray.500" mb={0}>
                Документот во pdf формат ќе биде прикачен на емаил.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter position={modalSize === 'full' ? 'absolute' : 'initial'} bottom={4} width="100%">
            <Button size="sm" variant="ghost" mr={3} onClick={onModalClose} isDisabled={isLoading}>
              Откажи
            </Button>
            <Button
              type="submit"
              size="sm"
              colorScheme={buttonColorScheme}
              isLoading={isLoading || isFetchingInvoice || isFetchingEstimate}
            >
              Испрати
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
};

MailTreasuryModal.displayName = 'MailTreasuryModal';
