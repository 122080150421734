import { Box } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/products.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import {
  useDeleteProductMutation,
  useGetAllProductsQuery,
  useUpdateProductMutation,
} from '@/Products/api/products.api';
import { Product } from '@/Products/api/products.types';
import { notifyProductDeleted, notifyProductUpdated } from '@/Products/api/products.utils';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useMatch, useNavigate } from 'react-router-dom';
import { ProductItem } from './ProductItem';

const EmptyText: React.FC = () => {
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      Клик на
      <Box
        borderRadius="sm"
        mx={1}
        backgroundColor={colorSchemeValue}
        color="white"
        boxSize="20px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Box>{' '}
      да креирате нов производ.
    </Box>
  );
};

interface Props {
  onSuccessfulDelete: (product: Product) => void;
}

export const ProductsList: React.FC<React.PropsWithChildren<Props>> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetAllProductsQuery();
  const products = getSortedEntityItems<Product>(data);
  const [deleteProduct] = useDeleteProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const match = useMatch('/products/:id/edit');

  const { query, isSearching } = useSearchQuery();
  const items = React.useMemo(() => {
    if (!isSearching) {
      return products;
    }

    return products.filter((product) => {
      const foundCode = product?.skuCode?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
      const foundName = product?.name?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
      const foundDescription = product?.description?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());

      return foundCode || foundName || foundDescription;
    });
  }, [products, query, isSearching]);

  return (
    <MasterList isLoading={isLoading} data-test-id="products-master-list">
      {isEmpty(items) ? (
        <EmptyMasterViewList
          isSearching={isSearching}
          title={isSearching ? `Немате производи/услуги со вашето пребарување "${query}"` : 'Немате Производи'}
          text={isSearching ? null : <EmptyText />}
        >
          <EmptyState />
        </EmptyMasterViewList>
      ) : (
        <AnimatePresence initial={false}>
          {items.map((product) =>
            product ? (
              <ProductItem
                key={product.id}
                isLoading={isLoading || isFetching}
                product={product}
                onSave={async (product) => {
                  const result = await updateProduct(product);
                  const saved = notifyProductUpdated(product, result);
                  if (saved) {
                    if (isMobile) {
                      return navigate('/products');
                    }
                    return navigate(`/products/${saved.id}/`);
                  }
                }}
                onEdit={(product) => {
                  const isEditing = match != null && product.id === match?.params?.id;
                  navigate(isEditing ? `/products/${product.id}/` : `/products/${product.id}/edit`);
                }}
                onDelete={async (product) => {
                  const result = await deleteProduct(product.id);
                  const deleted = notifyProductDeleted(product, result);
                  if (deleted) {
                    onSuccessfulDelete(product);
                  }
                }}
              />
            ) : null,
          )}
        </AnimatePresence>
      )}
    </MasterList>
  );
};
ProductsList.displayName = 'ProductsList';
