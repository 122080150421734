import { useDeleteCashPaymentMutation } from '@/CashPayments/api/payments.api';
import { CashPayment } from '@/CashPayments/api/payments.types';
import { notifyCashPaymentDeleted } from '@/CashPayments/api/payments.utils';
import { useCashPaymentData } from '@/CashPayments/hooks/useCashPaymentData';
import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const CashPaymentMobilePage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "CashPaymentMobilePage" */ './CashPaymentMobilePage'),
    'CashPaymentMobilePage',
  ),
);
const CashPaymentPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "CashPaymentPage" */ './CashPaymentPage'), 'CashPaymentPage'),
);

export const CashPaymentPageLoader: React.FC = () => {
  const navigate = useNavigate();
  const { cashPayment } = useCashPaymentData();
  const [deleteCashPayment] = useDeleteCashPaymentMutation();

  const onDeleteCashPayment = async (cashPaymentToDelete: CashPayment) => {
    const result = await deleteCashPayment(cashPaymentToDelete.id);
    const deleted = notifyCashPaymentDeleted(cashPaymentToDelete, result);
    if (!deleted) {
      return;
    }

    navigate(`/cashPayments`);
  };

  return cashPayment ? (
    isMobile ? (
      <CashPaymentMobilePage cashPayment={cashPayment} onDelete={onDeleteCashPayment} />
    ) : (
      <CashPaymentPage cashPayment={cashPayment} onDelete={onDeleteCashPayment} />
    )
  ) : (
    <DocumentNotFound />
  );
};
