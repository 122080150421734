import { isClientCompany, isClientIndividual } from '@/Clients/api/clients.utils';
import { Box, Flex, Heading, Text } from '@/core-components';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { Expense } from '@/Expenses/api/expenses.types';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import * as React from 'react';

interface Props {
  expense: Expense;
  query?: string;
}

export const SpotlightExpenseDetail: React.FC<Props> = ({ expense }) => {
  const { client } = expense;

  return expense ? (
    <Flex direction="column" justify="center">
      <Heading as="h6" size="md" textAlign="center">
        Трошок - Бр. {expense.expenseNumber}
      </Heading>
      <Text>
        <strong>Датум на исплата:</strong> {expense.expenseDate ? toFormattedDate(expense.expenseDate) : null}
      </Text>
      <Text>
        <strong>Белешки: </strong>
        {expense.notes}
      </Text>
      <Box>
        <Flex direction="column" width="100%" mt={5} mb={3} justify="flex-end">
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <Heading as="h6" size="sm">
              <strong>Износ </strong>
            </Heading>
            <Text>{formatMoney(parseFloat(expense.amount), expense.currency)}</Text>
          </Box>
        </Flex>
      </Box>
      <Box textAlign="left" mt={2}>
        <address>
          {isClientIndividual(client) ? (
            <>
              <Text>
                <strong>До: </strong>
                {client?.fullName}
              </Text>
              <Text>{client?.contactAddress}</Text>
              <Text>{client?.contactEmail} </Text>
            </>
          ) : null}
          {isClientCompany(client) ? (
            <>
              <Text>
                <strong>До: </strong>
                {client?.businessName}
              </Text>
              <Text>{client?.businessAddress}</Text>
              <Text>{client?.businessEmail} </Text>
            </>
          ) : null}
        </address>
      </Box>
    </Flex>
  ) : null;
};
SpotlightExpenseDetail.displayName = 'SpotlightExpenseDetail';
