import { Client } from '@/Clients/api/clients.types';
import { Flex } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { DashboardChartLoader } from '@/Core/components/Loaders/DashboardChartLoader';
import { ClientSelector } from '@/Core/components/Selectors/ClientSelector';
import { YearSelector } from '@/Core/components/Selectors/YearSelector';
import { useGetDashboardInvoicesQuery } from '@/Dashboard/api/dashboard.api';
import { getDashboardSummary } from '@/Dashboard/api/dashboard.utils';
import { AreaChart } from '@/Dashboard/components/AreaChart';
import { BarChart } from '@/Dashboard/components/BarChart';
import { roundAmount } from '@/Treasuries/store/treasuries.utils';
import { toFinite } from 'lodash-es';
import React, { useState } from 'react';
import { DashboardChartHeader } from './DashboardChartHeader';
import { ChartCardWrapper, ChartWrapper, DashboardFilters } from './DashboardCharts';
import { SwitchChartButton } from './SwitchChartButton';

interface Props {
  filterByClientEnabled?: boolean;
}

const calculateTotal = (total: number, next: number) => {
  const amount = total + next;
  return roundAmount(amount);
};

const currentYearToString = new Date().getFullYear();

export const InvoicesChart: React.FC<Props> = ({ filterByClientEnabled = true }) => {
  const [invoicesFilter, setInvoicesFilter] = useState<DashboardFilters>({
    year: new Date().getFullYear(),
  });
  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isFetching: isFetchingInvoices,
  } = useGetDashboardInvoicesQuery(invoicesFilter);

  const summary = getDashboardSummary(invoices, toFinite(invoicesFilter?.year ?? currentYearToString));
  const totalInvoices = summary?.reduce((total, next) => calculateTotal(total, next.amount), 0);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedClient, setSelectedClient] = useState<Partial<Client> | null>(null);
  const [chartType, setChartType] = useState('AreaChart');
  const isLoading = isLoadingInvoices || isFetchingInvoices;

  const changeYearFilter = (year: number) => {
    setSelectedYear(year);
    setInvoicesFilter({ year, client: selectedClient?.id });
  };

  const changeClientFilter = (client: Partial<Client> | null) => {
    setSelectedClient(client);
    setInvoicesFilter({ client: client?.id, year: selectedYear });
  };

  const toggleChart = () => {
    const type = chartType === 'AreaChart' ? 'BarChart' : 'AreaChart';
    setChartType(type);
  };

  return (
    <ChartCardWrapper>
      <DashboardChartHeader amount={totalInvoices} selectedYear={selectedYear} selectedClient={selectedClient}>
        <Flex align="center" justify="flex-end" direction="row" width="100%">
          <HideOnMobile>
            {filterByClientEnabled ? (
              <Flex width="260px" mr={2}>
                <ClientSelector placeholder="На Клиент" onClientSelected={changeClientFilter} />
              </Flex>
            ) : null}
          </HideOnMobile>
          <Flex width="120px">
            <YearSelector onYearSelected={changeYearFilter} />
          </Flex>
        </Flex>
      </DashboardChartHeader>
      <SwitchChartButton onClick={toggleChart} type={chartType === 'AreaChart' ? 'BarChart' : 'AreaChart'} />
      <ChartWrapper>
        {isLoading ? (
          <DashboardChartLoader />
        ) : chartType === 'AreaChart' ? (
          <AreaChart name="invoices" data={summary} color="#0084C7" />
        ) : (
          <BarChart data={summary} color="#0084C7" />
        )}
      </ChartWrapper>
    </ChartCardWrapper>
  );
};

InvoicesChart.displayName = 'InvoicesChart';
