import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import * as React from 'react';
import { useGetAllCashPaymentsQuery } from '../api/payments.api';
import { CashPaymentCreatorLoader } from './CashPaymentCreatorLoader';
import { CashPaymentPageLoader } from './CashPaymentLoader';
import { CashPaymentsMasterView } from './CashPaymentsMasterView';

export const CashPayments: React.FC = () => {
  const { isLoading } = useGetAllCashPaymentsQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.CashPayment}
      masterView={<CashPaymentsMasterView />}
      detailView={<CashPaymentPageLoader />}
      creatorView={<CashPaymentCreatorLoader />}
    />
  );
};
CashPayments.displayName = 'CashPaymentsMobile';
export default CashPayments;
