import { api, TagType } from '@/Core/http/api';
import { ApiEndpoints } from '@/Core/http/api-endpoints';
import { ExpenseCategory } from '@/ExpenseCategories/api/expenseCategories.types';

const { ExpenseCategoriesUrl } = ApiEndpoints;

export const expenseCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllExpenseCategories: build.query<ExpenseCategory[], void>({
      query: () => ({ url: ExpenseCategoriesUrl, method: 'GET' }),
      providesTags: () => [TagType.ExpenseCategories],
    }),
  }),
});

export const { useGetAllExpenseCategoriesQuery } = expenseCategoryApi;
