import {
  Badge,
  Box,
  ButtonGroup,
  IconButton,
  Skeleton,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@/core-components';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { Feature, useShouldEnableFeature } from '@/Core/utils/shouldEnableFeature';
import { getTranslatedTreasuryType } from '@/Core/utils/translatableTypes';
import { createSuccessToast } from '@/Toaster/store/toaster.utils';
import {
  useDestroyCashPaymentMutation,
  useDestroyClientMutation,
  useDestroyEstimateMutation,
  useDestroyExpenseMutation,
  useDestroyInvoiceMutation,
  useDestroyProductMutation,
  useDestroyProjectMutation,
  useGetAllTrashQuery,
  useRestoreCashPaymentMutation,
  useRestoreClientMutation,
  useRestoreEstimateMutation,
  useRestoreExpenseMutation,
  useRestoreInvoiceMutation,
  useRestoreProductMutation,
  useRestoreProjectMutation,
} from '@/Trash/api/trash.api';
import { formatMoney, getTreasuryTotalAmount } from '@/Treasuries/store/treasuries.utils';
import { faCircleInfo, faTrashCanUndo, faTrashCanXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import './Trash.scss';

interface RowIconProps {
  icon: React.ReactElement;
  color?: string;
  ml?: string | number;
  colorScheme: string;
  onClick: () => void;
}

const TAB_FONT_SIZE = '13px';
export const RowIcon = React.forwardRef<HTMLButtonElement, RowIconProps>(
  ({ icon, color = 'gray.600', onClick, ml = 2, colorScheme, ...rest }, ref) => (
    <IconButton
      ref={ref}
      size="sm"
      ml={ml}
      colorScheme={colorScheme}
      variant="outline"
      icon={icon}
      color={color}
      aria-label="icon"
      onClick={onClick}
      {...rest}
    />
  ),
);

interface RowActionsProps {
  onRestore: () => void;
  onDelete: () => void;
}

export const RowActions: React.FC<RowActionsProps> = ({ onRestore, onDelete }) => (
  <Box display="flex" justifyContent="center" role="group">
    <ButtonGroup spacing={3}>
      <Tooltip hasArrow aria-label="Врати назад" label="Врати назад" placement="top" bg="gray.800" color="white">
        <RowIcon
          icon={<FontAwesomeIcon icon={faTrashCanUndo} size="lg" />}
          colorScheme="gray"
          color="gray.700"
          onClick={onRestore}
        />
      </Tooltip>
      <Tooltip hasArrow aria-label="Избриши трајно" label="Избриши трајно" placement="top" bg="red.500" color="white">
        <RowIcon
          icon={<FontAwesomeIcon icon={faTrashCanXmark} size="lg" />}
          ml="2"
          color="red.500"
          colorScheme="red"
          onClick={onDelete}
        />
      </Tooltip>
    </ButtonGroup>
  </Box>
);

const TrashTable: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <TableContainer>
    <Table size="sm" variant="simple">
      {children}
    </Table>
  </TableContainer>
);

const TrashTableHeader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Thead>
    <Tr>{children}</Tr>
  </Thead>
);
const TrashTableBody: React.FC<
  React.PropsWithChildren<{ isLoading: boolean; loadingRows?: number; loadingSpan?: number }>
> = ({ children, isLoading, loadingRows = 4, loadingSpan = 5 }) => (
  <Tbody>
    {isLoading
      ? Array.from(Array(loadingRows || 4).keys()).map((_, index) => (
          <Tr key={index}>
            <Td colSpan={loadingSpan}>
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="33px" />
            </Td>
          </Tr>
        ))
      : children}
  </Tbody>
);

export const CompanyTrash: React.FC = () => {
  const toast = useToast();
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);
  const { data: trashables, isLoading, isFetching, refetch } = useGetAllTrashQuery();
  const [restoreInvoice] = useRestoreInvoiceMutation();
  const [deleteInvoice] = useDestroyInvoiceMutation();

  const [restoreEstimate] = useRestoreEstimateMutation();
  const [deleteEstimate] = useDestroyEstimateMutation();

  const [restoreExpense] = useRestoreExpenseMutation();
  const [deleteExpense] = useDestroyExpenseMutation();

  const [restoreCashPayment] = useRestoreCashPaymentMutation();
  const [deleteCashPayment] = useDestroyCashPaymentMutation();

  const [restoreClient] = useRestoreClientMutation();
  const [deleteClient] = useDestroyClientMutation();

  const [restoreProject] = useRestoreProjectMutation();
  const [deleteProject] = useDestroyProjectMutation();

  const [restoreProduct] = useRestoreProductMutation();
  const [deleteProduct] = useDestroyProductMutation();

  const onMutationSuccess = (description: string) => {
    refetch();
    toast(createSuccessToast({ position: 'top', description }));
  };

  const { invoices, estimates, clients, payments, expenses, products, projects } = trashables || {
    invoices: [],
    estimates: [],
    clients: [],
    payments: [],
    expenses: [],
    products: [],
    projects: [],
  };

  return (
    <Box>
      <Box mb={4} display="flex" alignItems="center">
        <Box display="inline-flex" fontSize="xl" mr={2} color="sky.400">
          <FontAwesomeIcon icon={faCircleInfo} />
        </Box>
        <Box>
          <Text mb={0}>Од корпата можете да ги вратите назад документите или да ги избришете засекогаш.</Text>
          <Text color="red.500" fontSize="sm">
            Имајте во предвид доколку ги избришете документите од корпата истите не може да се вратат назад!
          </Text>
        </Box>
      </Box>
      <Tabs align="center" isFitted colorScheme="black">
        <TabList>
          <Tab>
            <Text fontSize={TAB_FONT_SIZE}>Фактури</Text>
            <Badge variant="subtle" ml="1">
              {invoices?.length}{' '}
            </Badge>
          </Tab>
          <Tab>
            <Text fontSize={TAB_FONT_SIZE}>Пресметки</Text>
            <Badge variant="subtle" ml="1">
              {estimates?.length}{' '}
            </Badge>
          </Tab>
          {shouldEnablePayments ? (
            <Tab>
              <Text fontSize={TAB_FONT_SIZE}>Каса Прими</Text>
              <Badge variant="subtle" ml="1">
                {' '}
                {payments?.length}{' '}
              </Badge>
            </Tab>
          ) : null}
          <Tab>
            <Text fontSize={TAB_FONT_SIZE}>Расходи</Text>
            <Badge variant="subtle" ml="1">
              {' '}
              {expenses?.length}{' '}
            </Badge>
          </Tab>
          <Tab>
            <Text fontSize={TAB_FONT_SIZE}>Клиенти</Text>
            <Badge variant="subtle" ml="1">
              {' '}
              {clients?.length}{' '}
            </Badge>
          </Tab>
          <Tab>
            <Text fontSize={TAB_FONT_SIZE}>Проекти</Text>
            <Badge variant="subtle" ml="1">
              {' '}
              {projects?.length}{' '}
            </Badge>
          </Tab>
          <Tab>
            <Text fontSize={TAB_FONT_SIZE}>Производи</Text>
            <Badge variant="subtle" ml="1">
              {' '}
              {products?.length}{' '}
            </Badge>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col">Бр.</Th>
                <Th scope="col">Клиент</Th>
                <Th scope="col">Износ со ДДВ</Th>
                <Th scope="col">Тип</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={invoices?.length} loadingSpan={5}>
                {invoices?.map((invoice) => (
                  <Tr key={invoice.id}>
                    <Td>
                      {invoice.invoiceNumber} <br />
                      <Text color="gray.500" fontSize="sm">
                        {invoice.invoicedDate ? toFormattedDate(invoice.invoicedDate) : null}
                      </Text>
                    </Td>
                    <Td>{invoice?.client?.fullName}</Td>
                    <Td>{formatMoney(getTreasuryTotalAmount(invoice), invoice.currency)}</Td>
                    <Td>{getTranslatedTreasuryType(invoice.type)}</Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteInvoice(invoice);
                          if ('data' in result) {
                            onMutationSuccess('Фактурата е избришана.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreInvoice(invoice);
                          if ('data' in result) {
                            onMutationSuccess('Фактурата е вратена назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>

          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col">Бр.</Th>
                <Th scope="col">Клиент</Th>
                <Th scope="col">Износ со ДДВ</Th>
                <Th scope="col">Тип</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={estimates?.length}>
                {estimates?.map((estimate) => (
                  <Tr key={estimate.id}>
                    <Td>
                      {estimate.estimateNumber} <br />
                      <Text color="gray.500" fontSize="sm">
                        {toFormattedDate(estimate.estimatedAt)}
                      </Text>
                    </Td>
                    <Td>{estimate?.client?.fullName}</Td>
                    <Td>{formatMoney(getTreasuryTotalAmount(estimate), estimate.currency)}</Td>
                    <Td>{getTranslatedTreasuryType(estimate.type)}</Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteEstimate(estimate);
                          if ('data' in result) {
                            onMutationSuccess('Пресметката е избришана.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreEstimate(estimate);
                          if ('data' in result) {
                            onMutationSuccess('Пресметката е вратена назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>

          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col">Бр.</Th>
                <Th scope="col">Цел на уплата</Th>
                <Th scope="col">Клиент</Th>
                <Th scope="col">Вкупен износ</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={payments?.length}>
                {payments?.map((payment) => (
                  <Tr key={payment.id}>
                    <Td>
                      {payment.referenceNumber} <br />
                      <Text color="gray.500" fontSize="sm">
                        {payment.paymentDate && toFormattedDate(payment.paymentDate)}
                      </Text>
                    </Td>
                    <Td>{payment.paymentFor}</Td>
                    <Td>{payment?.client?.fullName}</Td>
                    <Td>{formatMoney(parseFloat(payment.amount), payment.currency)}</Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteCashPayment(payment);
                          if ('data' in result) {
                            onMutationSuccess('Сметката е избришана.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreCashPayment(payment);
                          if ('data' in result) {
                            onMutationSuccess('Сметката е вратена назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>

          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col">Бр.</Th>
                <Th scope="col">Опис</Th>
                <Th scope="col">Клиент</Th>
                <Th scope="col">Вкупен износ</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={expenses?.length}>
                {expenses?.map((expense) => (
                  <Tr key={expense.id}>
                    <Td>
                      <Text> {expense.expenseNumber} </Text>
                      <Text color="gray.500" fontSize="sm">
                        {expense.expenseDate ? toFormattedDate(expense.expenseDate) : null}
                      </Text>
                    </Td>
                    <Td>{expense.expenseFor}</Td>
                    <Td>{expense?.client?.fullName}</Td>
                    <Td>
                      <Text>{formatMoney(parseFloat(expense.amount), expense.currency)}</Text>
                      <Text pl={1} color="gray.500" fontSize="xs">
                        {expense.category ? expense.category?.name : null}
                      </Text>
                    </Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteExpense(expense);
                          if ('data' in result) {
                            onMutationSuccess('Сметката е избришанa.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreExpense(expense);
                          if ('data' in result) {
                            onMutationSuccess('Сметката е вратена назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>

          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col">Име Презиме</Th>
                <Th scope="col">Емаил</Th>
                <Th scope="col">Тип</Th>
                <Th scope="col">Компанија</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={clients?.length}>
                {clients?.map((client) => (
                  <Tr key={client.id}>
                    <Td>{client.fullName}</Td>
                    <Td>{client.contactEmail}</Td>
                    <Td>{client.type === 'Individual' ? 'Физичко Лице' : 'Компанија'}</Td>
                    <Td>{client.businessName}</Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteClient(client);
                          if ('data' in result) {
                            onMutationSuccess('Клиентот е избришан.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreClient(client);
                          if ('data' in result) {
                            onMutationSuccess('Клиентот е вратен назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>

          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col">Бр. на проект</Th>
                <Th scope="col">Име на проект</Th>
                <Th scope="col">Клиент</Th>
                <Th scope="col">Опис</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={projects?.length}>
                {projects?.map((project) => (
                  <Tr key={project.id}>
                    <Td>{project.code}</Td>
                    <Td>{project.name}</Td>
                    <Td>{project?.client?.fullName}</Td>
                    <Td>{project.description}</Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteProject(project);
                          if ('data' in result) {
                            onMutationSuccess('Проектот е избришан.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreProject(project);
                          if ('data' in result) {
                            onMutationSuccess('Проектот е вратен назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>

          <TabPanel>
            <TrashTable>
              <TrashTableHeader>
                <Th scope="col" style={{ width: '260px' }}>
                  Име на производ
                </Th>
                <Th scope="col">Тип</Th>
                <Th scope="col">Мерна единица</Th>
                <Th scope="col">Цена на единица</Th>
                <Th scope="col" style={{ width: '180px' }}></Th>
              </TrashTableHeader>
              <TrashTableBody isLoading={isLoading || isFetching} loadingRows={products?.length} loadingSpan={6}>
                {products?.map((product) => (
                  <Tr key={product.id}>
                    <Td>
                      <Text noOfLines={1}>{product.name}</Text>
                    </Td>
                    <Td>{product.type === 'Product' ? 'Производ' : 'Услуга'}</Td>
                    <Td>{product.unit}</Td>
                    <Td>{formatMoney(product.unitPrice, product.currency)}</Td>
                    <Td>
                      <RowActions
                        onDelete={async () => {
                          const result = await deleteProduct(product);
                          if ('data' in result) {
                            onMutationSuccess('Производот е избришан.');
                          }
                        }}
                        onRestore={async () => {
                          const result = await restoreProduct(product);
                          if ('data' in result) {
                            onMutationSuccess('Производот е вратен назад.');
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </TrashTableBody>
            </TrashTable>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
CompanyTrash.displayName = 'CompanyTrash';
// exporting as default for lazy load
export default CompanyTrash;
