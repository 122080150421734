import { Badge, Box, ButtonGroup, Text, Tooltip } from '@/core-components';
import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge.tsx';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton.tsx';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction.tsx';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { highlightTerm } from '@/Core/utils/highlighter';
import { Invoice } from '@/Invoices/api/invoices.types';
import { isOverdue, isPaid, isPartiallyPaid, isPending } from '@/Invoices/api/invoices.utils';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import { faCopy, faPenToSquare, faSquareCheck, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useMatch } from 'react-router-dom';

interface ActionsProps {
  invoice: Invoice;
  isEditing: boolean;
  isSaving: boolean;
  disabled?: boolean;
  onEdit?: (invoice: Invoice) => Invoice | unknown;
  onDelete?: (invoice: Invoice) => Invoice | unknown;
  onPay?: (invoice: Invoice) => Invoice | unknown;
  onCopy?: (invoice: Invoice) => Invoice | unknown;
}

const Actions: React.FC<ActionsProps> = ({ invoice, isEditing, onEdit, onDelete, onPay, onCopy, disabled }) => (
  <ButtonGroup spacing={3}>
    {!isEditing ? (
      isMobile ? null : (
        <ActionButton
          icon={<FontAwesomeIcon icon={faSquareCheck} />}
          label={isPaid(invoice) ? 'Платенa' : 'Наплати'}
          onClick={() => onPay?.(invoice)}
          disabled={disabled}
        />
      )
    ) : null}
    <ActionButton
      isEditing={isEditing}
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(invoice)}
      disabled={disabled}
    />
    <ActionButton
      icon={<FontAwesomeIcon icon={faCopy} />}
      label="Копирај"
      onClick={() => onCopy?.(invoice)}
      disabled={disabled}
    />
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label="Додади во корпа"
      tooltipBg="red.600"
      onClick={() => onDelete?.(invoice)}
      disabled={disabled}
    />
  </ButtonGroup>
);

const PaymentStatus: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const status = React.useMemo(() => {
    try {
      if (isPaid(invoice)) {
        return { label: 'Платенa', color: 'green' };
      }
      if (isPending(invoice)) {
        return { label: 'Во тек', color: 'gray' };
      }
      if (isPartiallyPaid(invoice)) {
        return { label: 'Делумно платенa', color: 'gray' };
      }
      if (isOverdue(invoice)) {
        return { label: 'Неплатена', color: 'red' };
      }
      return { label: '', color: '' };
    } catch {
      return { label: '', color: '' };
    }
  }, [invoice]) as { label: string; color: string };

  return (
    <Box display="flex" width="100%" marginBottom={0} justifyContent="flex-end">
      <Tooltip label={isOverdue(invoice) ? `Краен рок: ${toFormattedDate(invoice.dueDate)}` : null} placement="top">
        <Badge textTransform="capitalize" colorScheme={status.color}>
          {status.label}
        </Badge>
      </Tooltip>
    </Box>
  );
};

interface Props {
  invoice: Invoice;
  isLoading: boolean;
  style?: React.CSSProperties;
  forwardRef?: React.Ref<HTMLDivElement>;
  onEdit?: (invoice: Invoice) => Invoice | unknown;
  onDelete?: (invoice: Invoice) => Invoice | unknown;
  onPay?: (invoice: Invoice) => Invoice | unknown;
  onCopy?: (invoice: Invoice) => Invoice | unknown;
}

export const InvoiceItem: React.FC<Props> = ({
  invoice,
  onEdit,
  onDelete,
  onPay,
  onCopy,
  isLoading,
  style,
  forwardRef,
  ...rest
}) => {
  const match = useMatch('/invoices/:id/edit');
  const isEditing = match != null && invoice.id === match?.params?.id;
  const { query } = useSearchQuery();

  const shouldDisableActions = React.useMemo(() => {
    return isEditing ?? isLoading;
  }, [isEditing, isLoading]);

  const { invoiceNumber, invoicedDate, client, currency } = invoice;
  const highlightedNumber = query ? highlightTerm(invoiceNumber, query) : invoiceNumber;
  const highlightedClientFullName = query ? highlightTerm(client?.fullName, query) : client?.fullName;
  const highlightedClientBusinessName = query ? highlightTerm(client?.businessName, query) : client?.businessName;

  return (
    <ListItemAction
      forwardRef={forwardRef}
      id={invoice.id}
      item={{ entity: invoice, path: 'invoices' }}
      disabled={shouldDisableActions}
      style={style}
      {...rest}
      actions={
        <Actions
          isEditing={isEditing}
          isSaving={isLoading}
          disabled={shouldDisableActions}
          invoice={invoice}
          onCopy={onCopy}
          onEdit={() => {
            onEdit?.(invoice);
          }}
          onDelete={onDelete}
          onPay={onPay}
        />
      }
    >
      <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

      <Box display="flex" width="100%" marginBottom={1} justifyContent="space-between">
        <Text
          fontSize="sm"
          color="gray.600"
          dangerouslySetInnerHTML={{ __html: highlightedNumber ?? `${invoiceNumber ?? ''}` }}
        />
        <Text fontSize="sm" color="gray.600">
          {invoicedDate ? toFormattedDate(invoicedDate) : null}
        </Text>
      </Box>

      <Box display="flex" width="100%" marginTop={2} marginBottom={1} flexDirection="column" minHeight="40px">
        <Text
          marginBottom={0}
          dangerouslySetInnerHTML={{ __html: highlightedClientBusinessName ?? `${client?.businessName ?? ''}` }}
        />
        <Text
          fontWeight="light"
          dangerouslySetInnerHTML={{ __html: highlightedClientFullName ?? `${client?.fullName ?? ''}` }}
        />
      </Box>

      <PaymentStatus invoice={invoice} />

      <Box display="flex" width="100%" marginBottom={1} justifyContent="flex-end">
        <Text fontSize="md" fontWeight="bold">
          {formatMoney(invoice.totalAmount ?? 0, currency)}
        </Text>
      </Box>
    </ListItemAction>
  );
};

InvoiceItem.displayName = 'InvoiceItem';
