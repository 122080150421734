import { useAppSelector } from '@/Core/AppStore';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage.tsx';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { slugify } from '@/Core/utils/slugify';
import { ExpensesChart } from '@/Dashboard/components/ExpensesChart.tsx';
import {
  saveToPDF,
  useCreateExpenseMutation,
  useDeleteExpenseMutation,
  useGetAllExpensesQuery,
} from '@/Expenses/api/expenses.api';
import { makeExpense } from '@/Expenses/api/expenses.model';
import { Expense } from '@/Expenses/api/expenses.types';
import { notifyExpenseDeleted } from '@/Expenses/api/expenses.utils';
import { ExpensesTable } from '@/Expenses/components/ExpensesTable';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { Button, useToast } from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const Expenses: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data } = useGetAllExpensesQuery();
  const expenses = React.useMemo(() => getSortedEntityItems<Expense>(data), [data]);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [createExpense, { isLoading: isCreating }] = useCreateExpenseMutation();
  const [deleteExpense] = useDeleteExpenseMutation();

  const [processingExpense, setProcessingExpense] = React.useState<Expense | null>(null);

  const onCreateExpense = async () => {
    captureEvent('create_expense');
    const expense = makeExpense();
    const result = await createExpense(expense);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/expenses/${result.data.id}/edit`);
  };

  const onDeleteExpense = React.useCallback(
    async (expense: Expense) => {
      captureEvent('delete_expense');
      setProcessingExpense(expense);

      try {
        const result = await deleteExpense(expense.id);
        notifyExpenseDeleted(expense, result);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingExpense(null);
      }
    },
    [deleteExpense],
  );

  const onDownloadPDF = async (expense: Expense) => {
    if (!expense) {
      return;
    }
    captureEvent('download_expense_pdf');
    setProcessingExpense(expense);

    try {
      const result = await saveToPDF(expense);
      const id = expense.expenseNumber || expense.id;
      const file = new Blob([result.data], { type: 'application/pdf' });
      saveAs(file, `presmetka-${slugify(id)}.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessingExpense(null);
    }
  };

  return (
    <GridPage
      title="Расходи"
      actions={
        <Button
          onClick={onCreateExpense}
          isLoading={isCreating}
          colorScheme={colorScheme}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Нова Сметка
        </Button>
      }
    >
      <GridCard title="">
        <ExpensesTable
          expenses={expenses}
          processingExpense={processingExpense}
          onDelete={onDeleteExpense}
          onDownload={onDownloadPDF}
        />
      </GridCard>
      <GridCard title="">
        <ExpensesChart />
      </GridCard>
    </GridPage>
  );
};
Expenses.displayName = 'Expenses';

export default Expenses;
