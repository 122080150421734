import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { format } from 'date-fns';
import debounce from 'lodash-es/debounce';
import posthog from 'posthog-js';
import { standaloneToast as toast } from '../../Toaster/components/Toaster';
import { createErrorToast, createWarningToast } from '../../Toaster/store/toaster.utils';
import { ApiEndpoints } from './api-endpoints';

const REACT_APP_API_ENDPOINT = import.meta.env.VITE_APP_API_ENDPOINT;

const generateToast = ({
  type,
  title,
  description,
}: {
  type: 'info' | 'error' | 'warning';
  title: string;
  description: string;
}) => {
  const id = format(new Date(), 'yyyy-MM-dd-HH:mm');
  if (toast.isActive(id)) {
    return;
  }

  if (type === 'error') {
    return toast(
      createErrorToast({
        id,
        title,
        description,
      }),
    );
  }

  if (type === 'warning') {
    return toast(
      createWarningToast({
        id,
        title,
        description,
      }),
    );
  }

  // Handle 'info' type or any other type if necessary
};

const debounceForbidden = debounce(() => {
  generateToast({
    type: 'error',
    title: 'Забрането',
    description: 'Промените не се дозволени!',
  });
}, 100);

const debounceUnauthorizedToast = debounce(() => {
  generateToast({
    type: 'error',
    title: 'Неовластено',
    description: 'Одјавување од апликацијата...',
  });
}, 100);

const debounceInternalServerError = debounce(() => {
  generateToast({
    type: 'error',
    title: 'Error',
    description: '☠️⚡ Internal Server Error ⚡☠️',
  });
}, 100);

const debounceTooManyRequests = debounce(() => {
  generateToast({
    type: 'warning',
    title: 'Warning',
    description: 'Премногу барања 🔥🔥🔥',
  });
}, 100);

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  RequestEntityTooLarge = 413,
  TokenMismatch = 419,
  TooManyRequests = 429,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

const handleError = async (errorResponse: AxiosError) => {
  const { status } = errorResponse.response ?? { status: 500 };

  switch (status) {
    case StatusCode.InternalServerError: {
      debounceInternalServerError();
      break;
    }
    case StatusCode.RequestEntityTooLarge: {
      generateToast({
        type: 'error',
        title: 'Error',
        description: 'Request is insanely large ️️️☠️☠️☠️',
      });
      break;
    }
    case StatusCode.Forbidden: {
      debounceForbidden();
      logout();
      break;
    }
    case StatusCode.Unauthorized: {
      debounceUnauthorizedToast();
      logout();
      break;
    }
    case StatusCode.TokenMismatch: {
      generateToast({
        type: 'warning',
        title: 'Warning',
        description: 'Token Mismatch!',
      });
      redirect();
      break;
    }
    case StatusCode.TooManyRequests: {
      debounceTooManyRequests();
      break;
    }
    case StatusCode.UnprocessableEntity: {
      const { data } = errorResponse.response as AxiosResponse;
      generateToast({
        type: 'error',
        title: 'Error',
        description: Object.keys(data.errors)
          .map((prop) => data.errors[prop])
          .join('\n'),
      });
      break;
    }
    default: {
      // Handle unexpected status codes
      generateToast({
        type: 'error',
        title: 'Error',
        description: 'An unexpected error occurred',
      });
      break;
    }
  }

  return Promise.reject(errorResponse);
};

const headers: Partial<AxiosRequestConfig['headers']> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': 'true',
  'X-Requested-With': 'XMLHttpRequest',
};

const httpRequest = axios.create({
  headers,
  baseURL: `${REACT_APP_API_ENDPOINT}`,
  withCredentials: true,
  withXSRFToken: true,
});

httpRequest.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (axios.isCancel(error)) {
      // Do nothing when request is canceled due to abort controller
      return Promise.resolve({ data: null });
    }

    // @ts-expect-error error is AxiosError
    const exception = error.error || error.message || error.originalError || error.response || error;
    Sentry.captureException(exception);

    return await handleError(error);
  },
);

const request = async <T = unknown, R = AxiosResponse<T>, D = unknown>(config: AxiosRequestConfig<D>): Promise<R> => {
  return httpRequest.request({
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const get = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  return httpRequest.get<T, R>(url, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const post = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  return httpRequest.post<T, R>(url, data, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const put = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  return httpRequest.put<T, R>(url, data, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const del = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  return httpRequest.delete<T, R>(url, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const redirect = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.replace('/login');
};

const logout = async () => {
  try {
    posthog.reset();
    await httpRequest.post(ApiEndpoints.LogoutUrl);
    return redirect();
  } catch {
    return redirect();
  }
};

export const http = {
  request,
  get,
  post,
  put,
  delete: del,
};
