import { Box, IconButton } from '@/core-components';
import { useIsOnMobile } from '@/Core/hooks/useIsOnMobile';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ToolbarProps {
  position?: 'top' | 'bottom';
  disabled?: boolean;
  usedInCreator?: boolean;
  onGoBack?: () => void;
}

export const Toolbar: React.FC<React.PropsWithChildren<ToolbarProps>> = ({
  position = 'top',
  usedInCreator = false,
  disabled,
  children,
  onGoBack,
}) => {
  const isOnMobile = useIsOnMobile();
  const isOnTop = position === 'top';

  return (
    <Box
      position={isOnMobile ? 'fixed' : 'relative'}
      display="flex"
      alignItems="center"
      bg="white"
      width="100%"
      height={isOnMobile && position === 'bottom' ? '64px' : '48px'}
      zIndex={10}
      transition="all 0.1s ease-in-out"
      opacity={disabled ? 0.7 : 1}
      pointerEvents={disabled ? 'none' : 'auto'}
      boxShadow={position === 'bottom' ? '0 -3px 3px -2px rgba(0, 0, 0, 0.085)' : '0 2px 3px 0 rgba(0, 0, 0, 0.085)'}
      top={isOnMobile && isOnTop ? '49px' : position === 'top' ? 0 : 'auto'}
      bottom={position === 'bottom' ? 0 : 'auto'}
      px={3}
      py={1.5}
      {...(isOnMobile && position === 'bottom' && { pb: 4 })}
    >
      {isOnMobile ? (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={2}>
          <Box display="flex" alignItems="center">
            {isOnTop ? (
              <IconButton
                onClick={onGoBack}
                color="gray.600"
                variant="ghost"
                size="sm"
                aria-label="Go Back"
                fontSize="20px"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
              />
            ) : null}
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            {children}
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={usedInCreator ? 'flex-end' : 'space-between'}
          width="100%"
          gap={3}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
