import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { notifyEstimateConvertedToInvoice } from '@/Invoices/api/invoices.utils';
import { lazyRetry } from '@/lazyRetry';
import { captureEvent } from '@/setupPostHog';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import {
  useConvertEstimateToInvoiceMutation,
  useDeleteEstimateMutation,
  useGetEstimateQuery,
  useReplicateEstimateMutation,
} from '../api/estimates.api';
import { Estimate } from '../api/estimates.types';
import { notifyEstimateCopied, notifyEstimateDeleted } from '../api/estimates.utils';

const EstimateMobilePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EstimateMobilePage" */ './EstimateMobilePage'), 'EstimateMobilePage'),
);
const EstimatePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "EstimatePage" */ './EstimatePage'), 'EstimatePage'),
);

const useEstimateData = () => {
  const { id } = useParams<{ id: string }>();
  const { data: estimate, ...rest } = useGetEstimateQuery(id!);
  return [estimate, rest] as const;
};

export const EstimatePageLoader: React.FC = () => {
  const navigate = useNavigate();
  const [estimate, { isLoading }] = useEstimateData();
  const [deleteEstimate, { isLoading: isDeleting }] = useDeleteEstimateMutation();
  const [copyEstimate, { isLoading: isCopying }] = useReplicateEstimateMutation();
  const [convertToInvoice, { isLoading: isConverting }] = useConvertEstimateToInvoiceMutation();

  const onDeleteEstimate = async (estimateToDelete: Estimate) => {
    captureEvent('delete_estimate');
    const result = await deleteEstimate(estimateToDelete.id);
    const deleted = notifyEstimateDeleted(estimateToDelete, result);

    if (deleted) {
      navigate(`/estimates`);
    }
  };

  const onCopyEstimate = async (estimateToCopy: Estimate) => {
    captureEvent('duplicate_estimate');
    const result = await copyEstimate(estimateToCopy);
    const copy = notifyEstimateCopied(estimateToCopy, result);

    if (copy) {
      navigate(`/estimates/${copy.id}/edit`);
    }
  };

  const onTransformEstimate = async (estimateToTransform: Estimate) => {
    captureEvent('transform_estimate_to_invoice');
    const result = await convertToInvoice(estimateToTransform);
    const converted = notifyEstimateConvertedToInvoice(result);

    if (converted) {
      return navigate(`/invoices/${converted.id}`);
    }
  };

  return estimate ? (
    isMobile ? (
      <EstimateMobilePage estimate={estimate} onDelete={onDeleteEstimate} />
    ) : (
      <EstimatePage
        isLoading={isLoading || isDeleting || isCopying || isConverting}
        estimate={estimate}
        onDelete={onDeleteEstimate}
        onCopy={onCopyEstimate}
        onTransform={onTransformEstimate}
      />
    )
  ) : (
    <DocumentNotFound />
  );
};
