import { Badge } from '@/core-components';
import * as React from 'react';
import { DraftableStatus } from '../../core.types';
interface Props {
  status: DraftableStatus;
}
export const DraftItemBadge: React.FC<Props> = ({ status }) => {
  const isDraftDocument = status === DraftableStatus.Draft;
  return isDraftDocument ? (
    <div className="d-flex w-100 justify-content-end">
      <Badge textTransform="none" fontSize="10px" variant="subtle">
        Драфт
      </Badge>
    </div>
  ) : null;
};
DraftItemBadge.displayName = 'DraftItemBadge';
