import { useAppSelector } from '@/Core/AppStore';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { Button, useToast } from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateClientMutation, useDeleteClientMutation, useGetAllClientsQuery } from '../api/clients.api';
import { makeClient } from '../api/clients.model';
import { Client } from '../api/clients.types';
import { notifyClientDeleted } from '../api/clients.utils';
import { ClientsTable } from './ClientsTable';

export const Clients: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const { data } = useGetAllClientsQuery();
  const clients = React.useMemo(() => getSortedEntityItems<Client>(data), [data]);

  const [processingClient, setProcessingClient] = React.useState<Client | null>(null);

  const [createClient, { isLoading: isCreating }] = useCreateClientMutation();
  const [deleteClient] = useDeleteClientMutation();

  const onCreateClient = async () => {
    captureEvent('create_client');
    const client = makeClient();
    const result = await createClient(client);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/clients/${result.data.id}/edit`);
  };

  const onDeleteClient = React.useCallback(
    async (client: Client) => {
      captureEvent('delete_client');
      setProcessingClient(client);

      try {
        const result = await deleteClient(client.id);
        notifyClientDeleted(client, result);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingClient(null);
      }
    },
    [deleteClient],
  );

  return (
    <GridPage
      title="Клиенти"
      actions={
        <Button
          onClick={onCreateClient}
          isLoading={isCreating}
          colorScheme={colorScheme}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Нов Клиент
        </Button>
      }
    >
      <GridCard title="">
        <ClientsTable clients={clients} processingClient={processingClient} onDelete={onDeleteClient} />
      </GridCard>
    </GridPage>
  );
};
Clients.displayName = 'Clients';

export default Clients;
