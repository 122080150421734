import { Badge, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@/core-components';
import { faEllipsisStroke, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { getTranslatedProductType } from '../../Core/utils/translatableTypes';
import { formatMoney } from '../../Treasuries/store/treasuries.utils';
import { Product, ProductType } from '../api/products.types';

const columnHelper = createColumnHelper<Product>();

interface Props {
  onDelete: (product: Product) => void;
}

export const useProductsTableColumns = ({ onDelete }: Props) => {
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('skuCode', {
        header: () => 'Код',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),
      columnHelper.accessor('name', {
        header: () => 'Име на производ',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),
      columnHelper.accessor('unitPrice', {
        header: () => 'Цена на единица',
        cell: (info) => {
          return <Text>{formatMoney(info.getValue(), info.row.original.currency)}</Text>;
        },
      }),
      columnHelper.accessor('unit', {
        header: () => 'Мерна единица',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),
      columnHelper.accessor('type', {
        header: () => 'Тип',
        cell: (info) => {
          const type = getTranslatedProductType(info.getValue());
          const color = info.getValue() === ProductType.Product ? 'sky' : 'green';

          return (
            <Badge textTransform="capitalize" colorScheme={color}>
              {type}
            </Badge>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: () => '',
        cell: (info) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
              variant="ghost"
              size="sm"
              fontSize={18}
              onClick={(e) => e.stopPropagation()}
            />
            <MenuList>
              {/* <MenuDivider /> */}
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (info.row.original) {
                    onDelete(info.row.original);
                  }
                }}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
              >
                Додади во корпа
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      }),
    ],
    [onDelete],
  );

  return columns;
};
