import { Box } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/estimates.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useFilterQuery, useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import {
  useConvertEstimateToInvoiceMutation,
  useDeleteEstimateMutation,
  useGetAllEstimatesQuery,
  useGetEstimateQuery,
  useReplicateEstimateMutation,
  useUpdateEstimateMutation,
} from '@/Estimates/api/estimates.api';
import { Estimate } from '@/Estimates/api/estimates.types';
import { notifyEstimateCopied, notifyEstimateDeleted, notifyEstimateUpdated } from '@/Estimates/api/estimates.utils';
import { EstimateItem } from '@/Estimates/components/EstimateItem';
import { notifyEstimateConvertedToInvoice } from '@/Invoices/api/invoices.utils';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';
import { useMatch, useNavigate } from 'react-router-dom';

const EmptyText: React.FC = () => {
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      Клик на
      <Box
        borderRadius="sm"
        mx={1}
        backgroundColor={colorSchemeValue}
        color="white"
        boxSize="20px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Box>{' '}
      да креирате нова пресметка.
    </Box>
  );
};

interface Props {
  onSuccessfulDelete: (estimate: Estimate) => void;
}

export const EstimatesList: React.FC<React.PropsWithChildren<Props>> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { query, isSearching } = useSearchQuery();
  const { isFiltering } = useFilterQuery();
  const match = useMatch('/estimates/:id/edit');
  const { data, isLoading, isFetching } = useGetAllEstimatesQuery();
  const estimates = getSortedEntityItems<Estimate>(data);
  const { data: selectedEstimate } = useGetEstimateQuery(id!, { skip: !isFiltering });

  const [deleteEstimate, { isLoading: isDeleting }] = useDeleteEstimateMutation();
  const [updateEstimate, { isLoading: isUpdating }] = useUpdateEstimateMutation();
  const [copyEstimate, { isLoading: isCopying }] = useReplicateEstimateMutation();
  const [convertToInvoice, { isLoading: isConverting }] = useConvertEstimateToInvoiceMutation();

  const shouldDisableActions = React.useMemo(
    () => isLoading || isFetching || isDeleting || isUpdating || isCopying || isConverting,
    [isLoading, isFetching, isDeleting, isUpdating, isCopying, isConverting],
  );

  const items = React.useMemo(() => {
    if (!isSearching) {
      return estimates;
    }

    return estimates
      .filter((estimate) => {
        return isFiltering ? estimate?.client?.id === selectedEstimate?.client?.id : estimate;
      })
      .filter((estimate) => {
        const foundNumber = estimate?.estimateNumber?.includes(query);
        const foundClient =
          estimate?.client?.fullName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase()) ||
          estimate?.client?.businessName?.toLocaleLowerCase().includes(query?.toLocaleLowerCase());

        return foundNumber || foundClient;
      });
  }, [estimates, query, isSearching, isFiltering, selectedEstimate]);

  return (
    <>
      <MasterList isLoading={isLoading || isFetching} data-test-id="estimates-master-list">
        {isEmpty(items) ? (
          <EmptyMasterViewList
            isSearching={isSearching}
            title={isSearching ? `Немате пресметки со вашето пребарување "${query}"` : 'Немате Пресметки'}
            text={isSearching ? null : <EmptyText />}
          >
            <EmptyState />
          </EmptyMasterViewList>
        ) : (
          <AnimatePresence initial={false}>
            {items.map((estimate) =>
              estimate ? (
                <EstimateItem
                  key={estimate.id}
                  isLoading={shouldDisableActions}
                  estimate={estimate}
                  onSave={async (estimate) => {
                    const result = await updateEstimate(estimate);
                    const saved = notifyEstimateUpdated(estimate, result);
                    if (saved) {
                      if (isMobile) {
                        return navigate('/estimates');
                      }
                      return navigate(`/estimates/${saved.id}/`);
                    }
                  }}
                  onEdit={(estimate) => {
                    const isEditing = match != null && estimate.id === match?.params?.id;
                    navigate(isEditing ? `/estimates/${estimate.id}/` : `/estimates/${estimate.id}/edit`);
                  }}
                  onDelete={async (estimate) => {
                    const result = await deleteEstimate(estimate.id);
                    const deleted = notifyEstimateDeleted(estimate, result);
                    if (deleted) {
                      onSuccessfulDelete(estimate);
                    }
                  }}
                  onCopy={async (estimate) => {
                    const result = await copyEstimate(estimate);
                    const copy = notifyEstimateCopied(estimate, result);
                    if (copy) {
                      return navigate(`/estimates/${copy.id}/?copy=true`);
                    }
                  }}
                  onTransform={async (estimate) => {
                    const result = await convertToInvoice(estimate);
                    const converted = notifyEstimateConvertedToInvoice(result);
                    if (converted) {
                      return navigate(`/invoices/${converted.id}/?copy=true`);
                    }
                  }}
                />
              ) : null,
            )}
          </AnimatePresence>
        )}
      </MasterList>
    </>
  );
};
EstimatesList.displayName = 'EstimatesList';
