import { isClientCompany } from '@/Clients/api/clients.utils';
import {
  Badge,
  Link as ChakraLink,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@/core-components';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { getTranslatedTreasuryType } from '@/Core/utils/translatableTypes';
import { Estimate } from '@/Estimates/api/estimates.types';
import { TreasuryType } from '@/Treasuries/store/treasuries.types';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import {
  faCopy,
  faEllipsisStroke,
  faEnvelope,
  faFileExport,
  faFilePdf,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper<Estimate>();

// Extracted memoized component for the actions menu
const ActionsMenu = React.memo(
  ({
    estimate,
    openMailTreasuryModal,
    onCopy,
    onTransform,
    onDownload,
    onDelete,
    setSearchParams,
  }: {
    estimate: Estimate;
    openMailTreasuryModal: () => void;
    onCopy: (estimate: Estimate) => void;
    onTransform: (estimate: Estimate) => void;
    onDownload: (estimate: Estimate) => void;
    onDelete: (estimate: Estimate) => void;
    setSearchParams: ReturnType<typeof useSearchParams>['setSearchParams'];
  }) => {
    // Memoize handlers to prevent recreating functions on each render
    const handleCopyClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        onCopy(estimate);
      },
      [estimate, onCopy],
    );

    const handleTransformClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setSearchParams((prev) => [...prev.entries(), ['id', estimate.id], ['type', 'estimate']]);
        onTransform(estimate);
      },
      [estimate, onTransform, setSearchParams],
    );

    const handleEmailClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setSearchParams((prev) => [...prev.entries(), ['id', estimate.id], ['type', 'estimate']]);
        openMailTreasuryModal();
      },
      [estimate.id, openMailTreasuryModal, setSearchParams],
    );

    const handleDownloadClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        onDownload(estimate);
      },
      [estimate, onDownload],
    );

    const handleDeleteClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        onDelete(estimate);
      },
      [estimate, onDelete],
    );

    const handleMenuButtonClick = React.useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
    }, []);

    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
          variant="ghost"
          size="sm"
          fontSize={18}
          onClick={handleMenuButtonClick}
        />
        <MenuList>
          <MenuItem onClick={handleCopyClick} icon={<FontAwesomeIcon icon={faCopy} />}>
            Копирај
          </MenuItem>
          <MenuItem onClick={handleTransformClick} icon={<FontAwesomeIcon icon={faFileExport} />}>
            Претвори во фактура
          </MenuItem>
          <MenuItem onClick={handleEmailClick} icon={<FontAwesomeIcon icon={faEnvelope} />}>
            Испрати по емаил
          </MenuItem>
          <MenuItem onClick={handleDownloadClick} icon={<FontAwesomeIcon icon={faFilePdf} />}>
            Превземи PDF
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleDeleteClick} icon={<FontAwesomeIcon icon={faTrashCan} />}>
            Додади во корпа
          </MenuItem>
        </MenuList>
      </Menu>
    );
  },
);

// For optimization, add display name
ActionsMenu.displayName = 'EstimateActionsMenu';

// Memoized client accessor function
const getClientName = (client: Estimate['client']) => {
  if (!client) return '';
  return isClientCompany(client) ? client.businessName : client.fullName;
};

interface Props {
  openMailTreasuryModal: () => void;
  onCopy: (estimate: Estimate) => void;
  onDownload: (estimate: Estimate) => void;
  onDelete: (estimate: Estimate) => void;
  onTransform: (estimate: Estimate) => void;
}

export const useEstimatesTableColumns = ({
  openMailTreasuryModal,
  onCopy,
  onTransform,
  onDownload,
  onDelete,
}: Props) => {
  const { setSearchParams } = useSearchParams();

  return React.useMemo(
    () => [
      columnHelper.accessor('estimateNumber', {
        header: () => 'Пресметка Бр.',
        size: 15,
        cell: (info) => (
          <ChakraLink as={Link} fontWeight="semibold" to={`/estimates/${info.row.original.id}`}>
            {info.getValue()}
          </ChakraLink>
        ),
      }),
      columnHelper.accessor((row) => getClientName(row.client), {
        id: 'client',
        header: () => 'Клиент',
        size: 20,
        filterFn: 'fuzzy',
        cell: (info) => {
          const clientId = info.row.original.client?.id;
          return clientId ? (
            <ChakraLink as={Link} to={`/clients/${clientId}`} onClick={(e) => e.stopPropagation()}>
              {info.getValue()}
            </ChakraLink>
          ) : null;
        },
      }),
      columnHelper.accessor('estimatedAt', {
        header: () => 'Издадена',
        size: 10,
        cell: (info) => (info.getValue() ? toFormattedDate(info.getValue()) : null),
      }),
      columnHelper.accessor('dueDate', {
        header: () => 'Краен рок',
        size: 10,
        cell: (info) => (info.getValue() ? toFormattedDate(info.getValue()) : null),
      }),
      columnHelper.accessor('totalAmount', {
        header: () => 'Износ',
        size: 25,
        cell: (info) => (info.getValue() ? formatMoney(info.getValue(), info.row.original.currency) : null),
      }),
      columnHelper.accessor('type', {
        header: () => 'Тип',
        size: 10,
        cell: (info) => {
          const type = getTranslatedTreasuryType(info.getValue());
          const isEstimate = info.getValue() === TreasuryType.Estimate;
          const color = isEstimate ? 'sky' : 'green';

          return (
            <Badge textTransform="capitalize" colorScheme={color}>
              {type}
            </Badge>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        size: 10,
        header: () => '',
        cell: (info) => (
          <ActionsMenu
            estimate={info.row.original}
            openMailTreasuryModal={openMailTreasuryModal}
            onCopy={onCopy}
            onTransform={onTransform}
            onDownload={onDownload}
            onDelete={onDelete}
            setSearchParams={setSearchParams}
          />
        ),
      }),
    ],
    [onCopy, setSearchParams, onTransform, openMailTreasuryModal, onDownload, onDelete],
  );
};
