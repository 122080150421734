import { Avatar, Box, Flex, Image, Progress, Skeleton, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { useDropzoneAvatarUpload } from '@/Core/hooks/useDropzoneUpload';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { uploadClientAvatar } from '../api/clients.api';
import { Client, ClientType } from '../api/clients.types';

interface Props {
  client?: Client;
}
export const ClientAvatarUploader: React.FC<React.PropsWithChildren<Props>> = ({ client }) => {
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const [clientAvatar, setClientAvatar] = useState(client?.avatarUrl);

  const [isUploadingAvatar, setUploadingAvatar] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const type = useWatch({ name: 'type', defaultValue: client?.type });

  const isCompany = type === ClientType.Company;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }

      setUploadingAvatar(true);

      const formData = new FormData();
      formData.append('avatar', acceptedFiles[0]);

      const body = {
        client,
        avatar: formData,
      };

      uploadClientAvatar(body, {
        onUploadProgress: (event) => {
          if (event.total) {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(percentCompleted);
          }
        },
      })
        .then(async ({ data }) => {
          setUploadingAvatar(false);
          setUploadProgress(0);
          setClientAvatar(data.avatarUrl);
        })
        .catch(() => {
          setUploadingAvatar(false);
          setUploadProgress(0);
        });
    },
    [client],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzoneAvatarUpload({ onDrop });

  return (
    <Box
      {...getRootProps()}
      p={4}
      display="flex"
      cursor="pointer"
      borderWidth="1px"
      borderStyle="dashed"
      // minHeight="140px"
      bg={isDragReject ? 'red.500' : 'white'}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
    >
      <input {...getInputProps()} disabled={isUploadingAvatar} />
      {isUploadingAvatar ? (
        <Skeleton startColor={inputFocusBorderColor} endColor="teal.500" height="120px" width="260px" />
      ) : isCompany && clientAvatar ? (
        <Image src={clientAvatar} alt={client?.businessName} />
      ) : (
        <Avatar name={client?.fullName} size="xl" src={clientAvatar} />
      )}

      <Flex ml={6} p={4}>
        {isDragReject && <Text color="white">Датотеката не е поддржана. Само "png" и "jpg"</Text>}
        {isDragActive && !isDragReject ? (
          <Text color="sky.600">Спушти {isCompany ? 'лого' : 'аватар'} овде ...</Text>
        ) : isUploadingAvatar ? (
          <Box>
            <Text color="sky.600">Поставување {isCompany ? 'лого' : 'аватар'}. Ве молиме почекајте.</Text>
            <Progress size="xs" value={uploadProgress} />
          </Box>
        ) : (
          !isDragReject && (
            <Text color="sky.600">
              Повлечи па спушти {isCompany ? 'лого' : 'аватар'} овде или кликнете за да изберете.
            </Text>
          )
        )}
      </Flex>
    </Box>
  );
};

ClientAvatarUploader.displayName = 'ClientAvatarUploader';
