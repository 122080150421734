import { Box, ButtonGroup, Text } from '@/core-components';
import { DraftItemBadge } from '@/Core/components/Helpers/DraftItemBadge.tsx';
import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge.tsx';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton.tsx';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction.tsx';
import { useIsEditing } from '@/Core/hooks/useIsEditing';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { highlightTerm } from '@/Core/utils/highlighter';
import { Project } from '@/Projects/api/projects.types';
import { faPenToSquare, faSave, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ActionsProps {
  project: Project;
  isSaving: boolean;
  isEditing: boolean;
  onSave?: (project: Project) => Project | unknown;
  onEdit?: (project: Project) => Project | unknown;
  onDelete?: (project: Project) => Project | unknown;
}

const Actions: React.FC<ActionsProps> = ({
  isSaving = false,
  isEditing = false,
  project,
  onSave,
  onEdit,
  onDelete,
}) => (
  <ButtonGroup spacing={3}>
    <ActionButton
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      isEditing={isEditing}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(project)}
      disabled={isSaving}
    />
    {isEditing ? (
      <ActionButton
        icon={<FontAwesomeIcon icon={faSave} />}
        label={'Зачувај'}
        onClick={() => onSave?.(project)}
        disabled={isSaving}
      />
    ) : null}
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label={'Додади во корпа'}
      tooltipBg="red.600"
      onClick={() => onDelete?.(project)}
      disabled={isSaving}
    />
  </ButtonGroup>
);

interface Props {
  project: Project;
  isLoading: boolean;
  onEdit: (project: Project) => Project | unknown;
  onDelete: (project: Project) => Project | unknown;
}

export const ProjectItem: React.FC<Props> = ({ project, onEdit, isLoading, onDelete }) => {
  const { query } = useSearchQuery();
  const [isEditing] = useIsEditing({ pattern: '/projects/:id/edit', id: project.id });

  const shouldDisableActions = React.useMemo(() => {
    return isEditing ?? false;
  }, [isEditing]);

  const { startedAt, code, name, client } = project;

  const highlightedCode = query ? highlightTerm(code, query) : code;
  const highlightedName = query ? highlightTerm(name, query) : name;
  const highlightedClientFullName = query ? highlightTerm(client?.fullName, query) : client?.fullName;
  const highlightedClientBusinessName = query ? highlightTerm(client?.businessName, query) : client?.businessName;

  return (
    <ListItemAction
      id={project.id}
      item={{ entity: project, path: 'projects' }}
      disabled={isLoading || shouldDisableActions}
      actions={
        <Actions
          project={project}
          isSaving={isLoading}
          isEditing={isEditing}
          onEdit={() => {
            onEdit(project);
          }}
          onDelete={onDelete}
        />
      }
    >
      <Box display="flex" flexDirection="column" width="100%" gap={3}>
        <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Text
            fontSize="sm"
            color="gray.600"
            dangerouslySetInnerHTML={{ __html: highlightedCode ?? `${code ?? ''}` }}
          />
          <Text fontSize="sm" color="gray.600">
            {startedAt ? toFormattedDate(startedAt) : null}
          </Text>
        </Box>

        <Box display="flex" width="100%" flexDirection="column">
          <Text
            fontSize="lg"
            fontWeight="medium"
            dangerouslySetInnerHTML={{ __html: highlightedName ?? `${name ?? ''}` }}
          />
        </Box>

        <Box display="flex" width="100%" flexDirection="column">
          <Text
            fontSize="md"
            fontWeight="medium"
            dangerouslySetInnerHTML={{ __html: highlightedClientBusinessName ?? `${client?.businessName ?? ''}` }}
          />
          <Text
            fontSize="sm"
            color="gray.600"
            dangerouslySetInnerHTML={{ __html: highlightedClientFullName ?? `${client?.fullName ?? ''}` }}
          />
        </Box>

        <DraftItemBadge status={project.status} />
      </Box>
    </ListItemAction>
  );
};
ProjectItem.displayName = 'ProjectItem';
