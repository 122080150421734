import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import { useGetAllProductsQuery } from '@/Products/api/products.api';
import * as React from 'react';
import { ProductCreatorLoader } from './ProductCreatorLoader';
import { ProductPageLoader } from './ProductLoader';
import { ProductsMasterView } from './ProductsMasterView';

export const Products: React.FC = () => {
  const { isLoading } = useGetAllProductsQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.Product}
      masterView={<ProductsMasterView />}
      detailView={<ProductPageLoader />}
      creatorView={<ProductCreatorLoader />}
    />
  );
};

Products.displayName = 'ProductsMobile';

export default Products;
