import { CashPayment } from '../CashPayments/api/payments.types';
import { Client } from '../Clients/api/clients.types';
import { Estimate } from '../Estimates/api/estimates.types';
import { Expense } from '../Expenses/api/expenses.types';
import { Invoice } from '../Invoices/api/invoices.types';
import { Product } from '../Products/api/products.types';
import { Project } from '../Projects/api/projects.types';

export type TODO = any; // :)
export type ApiResponseMeta = {
  current_page?: number;
  from?: number;
  last_page?: number;
  path?: string;
  per_page?: number;
  to?: number;
  total?: number;
};

export type ApiResponseLinks = {
  first?: string;
  last?: string;
  next?: string | null;
  prev?: string | null;
};

export enum RequestStatus {
  // Use this when you need specific checking status
  Fetching = 'Fetching',
  Creating = 'Creating',
  Updating = 'Updating',
  Deleting = 'Deleting',
  Downloading = 'Downloading',
  Filtering = 'Filtering',
  // Use these in bit more generic cases
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export enum DraftableStatus {
  Draft = 'Draft',
  Created = 'Created',
}

export type ResourceEntity = Invoice | Estimate | CashPayment | Expense | Client | Project | Product;
