import * as React from 'react';
import { useSearchParams } from './useSearchParams';

export function useSearchQuery() {
  const { searchParams } = useSearchParams();

  const query = React.useMemo(() => searchParams.get('query') || '', [searchParams]);
  const isSearching = React.useMemo(() => query?.length > 0, [query]);

  return { searchParams, query, isSearching };
}

export function useFilterQuery() {
  const { searchParams } = useSearchParams();

  const filter = React.useMemo(() => searchParams.get('filter') || '', [searchParams]);
  const isFiltering = React.useMemo(() => filter?.length > 0, [filter]);

  return { searchParams, filter, isFiltering };
}
