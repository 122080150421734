import { YearSelector } from '@/Core/components/Selectors/YearSelector';
import { useGetDashboardTopClientsQuery } from '@/Dashboard/api/dashboard.api';
import { ChartHeader } from '@/Dashboard/components/DashboardTopCharts';
import { PieChart } from '@/Dashboard/components/PieChart';
import EmptyState from '@/Dashboard/components/pie-chart.svg?react';
import { Box, Flex, Text } from '@/core-components';
import isEmpty from 'lodash-es/isEmpty';
import toFinite from 'lodash-es/toFinite';
import React, { useState } from 'react';

export const TopClientsChart: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const { data: topClients } = useGetDashboardTopClientsQuery({ year: selectedYear });

  const data = React.useMemo(
    () =>
      isEmpty(topClients)
        ? []
        : topClients?.map((item: any) => ({
            value: toFinite(item.totalAmount),
            name: item.business_name ?? item.full_name,
          })),
    [topClients],
  );

  const changeYearFilter = (year: number) => {
    setSelectedYear(year);
    // onFiltersChanged?.({ year, client: selectedClient?.id });
  };

  return (
    <Box
      bg="white"
      borderRadius={6}
      boxShadow="sm"
      height="auto"
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
    >
      <ChartHeader>
        <Flex align="center" justify="space-between" direction="row" width="100%">
          <Text fontSize="lg" fontWeight="bold">
            Топ 5 клиенти
          </Text>
          <Flex width="120px">
            <YearSelector onYearSelected={changeYearFilter} />
          </Flex>
        </Flex>
      </ChartHeader>
      {!isEmpty(data) ? (
        <PieChart data={data} />
      ) : (
        <Box flex={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Text fontSize="md" width="50%" p={4}>
            Откако ќе бидат платени вашите фактури за овој период, 5 најдобрите клиенти ќе се прикажат тука.
          </Text>
          <Box width="50%" opacity="0.7">
            <EmptyState />
          </Box>
        </Box>
      )}
    </Box>
  );
};
