import {
  useGetCompanyProfileQuery,
  useUpdateCompanyLogoMutation,
  useUpdateCompanyProfileMutation,
} from '@/Company/api/company.api';
import { Company } from '@/Company/api/company.types';
import { RenderCompanyLogo } from '@/Company/components/CompanyLogo/CompanyLogo';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Progress,
  Skeleton,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { CompanyLoader } from '@/Core/components/Loaders/CompanyLoader';
import { useDropzoneLogoUpload } from '@/Core/hooks/useDropzoneUpload';
import { validate } from '@/Core/utils/validate';
import { createErrorToast, createSuccessToast } from '@/Toaster/store/toaster.utils';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash-es/isEmpty';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface FormValues {
  name?: string;
  fullName?: string;
  businessId?: string;
  businessVatId?: string;
  currency?: string;
  businessUrl?: string;
  email?: string;
  businessBankAccount?: string;
  businessPhone?: string;
  mobilePhone?: string;
  address?: string;
  logo?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

const validationSchema = validate.object().shape({
  name: validate.string(),
  fullName: validate.string(),
  businessId: validate.string(),
  businessVatId: validate.string(),
  currency: validate.string(),
  businessUrl: validate.string(),
  email: validate.string(),
  businessBankAccount: validate.string(),
  businessPhone: validate.string(),
  mobilePhone: validate.string(),
  address: validate.string(),
  logo: validate.string(),
  city: validate.string(),
  postalCode: validate.string(),
  country: validate.string(),
});
const resolver = yupResolver(validationSchema);

export const CompanyProfile: React.FC = () => {
  const toast = useToast();
  const { data: company, isLoading } = useGetCompanyProfileQuery();
  const [uploadCompanyLogo, { isLoading: isUploadingLogo }] = useUpdateCompanyLogoMutation();
  const [updateCompanyProfile, { isLoading: isSaving }] = useUpdateCompanyProfileMutation();
  const buttonColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const isLoadingCompany = isLoading;

  const form = useForm<FormValues>({
    resolver,
    defaultValues: { ...company },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = form;

  const { reset } = form;

  useEffect(() => {
    if (company) {
      reset({ ...company });
    }
  }, [reset, company]);

  const onSubmit = async (values: FormValues) => {
    await updateCompanyProfile(values as Company);
    toast(
      createSuccessToast({
        position: 'top',
        title: 'Успешно',
        description: 'Промените се зачувани.',
      }),
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }
      const logo = acceptedFiles[0];
      const formData = new FormData();
      formData.append('logo', logo);

      // setUploadingLogo(true);

      await uploadCompanyLogo(formData)
        .then((result) => {
          if ('data' in result) {
            toast(
              createSuccessToast({
                position: 'top',
                title: 'Успешно',
                description: 'Логото е успешно поставено.',
              }),
            );
            // setUploadingLogo(false);
          }
        })
        .catch(() => {
          createErrorToast({
            title: 'Грешка',
            description: 'Проблеми при поставување на логото. Ве молиме обидете се подоцна.',
          });
          // setUploadingLogo(false);
        })
        .finally(() => {
          // setUploadingLogo(false);
        });
    },
    [toast, uploadCompanyLogo],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzoneLogoUpload({ onDrop });

  return (
    <VStack spacing={4} align="stretch">
      <Box>
        <Box display="flex" alignItems="center" mb={4}>
          <Box display="inline-flex" fontSize="xl" mr={2} color="sky.400">
            <FontAwesomeIcon icon={faCircleInfo} />
          </Box>
          <Box>
            <Text mb={0}>Додадените податоци за компанијата ќе бидат прикажани во фактурите и другите документи.</Text>
            <Text fontSize="sm" color="gray.500">
              Задолжителни податоци за валидна фактура: Име на компанија | Адреса и седиште на компанија | Единствен
              даночен број | Ж.Сметка
            </Text>
          </Box>
        </Box>
      </Box>
      {isLoadingCompany && !isUploadingLogo ? (
        <CompanyLoader />
      ) : (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={3} display="flex" alignItems="center">
              <Text fontWeight="bold">Лого</Text>
            </GridItem>
            <GridItem colSpan={8}>
              <Box
                {...getRootProps()}
                p={2}
                display="flex"
                width="97%"
                cursor="pointer"
                borderWidth="1px"
                borderStyle="dashed"
                minHeight="180px"
                bg={isDragReject ? 'red.500' : 'gray.50'}
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <input {...getInputProps()} disabled={isUploadingLogo} />
                {isUploadingLogo ? (
                  <Skeleton startColor="sky.500" endColor="teal.500" height="120px" width="260px" />
                ) : company ? (
                  <Box alignItems="center">
                    <RenderCompanyLogo company={company} uploadable />
                  </Box>
                ) : null}

                <Box mt={2}>
                  {isDragReject ? <Text color="white">Датотеката не е поддржана. Само "png" и "jpg"</Text> : null}
                  {isDragActive && !isDragReject ? (
                    <Text color="sky.600">Спушти лого овде ...</Text>
                  ) : isUploadingLogo ? (
                    <Box>
                      <Text color="sky.600">Поставување лого. Ве молиме почекајте.</Text>
                      <Progress size="xs" isIndeterminate />
                    </Box>
                  ) : (
                    !isDragReject && (
                      <Text color="sky.600">Повлечи па спушти овде овде или кликнете за да изберете.</Text>
                    )
                  )}
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <Box mt={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="name">
                      Назив
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="name"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder={touchedFields.name ? 'Внеси име на компанија' : ''}
                      {...register('name')}
                    />
                    <FormErrorMessage>
                      <>{errors.name?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="fullName">
                      Целосен назив
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Textarea
                      fontSize="sm"
                      id="fullName"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder={touchedFields.fullName ? 'Внеси целосен назив на компанија' : ''}
                      {...register('fullName')}
                    />
                    <FormErrorMessage>
                      <>{errors.fullName?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="businessId">
                      ЕМБС (Единствен Матичен Број на Субјектот)
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="businessId"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="ЕМБС"
                      {...register('businessId')}
                    />
                    <FormErrorMessage>
                      <>{errors.businessId?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="businessVatId">
                      ЕДБ (Единствен Даночен Број)
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="businessVatId"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="MK-1234xxxxx"
                      {...register('businessVatId')}
                    />
                    <FormErrorMessage>
                      <>{errors.businessVatId?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="businessBankAccount">
                      Ж.Сметка
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="businessBankAccount"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="1234xxxxx"
                      {...register('businessBankAccount')}
                    />
                    <FormErrorMessage>
                      <>{errors.businessBankAccount?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="currency">
                      Основна валута
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="currency"
                      type="text"
                      isDisabled
                      focusBorderColor={inputFocusBorderColor}
                      {...register('currency')}
                    />
                    <FormErrorMessage>
                      <>{errors.currency?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="businessUrl">
                      Веб страница
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="businessUrl"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="www.example.com"
                      {...register('businessUrl')}
                    />
                    <FormErrorMessage>
                      <>{errors.businessUrl?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="email">
                      Емаил
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="email"
                      type="email"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="name@example.com"
                      {...register('email')}
                    />
                    <FormErrorMessage>
                      <>{errors.email?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="businessPhone">
                      Телефон
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="businessPhone"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="+389xx xxx xxx"
                      {...register('businessPhone')}
                    />
                    <FormErrorMessage>
                      <>{errors.businessPhone?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="mobilePhone">
                      Мобилен
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="mobilePhone"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="+389xx xxx xxx"
                      {...register('mobilePhone')}
                    />
                    <FormErrorMessage>
                      <>{errors.mobilePhone?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="address">
                      Адреса
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="address"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="Адреса"
                      {...register('address')}
                    />
                    <FormErrorMessage>
                      <>{errors.address?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="city">
                      Град
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="city"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="Град"
                      {...register('city')}
                    />
                    <FormErrorMessage>
                      <>{errors.city?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="postalCode">
                      Поштенски Код
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="postalCode"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="Поштенски Код"
                      {...register('postalCode')}
                    />
                    <FormErrorMessage>
                      <>{errors.postalCode?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl mb={3}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <FormLabel fontSize="sm" htmlFor="country">
                      Држава
                    </FormLabel>
                  </GridItem>
                  <GridItem colSpan={8}>
                    <Input
                      id="country"
                      type="text"
                      isDisabled={isSaving}
                      focusBorderColor={inputFocusBorderColor}
                      placeholder="Држава"
                      {...register('country')}
                    />
                    <FormErrorMessage>
                      <>{errors.country?.message}</>
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  colorScheme={buttonColorScheme}
                  isLoading={isSaving || isLoadingCompany}
                  isDisabled={isSaving || isLoadingCompany}
                >
                  Зачувај
                </Button>
              </Box>
            </form>
          </Box>
        </>
      )}
    </VStack>
  );
};

export default CompanyProfile;
CompanyProfile.displayName = 'CompanyProfile';
