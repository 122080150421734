import { Box, ButtonGroup, Text } from '@/core-components';
import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { highlightTerm } from '@/Core/utils/highlighter';
import { getTranslatedTreasuryType } from '@/Core/utils/translatableTypes';
import { Estimate } from '@/Estimates/api/estimates.types';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import { faCopy, faFileExport, faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useMatch } from 'react-router-dom';

interface ActionsProps {
  estimate: Estimate;
  isEditing: boolean;
  isSaving: boolean;
  disabled?: boolean;
  onEdit?: (estimate: Estimate) => Estimate | unknown;
  onDelete?: (estimate: Estimate) => Estimate | unknown;
  onCopy?: (estimate: Estimate) => Estimate | unknown;
  onTransform?: (estimate: Estimate) => Estimate | unknown;
}

const Actions: React.FC<ActionsProps> = ({
  estimate,
  isEditing,
  isSaving,
  onTransform,
  onDelete,
  onCopy,
  onEdit,
  disabled,
}) => (
  <ButtonGroup spacing={3}>
    <ActionButton
      isEditing={isEditing}
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(estimate)}
      disabled={disabled || isSaving}
    />
    {!isEditing ? (
      <ActionButton
        icon={<FontAwesomeIcon icon={faFileExport} />}
        label="Претвори во фактура"
        onClick={() => onTransform?.(estimate)}
        disabled={disabled || isSaving}
      />
    ) : null}
    <ActionButton
      icon={<FontAwesomeIcon icon={faCopy} />}
      label="Копирај"
      onClick={() => onCopy?.(estimate)}
      disabled={disabled || isSaving}
    />
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label="Додади во корпа"
      tooltipBg="red.600"
      onClick={() => onDelete?.(estimate)}
      disabled={disabled || isSaving}
    />
  </ButtonGroup>
);

Actions.displayName = 'EstimateItemActions';

interface Props {
  estimate: Estimate;
  isLoading: boolean;
  onSave?: (estimate: Estimate) => Estimate | unknown;
  onEdit?: (estimate: Estimate) => Estimate | unknown;
  onDelete?: (estimate: Estimate) => Estimate | unknown;
  onCopy?: (estimate: Estimate) => Estimate | unknown;
  onTransform?: (estimate: Estimate) => Estimate | unknown;
}

export const EstimateItem: React.FC<Props> = ({ estimate, isLoading, onEdit, onDelete, onCopy, onTransform }) => {
  const match = useMatch('/estimates/:id/edit');
  const isEditing = match != null && estimate.id === match?.params?.id;
  const { query } = useSearchQuery();

  const shouldDisableActions = React.useMemo(() => {
    return isEditing ?? isLoading;
  }, [isEditing, isLoading]);

  const { estimateNumber, estimatedAt, client, type, currency } = estimate;

  const highlightedNumber = query ? highlightTerm(estimateNumber, query) : estimateNumber;
  const highlightedClientFullName = query ? highlightTerm(client?.fullName, query) : client?.fullName;
  const highlightedClientBusinessName = query ? highlightTerm(client?.businessName, query) : client?.businessName;

  return (
    <ListItemAction
      id={estimate.id}
      item={{ entity: estimate, path: 'estimates' }}
      disabled={shouldDisableActions}
      actions={
        <Actions
          isEditing={isEditing}
          isSaving={isLoading}
          disabled={shouldDisableActions}
          estimate={estimate}
          onEdit={() => {
            onEdit?.(estimate);
          }}
          onCopy={onCopy}
          onDelete={onDelete}
          onTransform={onTransform}
        />
      }
    >
      <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

      <Box display="flex" width="100%" justifyContent="space-between" marginBottom={1}>
        <Text
          fontSize="sm"
          color="gray.600"
          dangerouslySetInnerHTML={{ __html: highlightedNumber ?? `${estimateNumber ?? ''}` }}
        />
        <Text fontSize="sm" color="gray.600">
          {estimatedAt ? toFormattedDate(estimatedAt) : null}
        </Text>
      </Box>

      <Box display="flex" width="100%" flexDirection="column" marginY={2}>
        <Text
          marginBottom={0}
          dangerouslySetInnerHTML={{ __html: highlightedClientBusinessName ?? `${client?.businessName ?? ''}` }}
        />
        <Text
          fontWeight="light"
          dangerouslySetInnerHTML={{ __html: highlightedClientFullName ?? `${client?.fullName ?? ''}` }}
        />
      </Box>

      <Box display="flex" width="100%" justifyContent="flex-end" marginBottom={1}>
        <Text fontSize="md" fontWeight="bold">
          {formatMoney(estimate.totalAmount ?? 0, currency)}
        </Text>
      </Box>

      <Box display="flex" width="100%" marginBottom={1} justifyContent="flex-end">
        <Text fontSize="sm" color="gray.500">
          {getTranslatedTreasuryType(type)}
        </Text>
      </Box>
    </ListItemAction>
  );
};

EstimateItem.displayName = 'EstimateItem';
