import { MenuItem, Text } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar';
import { useCreateProductMutation, useGetAllProductsQuery } from '@/Products/api/products.api';
import { makeProduct } from '@/Products/api/products.model';
import { ProductType } from '@/Products/api/products.types';
import { ProductsList } from '@/Products/components/ProductsList';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const ProductsMasterView: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetAllProductsQuery();
  const [createProduct, { isLoading: isCreating }] = useCreateProductMutation();

  const onCreateProduct = async (type: ProductType) => {
    const project = makeProduct({ type });
    const result = await createProduct(project);

    if ('error' in result) {
      throw new Error('Failed to create project');
    }
    navigate(`/products/${result.data.id}/edit?new=true`);
  };

  const onSuccessfulDelete = () => {
    return navigate(`/products`);
  };

  return (
    <>
      <MasterViewToolbar entity="Product">
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Производи и Услуги
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          <ToolbarAction isCreator label="Нов Производ" isLoading={isLoading || isCreating}>
            <MenuItem minH="33px" fontSize="md" onClick={() => onCreateProduct(ProductType.Product)}>
              Нов Производ
            </MenuItem>
            <MenuItem minH="33px" fontSize="md" onClick={() => onCreateProduct(ProductType.Service)}>
              Нова Услуга
            </MenuItem>
          </ToolbarAction>
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton>
          <MenuItem minH="33px" fontSize="md" onClick={() => onCreateProduct(ProductType.Product)}>
            Нов Производ
          </MenuItem>
          <MenuItem minH="33px" fontSize="md" onClick={() => onCreateProduct(ProductType.Service)}>
            Нова Услуга
          </MenuItem>
        </FloatingActionButton>
      </ShowOnMobile>
      <ProductsList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};

ProductsMasterView.displayName = 'ProductsMasterView';
