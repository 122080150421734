import { getFirstObject } from '@/Core/utils/getFirstObject';
import { lazyRetry } from '@/lazyRetry';
import { useGetAllProjectsQuery } from '@/Projects/api/projects.api';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

const EmptyProjects = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmptyProjects" */ '../../Core/components/EmptyStates/EmptyProjects'),
    'EmptyProjects',
  ),
);

export const ProjectsHome: React.FC = () => {
  const { data: projects } = useGetAllProjectsQuery();
  const project = getFirstObject(projects);

  return project ? <Navigate to={`${project?.id}`} state={{ project }} replace /> : <EmptyProjects />;
};
export default ProjectsHome;
ProjectsHome.displayName = 'ProjectHome';
