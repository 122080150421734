import { AppThunk } from '@/Core/AppStore';
import { authService } from '@/Core/auth/authService';
import { TODO } from '@/Core/core.types';
import { createAction } from '@reduxjs/toolkit';

export const loginRequest = createAction<string, '@auth/LOGIN_REQUEST'>('@auth/LOGIN_REQUEST');
export const loginSuccess = createAction<TODO>('@auth/LOGIN_SUCCESS');
export const loginError = createAction<{ errors: TODO }>('@auth/LOGIN_ERROR');

export const login =
  (email: string, password: string, remember?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(loginRequest(email));

    try {
      const data = await authService.login({ email, password, remember });

      dispatch(loginSuccess(data));

      return data;
    } catch (response: TODO) {
      if (response?.data?.errors) {
        return dispatch(loginError({ errors: response.data.errors }));
      }

      dispatch(
        loginError({
          errors: {
            message: 'Проблеми во системот 😞. Обидете се повторно подоцна!',
            reason: response?.data?.message ?? 'Unknown',
          },
        }),
      );

      throw response;
    }
  };
