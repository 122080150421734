import { Badge } from '@/core-components';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
interface Props {
  isEditing: boolean;
  hasUnsavedChanges: boolean;
}
export const UnsavedChangesBadge: React.FC<Props> = ({ isEditing, hasUnsavedChanges }) => {
  return isEditing ? (
    <div className="d-flex mb-1 justify-content-end">
      <Badge colorScheme={hasUnsavedChanges ? 'red' : 'purple'} textTransform="none">
        {hasUnsavedChanges ? (
          <>
            <FontAwesomeIcon icon={faPen} /> Незачувани промени
          </>
        ) : (
          'Се уредува'
        )}
      </Badge>
    </div>
  ) : null;
};
