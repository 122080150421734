import { rankItem } from '@tanstack/match-sorter-utils';
import { Row } from '@tanstack/react-table';
import { TODO } from '../core.types';

export const DEFAULT_PAGE_SIZE = 20;

export function fuzzyFilter(row: Row<TODO>, columnId: string, value: string, addMeta: (itemRank: TODO) => void) {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the ranking info
  addMeta?.(itemRank);
  // Return if the item should be filtered in/out
  return itemRank.passed;
}
