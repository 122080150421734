import { useGetAuthenticatedUserQuery } from '@/Authentication/store/authenticatedUser.api';
import { useGetAllClientsQuery } from '@/Clients/api/clients.api';
import { useGetCompanyProfileQuery } from '@/Company/api/company.api';
import { LoadingBrojki } from '@/Core/components/BrojkiLogo/LoadingBrojki';
import { useIsAuthenticated } from '@/Core/hooks/useIsAuthenticated';
import { useMobileViewHeight } from '@/Core/hooks/useMobileViewHeight';
import { useSidebarCollapsed } from '@/Core/hooks/useSidebarCollapsed';
import { brojkiTheme } from '@/Core/utils/brojkiTheme';
import { useGetAllProductsQuery } from '@/Products/api/products.api';
import PWABadge from '@/PWABadge.tsx';
import { useSentryUser } from '@/setupSentry';
import { ToastContainer } from '@/Toaster/components/Toaster';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './App.module.scss';

const App: React.FC = () => {
  useMobileViewHeight();
  const [isAuthenticated] = useIsAuthenticated();
  const sidebarCollapsed = useSidebarCollapsed();
  useGetAuthenticatedUserQuery(undefined, { skip: !isAuthenticated });
  useGetCompanyProfileQuery(undefined, { skip: !isAuthenticated });
  useSentryUser(isAuthenticated);
  // useGetAllExpenseCategoriesQuery(undefined, { skip: !isAuthenticated });
  useGetAllClientsQuery(undefined, { skip: !isAuthenticated });
  // useGetAllInvoicesQuery(undefined, { skip: !isAuthenticated });
  // useGetAllEstimatesQuery(undefined, { skip: !isAuthenticated });
  // useGetAllExpensesQuery(undefined, { skip: !isAuthenticated });
  // useGetAllProjectsQuery(undefined, { skip: !isAuthenticated });
  useGetAllProductsQuery(undefined, { skip: !isAuthenticated });

  const container = clsx(styles.App, { [styles.collapsed]: sidebarCollapsed });
  return (
    <React.Suspense fallback={<LoadingBrojki />}>
      <main className={container}>
        <Outlet />
      </main>
    </React.Suspense>
  );
};
App.displayName = 'Brojki';

const logWarning = () => {
  if (process.env.NODE_ENV === 'production') {
    console.log(
      '%cStop! Стоп! Stop!',
      'color: #0ca5e9; font-family: sans-serif; font-size: 5em; font-weight: bolder; text-shadow: #0052CC 1px 1px;',
    );
    console.log(
      '%cАко некој ви рече да копирате (copy/paste) нешто во конзолата, постои шанса да бидете измамени!',
      'font-family: sans-serif; font-weight:bold; font-size: 1.6em;',
    );
    console.log(
      '%cВметнувањето што било овде во конзолата може да им овозможи на напаѓачите пристап до вашата сметка на Brojki!',
      'color:#E14A3A;font-family: sans-serif; font-size: 1.5em;',
    );
  }
};

export const ThemedApp: React.FC = () => {
  useEffect(() => {
    logWarning();
  }, []);

  return (
    <React.StrictMode>
      <ChakraProvider theme={brojkiTheme}>
        <React.Suspense fallback={<LoadingBrojki />}>
          <CSSReset />
          <App />
          <ToastContainer />
          <PWABadge />
        </React.Suspense>
      </ChakraProvider>
    </React.StrictMode>
  );
};
