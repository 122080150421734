import { Box, Text } from '@/core-components';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { SpotlightEntity } from '../api/spotlight.types';

interface Props {
  title: string;
  items?: SpotlightEntity[];
  selected: SpotlightEntity | null;
  setSelected?: (item: SpotlightEntity) => SpotlightEntity | unknown;
  onClick?: (item: SpotlightEntity) => SpotlightEntity | unknown;
  children?: (item: SpotlightEntity) => React.ReactElement | null;
}

export const SpotlightMasterList: React.FC<Props> = ({ title, items, children, selected, setSelected, onClick }) => {
  return items ? (
    <>
      <Box>
        {!isEmpty(items) ? (
          <Box mt={2}>
            <Text fontWeight="semibold">{title}</Text>
          </Box>
        ) : null}
        <Box as="ul" display="flex" flexDirection="column" listStyleType="none" p={0}>
          {items?.map((item) => (
            <Box
              as="li"
              key={item.id}
              onClick={() => onClick?.(item)}
              onMouseEnter={() => setSelected?.(item)}
              display="flex"
              alignItems="center"
              pl={1}
              p={2}
              cursor="pointer"
              bg={selected && item.id === selected.id ? 'blue.50' : 'transparent'}
              _hover={{ bg: 'gray.50' }}
            >
              {children ? children(item) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  ) : null;
};

SpotlightMasterList.displayName = 'SpotlightMasterList';
