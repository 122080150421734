import { SerializedError } from '@reduxjs/toolkit';
import isFinite from 'lodash-es/isFinite';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { Product, ProductType } from './products.types';

interface ProductStockValue {
  amountInStock?: string;
  unitCost?: string;
}

export const getProductStockValue = ({
  amountInStock = '0.00',
  unitCost = '0.00',
}: ProductStockValue): number | null => {
  const amount = parseFloat(amountInStock);
  const cost = parseFloat(unitCost);

  return isFinite(amount) && isFinite(cost) ? amount * cost : null;
};

type Error =
  | {
      status: number;
      data: {
        message?: string | undefined;
        error?: string | undefined;
        errorCode?: string | undefined;
      };
    }
  | SerializedError;

type Result =
  | {
      data: Product;
    }
  | {
      error: Error;
    };

export const notifyProductDeleted = (product: Product, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${product.id}-error`,
      title: 'Failed to delete product',
    });
    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === product.id) {
    toast = createInfoToast({
      id: `${product.id}-success`,
      title: 'Успешно',
      description:
        product.type === ProductType.Product
          ? `Производот "${product.name ?? ''}" е додаден во корпа.`
          : `Услугата "${product.name ?? ''}" е додадена во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyProductUpdated = (product: Product, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${product.id}-error`,
      title: 'Failed to update product',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === product.id) {
    toast = createInfoToast({
      id: `${product.id}-success`,
      title: 'Успешно',
      description:
        product.type === ProductType.Product
          ? `Производот "${product?.name ?? ''}" e зачуван!`
          : `Услугата "${product?.name ?? ''}" e зачувана!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const redirectProductAfterDeletion = (product: Product, products?: Record<string, Product>) => {
  if (!products) {
    return null;
  }
  const { [product.id]: _, ...withoutRemoved } = products;
  const deletedIndex = Object.keys(products).findIndex((i) => products[i]?.id === product.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
