import { lazyRetry } from '@/lazyRetry';
import { useProductData } from '@/Products/hooks/useProductData';
import * as React from 'react';

const ProductCreator = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProductCreator" */ './ProductCreator'), 'ProductCreator'),
);

export const ProductCreatorLoader: React.FC = () => {
  const { product } = useProductData();
  return product ? <ProductCreator product={product} /> : null;
};
export default ProductCreatorLoader;
