import { SerializedError } from '@reduxjs/toolkit';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { Expense } from './expenses.types';

type Error =
  | {
      status: number;
      data: {
        message?: string | undefined;
        error?: string | undefined;
        errorCode?: string | undefined;
      };
    }
  | SerializedError;

type Result =
  | {
      data: Expense;
    }
  | {
      error: Error;
    };

export const notifyExpenseDeleted = (expense: Expense, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${expense.id}-error`,
      title: 'Failed to delete expense',
    });
    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === expense.id) {
    toast = createInfoToast({
      id: `${expense.id}-success`,
      title: 'Успешно',
      description: `Сметката "${expense?.expenseNumber ?? ''}" е додадена во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyExpenseUpdated = (expense: Expense, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${expense.id}-error`,
      title: 'Failed to update expense',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === expense.id) {
    toast = createInfoToast({
      id: `${expense.id}-success`,
      title: 'Успешно',
      description: `Сметката "${expense?.expenseNumber ?? ''}" e зачувана!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const redirectExpenseAfterDeletion = (expense: Expense, expenses?: Record<string, Expense>) => {
  if (!expenses) {
    return null;
  }
  const { [expense.id]: _, ...withoutRemoved } = expenses;
  const deletedIndex = Object.keys(expenses).findIndex((i) => expenses[i]?.id === expense.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
