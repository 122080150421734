import { Box, Button, Flex, Text } from '@/core-components';
import { TODO } from '@/Core/core.types';
import * as Sentry from '@sentry/react';
import { Component, ErrorInfo } from 'react';
import EmptyState from './lost-in-space.svg?react';

interface ErrorBoundaryState {
  eventId: string | undefined;
  hasError: boolean;
  error: null | Error;
  errorInfo: null | ErrorInfo;
}

export default class ErrorBoundary extends Component<TODO, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    eventId: undefined,
    error: null,
    errorInfo: null,
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV === 'development') {
      console.log('---'.repeat(5));
      console.error(error);
      console.error(errorInfo);
      console.log('---'.repeat(5));
      this.setState({
        error,
        errorInfo,
      });
    }
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras({ error: errorInfo });
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (process.env.NODE_ENV === 'development') {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        </div>
      );
    }
    return (
      <Box height="100%" width="100%">
        <Flex align="center" justify="center" direction="column">
          <Box p={8} width="100%">
            <Text fontSize="3xl" color="gray.700">
              Технички проблеми!
            </Text>
            <Box display="flex" width="100%" height="60vh" alignContent="center" justifyContent="center">
              <EmptyState />
            </Box>
          </Box>
          <Box>
            <Button
              colorScheme="red"
              variant="outline"
              onClick={() => {
                Sentry.showReportDialog({
                  eventId: this.state.eventId,
                  title: 'Имате проблем?',
                  subtitle: 'Нашиот тим е известен. ',
                  subtitle2: 'Ако сакате да помогнете, кажете ни што се случи подолу.',
                  successMessage: 'Ви благодаримe. Вашиот проблем е пријавен.',
                  labelComments: 'Што се случи?',
                  labelName: 'Вашето име',
                  labelEmail: 'Вашата е-пошта',
                  labelClose: 'Затвори',
                  labelSubmit: 'Испрати',
                  onClose() {
                    location.reload();
                  },
                });
              }}
            >
              Пријавете го проблемот
            </Button>
          </Box>
        </Flex>
        <Text right={0} p={6} fontSize="xs" bottom={0} position="fixed">
          <a href="https://storyset.com/business">Business illustrations by Storyset</a>
        </Text>
      </Box>
    );
  }
}
