import { getFirstObject } from '@/Core/utils/getFirstObject';
import { useGetAllExpensesQuery } from '@/Expenses/api/expenses.api';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

const EmptyExpenses = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmptyExpenses" */ '../../Core/components/EmptyStates/EmptyExpenses'),
    'EmptyExpenses',
  ),
);

export const ExpensesHome: React.FC = () => {
  const { data: expenses } = useGetAllExpensesQuery();
  const expense = getFirstObject(expenses);

  return expense ? <Navigate to={`${expense?.id}`} state={{ expense }} replace /> : <EmptyExpenses />;
};
export default ExpensesHome;
ExpensesHome.displayName = 'ExpensesHome';
