import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Input,
  Text,
  VStack,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { CreatorContainer } from '@/Core/components/SplitView/DetailContainer.tsx';
import { DraftableStatus } from '@/Core/core.types';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Client, ClientType } from '../api/clients.types';
import { ClientAvatarUploader } from './ClientAvatarUploader';
import { ClientTypeControl } from './ClientTypeControl';

interface Props {
  client?: Client;
  isOnModal?: boolean;
}

export const ClientCreatorForm: React.FC<React.PropsWithChildren<Props>> = ({ client, isOnModal = false }) => {
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const isDraft = client?.status === DraftableStatus.Draft;
  const isCreated = client?.status === DraftableStatus.Created;
  const type = useWatch({ name: 'type', defaultValue: client?.type });
  const isCompany = type === ClientType.Company;

  return (
    <Box
      as="form"
      name="client-creator"
      pb={isOnModal ? 0 : 2}
      my={isOnModal ? 0 : 4}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
    >
      <CreatorContainer isOnModal={isOnModal}>
        <VStack spacing={6} align="stretch">
          <HStack justify="space-between" align="center">
            <Box>
              {isDraft ? <Heading size="md">Нов Клиент</Heading> : null}
              {isCreated ? <Heading size="md">Измени Клиент</Heading> : null}
            </Box>
          </HStack>

          <Box>
            <Text color="gray.600" maxW="860px">
              Во документите информациите ќе бидат прикажани според вид на комитент. Ако изберете "Компанија" само
              информациите за Компанијата ќе бидат прикажани на документите. Ако изберете "Физичко Лице" само
              информациите за Физичко Лице ќе бидат прикажани на документите.
            </Text>
          </Box>

          <ClientTypeControl control={control} type={client?.type} />
          <Divider />

          {!isOnModal && (
            <HStack justify="flex-end">
              <ClientAvatarUploader client={client} />
            </HStack>
          )}

          {isCompany ? (
            <VStack spacing={6} align="stretch">
              <Box>
                <Heading as="h5" size="md" mb={2}>
                  Информации за Компанијата
                </Heading>
                <Divider />
              </Box>

              <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4}>
                <FormControl isInvalid={errors.businessName != null}>
                  <FormLabel htmlFor="businessNameInput">Име на Компанија</FormLabel>
                  <Input
                    id="businessNameInput"
                    type="text"
                    placeholder="Ацме Инц"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('businessName')}
                  />
                  <FormErrorMessage>{errors.businessName?.message?.toString()}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.businessAddress != null}>
                  <FormLabel htmlFor="businessAddressInput">Адреса</FormLabel>
                  <Input
                    id="businessAddressInput"
                    type="text"
                    placeholder="ул. френ 123"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('businessAddress')}
                  />
                  <FormErrorMessage>{errors.businessAddress?.message?.toString()}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.businessEmail != null}>
                  <FormLabel htmlFor="businessEmailInput">Емаил на Компанија</FormLabel>
                  <Input
                    id="businessEmailInput"
                    type="email"
                    placeholder="johndoe@example.com"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('businessEmail')}
                  />
                  <FormErrorMessage>{errors.businessEmail?.message?.toString()}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.businessId != null}>
                  <FormLabel htmlFor="businessIdInput">ЕМБС</FormLabel>
                  <Input
                    id="businessIdInput"
                    type="text"
                    placeholder="Единствен Матичен Број на Субјектот"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('businessId')}
                  />
                  <FormErrorMessage>{errors.businessId?.message?.toString()}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.businessVatId != null}>
                  <FormLabel htmlFor="businessVatIdInput">ЕДБ</FormLabel>
                  <Input
                    id="businessVatIdInput"
                    type="text"
                    placeholder="Единствен Даночен Број"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('businessVatId')}
                  />
                  <FormErrorMessage>{errors.businessVatId?.message?.toString()}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.businessBankAccount != null}>
                  <FormLabel htmlFor="businessBankAccountInput">Ж.Сметка</FormLabel>
                  <Input
                    id="businessBankAccountInput"
                    type="text"
                    placeholder="xxx xyx xoxo"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('businessBankAccount')}
                  />
                  <FormErrorMessage>{errors.businessBankAccount?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </Grid>
            </VStack>
          ) : null}

          <VStack spacing={6} align="stretch">
            <Box>
              <Heading as="h5" size="md" mb={2}>
                Контакт лице
              </Heading>
              <Divider />
            </Box>

            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4}>
              <FormControl isInvalid={errors.fullName != null}>
                <FormLabel htmlFor="fullNameInput">Име Презиме</FormLabel>
                <Input
                  id="fullNameInput"
                  type="text"
                  placeholder="Џон Дое"
                  focusBorderColor={inputFocusBorderColor}
                  {...register('fullName')}
                />
                <FormErrorMessage>{errors.fullName?.message?.toString()}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.contactAddress != null}>
                <FormLabel htmlFor="contactAddressInput">Адреса</FormLabel>
                <Input
                  id="contactAddressInput"
                  type="text"
                  placeholder="ул. френ 123"
                  focusBorderColor={inputFocusBorderColor}
                  {...register('contactAddress')}
                />
                <FormErrorMessage>{errors.contactAddress?.message?.toString()}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.contactEmail != null}>
                <FormLabel htmlFor="contactEmailInput">Емаил</FormLabel>
                <Input
                  id="contactEmailInput"
                  type="email"
                  placeholder="johndoe@example.com"
                  focusBorderColor={inputFocusBorderColor}
                  {...register('contactEmail')}
                />
                <FormErrorMessage>{errors.contactEmail?.message?.toString()}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.contactPhone != null}>
                <FormLabel htmlFor="contactPhoneInput">Контакт Телефон</FormLabel>
                <Input
                  type="tel"
                  placeholder="+389 xx xxx xxx"
                  id="contactPhoneInput"
                  focusBorderColor={inputFocusBorderColor}
                  {...register('contactPhone')}
                />
                <FormErrorMessage>{errors.contactPhone?.message?.toString()}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.website != null}>
                <FormLabel htmlFor="websiteInput">Контакт веб страница</FormLabel>
                <Input
                  id="websiteInput"
                  type="url"
                  placeholder="https://www.example.com"
                  focusBorderColor={inputFocusBorderColor}
                  {...register('website')}
                />
                <FormErrorMessage>{errors.website?.message?.toString()}</FormErrorMessage>
              </FormControl>
            </Grid>
          </VStack>
        </VStack>
      </CreatorContainer>
    </Box>
  );
};

ClientCreatorForm.displayName = 'ClientCreatorForm';
