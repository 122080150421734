import { Box, Text } from '@/core-components';
import { TODO } from '@/Core/core.types';
import { useGetAllExpenseCategoriesQuery } from '@/ExpenseCategories/api/expenseCategories.api';
import { ExpenseCategory } from '@/ExpenseCategories/api/expenseCategories.types';
import * as React from 'react';
import { useController } from 'react-hook-form';
import Select, { OptionProps, SingleValue, StylesConfig, components } from 'react-select';
import { customStyle } from './customSelectorStyle';

type SelectOption = { label: string; value: string };
const Option = (props: OptionProps<SelectOption>) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <Text width="100%" textAlign="left">
        {data?.label}
      </Text>
    </components.Option>
  );
};

interface Props {
  placeholder?: string;
  onCategorySelected?: (selected: TODO) => void;
  defaultValue?: Partial<ExpenseCategory>;
  name?: string;
  control?: TODO;
}

export const ExpenseCategorySelector: React.FC<React.PropsWithChildren<Props>> = ({
  placeholder = 'Изберете категорија',
  onCategorySelected,
  defaultValue = null,
  name = 'category',
  control,
}) => {
  const { data: categories, isLoading, isFetching } = useGetAllExpenseCategoriesQuery();

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ defaultValue, control, name });

  const options = categories?.map((category) => ({
    id: category.id,
    value: category.id, // required by Select
    label: category.name, // required by Select
    name: category.name,
  }));

  const handleChange = (selected: Partial<ExpenseCategory> | null) => {
    const category = selected ? { ...selected, id: selected?.id, name: selected?.name } : null;
    onCategorySelected?.(category);

    return category;
  };

  return (
    <Box width="100%">
      <Select
        ref={ref}
        value={options?.find((option) => option?.id === value?.id)}
        isClearable
        defaultValue={defaultValue as TODO}
        isLoading={isLoading || isFetching}
        placeholder={placeholder}
        noOptionsMessage={(option) => `Нема опции за "${option.inputValue}"`}
        styles={
          {
            ...customStyle,
            placeholder: (base) => ({ ...base, display: 'flex', justifyContent: 'flex-start' }),
          } as StylesConfig<SelectOption>
        }
        options={options}
        onChange={(selected) => {
          const option = selected as SingleValue<ExpenseCategory>;
          const category = handleChange(option);
          return onChange(category || '');
        }}
        onBlur={onBlur}
        components={{ Option }}
      />
    </Box>
  );
};
