import { useGetEstimateQuery } from '@/Estimates/api/estimates.api';
import { useGetInvoiceQuery } from '@/Invoices/api/invoices.api';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { useParams } from 'react-router';

const TreasuryCreator = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "TreasuryCreator" */ './TreasuryCreator'), 'TreasuryCreator'),
);

export const InvoiceCreatorDataLoader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: invoice } = useGetInvoiceQuery(id!);

  return invoice ? <TreasuryCreator treasury={invoice} /> : null;
};

export const EstimateCreatorDataLoader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: estimate } = useGetEstimateQuery(id!);

  return estimate ? <TreasuryCreator treasury={estimate} /> : null;
};
