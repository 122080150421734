import { IconButton, Tooltip } from '@/core-components';
import * as React from 'react';
interface ActionProps {
  disabled?: boolean;
  isEditing?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  icon: React.ReactElement;
  label: string;
  tooltipBg?: string;
  colorScheme?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
}
export const ActionButton: React.FC<ActionProps> = ({
  icon,
  label,
  onClick,
  colorScheme = 'gray',
  tooltipBg = 'gray.700',
  isLoading = false,
  isEditing = false,
  isActive = false,
  disabled = false,
  ...rest
}) => (
  <Tooltip hasArrow aria-label={label} label={label} placement="top" zIndex={999} bg={tooltipBg} color="white">
    <IconButton
      isRound
      size="sm"
      variant="ghost"
      isActive={isActive}
      isDisabled={disabled}
      isLoading={isLoading}
      colorScheme={isEditing ? 'sky' : colorScheme}
      aria-label={label}
      fontSize="18px"
      icon={icon}
      cursor="pointer"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
      }}
      {...rest}
    />
  </Tooltip>
);

ActionButton.displayName = 'ActionButton';
