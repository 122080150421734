import { Flex, Text } from '@/core-components';
import * as React from 'react';
import EmptyState from './document-missing.svg?react';

export const DocumentNotFound: React.FC = () => {
  return (
    <Flex p={8} background="white" height="100%" width="100%">
      <Flex height="80vh" align="center" justify="space-around" direction="column" width="100%">
        <Text fontSize="2xl" mb={8} color="gray.700">
          Документот не е пронајден!
        </Text>
        <Flex width="100%" maxH="420px" alignContent="center" justifyContent="center" opacity={0.7}>
          <EmptyState />
        </Flex>
        <Text fontSize="9xl" color="pink.500" bgClip="text" bgGradient="linear(to-l, #007CF0, #00DFD8)">
          404
        </Text>
      </Flex>
    </Flex>
  );
};
export default DocumentNotFound;
