import { Box, Text } from '@/core-components';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { useGetAllNotificationsQuery } from '@/Notifications/api/notifications.api';
import { Notification } from '@/Notifications/api/notifications.types';
import * as React from 'react';

export const Notifications: React.FC = () => {
  const { data } = useGetAllNotificationsQuery();
  const notifications = React.useMemo(() => getSortedEntityItems<Notification>(data), [data]);

  return (
    <GridPage title="Нотификации">
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <GridCard title={notification.data.title} key={notification.id}>
            <Box sx={{ '*': { all: 'revert' } }}>
              <Text fontSize="md" dangerouslySetInnerHTML={{ __html: notification.data.content }} />
            </Box>
          </GridCard>
        ))
      ) : (
        <GridCard title="">
          <Text fontSize="lg" fontWeight="bold">
            Нема нови активности
          </Text>
        </GridCard>
      )}
    </GridPage>
  );
};
