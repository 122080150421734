import { HStack, Skeleton, SkeletonCircle } from '@/core-components';
import * as React from 'react';
import { LoaderContainer } from './LoaderContainer';

const ItemRow = React.memo(() => {
  return (
    <LoaderContainer>
      <HStack width="100%" height="100%" justifyContent="space-between">
        <SkeletonCircle startColor="#f3f3f3" endColor="#ecebeb" size="12px" />
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width={200} height="10px" />
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width={80} height="10px" />
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width={120} height="10px" />
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width={200} height="10px" />
      </HStack>
    </LoaderContainer>
  );
});

ItemRow.displayName = 'TableRow';

export const CompanyLoader = React.memo(() => {
  return Array(24)
    .fill('')
    .map((_, i) => <ItemRow key={i} />);
});

CompanyLoader.displayName = 'CompanyLoader';
