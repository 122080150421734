import { Box, ButtonGroup, HStack, Skeleton } from '@/core-components';
import { Toolbar } from '@/Core/components/Toolbar/Toolbar';
import { ToolbarButton } from '@/Core/components/Toolbar/ToolbarButton';
import { faPenToSquare, faSquareCheck, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { LoaderContainer } from './LoaderContainer';

export const DetailsViewLoader = React.memo(() => {
  return (
    <Box m={4} height="100%" overflow="hidden">
      <Box opacity={0.6}>
        <Toolbar disabled>
          <Box display="flex" justifyContent="flex-start">
            <ButtonGroup spacing={3}>
              <ToolbarButton buttonType="icon" label="Наплати" icon={<FontAwesomeIcon icon={faSquareCheck} />} />
              <ToolbarButton buttonType="icon" label="Измени" icon={<FontAwesomeIcon icon={faPenToSquare} />} />
              <ToolbarButton buttonType="icon" label="Додади во корпа" icon={<FontAwesomeIcon icon={faTrashCan} />} />
            </ButtonGroup>
          </Box>
        </Toolbar>
      </Box>
      <LoaderContainer style={{ margin: '5rem auto' }}>
        <Box
          display="flex"
          flexDirection="column"
          padding={6}
          bg="white"
          width="100%"
          height="100%"
          justifyContent="space-between"
        >
          <Box>
            <Skeleton width="100%" mt={2} height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="80%" mt={2} height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="60%" mt={2} height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          </Box>
          <Box mt={10}>
            <Skeleton width="100%" mt={4} height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="100%" mt={4} height="120px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="100%" mt={2} height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="60%" mt={2} height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          </Box>
          <Box mt={10}>
            <Skeleton width="100%" mt={4} height="120px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="100%" mt={4} height="120px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          </Box>

          <HStack mt={10} spacing={4}>
            <Skeleton width="33%" height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="33%" height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="33%" height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          </HStack>
        </Box>
      </LoaderContainer>
    </Box>
  );
});
