import { Box, Skeleton, SkeletonCircle } from '@/core-components';
import * as React from 'react';
import { LoaderContainer } from './LoaderContainer';

export const UserProfileLoader = React.memo(() => {
  return (
    <LoaderContainer className="card card-light">
      <Box padding="6" mt={4} bg="white" width="80%" height="400px">
        <SkeletonCircle startColor="#f3f3f3" endColor="#ecebeb" size="20" />
        <Box mt={10}>
          <Skeleton startColor="#f3f3f3" endColor="#ecebeb" mt="6" height="20px" rounded={4} />
          <Skeleton startColor="#f3f3f3" endColor="#ecebeb" mt="6" height="20px" rounded={4} />
          <Skeleton startColor="#f3f3f3" endColor="#ecebeb" mt="6" height="20px" rounded={4} />
          <Skeleton startColor="#f3f3f3" endColor="#ecebeb" mt="6" height="20px" rounded={4} />
          <Skeleton startColor="#f3f3f3" endColor="#ecebeb" mt="6" height="20px" rounded={4} />
        </Box>
      </Box>
    </LoaderContainer>
  );
});
