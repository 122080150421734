import { HStack, Radio, RadioGroup } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import * as React from 'react';
import { useController } from 'react-hook-form';
interface Props {
  name: string;
  control: TODO;
  isLoading?: boolean;
}
export const SelectDefaultThemeSetting: React.FC<React.PropsWithChildren<Props>> = ({ control, name, isLoading }) => {
  const radioColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const {
    field: { onChange, value, onBlur, ref },
  } = useController({ defaultValue: 'Modern', control, name });

  return (
    <RadioGroup ref={ref} name={name} onChange={onChange} value={value} onBlur={onBlur} colorScheme={radioColorScheme}>
      <HStack spacing="24px">
        <Radio value="Modern" isDisabled={isLoading}>
          Modern
        </Radio>
        <Radio value="Classic" isDisabled={isLoading}>
          Classic
        </Radio>
        <Radio value="Simple" isDisabled={isLoading}>
          Simple
        </Radio>
      </HStack>
    </RadioGroup>
  );
};
