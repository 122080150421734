import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { useCashPaymentData } from '../hooks/useCashPaymentData';

const CashPaymentCreator = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "CashPaymentCreator" */ './CashPaymentCreator'), 'CashPaymentCreator'),
);
export const CashPaymentCreatorLoader: React.FC = () => {
  const { cashPayment } = useCashPaymentData();
  return cashPayment ? <CashPaymentCreator cashPayment={cashPayment} /> : null;
};

export default CashPaymentCreatorLoader;
