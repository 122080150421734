import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/expenses.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useFilterQuery, useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { useGetAllExpenseCategoriesQuery } from '@/ExpenseCategories/api/expenseCategories.api';
import {
  useDeleteExpenseMutation,
  useGetAllExpensesQuery,
  useGetExpenseQuery,
  useUpdateExpenseMutation,
} from '@/Expenses/api/expenses.api';
import { Expense } from '@/Expenses/api/expenses.types';
import { notifyExpenseDeleted, notifyExpenseUpdated } from '@/Expenses/api/expenses.utils';
import { ExpenseItem } from '@/Expenses/components/ExpenseItem';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';
import { useMatch, useNavigate } from 'react-router-dom';

interface Props {
  onSuccessfulDelete: (expense: Expense) => void;
}

export const ExpensesList: React.FC<React.PropsWithChildren<Props>> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { query, isSearching } = useSearchQuery();
  const { isFiltering } = useFilterQuery();
  const { data, isLoading, isFetching } = useGetAllExpensesQuery();
  useGetAllExpenseCategoriesQuery();
  const { data: selectedExpense } = useGetExpenseQuery(id!, { skip: !isFiltering });
  const expenses = getSortedEntityItems(data);

  const [deleteExpense] = useDeleteExpenseMutation();
  const [updateExpense] = useUpdateExpenseMutation();

  const match = useMatch('/expenses/:id/edit');

  const items = React.useMemo(() => {
    if (!isSearching) {
      return expenses;
    }

    return expenses
      .filter((expense) => {
        return isFiltering ? expense?.client?.id === selectedExpense?.client?.id : expense;
      })
      .filter((expense) => {
        const foundNumber = expense?.expenseNumber?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
        const foundClient =
          expense?.client?.fullName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase()) ||
          expense?.client?.businessName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
        const foundReason = expense?.expenseFor?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());

        return foundNumber || foundClient || foundReason;
      });
  }, [expenses, query, isSearching, isFiltering, selectedExpense]);

  return (
    <MasterList isLoading={isLoading || isFetching} data-test-id="expenses-master-list">
      {isEmpty(expenses) ? (
        <EmptyMasterViewList
          isSearching={isSearching}
          title={isSearching ? `Немате сметки со вашето пребарување "${query}"` : 'Немате Сметки'}
        >
          <EmptyState />
        </EmptyMasterViewList>
      ) : null}
      <AnimatePresence initial={false}>
        {items.map((expense) =>
          expense ? (
            <ExpenseItem
              key={expense.id}
              expense={expense}
              isLoading={isLoading || isFetching}
              onSave={async (expense) => {
                const result = await updateExpense(expense);
                const saved = notifyExpenseUpdated(expense, result);
                if (saved) {
                  if (isMobile) {
                    return navigate('/expenses');
                  }
                  return navigate(`/expenses/${saved.id}/`);
                }
              }}
              onEdit={(expense) => {
                const isEditing = match != null && expense.id === match?.params?.id;
                navigate(isEditing ? `/expenses/${expense.id}/` : `/expenses/${expense.id}/edit`);
              }}
              onDelete={async (expense) => {
                const result = await deleteExpense(expense.id);
                const deleted = notifyExpenseDeleted(expense, result);
                if (deleted) {
                  onSuccessfulDelete(expense);
                }
              }}
            />
          ) : null,
        )}
      </AnimatePresence>
    </MasterList>
  );
};

ExpensesList.displayName = 'ExpensesList';
