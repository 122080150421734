import { Box } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/projects.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { useDeleteProjectMutation, useGetAllProjectsQuery } from '@/Projects/api/projects.api';
import { Project } from '@/Projects/api/projects.types';
import { notifyProjectDeleted } from '@/Projects/api/projects.utils';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { ProjectItem } from './ProjectItem';

const EmptyText: React.FC = () => {
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      Клик на
      <Box
        borderRadius="sm"
        mx={1}
        backgroundColor={colorSchemeValue}
        color="white"
        boxSize="20px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Box>{' '}
      да креирате нов проект.
    </Box>
  );
};

interface Props {
  onSuccessfulDelete: (project: Project) => void;
}

export const ProjectsList: React.FC<React.PropsWithChildren<Props>> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetAllProjectsQuery();
  const projects = getSortedEntityItems<Project>(data);
  const [deleteProject] = useDeleteProjectMutation();
  const match = useMatch('/projects/:id/edit');

  const { query, isSearching } = useSearchQuery();

  const items = React.useMemo(() => {
    return isSearching
      ? projects.filter((project) => {
          const foundCode = project?.code?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
          const foundDescription = project?.description?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
          const foundName = project?.name?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase());
          const foundProject =
            project?.client?.fullName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase()) ||
            project?.client?.businessName?.toLocaleLowerCase().includes(query?.toLocaleLowerCase());

          return foundCode || foundName || foundDescription || foundProject;
        })
      : projects;
  }, [projects, query, isSearching]);

  return (
    <MasterList isLoading={isLoading || isFetching} data-test-id="projects-master-list">
      {isEmpty(items) ? (
        <EmptyMasterViewList
          isSearching={isSearching}
          title={isSearching ? `Немате проекти со вашето пребарување "${query}"` : 'Немате Проекти'}
          text={isSearching ? null : <EmptyText />}
        >
          <EmptyState />
        </EmptyMasterViewList>
      ) : (
        <AnimatePresence initial={false}>
          {items.map((project) =>
            project ? (
              <ProjectItem
                key={project.id}
                isLoading={isLoading || isFetching}
                project={project}
                onEdit={(project) => {
                  const isEditing = match != null && project.id === match?.params?.id;
                  navigate(isEditing ? `/projects/${project.id}` : `/projects/${project.id}/edit`);
                }}
                onDelete={async (project) => {
                  const result = await deleteProject(project.id);
                  const deleted = notifyProjectDeleted(project, result);
                  if (deleted) {
                    onSuccessfulDelete(project);
                  }
                }}
              />
            ) : null,
          )}
        </AnimatePresence>
      )}
    </MasterList>
  );
};
ProjectsList.displayName = 'ProjectsList';
