import { Button, Flex } from '@/core-components';
import './PWABadge.css';

import { useRegisterSW } from 'virtual:pwa-register/react';

function PWABadge() {
  // check for updates every hour
  const period = 60 * 60 * 1000;

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === 'activated') {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === 'activated') registerPeriodicSync(period, swUrl, r);
        });
      }
    },
  });

  function close() {
    setNeedRefresh(false);
  }

  return (
    <div className="PWABadge" role="alert" aria-labelledby="toast-message">
      {needRefresh ? (
        <div className="PWABadge-toast">
          <div className="PWABadge-message">
            <span id="toast-message">
              Достапна е нова содржина. Кликнете на копчето "Освежи" за да ја ажурирате апликацијата.
            </span>
          </div>
          <Flex gap={2} mt={4}>
            <Button className="PWABadge-toast-button" colorScheme="sky" onClick={() => updateServiceWorker(true)}>
              Освежи
            </Button>
            <Button variant="ghost" className="PWABadge-toast-button" onClick={() => close()}>
              Затвори
            </Button>
          </Flex>
        </div>
      ) : null}
    </div>
  );
}

export default PWABadge;

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(period: number, swUrl: string, r: ServiceWorkerRegistration) {
  if (period <= 0) return;

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    });

    if (resp?.status === 200) await r.update();
  }, period);
}
