import { useExpenseData } from '@/Expenses/hooks/useExpenseData';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';

const ExpenseCreator = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ExpenseCreator" */ './ExpenseCreator'), 'ExpenseCreator'),
);
export const ExpenseCreatorLoader: React.FC = () => {
  const { expense } = useExpenseData();
  return expense ? <ExpenseCreator expense={expense} /> : null;
};
export default ExpenseCreatorLoader;
