import {
  AvatarBadge,
  AvatarGroup,
  Avatar as ChakraAvatar,
  type AvatarBadgeProps,
  type AvatarGroupProps,
  type AvatarProps,
} from '@chakra-ui/react';
import * as React from 'react';

export const Avatar: React.FC<AvatarProps> = (props) => {
  return <ChakraAvatar {...props} />;
};

export { AvatarBadge, AvatarGroup };
export type { AvatarBadgeProps, AvatarGroupProps, AvatarProps };
