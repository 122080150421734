import { useAppSelector } from '@/Core/AppStore';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage.tsx';
import { after15days } from '@/Core/utils/dateTime';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { slugify } from '@/Core/utils/slugify';
import {
  saveToPDF,
  useConvertEstimateToInvoiceMutation,
  useCreateEstimateMutation,
  useDeleteEstimateMutation,
  useGetAllEstimatesQuery,
  useReplicateEstimateMutation,
} from '@/Estimates/api/estimates.api';
import { makeEstimate } from '@/Estimates/api/estimates.model';
import { Estimate } from '@/Estimates/api/estimates.types';
import { notifyEstimateCopied, notifyEstimateDeleted } from '@/Estimates/api/estimates.utils';
import { EstimatesTable } from '@/Estimates/components/EstimatesTable';
import { notifyEstimateConvertedToInvoice } from '@/Invoices/api/invoices.utils';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { TreasuryType } from '@/Treasuries/store/treasuries.types';
import { Button, Menu, MenuButton, MenuItem, MenuList, useToast } from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { faChevronDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const Estimates: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const { data } = useGetAllEstimatesQuery();
  const estimates = React.useMemo(() => getSortedEntityItems<Estimate>(data), [data]);

  const [createEstimate, { isLoading: isCreating }] = useCreateEstimateMutation();
  const [deleteEstimate] = useDeleteEstimateMutation();
  const [copyEstimate] = useReplicateEstimateMutation();
  const [convertToInvoice] = useConvertEstimateToInvoiceMutation();

  const [processingEstimate, setProcessingEstimate] = React.useState<Estimate | null>(null);

  const onCreateEstimate = async (type: TreasuryType) => {
    captureEvent(`create_estimate_${type}`);
    const estimate = makeEstimate({ type, dueDate: after15days() });
    const result = await createEstimate(estimate);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/estimates/${result.data.id}/edit`);
  };

  const onDeleteEstimate = React.useCallback(
    async (estimate: Estimate) => {
      captureEvent('delete_estimate');
      setProcessingEstimate(estimate);

      try {
        const result = await deleteEstimate(estimate.id);
        notifyEstimateDeleted(estimate, result);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingEstimate(null);
      }
    },
    [deleteEstimate],
  );

  const onCopyEstimate = React.useCallback(
    async (estimate: Estimate) => {
      captureEvent('duplicate_estimate');
      setProcessingEstimate(estimate);

      try {
        const result = await copyEstimate(estimate);
        notifyEstimateCopied(estimate, result);
        if (result?.data) {
          navigate(`/estimates/${result.data.id}/edit`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingEstimate(null);
      }
    },
    [copyEstimate, navigate],
  );

  const onTransformEstimate = React.useCallback(
    async (estimate: Estimate) => {
      captureEvent('transform_estimate');
      setProcessingEstimate(estimate);
      try {
        const result = await convertToInvoice(estimate);
        const converted = notifyEstimateConvertedToInvoice(result);
        if (converted) {
          return navigate(`/invoices/${converted.id}`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingEstimate(null);
      }
    },
    [convertToInvoice, navigate],
  );

  const onDownloadPDF = async (estimate: Estimate) => {
    if (!estimate) {
      return;
    }
    captureEvent('download_estimate_pdf');
    setProcessingEstimate(estimate);

    try {
      const result = await saveToPDF(estimate);
      const id = estimate.estimateNumber || estimate.id;
      const file = new Blob([result.data], { type: 'application/pdf' });
      saveAs(file, `presmetka-${slugify(id)}.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessingEstimate(null);
    }
  };

  return (
    <GridPage
      title="Пресметки"
      actions={
        <Menu>
          <MenuButton
            isLoading={isCreating}
            colorScheme={colorScheme}
            as={Button}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            Нова Пресметка
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => onCreateEstimate(TreasuryType.Estimate)}>Нова Про Фактура</MenuItem>
            <MenuItem onClick={() => onCreateEstimate(TreasuryType.Offer)}>Нова Понуда</MenuItem>
          </MenuList>
        </Menu>
      }
    >
      <GridCard title="">
        <EstimatesTable
          estimates={estimates}
          processingEstimate={processingEstimate}
          onTransform={onTransformEstimate}
          onDelete={onDeleteEstimate}
          onCopy={onCopyEstimate}
          onDownload={onDownloadPDF}
        />
      </GridCard>
    </GridPage>
  );
};
Estimates.displayName = 'Estimates';

export default Estimates;
