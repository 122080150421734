import { Flex, Icon, Td, Text, Tr } from '@/core-components';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface Props {
  title: string;
  icon: IconDefinition;
}

export const TableEmptyState: React.FC<Props> = ({ title, icon }) => (
  <Tr>
    <Td colSpan={1000}>
      <Flex gap={4} flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" py={10}>
        <Icon as={FontAwesomeIcon} icon={icon} size="4x" color="gray.500" />
        <Text color="gray.500" fontSize="lg">
          {title}
        </Text>
      </Flex>
    </Td>
  </Tr>
);
