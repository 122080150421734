import { useCreateClientMutation, useGetAllClientsQuery } from '@/Clients/api/clients.api';
import { clientValidationSchema, makeClient } from '@/Clients/api/clients.model';
import { Client, ClientType } from '@/Clients/api/clients.types';
import { ClientCreatorForm } from '@/Clients/components/ClientCreatorForm';
import {
  Avatar,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { DraftableStatus, TODO } from '@/Core/core.types';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { highlightTerm } from '@/Core/utils/highlighter';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash-es/isEmpty';
import React, { useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { FormatOptionLabelMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyle } from './customSelectorStyle';

interface Props {
  id?: string;
  name?: string;
  control?: TODO;
  isClearable?: boolean;
  placeholder?: string;
  defaultValue?: Client | null;
  onClientSelected?: (client: Partial<Client> | null) => void;
}

type ClientOption = Partial<Client> & { label?: string; value?: string };

// Memoize the CreateNewClientModal component to prevent unnecessary rerenders
const CreateNewClientModal = React.memo(
  ({
    client,
    isOpen,
    onClose,
    onCreateNewClient,
  }: {
    client: Client;
    isOpen: boolean;
    onClose: () => void;
    onCreateNewClient: (client: Client) => void;
  }) => {
    const toast = useToast();
    const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
    const [createClient, { isLoading }] = useCreateClientMutation();
    const { handleSubmit } = useFormContext();

    const onSubmit = useCallback(
      async (newClient: TODO) => {
        const createdClient = await createClient({ ...newClient, status: DraftableStatus.Created });
        if ('data' in createdClient) {
          return onCreateNewClient(createdClient.data as Client);
        }
        if ('error' in createdClient) {
          toast(createErrorToast({ description: 'Проблеми со креирање на клиент. Ве молиме пробајте подоцна!' }));
          return;
        }
        // There are validation errors or something similar - handled by toasts
        if ((createdClient as TODO).status === 422) {
          return;
        }

        toast(createErrorToast({ description: 'Проблеми со креирање на клиент. Ве молиме пробајте подоцна!' }));
      },
      [createClient, onCreateNewClient, toast],
    );

    return (
      <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : '3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <ClientCreatorForm client={client} isOnModal={true} />
          </ModalBody>

          <ModalFooter>
            <Button size="md" variant="ghost" mr={3} onClick={onClose}>
              Откажи
            </Button>
            <Button
              type="submit"
              size="md"
              colorScheme={colorScheme}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Зачувај
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);

// Memoize the formatOptionLabel function to avoid creating it on each render
const formatOptionLabel = (client: Client & { __isNew__: boolean }, meta: FormatOptionLabelMeta<Client>) => {
  const isNew = client && client.__isNew__;

  // Only perform highlighting when there's actually an input value to highlight
  const highlightedBusinessName = meta.inputValue
    ? highlightTerm(client?.businessName, meta.inputValue)
    : client?.businessName;

  const highlightedClientName = meta.inputValue ? highlightTerm(client?.fullName, meta.inputValue) : client?.fullName;

  return (
    <Box display="flex" alignItems="center" cursor={isNew ? 'pointer' : 'initial'}>
      {client?.businessName ? (
        <Avatar size="xs" name={client?.businessName || meta?.inputValue} src={client?.avatarUrl} />
      ) : (
        <Avatar size="xs" name={client?.fullName || meta.inputValue} src={client?.avatarUrl} />
      )}
      <Box ml={2} display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
        {isNew ? (
          <Text width="100%" textAlign="left" fontSize="xs" color="sky.700">
            Креирај клиент "{meta.inputValue}"
          </Text>
        ) : null}
        <Text width="100%" textAlign="left" dangerouslySetInnerHTML={{ __html: highlightedBusinessName || '' }} />
        {!isNew ? (
          <Text
            width="100%"
            textAlign="left"
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: highlightedClientName || '' }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const resolver = yupResolver(clientValidationSchema);

export const ClientSelector: React.FC<Props> = ({
  id = 'clientInput',
  isClearable = true,
  control,
  placeholder = 'Изберете клиент',
  name = 'client',
  onClientSelected,
  defaultValue = null,
}) => {
  const [newClient, setNewClient] = useState<Client>(makeClient({ type: ClientType.Company }));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = useGetAllClientsQuery();

  // Memoize clients to avoid recreating on every render
  const clients = useMemo(() => getSortedEntityItems<Client>(data), [data]);

  const clientCreatorForm = useForm<TODO>({
    resolver,
    defaultValues: { ...newClient, type: ClientType.Company },
  });

  const form = useForm();

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ defaultValue, control: control ?? form.control, name });

  // Memoize options array to avoid recreating on every render
  const options: ClientOption[] = useMemo(
    () =>
      clients?.map((client) => ({
        id: client?.id,
        value: client?.id, // required by select
        label: client?.type === ClientType.Company ? client?.businessName : client?.fullName, // required by select
        fullName: client?.fullName,
        businessName: client?.businessName,
        avatarUrl: client?.avatarUrl,
        type: client?.type,
      })) || [],
    [clients],
  );

  // Memoize handleChange function to avoid recreating on every render
  const handleChange = useCallback(
    (selected: Partial<Client> | null) => {
      const client = selected
        ? {
            businessName: selected?.businessName,
            fullName: selected?.fullName,
            id: selected?.id,
            type: selected?.type,
          }
        : null;

      onClientSelected?.(client);
      return client;
    },
    [onClientSelected],
  );

  // Memoize handleCreation function
  const handleCreation = useCallback(
    (inputValue: string) => {
      const client = makeClient({ ...newClient, businessName: inputValue });
      clientCreatorForm.reset({ ...client });
      setNewClient(client);
      onOpen();
    },
    [clientCreatorForm, newClient, onOpen],
  );

  // Memoize onCreateNewClient function
  const onCreateNewClient = useCallback(
    async (client: Client) => {
      setNewClient(client);
      const selected = handleChange(client);
      onChange(selected);
      onClose();
    },
    [handleChange, onChange, onClose],
  );

  // Memoize the noOptionsMessage function to avoid recreating on each render
  const noOptionsMessage = useCallback(
    (option: { inputValue: string }) => (isEmpty(options) ? `Немате клиенти` : `Нема опции за "${option.inputValue}"`),
    [options],
  );

  // Memoize the formatCreateLabel function
  const formatCreateLabel = useCallback((inputValue: string) => `Креирај клиент "${inputValue}"`, []);

  return (
    <>
      <Box width="100%">
        <CreatableSelect
          ref={ref}
          id={id}
          isClearable={isClearable}
          value={options?.find((option) => option?.id === value?.id) as TODO}
          defaultValue={options?.find((option) => option?.id === defaultValue?.id) as TODO}
          isLoading={isLoading}
          isDisabled={isLoading}
          placeholder={placeholder}
          options={options as TODO}
          formatOptionLabel={formatOptionLabel}
          styles={customStyle as TODO}
          noOptionsMessage={noOptionsMessage}
          formatCreateLabel={formatCreateLabel}
          onCreateOption={handleCreation}
          onChange={(selected) => {
            const client = handleChange(selected as TODO);
            return onChange(client || '');
          }}
          onBlur={onBlur}
        />
      </Box>
      {isOpen ? (
        <FormProvider {...clientCreatorForm}>
          <CreateNewClientModal
            client={newClient}
            isOpen={isOpen}
            onClose={onClose}
            onCreateNewClient={onCreateNewClient}
          />
        </FormProvider>
      ) : null}
    </>
  );
};
