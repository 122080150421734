import { SerializedError } from '@reduxjs/toolkit';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { CashPayment } from './payments.types';

type Error =
  | {
      status: number;
      data: {
        message?: string | undefined;
        error?: string | undefined;
        errorCode?: string | undefined;
      };
    }
  | SerializedError;

type Result =
  | {
      data: CashPayment;
    }
  | {
      error: Error;
    };

export const notifyCashPaymentDeleted = (cashPayment: CashPayment, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${cashPayment.id}-error`,
      title: 'Failed to delete cashPayment',
    });
    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === cashPayment.id) {
    toast = createInfoToast({
      id: `${cashPayment.id}-success`,
      title: 'Успешно',
      description: `Сметката "${cashPayment?.referenceNumber ?? ''}" е додадена во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyCashPaymentUpdated = (cashPayment: CashPayment, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${cashPayment.id}-error`,
      title: 'Failed to update cashPayment',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === cashPayment.id) {
    toast = createInfoToast({
      id: `${cashPayment.id}-success`,
      title: 'Успешно',
      description: `Сметката "${cashPayment?.referenceNumber ?? ''}" e зачувана!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const redirectCashPaymentAfterDeletion = (
  cashPayment: CashPayment,
  cashPayments?: Record<string, CashPayment>,
) => {
  if (!cashPayments) {
    return null;
  }
  const { [cashPayment.id]: _, ...withoutRemoved } = cashPayments;
  const deletedIndex = Object.keys(cashPayments).findIndex((i) => cashPayments[i]?.id === cashPayment.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
