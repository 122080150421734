import { useAppSelector } from '@/Core/AppStore';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage.tsx';
import { after15days } from '@/Core/utils/dateTime';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { slugify } from '@/Core/utils/slugify';
import { InvoicesChart } from '@/Dashboard/components/InvoicesChart.tsx';
import {
  saveToPDF,
  useCreateInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetAllInvoicesQuery,
  useReplicateInvoiceMutation,
} from '@/Invoices/api/invoices.api';
import { makeInvoice } from '@/Invoices/api/invoices.model';
import { Invoice } from '@/Invoices/api/invoices.types';
import { notifyInvoiceCopied, notifyInvoiceDeleted } from '@/Invoices/api/invoices.utils';
import { InvoicesStats } from '@/Invoices/components/InvoicesStats.tsx';
import { InvoicesTable } from '@/Invoices/components/InvoicesTable';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { Button, useToast } from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const Invoices: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data } = useGetAllInvoicesQuery();
  const invoices = React.useMemo(() => getSortedEntityItems<Invoice>(data), [data]);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [createInvoice, { isLoading: isCreating }] = useCreateInvoiceMutation();
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [copyInvoice] = useReplicateInvoiceMutation();

  const [processingInvoice, setProcessingInvoice] = React.useState<Invoice | null>(null);

  const onCreateInvoice = async () => {
    captureEvent('create_invoice');
    const invoice = makeInvoice({ dueDate: after15days() });
    const result = await createInvoice(invoice);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/invoices/${result.data.id}/edit`);
  };

  const onDeleteInvoice = React.useCallback(
    async (invoice: Invoice) => {
      captureEvent('delete_invoice');
      setProcessingInvoice(invoice);

      try {
        const result = await deleteInvoice(invoice.id);
        notifyInvoiceDeleted(invoice, result);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingInvoice(null);
      }
    },
    [deleteInvoice],
  );

  const onCopyInvoice = React.useCallback(
    async (invoice: Invoice) => {
      captureEvent('duplicate_invoice');
      setProcessingInvoice(invoice);

      try {
        const result = await copyInvoice(invoice);
        const copy = notifyInvoiceCopied(invoice, result);

        if (copy) {
          navigate(`/invoices/${copy.id}/edit`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingInvoice(null);
      }
    },
    [copyInvoice, navigate],
  );

  const onDownloadPDF = async (invoice: Invoice) => {
    if (!invoice) {
      return;
    }
    captureEvent('download_invoice_pdf');
    setProcessingInvoice(invoice);

    try {
      const result = await saveToPDF(invoice);
      const id = invoice.invoiceNumber || invoice.id;
      const file = new Blob([result.data], { type: 'application/pdf' });
      saveAs(file, `faktura-${slugify(id)}.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessingInvoice(null);
    }
  };

  return (
    <GridPage
      title="Фактури"
      actions={
        <Button
          onClick={onCreateInvoice}
          isLoading={isCreating}
          colorScheme={colorScheme}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Нова Фактура
        </Button>
      }
    >
      <GridCard title="">
        <InvoicesTable
          invoices={invoices}
          processingInvoice={processingInvoice}
          onDelete={onDeleteInvoice}
          onCopy={onCopyInvoice}
          onDownload={onDownloadPDF}
        />
      </GridCard>
      <GridCard title="">
        <InvoicesStats invoices={invoices} />
      </GridCard>
      <GridCard title="">
        <InvoicesChart />
      </GridCard>
    </GridPage>
  );
};
Invoices.displayName = 'Invoices';

export default Invoices;
