import { formatISO } from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';
import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { getFormattedDate } from '../../Core/utils/dateTime';
import { makeProduct } from '../../Products/api/products.model';
import { PaymentStatus, TreasuryTheme, TreasuryType } from '../../Treasuries/store/treasuries.types';
import { Currency } from '../../Treasuries/store/treasuries.utils';
import { DocumentLineItem, Invoice, InvoiceSettings } from './invoices.types';

export const makeInvoice = (invoice?: Partial<Invoice>): Invoice => {
  return {
    id: v4(),
    paymentStatus: PaymentStatus.Unpaid,
    type: TreasuryType.Invoice,
    currency: Currency.MKD,
    status: DraftableStatus.Draft,
    payments: [],
    totalAmount: 0,
    paidAmount: 0,
    ...invoice,
    lineItems: cloneDeep(invoice?.lineItems ?? []),
    settings: makeInvoiceSettings(invoice?.settings),
    dueDate: invoice?.dueDate ? getFormattedDate(invoice?.dueDate) : null,
    invoicedDate: invoice?.invoicedDate ? getFormattedDate(invoice?.invoicedDate) : getFormattedDate(new Date()),
    createdAt: formatISO(new Date()),
  };
};

export const makeInvoiceSettings = (settings?: Partial<InvoiceSettings> | null): InvoiceSettings => {
  return {
    id: v4(),
    showSignaturesLine: true,
    showDiscountOnLineItems: false,
    showLineNumber: false,
    theme: TreasuryTheme.Simple,
    signatureLineOneLabel: 'Примил',
    signatureLineTwoLabel: '',
    signatureLineThreeLabel: 'Одобрил',
    language: 'mkd',
    ...settings,
  };
};

export const makeLineItem = (item?: Partial<DocumentLineItem> | null): DocumentLineItem => {
  return {
    id: v4(),
    uuid: v4(),
    lineNumber: 1,
    name: '',
    description: '',
    quantity: 1,
    unitPrice: 0,
    discount: 0,
    taxRate: 18,
    ...item,
    product: makeProduct(item?.product),
  };
};
