import { formatISO } from 'date-fns';
import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { getFormattedDate } from '../../Core/utils/dateTime';
import { PaymentMethod } from '../../Core/utils/payment-methods';
import { Currency } from '../../Treasuries/store/treasuries.utils';
import { Expense } from './expenses.types';

export const makeExpense = (expense?: Partial<Expense>): Expense => {
  return {
    id: v4(),
    taxRate: 18,
    status: DraftableStatus.Draft,
    paymentMethod: PaymentMethod.Cash,
    currency: Currency.MKD,
    amount: '0',
    ...expense,
    expenseDate: expense?.expenseDate ? getFormattedDate(expense.expenseDate) : getFormattedDate(new Date()),
    createdAt: formatISO(new Date()),
  };
};
