import { useGetCompanyProfileQuery, useUpdateCompanyLogoMutation } from '@/Company/api/company.api';
import { Company } from '@/Company/api/company.types';
import { Box, Image, Skeleton } from '@/core-components';
import { useDropzoneLogoUpload } from '@/Core/hooks/useDropzoneUpload';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash-es/isEmpty';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './CompanyLogo.module.scss';

// DA FUQ IS HAPPENING HERE

const LogoUploader: React.FC<React.PropsWithChildren<{ onUpload: (flag: boolean) => void }>> = ({
  children,
  onUpload,
}) => {
  const [uploadCompanyLogo, { isLoading }] = useUpdateCompanyLogoMutation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }

      onUpload(isLoading);
      const formData = new FormData();
      formData.append('logo', acceptedFiles[0]);

      uploadCompanyLogo(formData)
        .then(() => {
          onUpload(isLoading);
        })
        .catch(() => {
          onUpload(isLoading);
        });
    },
    [onUpload, uploadCompanyLogo, isLoading],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzoneLogoUpload({ onDrop });

  return (
    <div {...getRootProps()} style={{ cursor: 'pointer' }}>
      <input {...getInputProps()} />

      {isDragActive ? (
        <Box
          border="1px"
          borderRadius="lg"
          height="100px"
          width="200px"
          color="sky.500"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize="xl" w={10} h={10}>
            <FontAwesomeIcon icon={faCloudArrowUp} />
          </Box>
        </Box>
      ) : (
        children
      )}
    </div>
  );
};

export const LogoPlaceholder: React.FC<{ isUploadingLogo: boolean; uploadable: boolean }> = ({
  isUploadingLogo,
  uploadable = true,
}) => {
  return (
    <div className={`${styles.card} d-print-none`} style={{ cursor: uploadable ? 'pointer' : 'default' }}>
      <div className="card-body d-flex align-items-center justify-content-center">
        {isUploadingLogo ? (
          <Skeleton startColor="sky.500" endColor="sky.500" height="60px" width="160px" />
        ) : (
          <h5 className="card-title text-center mb-0 text-muted">лого</h5>
        )}
      </div>
      {uploadable ? <small className="text-center text-muted mb-2">прикачи лого на компанија</small> : null}
    </div>
  );
};

export const JustLogo: React.FC<React.PropsWithChildren<{ url: string; name?: string; isUploadingLogo: boolean }>> = ({
  url,
  name,
  isUploadingLogo,
}) => (
  <div className={styles.logo}>
    {isUploadingLogo ? (
      <Skeleton startColor="sky.500" endColor="teal.500" height="100px" width="200px" />
    ) : (
      <React.Suspense fallback={<Skeleton startColor="sky.500" endColor="teal.500" height="100px" width="200px" />}>
        <Image
          objectFit="cover"
          src={url}
          alt={`${name || 'Company'} Logo`}
          className={styles.img}
          ignoreFallback
          fallbackSrc="https://space-works.ams3.cdn.digitaloceanspaces.com/placeholders/logo-ipsum-place.png"
        />
      </React.Suspense>
    )}
  </div>
);

export const RenderCompanyLogo: React.FC<{ company?: Company; isUploadingLogo?: boolean; uploadable: boolean }> = ({
  company,
  isUploadingLogo = false,
  uploadable = true,
}) => {
  const hasLogo: boolean = company?.logos?.medium != null;
  const url = hasLogo ? company?.logos?.medium : company?.logoUrl;

  return useMemo(() => {
    if (url != null) {
      return <JustLogo url={url} name={company?.name} isUploadingLogo={isUploadingLogo} />;
    }
    return <LogoPlaceholder isUploadingLogo={isUploadingLogo} uploadable={uploadable} />;
  }, [url, company?.name, isUploadingLogo, uploadable]);
};

export const CompanyLogo: React.FC<{ uploadable?: boolean }> = ({ uploadable = true }) => {
  const { data: company } = useGetCompanyProfileQuery();
  const [isUploadingLogo, setUploadingLogo] = useState(false);

  const onUpload = (flag: boolean) => setUploadingLogo(flag);

  if (!company) {
    return <LogoPlaceholder isUploadingLogo={isUploadingLogo} uploadable={uploadable} />;
  }

  return uploadable ? (
    <LogoUploader onUpload={onUpload}>
      <RenderCompanyLogo company={company} isUploadingLogo={isUploadingLogo} uploadable={uploadable} />
    </LogoUploader>
  ) : (
    <RenderCompanyLogo company={company} uploadable={uploadable} />
  );
};
CompanyLogo.displayName = 'CompanyLogo';
