import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { useClientData } from '../hooks/useClientData';

const ClientCreator = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ClientCreator" */ './ClientCreator'), 'ClientCreator'),
);

export const ClientCreatorLoader: React.FC = () => {
  const { client } = useClientData();
  return client ? <ClientCreator client={client} /> : null;
};

export default ClientCreatorLoader;
