import { Box, SimpleGrid } from '@/core-components';
import React from 'react';
import { TopCategoriesChart } from './TopCategoriesChart';
import { TopClientsChart } from './TopClientsChart';

export const ChartHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      width="100%"
      height="86px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      borderBottom="1px solid #e2e8f0"
      p={3}
    >
      {children}
    </Box>
  );
};

export const DashboardTopCharts: React.FC = () => {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
      <TopCategoriesChart />
      <TopClientsChart />
    </SimpleGrid>
  );
};
DashboardTopCharts.displayName = 'DashboardTopCharts';
