import { Avatar, Badge, Box, ButtonGroup, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction';
import { useIsEditing } from '@/Core/hooks/useIsEditing';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { highlightTerm } from '@/Core/utils/highlighter';
import { getTranslatedClientType } from '@/Core/utils/translatableTypes';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Client, ClientType } from '../api/clients.types';

interface ActionsProps {
  client: Client;
  isEditing: boolean;
  onEdit?: (client: Client) => Client | unknown;
  onDelete?: (client: Client) => Client | unknown;
}

const Actions: React.FC<ActionsProps> = ({ client, isEditing, onEdit, onDelete }) => (
  <ButtonGroup spacing={3}>
    <ActionButton
      isEditing={isEditing}
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(client)}
    />
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label="Додади во корпа"
      tooltipBg="red.600"
      onClick={() => onDelete?.(client)}
    />
  </ButtonGroup>
);

interface Props {
  client: Client;
  isLoading: boolean;
  onSave?: (client: Client) => Client | unknown;
  onEdit?: (client: Client) => Client | unknown;
  onDelete?: (client: Client) => Client | unknown;
}

export const ClientItem: React.FC<Props> = ({ isLoading, client, onEdit, onDelete }) => {
  const { query } = useSearchQuery();
  const [isEditing] = useIsEditing({ pattern: '/clients/:id/edit', id: client.id });
  const { fullName, businessName, type, avatarUrl } = client;
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const highlightedFullName = query ? highlightTerm(fullName, query) : fullName;
  const highlightedBusinessName = query ? highlightTerm(businessName, query) : businessName;

  return (
    <ListItemAction
      id={client.id}
      item={{ entity: client, path: 'clients' }}
      disabled={isLoading}
      actions={<Actions isEditing={isEditing} client={client} onEdit={() => onEdit?.(client)} onDelete={onDelete} />}
    >
      <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

      <Box display="flex" marginBottom={1} justifyContent="flex-end">
        <Text fontSize="xs">
          <Badge colorScheme={colorScheme} variant="subtle" textTransform="none" fontSize="0.8em">
            {getTranslatedClientType(type)}
          </Badge>
        </Text>
      </Box>

      <Box display="flex" width="100%" marginTop={3} marginBottom={3} justifyContent="flex-start" alignItems="center">
        <Avatar name={fullName} src={avatarUrl} size="sm" />

        <Box display="flex" width="100%" marginLeft={1} flexDirection="column">
          <Text
            paddingLeft={2}
            color={type === ClientType.Company ? 'gray.600' : 'black'}
            dangerouslySetInnerHTML={{ __html: highlightedFullName ?? `${fullName ?? ''}` }}
          />

          <Text
            fontSize="md"
            paddingLeft={2}
            dangerouslySetInnerHTML={{ __html: highlightedBusinessName ?? `${businessName ?? ''}` }}
          />
        </Box>
      </Box>
    </ListItemAction>
  );
};

ClientItem.displayName = 'ClientItem';
