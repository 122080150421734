import { useGetCompanyProfileQuery } from '@/Company/api/company.api';
import { CompanyLogo } from '@/Company/components/CompanyLogo/CompanyLogo';
import { Box, Button, Collapse, Flex, Heading, SimpleGrid, Text } from '@/core-components';
import { DashboardHeaderLoader } from '@/Core/components/Loaders/DashboardHeaderLoader';
import { useColorModeValue } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { Link } from 'react-router-dom';

const HeaderRow: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Flex minHeight="60px" alignItems="center" data-testid="dashboard-header">
      <Box w="100%" bg={bgColor} p={4} borderRadius="lg" borderWidth="1px" borderColor={borderColor} boxShadow="sm">
        {children}
      </Box>
    </Flex>
  );
};

const InfoItem: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
  <Flex alignItems="center" mb={1}>
    <Text fontWeight="medium" color="gray.500" minW="80px">
      {label}:
    </Text>
    <Text ml={2}>{value}</Text>
  </Flex>
);

export const DashboardHeader: React.FC = () => {
  const { data: company, isLoading, isFetching } = useGetCompanyProfileQuery();
  const headingColor = useColorModeValue('gray.800', 'white');
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (isLoading || isFetching || isEmpty(company)) {
    return (
      <HeaderRow>
        <DashboardHeaderLoader />
      </HeaderRow>
    );
  }

  return (
    <HeaderRow>
      <Flex direction="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontSize="xl" color={headingColor} _hover={{ color: 'blue.500' }}>
            <Link to={'/company/profile/'} aria-label="company-name">
              {company?.name}
            </Link>
          </Heading>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label={isExpanded ? 'Hide details' : 'Show details'}
          >
            <FontAwesomeIcon
              icon={isExpanded ? faChevronUp : faChevronDown}
              style={{ width: '16px', height: '16px' }}
            />
          </Button>
        </Flex>

        <Collapse in={isExpanded}>
          <Box mt={4}>
            <Flex direction="column">
              <Flex direction={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }} gap={6}>
                <Box maxWidth="200px" height="150px" flexShrink={0}>
                  <Link to="/company/profile/">
                    <CompanyLogo uploadable={false} />
                  </Link>
                </Box>
                <Box flex="1" width="100%">
                  <SimpleGrid spacing="20px" columns={{ base: 1, md: 2 }}>
                    <Box>
                      <InfoItem label="Адреса" value={company?.address} />
                      <Flex>
                        {company?.city && <Text>{company.city},&nbsp;</Text>}
                        {company?.postalCode && <Text>{company.postalCode} - &nbsp;</Text>}
                        {company?.country && <Text>{company.country}</Text>}
                      </Flex>
                      <InfoItem label="Телефон" value={company?.businessPhone} />
                      <InfoItem label="Мобилен" value={company?.mobilePhone} />
                    </Box>

                    <Box>
                      <InfoItem
                        label="Веб"
                        value={
                          <a
                            href={company?.businessUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'blue.500', textDecoration: 'underline' }}
                          >
                            {company?.businessUrl}
                          </a>
                        }
                      />
                      <InfoItem label="Емаил" value={company?.email} />
                      <InfoItem label="ЕМБС" value={company?.businessId} />
                      <InfoItem label="ЕДБ" value={company?.businessVatId} />
                      <InfoItem label="Ж.Сметка" value={company?.businessBankAccount} />
                    </Box>
                  </SimpleGrid>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Collapse>
      </Flex>
    </HeaderRow>
  );
};

DashboardHeader.displayName = 'DashboardHeader';
