import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { lazyRetry } from '@/lazyRetry';
import { useDeleteProductMutation } from '@/Products/api/products.api';
import { Product } from '@/Products/api/products.types';
import { notifyProductDeleted } from '@/Products/api/products.utils';
import { useProductData } from '@/Products/hooks/useProductData';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const ProductMobilePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProductMobilePage" */ './ProductMobilePage'), 'ProductMobilePage'),
);
const ProductPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProductPage" */ './ProductPage'), 'ProductPage'),
);

export const ProductPageLoader: React.FC = () => {
  const navigate = useNavigate();
  const { product } = useProductData();
  const [deleteProduct] = useDeleteProductMutation();

  const onDeleteProduct = async (productToDelete: Product) => {
    const result = await deleteProduct(productToDelete.id);
    notifyProductDeleted(productToDelete, result);

    return navigate(`/products`);
  };

  return product ? (
    isMobile ? (
      <ProductMobilePage product={product} onDelete={onDeleteProduct} />
    ) : (
      <ProductPage product={product} onDelete={onDeleteProduct} />
    )
  ) : (
    <DocumentNotFound />
  );
};
