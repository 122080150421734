import { useGetAuthenticatedUserQuery } from '@/Authentication/store/authenticatedUser.api';
import { useGetCompanyProfileQuery } from '@/Company/api/company.api';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  IconButton,
  Progress,
  Text,
} from '@/core-components';
import { useAppDispatch, useAppSelector } from '@/Core/AppStore';
import { BrojkiLogo } from '@/Core/components/BrojkiLogo/BrojkiLogo';
import { NavbarItemLoader } from '@/Core/components/Loaders/NavbarItemLoader';
import styles from '@/Core/components/Navbar/Navbar.module.scss';
import { useIsLoadingNavigation } from '@/Core/hooks/useIsLoading';
import { useMediaMatch } from '@/Core/hooks/useMediaMatch';
import { useOnline } from '@/Core/hooks/useOnline';
import { MediaQuery } from '@/Core/utils/mediaQuery';
import { NotificationsPopover } from '@/Notifications/components/NotificationsPopover';
import { toggleMobileSidebar } from '@/Sidebar/store/sidebar.actions';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useMatch } from 'react-router-dom';

const Header: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [isLoading] = useIsLoadingNavigation();

  return (
    <Box position="relative" backgroundColor="white" borderBottom="1px solid #e2e8f0" data-testid="navbar">
      {isLoading ? <Progress colorScheme={colorScheme} size="xs" isIndeterminate /> : <Box height="4px" />}
      <Box
        display="flex"
        flex="0 0 auto"
        justifyContent="space-between"
        maxHeight="56px"
        width="100%"
        color="#333"
        zIndex="1000"
        sx={{ '@media print': { display: 'none' } }}
        position="relative"
        marginTop="-4px"
      >
        {children}
      </Box>
    </Box>
  );
};

const CurrentUser: React.FC<React.PropsWithChildren<{ isActive: boolean }>> = ({ children, isActive }) => (
  <Box
    data-test-id="current-user"
    className={clsx({ [styles.active]: isActive })}
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding="0.5rem"
    borderLeft="1px solid #d2d7e0"
    textShadow="1px 1px rgba(0, 0, 0, 0.1)"
    __css={{
      [`a:hover`]: {
        background: isActive ? '#333' : '-webkit-linear-gradient(#00dfd8, #007cf0)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    }}
  >
    {children}
  </Box>
);

const CurrentCompany: React.FC<React.PropsWithChildren<{ isActive: boolean }>> = ({ children, isActive }) => (
  <Box
    data-test-id="current-company"
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding="0.5rem"
    textShadow="1px 1px rgba(0, 0, 0, 0.1)"
    borderLeft="1px solid #d2d7e0"
    className={clsx({ [styles.active]: isActive })}
    __css={{
      p: {
        maxWidth: '220px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      [`a:hover`]: {
        background: isActive ? '#333' : '-webkit-linear-gradient(#00dfd8, #007cf0)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    }}
  >
    {children}
  </Box>
);

const Actions: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Flex alignItems="center" justifyContent="center" position="relative">
    {children}
  </Flex>
);

export const Navbar: React.FC = () => {
  const isMedium = useMediaMatch(MediaQuery.medium);
  const isLarge = useMediaMatch(MediaQuery.large);
  const matchCompany = useMatch('/company/*');
  const matchProfile = useMatch('/profile/*');
  const dispatch = useAppDispatch();
  const isOnline = useOnline();

  const isInCompanyProfile = matchCompany != null;
  const isInUserProfile = matchProfile != null;

  const { data: user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useGetAuthenticatedUserQuery();
  const { data: company, isLoading: isLoadingCompany, isFetching: isFetchingCompany } = useGetCompanyProfileQuery();

  const isLoading = isLoadingUser || isFetchingUser;
  return (
    <Header>
      <Box display="flex" alignItems="center" justifyContent="center" ml={isMobile ? 0 : 2}>
        {isMobile ? (
          <IconButton
            onClick={() => dispatch(toggleMobileSidebar(true))}
            variant="ghost"
            colorScheme="gray"
            aria-label="Open Menu"
            fontSize="26px"
            icon={<FontAwesomeIcon icon={faBars} />}
          />
        ) : null}
        <BrojkiLogo
          height={34}
          width={40}
          showLogoShape={!isMobile}
          showLogoText
          vertical
          sShapeColor="#1d4263"
          pShapeColor="#1d4263"
          textColor="#1d4263"
        />
      </Box>
      {!isOnline ? (
        <Flex ml="auto" mr="auto">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Немате интернет конекција!</AlertTitle>
            {isLarge ? (
              <AlertDescription>Ве молиме проверете ја вашата конекција пред да продолжете!</AlertDescription>
            ) : null}
          </Alert>
        </Flex>
      ) : null}
      <Actions>
        <NotificationsPopover />
        {isLoadingCompany || isFetchingCompany ? (
          <NavbarItemLoader />
        ) : (
          <CurrentCompany isActive={isInCompanyProfile} data-test-id="current-company">
            <Link to="/company/profile">
              <Flex alignItems="center">
                <Avatar
                  name={company?.name || 'Компанија'}
                  size="sm"
                  src={company?.logos ? company?.logos.small : undefined}
                />
                {isMedium || isLarge ? (
                  <Text fontSize="md" fontWeight="bold" ml="2">
                    {company?.name || 'Компанија'}
                  </Text>
                ) : null}
              </Flex>
            </Link>
          </CurrentCompany>
        )}
        {isLoading ? (
          <NavbarItemLoader />
        ) : (
          <CurrentUser isActive={isInUserProfile} data-test-id="current-user">
            <Link to="/profile">
              <Flex alignItems="center">
                <Avatar name={user?.name} size="sm" src={user?.avatarUrl}>
                  <AvatarBadge boxSize="1rem" bg={isOnline ? 'green.500' : 'red.500'} />
                </Avatar>
                {isMedium || isLarge ? (
                  <Text fontSize="md" fontWeight="bold" ml="2">
                    {user?.name}
                  </Text>
                ) : null}
              </Flex>
            </Link>
          </CurrentUser>
        )}
      </Actions>
    </Header>
  );
};
Navbar.displayName = 'Navbar';
