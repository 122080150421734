import React from 'react';
import { lazyRetry } from '../../lazyRetry';
import { useProjectData } from '../hooks/useProjectData';

const ProjectCreator = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProjectCreator" */ './ProjectCreator'), 'ProjectCreator'),
);
export const ProjectCreatorLoader: React.FC = () => {
  const { project } = useProjectData();
  return project ? <ProjectCreator project={project} /> : null;
};
export default ProjectCreatorLoader;
