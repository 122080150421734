import { useGetCompanyProfileQuery, useUpdateCompanySettingsMutation } from '@/Company/api/company.api';
import { Company } from '@/Company/api/company.types';
import { EstimatesSettings } from '@/Company/components/EstimatesSettings';
import { InvoicesSettings } from '@/Company/components/InvoicesSettings';
import { Box, Button, Divider, Text, useToast, VStack } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { CompanyLoader } from '@/Core/components/Loaders/CompanyLoader';
import { createSuccessToast } from '@/Toaster/store/toaster.utils';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface FormValues {
  invoiceDefaultTerms?: string;
  invoiceDefaultTheme?: 'Modern' | 'Simple' | 'Classic';
  invoiceShowLineNumber?: boolean;
  invoiceShowDiscountOnLineItems?: boolean;
  invoiceShowSignaturesLine?: boolean;
  invoiceSignatureLineOneLabel?: string;
  invoiceSignatureLineTwoLabel?: string;
  invoiceSignatureLineThreeLabel?: string;
  estimateDefaultTerms?: string;
  estimateDefaultTheme?: 'Modern' | 'Simple' | 'Classic';
  estimateShowLineNumber?: boolean;
  estimateShowDiscountOnLineItems?: boolean;
  estimateShowSignaturesLine?: boolean;
  estimateSignatureLineOneLabel?: string;
  estimateSignatureLineTwoLabel?: string;
  estimateSignatureLineThreeLabel?: string;
}
const defaultValues: FormValues = {
  invoiceDefaultTerms: '',
  invoiceDefaultTheme: 'Simple',
  invoiceShowLineNumber: false,
  invoiceShowDiscountOnLineItems: false,
  invoiceShowSignaturesLine: true,
  invoiceSignatureLineOneLabel: '',
  invoiceSignatureLineTwoLabel: '',
  invoiceSignatureLineThreeLabel: '',
  estimateDefaultTerms: '',
  estimateDefaultTheme: 'Simple',
  estimateShowLineNumber: false,
  estimateShowDiscountOnLineItems: false,
  estimateShowSignaturesLine: true,
  estimateSignatureLineOneLabel: '',
  estimateSignatureLineTwoLabel: '',
  estimateSignatureLineThreeLabel: '',
};
export const CompanySettings = () => {
  const toast = useToast();
  const { data: company, isLoading: isLoadingCompany, isFetching } = useGetCompanyProfileQuery();
  const [updateCompanyProfileSettings, { isLoading: isUpdating }] = useUpdateCompanySettingsMutation();
  const buttonColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const isLoading = isLoadingCompany || isFetching || isEmpty(company);
  const isSaving = isUpdating;

  const form = useForm({
    defaultValues: useMemo(() => {
      return { ...defaultValues, ...company?.settings };
    }, [company?.settings]),
  });
  const { reset } = form;

  useEffect(() => {
    if (company?.settings) {
      reset({
        ...defaultValues,
        ...company?.settings,
      });
    }
  }, [reset, company?.settings]);

  const onSubmit = async (values: FormValues) => {
    await updateCompanyProfileSettings(values as Company);

    toast(
      createSuccessToast({
        position: 'top',
        title: 'Успешно',
        description: 'Подесувањата се зачувани.',
      }),
    );
  };

  return (
    <VStack spacing={4} align="stretch">
      <Box>
        <Box display="flex" alignItems="center" mb={4}>
          <Box display="inline-flex" fontSize="xl" mr={2} color="sky.400">
            <FontAwesomeIcon icon={faCircleInfo} />
          </Box>
          <Box>
            <Text>Овде можете да ги конфигурирате предодредените подесувања.</Text>
            <Text color="gray.600">Секој нов документ ќе се создава со овие подесувања.</Text>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <CompanyLoader />
      ) : (
        <Box mt={4}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <InvoicesSettings isLoading={isSaving || isLoading} />
              <Divider my={6} color="gray.200" />
              <EstimatesSettings isLoading={isSaving || isLoading} />
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  colorScheme={buttonColorScheme}
                  isLoading={isLoading || isSaving}
                  isDisabled={isLoading || isSaving}
                >
                  Зачувај Подесувања
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      )}
    </VStack>
  );
};
// exporting as default for lazy load
export default CompanySettings;
