import {
  Box,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Switch,
  Text,
  Textarea,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectDefaultThemeSetting } from './SelectDefaultThemeSetting';

export const InvoicesSettings: React.FC<React.PropsWithChildren<{ isLoading: boolean }>> = ({ isLoading }) => {
  const switchColor = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const {
    watch,
    control,
    formState: { errors, touchedFields },
    register,
  } = useFormContext();

  const showSignaturesLine = watch('invoiceShowSignaturesLine');
  return (
    <>
      <Text as="legend" mb={0}>
        Фактури
      </Text>
      <Text color="gray.500">Секоја нова фактура ќе се создава со овие подесувања.</Text>
      <Box mb={4} />
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="invoiceDefaultTerms">
              Правила и Услови
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Textarea
              id="invoiceDefaultTerms"
              {...register('invoiceDefaultTerms')}
              isDisabled={isLoading}
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.invoiceDefaultTerms ? 'Внеси име на компанија' : ''}
            />
            <FormErrorMessage>{errors.invoiceDefaultTerms?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="invoiceDefaultTheme">
              Шаблон
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <SelectDefaultThemeSetting control={control} name="invoiceDefaultTheme" isLoading={isLoading} />
            <FormErrorMessage>{errors.invoiceDefaultTheme?.message?.toString()} </FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="invoiceShowLineNumber">
              Прикажи реден број на линија
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              id="invoiceShowLineNumber"
              colorScheme={switchColor}
              isDisabled={isLoading}
              {...register('invoiceShowLineNumber')}
            />
            <FormErrorMessage>{errors.invoiceShowLineNumber?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      {/* <FormControl className="mb-3 row align-items-end">
        <FormLabel fontSize="sm" htmlFor="invoiceShowDiscountOnLineItems" className={`col-lg-4 col-form-label`}>
          Додатете рабат на артиклите
        </FormLabel>
        <div className={`col-lg-8`}>
          <Switch
            id="invoiceShowDiscountOnLineItems"
            isDisabled={isLoading}
            colorScheme={switchColor}
            {...register('invoiceShowDiscountOnLineItems')}
          />
          <FormErrorMessage><>{errors.invoiceShowDiscountOnLineItems}</></FormErrorMessage>
        </div>
      </FormControl> */}
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="invoiceShowUnit">
              Прикажи мерна единица на артиклите
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              id="invoiceShowUnit"
              colorScheme={switchColor}
              isDisabled={isLoading}
              {...register('invoiceShowUnit')}
            />
            <FormErrorMessage>{errors.invoiceShowUnit?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl mb={3}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={4}>
            <FormLabel fontSize="sm" htmlFor="invoiceShowSignaturesLine">
              Прикажи ја линијата за потписи
            </FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              id="invoiceShowSignaturesLine"
              colorScheme={switchColor}
              isDisabled={isLoading}
              {...register('invoiceShowSignaturesLine')}
            />
            <FormErrorMessage>{errors.invoiceShowSignaturesLine?.message?.toString()}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <Collapse in={showSignaturesLine}>
        <FormControl mb={3}>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={3}>
              <FormLabel fontSize="sm" htmlFor="invoiceSignatureLabels">
                Лабели за потписи
              </FormLabel>
            </GridItem>
            <GridItem colSpan={9}>
              <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="invoiceSignatureLineOneLabel">
                      Лабела за потпис 1
                    </FormLabel>
                    <Textarea
                      rows={3}
                      id="invoiceSignatureLineOneLabel"
                      placeholder="Потпис 1"
                      focusBorderColor={inputFocusBorderColor}
                      isDisabled={isLoading}
                      {...register('invoiceSignatureLineOneLabel')}
                    />
                    <FormErrorMessage>{errors.invoiceSignatureLineOneLabel?.message?.toString()}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="invoiceSignatureLineTwoLabel">
                      Лабела за потпис 2
                    </FormLabel>
                    <Textarea
                      rows={3}
                      id="invoiceSignatureLineTwoLabel"
                      placeholder="Потпис 2"
                      focusBorderColor={inputFocusBorderColor}
                      isDisabled={isLoading}
                      {...register('invoiceSignatureLineTwoLabel')}
                    />
                    <FormErrorMessage>{errors.invoiceSignatureLineTwoLabel?.message?.toString()}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" htmlFor="invoiceSignatureLineThreeLabel">
                      Лабела за потпис 3
                    </FormLabel>
                    <Textarea
                      rows={3}
                      id="invoiceSignatureLineThreeLabel"
                      placeholder="Потпис 3"
                      focusBorderColor={inputFocusBorderColor}
                      isDisabled={isLoading}
                      {...register('invoiceSignatureLineThreeLabel')}
                    />
                    <FormErrorMessage>{errors.invoiceSignatureLineThreeLabel?.message?.toString()}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </FormControl>
      </Collapse>
    </>
  );
};
