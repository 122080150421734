import { useAppSelector } from '@/Core/AppStore';
import { CreatorDatePicker } from '@/Core/components/DatePicker/CreatorDatePicker';
import { PaymentMethodSelector } from '@/Core/components/Selectors/PaymentMethodSelector';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { validate } from '@/Core/utils/validate';
import { useAddInvoicePaymentMutation, useGetInvoiceQuery } from '@/Invoices/api/invoices.api';
import { Invoice, InvoicePayment } from '@/Invoices/api/invoices.types';
import { getInvoiceTotalPaidAmount } from '@/Invoices/api/invoices.utils';
import { PaymentsHistoryList } from '@/Invoices/components/PaymentsHistoryList';
import { createSuccessToast } from '@/Toaster/store/toaster.utils';
import { formatMoney, getTreasuryTotalAmount } from '@/Treasuries/store/treasuries.utils';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash-es/isEmpty';
import round from 'lodash-es/round';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMatch } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  invoice?: Invoice;
  modalSize?: 'sm' | 'lg' | 'xl' | 'xs' | 'md' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
}

const validationSchema = validate.object().shape({
  amount: validate.number(),
  paymentMethod: validate.string(),
  paymentDate: validate.date(),
  notes: validate.string(),
});

interface FormValues {
  amount?: number;
  paymentMethod?: string;
  paymentDate?: Date;
  notes?: string;
}

const resolver = yupResolver(validationSchema);
export const InvoicePaymentModal: React.FC<Props> = ({ isOpen, onClose, modalSize = '2xl' }) => {
  const toast = useToast();
  const { searchParams, setSearchParams } = useSearchParams();
  const match = useMatch('/invoices/:id');
  const invoiceId = match?.params.id ?? searchParams.get('id');
  const { data: invoice, isLoading } = useGetInvoiceQuery(invoiceId ?? '');
  const [registerInvoicePayment, { isLoading: isUpdating }] = useAddInvoicePaymentMutation();
  const buttonColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  const hasPayments = React.useMemo(() => {
    if (invoice && invoice?.payments) {
      return !isEmpty(invoice?.payments) && invoice?.payments?.length > 0;
    }
    return false;
  }, [invoice]);

  const leftToPay = React.useMemo(() => {
    return invoice ? round(getTreasuryTotalAmount(invoice) - getInvoiceTotalPaidAmount(invoice)) : 0;
  }, [invoice]);

  const noMoreLeftToPay = React.useMemo(
    () =>
      invoice?.payments &&
      invoice?.payments?.length > 0 &&
      round(invoice?.totalAmount) === round(invoice?.paidAmount) &&
      leftToPay <= 0,
    [invoice, leftToPay],
  );

  const form = useForm<FormValues>({
    resolver,
    defaultValues: { amount: leftToPay, paymentMethod: 'Invoice', paymentDate: new Date(), notes: '' },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  React.useEffect(() => {
    reset({ amount: leftToPay, paymentMethod: 'Invoice', paymentDate: new Date(), notes: '' });
  }, [invoice, leftToPay, reset]);

  const closeModal = () => {
    setSearchParams((params) => {
      searchParams.delete('id');
      return params;
    });
    onClose?.();
  };

  const onSubmit = async (values: FormValues) => {
    if (!invoice) {
      return Promise.resolve(null);
    }
    captureEvent('register_invoice_payment');
    await registerInvoicePayment({ invoice, payment: values as InvoicePayment });
    closeModal();
    toast(
      createSuccessToast({
        title: 'Успешно',
        description: 'Плаќањето е регистрирано.',
      }),
    );
  };

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius={modalSize === 'full' ? 'none' : 'md'}>
        <ModalHeader>
          <Text fontWeight="normal" fontSize="lg">
            Регистрирај плаќање за Фактура Бр: <b>{invoice?.invoiceNumber}</b>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <div className="d-flex w-100 mb-2 flex-column">
                <p className="text-muted mb-1">Клиент</p>
                {invoice?.client?.fullName && <p className="mb-0">{invoice?.client?.fullName}</p>}
                {invoice?.client?.businessName && <p className="fw-light mb-0">{invoice?.client?.businessName}</p>}
              </div>

              {hasPayments ? (
                <div className="mb-3">
                  <div className="row">
                    <div className="col-4">Вкупно</div>
                    <div className="col-4">Платено</div>
                    <div className="col-4">Преостанато</div>
                  </div>
                  <div className="row">
                    <div className="col-4 text-info px-2">
                      <Text fontSize="lg"> {formatMoney(invoice ? getTreasuryTotalAmount(invoice) : 0)}</Text>
                    </div>
                    <div className="col-4 text-success px-2">
                      <Text fontSize="lg">{formatMoney(invoice ? getInvoiceTotalPaidAmount(invoice) : 0)}</Text>
                    </div>
                    <div className="col-4 text-danger px-2">
                      <Text fontSize="lg">{formatMoney(leftToPay)}</Text>
                    </div>
                  </div>
                </div>
              ) : null}
              <br />

              <FormControl className="mb-3">
                <FormLabel htmlFor="amount">Износ</FormLabel>
                <InputGroup className="input-group mb-2">
                  <Input
                    disabled={noMoreLeftToPay}
                    id="amount"
                    placeholder="Износ"
                    type="text"
                    className="form-control"
                    roundedRight="0"
                    focusBorderColor={inputFocusBorderColor}
                    {...register('amount')}
                  />
                  <InputRightAddon children={invoice?.currency} />
                  <FormErrorMessage>
                    <>{errors.amount?.message}</>
                  </FormErrorMessage>
                </InputGroup>
              </FormControl>

              <FormControl className="mb-3">
                <FormLabel htmlFor="paymentDate">Датум на исплата</FormLabel>
                <CreatorDatePicker id="paymentDateInput" name="paymentDate" isNestedForm />
                <FormErrorMessage>
                  <>{errors.paymentDate?.message}</>
                </FormErrorMessage>
              </FormControl>

              <FormControl className="mb-3">
                <FormLabel htmlFor="paymentMethod">Начин на плаќање</FormLabel>
                <PaymentMethodSelector control={control} />
                <FormErrorMessage>
                  <>{errors.paymentMethod?.message}</>
                </FormErrorMessage>
              </FormControl>

              <FormControl className="mb-3">
                <FormLabel htmlFor="notes">Белешки</FormLabel>
                <Textarea
                  id="notes"
                  className="form-control notes-textarea"
                  rows={2}
                  cols={80}
                  {...register('notes')}
                  focusBorderColor={inputFocusBorderColor}
                />
                <FormErrorMessage>
                  <>{errors.notes?.message}</>
                </FormErrorMessage>
              </FormControl>

              {hasPayments && invoice ? <PaymentsHistoryList invoice={invoice} /> : null}
              {noMoreLeftToPay ? (
                <Alert status="info">
                  <AlertIcon />
                  Фактурата е веќе платена. Не може да се регистрира плаќање.
                </Alert>
              ) : null}
            </ModalBody>

            <ModalFooter position={modalSize === 'full' ? 'absolute' : 'initial'} bottom={4} w="100%">
              <Button isDisabled={isLoading || isUpdating} size="sm" variant="ghost" mr={3} onClick={closeModal}>
                Откажи
              </Button>
              <Button
                isLoading={isLoading || isUpdating}
                isDisabled={noMoreLeftToPay}
                type="submit"
                size="sm"
                colorScheme={buttonColorScheme}
              >
                {noMoreLeftToPay ? 'Платена' : 'Регистрирај'}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
InvoicePaymentModal.displayName = 'InvoicePaymentModal';
