import { AppThunk } from '@/Core/AppStore';
import { CURRENT_USER_STORAGE_KEY } from '@/Core/auth/authService';
import { TODO } from '@/Core/core.types';
import { ApiEndpoints } from '@/Core/http/api-endpoints';
import { http } from '@/Core/http/http';
import { AxiosRequestConfig } from 'axios';
import { authUserApi } from './authenticatedUser.api';
import { AuthUser } from './authentication.types';

export const updateUserProfile =
  ({
    name,
    password,
    password_confirmation,
  }: AuthUser & { password: string; password_confirmation: string }): AppThunk =>
  async (dispatch) => {
    return http
      .post<TODO>(ApiEndpoints.UserProfileUrl, { name, password, password_confirmation })
      .then(({ data }) => {
        dispatch(authUserApi.endpoints.getAuthenticatedUser.initiate(undefined, { forceRefetch: true }));
        localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(data.data));
        return data;
      })
      .catch(() => {
        throw new Error('Проблеми во системот 😞. Обидете се повторно подоцна!');
      });
  };

export const uploadUserAvatar = async (avatar: FormData, config?: AxiosRequestConfig): Promise<{ data: AuthUser }> => {
  const { data } = await http.post<FormData, TODO>(`${ApiEndpoints.UserProfileUrl}/avatar`, avatar, {
    ...config,
    headers: { ...config?.headers, 'Content-Type': 'multipart/form-data' } as TODO,
  });
  return { data: data.data as AuthUser };
};

export const initiateEmailVerification = async (): Promise<{
  data: { status: 'sent' | 'verified'; message: string };
}> => {
  const { data } = await http.post<{ message: string; status: 'sent' | 'verified' }>(
    ApiEndpoints.EmailVerificationInitiateUrl,
  );
  return { data };
};
