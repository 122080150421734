import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { useDeleteInvoiceMutation, useGetInvoiceQuery, useReplicateInvoiceMutation } from '@/Invoices/api/invoices.api';
import { Invoice } from '@/Invoices/api/invoices.types';
import { notifyInvoiceCopied, notifyInvoiceDeleted } from '@/Invoices/api/invoices.utils';
import { lazyRetry } from '@/lazyRetry';
import { captureEvent } from '@/setupPostHog';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';

const InvoiceMobilePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "InvoiceMobilePage" */ './InvoiceMobilePage'), 'InvoiceMobilePage'),
);
const InvoicePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "InvoicePage" */ './InvoicePage'), 'InvoicePage'),
);

const useInvoiceData = () => {
  const { id } = useParams<{ id: string }>();
  const { data: invoice, ...rest } = useGetInvoiceQuery(id!);
  return [invoice, rest] as const;
};

export const InvoicePageLoader: React.FC = () => {
  const navigate = useNavigate();
  const [invoice, { isLoading }] = useInvoiceData();
  const [deleteInvoice, { isLoading: isDeleting }] = useDeleteInvoiceMutation();
  const [copyInvoice, { isLoading: isCopying }] = useReplicateInvoiceMutation();

  const onDeleteInvoice = async (invoiceToDelete: Invoice) => {
    captureEvent('delete_invoice');
    const result = await deleteInvoice(invoiceToDelete.id);
    const deleted = notifyInvoiceDeleted(invoiceToDelete, result);

    if (deleted) {
      navigate(`/invoices`);
    }
  };

  const onCopyInvoice = async (invoiceToCopy: Invoice) => {
    captureEvent('duplicate_invoice');
    const result = await copyInvoice(invoiceToCopy);
    const copy = notifyInvoiceCopied(invoiceToCopy, result);

    if (copy) {
      navigate(`/invoices/${copy.id}`);
    }
  };

  return invoice ? (
    isMobile ? (
      <InvoiceMobilePage invoice={invoice} onDelete={onDeleteInvoice} />
    ) : (
      <InvoicePage
        isLoading={isLoading || isDeleting || isCopying}
        invoice={invoice}
        onDelete={onDeleteInvoice}
        onCopy={onCopyInvoice}
      />
    )
  ) : (
    <DocumentNotFound />
  );
};
