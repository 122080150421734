import { useGetCompanyProfileQuery } from '@/Company/api/company.api';
import { Box, Flex, HStack, Link, Skeleton, Spinner, Text } from '@/core-components';
import { DetailSingleContainer } from '@/Core/components/SplitView/DetailContainer';
import { useGetAllTrashQuery } from '@/Trash/api/trash.api';
import { faBuilding, faGear, faRectangleHistoryCircleUser, faTrashCanList } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense } from 'react';
import { Outlet, NavLink as RouterNavLink } from 'react-router-dom';

export const CompanyPage: React.FC = () => {
  const { data: company, isLoading } = useGetCompanyProfileQuery();

  useGetAllTrashQuery();

  return (
    <DetailSingleContainer>
      <Box mt="1.5rem" p={4}>
        <Box maxW="container.xl" mx="auto">
          <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
            <Flex align="center" p={2}>
              {isLoading ? (
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="sky.500" size="xl" />
              ) : null}
              <Text fontSize="4xl">{company ? company.name : isLoading ? '' : 'Компанија'}</Text>
            </Flex>
            <Box mt={3} mb={4}>
              <HStack as="nav" spacing={4} borderBottom="1px" borderColor="gray.200" pb={2}>
                <Link
                  as={RouterNavLink}
                  to="/company/profile"
                  _hover={{
                    textDecoration: 'none',
                    borderBottom: '2px solid #333',
                    opacity: 0.8,
                  }}
                  _activeLink={{
                    borderBottom: '2px solid #333',
                  }}
                  borderBottom="2px solid transparent"
                  pb="0.5rem"
                  transition="all 0.2s"
                >
                  <HStack spacing={2}>
                    <FontAwesomeIcon icon={faBuilding} size="lg" />
                    <Text>Профил</Text>
                  </HStack>
                </Link>
                <Link
                  as={RouterNavLink}
                  to="/company/settings"
                  _hover={{
                    textDecoration: 'none',
                    borderBottom: '2px solid #333',
                    opacity: 0.8,
                  }}
                  _activeLink={{
                    borderBottom: '2px solid #333',
                  }}
                  borderBottom="2px solid transparent"
                  pb="0.5rem"
                  transition="all 0.2s"
                >
                  <HStack spacing={2}>
                    <FontAwesomeIcon icon={faGear} size="lg" />
                    <Text>Подесувања</Text>
                  </HStack>
                </Link>
                <Link
                  as={RouterNavLink}
                  to="/company/trash"
                  _hover={{
                    textDecoration: 'none',
                    borderBottom: '2px solid #333',
                    opacity: 0.8,
                  }}
                  _activeLink={{
                    borderBottom: '2px solid #333',
                  }}
                  borderBottom="2px solid transparent"
                  pb="0.5rem"
                  transition="all 0.2s"
                >
                  <HStack spacing={2}>
                    <FontAwesomeIcon icon={faTrashCanList} size="lg" />
                    <Text>Корпа</Text>
                  </HStack>
                </Link>
                <Link
                  as={RouterNavLink}
                  to="/company/audits"
                  _hover={{
                    textDecoration: 'none',
                    borderBottom: '2px solid #333',
                    opacity: 0.8,
                  }}
                  _activeLink={{
                    borderBottom: '2px solid #333',
                  }}
                  borderBottom="2px solid transparent"
                  pb="0.5rem"
                  transition="all 0.2s"
                >
                  <HStack spacing={2}>
                    <FontAwesomeIcon icon={faRectangleHistoryCircleUser} size="lg" />
                    <Text>Ревизија</Text>
                  </HStack>
                </Link>
              </HStack>
            </Box>
            <Box mt={4}>
              <Suspense fallback={<Skeleton startColor="#f3f3f3" endColor="#ecebeb" />}>
                <Outlet />
              </Suspense>
            </Box>
          </Box>
        </Box>
      </Box>
    </DetailSingleContainer>
  );
};
CompanyPage.displayName = 'Company';

export default CompanyPage;
