import { Box, Flex, Input, SkeletonText, Table, Tbody, Td, Th, Thead, Tr } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TableEmptyState } from '@/Core/components/Tables/TableEmptyState.tsx';
import { TableFooter } from '@/Core/components/Tables/TableFooter.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { initiateProductRequest } from '@/Products/api/products.api';
import { Product, ProductType } from '@/Products/api/products.types';
import { useProductsTableColumns } from '@/Products/hooks/useProductsTableColumns';
import { faCubes } from '@fortawesome/pro-light-svg-icons';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Select as ChakraSelect } from 'chakra-react-select';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  products: Product[];
  onDelete: (product: Product) => void;
  processingProduct: Product | null;
}

type ProductTypeOption = {
  label: string;
  value: string;
};

const types: ProductTypeOption[] = [
  { label: 'Производ', value: ProductType.Product },
  { label: 'Услуга', value: ProductType.Service },
];
export const ProductsTable: React.FC<Props> = ({ products, processingProduct, onDelete }) => {
  const navigate = useNavigate();
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [productOption, setProductOption] = React.useState<ProductTypeOption | null>(null);

  const [search, setSearch] = React.useState('');
  const { searchParams, setSearchParams } = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const data = React.useMemo(() => {
    if (productOption == null) {
      return products;
    }
    return products.filter((product) => product.type === productOption.value);
  }, [products, productOption]);

  const columns = useProductsTableColumns({ onDelete });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '',
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    setSearchParams({ page: (pageIndex + 1).toString() });
  }, [pageIndex, setSearchParams]);

  const rows = table.getRowModel().rows;

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Производ / Услуга"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={colorSchemeValue}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
        <Box minWidth="200px">
          <ChakraSelect
            isClearable
            name="productType"
            tagColorScheme={colorScheme}
            focusBorderColor={colorSchemeValue}
            selectedOptionColorScheme={colorScheme}
            size="sm"
            placeholder="Тип"
            options={types}
            onChange={(option) => setProductOption(option)}
          />
        </Box>
      </Flex>
      <Box data-testid="products-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isEmpty(rows) ? (
              <TableEmptyState icon={faCubes} title="Нема Производи и Услуги" />
            ) : (
              rows?.map((row) => (
                <Tr
                  key={row.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'gray.50' }}
                  onMouseEnter={() => {
                    initiateProductRequest(row.original.id);
                  }}
                  onClick={() => {
                    navigate(`/products/${row.original.id}`);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} maxW="100px" whiteSpace="normal">
                      {row.original.id === processingProduct?.id ? (
                        <SkeletonText noOfLines={1} skeletonHeight="8" />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <TableFooter table={table} />
    </Box>
  );
};
