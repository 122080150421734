import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import { useGetAllInvoicesQuery } from '@/Invoices/api/invoices.api';
import { InvoicePageLoader } from '@/Invoices/components/InvoiceLoader';
import { InvoicesMasterView } from '@/Invoices/components/InvoicesMasterView';
import { InvoiceCreatorDataLoader } from '@/Treasuries/components/TreasuryDataLoader';
import * as React from 'react';

export const Invoices: React.FC = () => {
  const { isLoading } = useGetAllInvoicesQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.Invoice}
      masterView={<InvoicesMasterView />}
      detailView={<InvoicePageLoader />}
      creatorView={<InvoiceCreatorDataLoader />}
    />
  );
};
Invoices.displayName = 'InvoicesMobile';

export default Invoices;
