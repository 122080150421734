import { Badge, Box, ButtonGroup, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { DraftItemBadge } from '@/Core/components/Helpers/DraftItemBadge';
import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction';
import { useIsEditing } from '@/Core/hooks/useIsEditing';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { highlightTerm } from '@/Core/utils/highlighter';
import { getTranslatedProductType } from '@/Core/utils/translatableTypes';
import { Product } from '@/Products/api/products.types';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import { faPenToSquare, faSave, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ActionsProps {
  product: Product;
  isEditing: boolean;
  isSaving: boolean;
  onSave?: (product: Product) => Product | unknown;
  onEdit?: (product: Product) => Product | unknown;
  onDelete?: (product: Product) => Product | unknown;
}

const Actions: React.FC<React.PropsWithChildren<ActionsProps>> = ({
  isEditing = false,
  isSaving = false,
  product,
  onDelete,
  onSave,
  onEdit,
}) => (
  <ButtonGroup spacing={3}>
    <ActionButton
      isEditing={isEditing}
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(product)}
    />
    {isEditing ? (
      <ActionButton
        icon={<FontAwesomeIcon icon={faSave} />}
        label="Зачувај"
        onClick={() => onSave?.(product)}
        disabled={isSaving}
      />
    ) : null}
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label="Додади во корпа"
      tooltipBg="red.600"
      onClick={() => onDelete?.(product)}
    />
  </ButtonGroup>
);

interface Props {
  product: Product;
  isLoading: boolean;
  onSave: (product: Product) => Product | unknown;
  onEdit: (product: Product) => Product | unknown;
  onDelete: (product: Product) => Product | unknown;
}

export const ProductItem: React.FC<Props> = ({ isLoading, product, onEdit, onDelete }) => {
  const { query } = useSearchQuery();
  const [isEditing] = useIsEditing({ pattern: '/products/:id/edit', id: product.id });
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  const shouldDisableActions = React.useMemo(() => {
    return isEditing ?? false;
  }, [isEditing]);

  const { name, description, unitPrice, currency, skuCode } = product;
  const highlightedCode = query ? highlightTerm(skuCode, query) : skuCode;
  const highlightedName = query ? highlightTerm(name, query) : name;
  const highlightedDescription = query ? highlightTerm(description, query) : description;

  return (
    <ListItemAction
      id={product.id}
      item={{ entity: product, path: 'products' }}
      disabled={isLoading || shouldDisableActions}
      actions={
        <Actions
          isEditing={isEditing}
          isSaving={isLoading}
          product={product}
          onEdit={() => onEdit?.(product)}
          onDelete={onDelete}
        />
      }
    >
      <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

      <Box display="flex" width="100%" marginBottom={1} justifyContent="space-between">
        <Text
          fontSize="sm"
          color="gray.600"
          dangerouslySetInnerHTML={{ __html: highlightedCode ?? `${skuCode ?? ''}` }}
        />
        <Text fontSize="xs">
          <Badge colorScheme={colorScheme} variant="subtle" textTransform="none" fontSize="0.8em">
            {getTranslatedProductType(product.type)}
          </Badge>
        </Text>
      </Box>

      <Box display="flex" width="100%" marginTop={2} marginBottom={1} flexDirection="column">
        <Text marginBottom={1} fontSize="md" dangerouslySetInnerHTML={{ __html: highlightedName ?? `${name ?? ''}` }} />
        <Text
          fontWeight="light"
          dangerouslySetInnerHTML={{ __html: highlightedDescription ?? `${description ?? ''}` }}
        />
      </Box>

      <Box display="flex" width="100%" marginBottom={1} justifyContent="flex-end">
        <Text fontSize="md" fontWeight="bold">
          {unitPrice ? formatMoney(unitPrice, currency) : null}
        </Text>
      </Box>

      <DraftItemBadge status={product.status} />
    </ListItemAction>
  );
};

ProductItem.displayName = 'ProductItem';
