import { formatISO } from 'date-fns';
import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { getFormattedDate } from '../../Core/utils/dateTime';
import { PaymentMethod } from '../../Core/utils/payment-methods';
import { Currency } from '../../Treasuries/store/treasuries.utils';
import { CashPayment } from './payments.types';

export const makeCashPayment = (payment?: Partial<CashPayment>): CashPayment => {
  return {
    id: v4(),
    taxRate: 18,
    status: DraftableStatus.Draft,
    paymentMethod: PaymentMethod.Cash,
    currency: Currency.MKD,
    amount: '0',
    ...payment,
    paymentDate: payment?.paymentDate ? getFormattedDate(payment.paymentDate) : getFormattedDate(new Date()),
    createdAt: formatISO(new Date()),
  };
};
