import { authUserLoader } from '@/Authentication/store/authenticatedUser.api';
import { cashPaymentLoader, cashPaymentsLoader } from '@/CashPayments/api/payments.api';
import { clientLoader, clientsLoader } from '@/Clients/api/clients.api';
import { AppLogout } from '@/Core/components/AutoLogout.tsx';
import { LoadingBrojki } from '@/Core/components/BrojkiLogo/LoadingBrojki.tsx';
import { Navbar } from '@/Core/components/Navbar/Navbar.tsx';
import { PrivateRoute } from '@/Core/components/PrivateRoutes/PrivateRoute.tsx';
import { estimateLoader, estimatesLoader } from '@/Estimates/api/estimates.api';
import { expenseLoader, expensesLoader } from '@/Expenses/api/expenses.api';
import { invoiceLoader, invoicesLoader } from '@/Invoices/api/invoices.api';
import { productLoader, productsLoader } from '@/Products/api/products.api';
import { projectLoader, projectsLoader } from '@/Projects/api/projects.api';
import { AppContainer, Root, RouteNotFound } from '@/Root.tsx';
import { EstimateCreatorDataLoader, InvoiceCreatorDataLoader } from '@/Treasuries/components/TreasuryDataLoader.tsx';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router';
import { CashPaymentCreatorLoader } from '../CashPayments/components/CashPaymentCreatorLoader.tsx';
import { CashPaymentPageLoader } from '../CashPayments/components/CashPaymentLoader.tsx';
import { CashPayments } from '../CashPayments/components/CashPayments.tsx';
import { ClientCreatorLoader } from '../Clients/components/ClientCreatorLoader.tsx';
import { ClientPageLoader } from '../Clients/components/ClientLoader.tsx';
import { Clients } from '../Clients/components/Clients.tsx';
import Error500Boundary from '../Core/ErrorBoundary/Error500Boundary.tsx';
import { EstimatePageLoader } from '../Estimates/components/EstimateLoader.tsx';
import { Estimates } from '../Estimates/components/Estimates.tsx';
import { ExpenseCreatorLoader } from '../Expenses/components/ExpenseCreatorLoader.tsx';
import { ExpensePageLoader } from '../Expenses/components/ExpenseLoader.tsx';
import { Expenses } from '../Expenses/components/Expenses.tsx';
import { InvoicePageLoader } from '../Invoices/components/InvoiceLoader.tsx';
import { Invoices } from '../Invoices/components/Invoices.tsx';
import { ProductCreatorLoader } from '../Products/components/ProductCreatorLoader.tsx';
import { ProductPageLoader } from '../Products/components/ProductLoader.tsx';
import { Products } from '../Products/components/Products.tsx';
import { ProjectCreatorLoader } from '../Projects/components/ProjectCreatorLoader.tsx';
import { ProjectPageLoader } from '../Projects/components/ProjectLoader.tsx';
import { Projects } from '../Projects/components/Projects.tsx';
import { commonPrivateRoutes, commonPublicRoutes } from './commonRoutes.tsx';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const desktopRouter = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      Component: Root,
      HydrateFallback: LoadingBrojki,
      ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
      children: [
        ...commonPublicRoutes,
        {
          ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
          loader: authUserLoader,
          Component: () => (
            <PrivateRoute>
              <AppLogout />
              <Navbar />
              <AppContainer />
            </PrivateRoute>
          ),
          children: [
            ...commonPrivateRoutes,
            {
              path: '/invoices',
              loader: invoicesLoader,
              Component: Invoices,
            },
            {
              path: '/invoices/:id',
              loader: invoiceLoader,
              Component: InvoicePageLoader,
            },
            {
              path: '/invoices/:id/edit',
              loader: invoiceLoader,
              Component: InvoiceCreatorDataLoader,
            },
            {
              path: '/estimates',
              loader: estimatesLoader,
              Component: Estimates,
            },
            {
              path: '/estimates/:id',
              loader: estimateLoader,
              Component: EstimatePageLoader,
            },
            {
              path: '/estimates/:id/edit',
              loader: estimateLoader,
              Component: EstimateCreatorDataLoader,
            },
            {
              path: '/expenses',
              loader: expensesLoader,
              Component: Expenses,
            },
            {
              path: '/expenses/:id',
              loader: expenseLoader,
              Component: ExpensePageLoader,
            },
            {
              path: '/expenses/:id/edit',
              loader: expenseLoader,
              Component: ExpenseCreatorLoader,
            },
            {
              path: '/cash-payments',
              loader: cashPaymentsLoader,
              Component: CashPayments,
            },
            {
              path: '/cash-payments/:id',
              loader: cashPaymentLoader,
              Component: CashPaymentPageLoader,
            },
            {
              path: '/cash-payments/:id/edit',
              loader: cashPaymentLoader,
              Component: CashPaymentCreatorLoader,
            },
            {
              path: '/clients',
              loader: clientsLoader,
              Component: Clients,
            },
            {
              path: '/clients/:id',
              loader: clientLoader,
              Component: ClientPageLoader,
            },
            {
              path: '/clients/:id/edit',
              loader: clientLoader,
              Component: ClientCreatorLoader,
            },
            {
              path: '/projects',
              loader: projectsLoader,
              Component: Projects,
            },
            {
              path: '/projects/:id',
              loader: projectLoader,
              Component: ProjectPageLoader,
            },
            {
              path: '/projects/:id/edit',
              loader: projectLoader,
              Component: ProjectCreatorLoader,
            },
            {
              path: '/products',
              loader: productsLoader,
              Component: Products,
            },
            {
              path: '/products/:id',
              loader: productLoader,
              Component: ProductPageLoader,
            },
            {
              path: '/products/:id/edit',
              loader: productLoader,
              Component: ProductCreatorLoader,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      Component: RouteNotFound,
    },
  ],
  {
    future: {
      v7_partialHydration: true,
    },
  },
);
