import { Text } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton.tsx';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar.tsx';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useCreateCashPaymentMutation, useGetAllCashPaymentsQuery } from '../api/payments.api';
import { makeCashPayment } from '../api/payments.model';
import { CashPaymentList } from './CashPaymentList';

export const CashPaymentsMasterView: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetAllCashPaymentsQuery();
  const [createCashPayment, { isLoading: isCreating }] = useCreateCashPaymentMutation();

  const onSuccessfulDelete = () => {
    return navigate(`/cash-payments`);
  };

  const onCreateCashPayment = async () => {
    const cashPayment = makeCashPayment();
    const result = await createCashPayment(cashPayment);

    if ('error' in result) {
      throw new Error('Failed to create cashPayment');
    }
    navigate(`/cash-payments/${result.data.id}/edit?new=true`);
  };

  return (
    <>
      <MasterViewToolbar isDisabled={isLoading || isCreating} entity="CashPayment">
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Каса Прими
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          {/* <ToolbarAction
            isActive={isFiltering}
            isDisabled={isCrudLoading(status)}
            icon={<FontAwesomeIcon icon={faRadar} color={isFiltering ? '#0ca5e9' : 'inherit'} size="sm" />}
            label="Прикажи све од овој клиент"
            onClick={() => {
              dispatch(filterCashPayments(!isFiltering));
            }}
          /> */}
          <ToolbarAction
            isCreator
            label="Нова Трансакција"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onCreateCashPayment}
          />
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton onClick={onCreateCashPayment} />
      </ShowOnMobile>
      <CashPaymentList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};

CashPaymentsMasterView.displayName = 'CashPaymentsMasterView';
