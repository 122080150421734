import { Input, InputGroup, InputRightAddon } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mk } from 'date-fns/locale';
import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller, useForm, useFormContext } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';

registerLocale('mk', mk);

const DatePickerInput = React.forwardRef(
  ({ id, name, value, onChange, onBlur, onClick, placeholder }: TODO, ref: TODO) => {
    const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
    return (
      <InputGroup>
        <Input
          id={id}
          name={name}
          ref={ref}
          borderRightRadius="0"
          autoComplete="off"
          onClick={onClick}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          focusBorderColor={inputFocusBorderColor}
        />
        <InputRightAddon fontSize="1.2rem" children={<FontAwesomeIcon icon={faCalendar} />} />
      </InputGroup>
    );
  },
);

interface Props {
  id?: string;
  name: string;
  control?: TODO;
  defaultValue?: Date | string;
  isNestedForm?: boolean;
}

export const CreatorDatePicker: React.FC<Props> = ({
  id,
  name = 'datePicker',
  isNestedForm = false,
  defaultValue = new Date(),
}) => {
  const form = useForm();
  const formContext = useFormContext();

  return (
    <Controller
      name={name}
      control={isNestedForm ? formContext?.control : form.control}
      defaultValue={defaultValue}
      render={(props) => (
        <DatePicker
          id={id}
          name={name}
          dateFormat="dd/MM/yyyy"
          placeholderText="дд/мм/гггг"
          locale="mk"
          selected={props.field.value}
          wrapperClassName="w-100"
          onChange={(date: Date | null) => {
            const d = date || new Date();
            props.field.onChange(d);
          }}
          customInput={
            (
              <DatePickerInput
                {...props}
                ref={isNestedForm ? (formContext?.register as TODO) : (form.register as TODO)}
              />
            ) as TODO
          }
        />
      )}
    />
  );
};
