import { isClientCompany } from '@/Clients/api/clients.utils';
import { Link as ChakraLink, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@/core-components';
import { Project } from '@/Projects/api/projects.types';
import { faEllipsisStroke, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper<Project>();

interface Props {
  onDelete: (project: Project) => void;
}

export const useProjectsTableColumns = ({ onDelete }: Props) => {
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('code', {
        header: () => 'Проект Бр.',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),
      columnHelper.accessor('name', {
        header: () => 'Име на проект',
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),

      columnHelper.accessor(
        (row) => {
          const client = row.client;
          return isClientCompany(client) ? client?.businessName : client?.fullName;
        },
        {
          id: 'client',
          header: () => 'Клиент',
          filterFn: 'fuzzy',
          cell: (info) => {
            return (
              <ChakraLink
                as={Link}
                to={`/clients/${info.row.original.client?.id}`}
                onClick={(e) => e.stopPropagation()}
              >
                {info.getValue()}
              </ChakraLink>
            );
          },
        },
      ),
      // columnHelper.accessor('description', {
      //   header: () => 'Опис',
      //   cell: (info) => {
      //     return <Text>{info.getValue()}</Text>;
      //   },
      // }),
      columnHelper.display({
        id: 'actions',
        header: () => '',
        cell: (info) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
              variant="ghost"
              size="sm"
              fontSize={18}
              onClick={(e) => e.stopPropagation()}
            />
            <MenuList>
              {/* <MenuDivider /> */}
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (info.row.original) {
                    onDelete(info.row.original);
                  }
                }}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
              >
                Додади во корпа
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      }),
    ],
    [onDelete],
  );

  return columns;
};
