import { store } from '@/Core/AppStore';
import { api, TagType } from '@/Core/http/api';
import { ApiEndpoints } from '@/Core/http/api-endpoints';
import { AuthUser } from './authentication.types';

const { UserProfileUrl, AuditsUrl } = ApiEndpoints;

export interface Audit {
  id: string;
  user: string;
  event: string;
  auditableType: string;
  auditableId: string;
  oldValues: string;
  newValues: string;
  url: string;
  tags: string;
  createdAt: string;
}

export const authUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthenticatedUser: build.query<AuthUser, void>({
      query: () => ({ method: 'GET', url: `/${UserProfileUrl}` }),
      providesTags: () => [{ type: TagType.AuthUser }],
    }),
    getAudits: build.query<Audit[], void>({
      query: () => ({ method: 'GET', url: `/${AuditsUrl}` }),
      providesTags: () => [{ type: TagType.AuthUser }],
    }),
  }),
});

export const authUserLoader = async () => {
  const request = store.dispatch(authUserApi.endpoints.getAuthenticatedUser.initiate());
  await request;
  request.unsubscribe();
  return request;
};

export const { useGetAuthenticatedUserQuery, useGetAuditsQuery } = authUserApi;
