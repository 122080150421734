import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';

const defaultOptions: DropzoneOptions = {
  maxFiles: 1,
  multiple: false,
  maxSize: 6000000, // 6MB in bytes
  accept: {
    'image/jpeg': [],
    'image/png': [],
    'image/webp': [],
  },
};

export const useDropzoneLogoUpload = (options: DropzoneOptions): DropzoneState => {
  return useDropzone({
    ...defaultOptions,
    ...options,
  });
};

export const useDropzoneAvatarUpload = (options: DropzoneOptions): DropzoneState => {
  return useDropzone({
    ...defaultOptions,
    ...options,
  });
};

export const useDropzoneDocumentUpload = (options: DropzoneOptions): DropzoneState => {
  return useDropzone({
    ...defaultOptions,
    ...options,
    accept: {
      'image/*': [],
      'audio/*': [],
      'video/*': [],
      'application/pdf': [],
    },
  });
};
