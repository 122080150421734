import { Box, Container } from '@/core-components';
import { useIsLoadingNavigation } from '@/Core/hooks/useIsLoading';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import * as React from 'react';
import styles from './DetailContainer.module.scss';

const variants = {
  enabled: { opacity: 1 },
  disabled: { opacity: 0.2 },
};

interface DetailContainerProps {
  disabled?: boolean;
  isCreator?: boolean;
  isOnModal?: boolean;
  withoutBackground?: boolean;
}

// NOTE: This one is used in SplitViews
export const DetailContainer: React.FC<React.PropsWithChildren<DetailContainerProps>> = ({
  children,
  disabled,
  withoutBackground = false,
}) => {
  const [isLoading] = useIsLoadingNavigation();

  return (
    <motion.div
      animate={disabled ? 'disabled' : 'enabled'}
      variants={variants}
      className={clsx(styles.host, styles.creator, {
        [styles.withoutBackground]: withoutBackground,
      })}
    >
      <Container
        className={clsx({ [styles.loading]: disabled || isLoading })}
        position="relative"
        width="100%"
        maxW="100%"
        px={0}
        py={0}
        height="auto"
      >
        {children}
      </Container>
    </motion.div>
  );
};
DetailContainer.displayName = 'DetailContainer';

export const CreatorContainer: React.FC<React.PropsWithChildren<DetailContainerProps>> = ({ children, isOnModal }) => {
  return (
    <Box maxWidth="1152px" backgroundColor="white" height="auto" p={isOnModal ? 0 : 8} margin="0 auto" borderRadius={6}>
      {children}
    </Box>
  );
};
CreatorContainer.displayName = 'CreatorContainer';
// NOTE: This one is used as single containers e.g. dashboard / company profile etc
export const DetailSingleContainer: React.FC<React.PropsWithChildren<DetailContainerProps>> = ({ children }) => {
  return (
    <motion.div className={clsx(styles.host)}>
      <Container width="100%" maxW="1152px" px={0} height="100%" alignItems="center">
        {children}
      </Container>
    </motion.div>
  );
};

DetailSingleContainer.displayName = 'DetailSingleContainer';
