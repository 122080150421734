import {
  Box,
  Flex,
  IconButton,
  Input,
  Select,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TableEmptyState } from '@/Core/components/Tables/TableEmptyState.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Select as ChakraSelect } from 'chakra-react-select';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { initiateClientRequest } from '../api/clients.api';
import { Client, ClientType } from '../api/clients.types';
import { useClientsTableColumns } from '../hooks/useClientsTableColumns';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  clients: Client[];
  onDelete: (client: Client) => void;
  processingClient: Client | null;
}

type ClientTypeOption = {
  label: string;
  value: string;
};

const types: ClientTypeOption[] = [
  { label: 'Компанија', value: ClientType.Company },
  { label: 'Физичко Лице', value: ClientType.Individual },
];
export const ClientsTable: React.FC<Props> = ({ clients, processingClient, onDelete }) => {
  const navigate = useNavigate();
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const [clientOption, setClientOption] = React.useState<ClientTypeOption | null>(null);

  const [search, setSearch] = React.useState('');
  const { searchParams, setSearchParams } = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const data = React.useMemo(() => {
    if (clientOption == null) {
      return clients;
    }
    return clients.filter((client) => client.type === clientOption.value);
  }, [clients, clientOption]);

  const columns = useClientsTableColumns({ onDelete });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '',
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    setSearchParams({ page: (pageIndex + 1).toString() });
  }, [pageIndex, setSearchParams]);

  const rows = table.getRowModel().rows;

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Клиенти"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={colorSchemeValue}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
        <Box minWidth="200px">
          <ChakraSelect
            isClearable
            name="clientType"
            tagColorScheme={colorScheme}
            focusBorderColor={colorSchemeValue}
            selectedOptionColorScheme={colorScheme}
            size="sm"
            placeholder="Вид на комитент"
            options={types}
            onChange={(option) => setClientOption(option)}
          />
        </Box>
      </Flex>
      <Box data-testid="clients-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} width={`${header.getSize()}%`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isEmpty(rows) ? (
              <TableEmptyState icon={faUsers} title="Нема Клиенти" />
            ) : (
              rows?.map((row) => (
                <Tr
                  key={row.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'gray.50' }}
                  onMouseEnter={() => initiateClientRequest(row.original.id)}
                  onClick={() => navigate(`/clients/${row.original.id}`)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {row.original.id === processingClient?.id ? (
                        <SkeletonText noOfLines={1} skeletonHeight="8" />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex alignItems="center" gap="2" mt="4" justifyContent="flex-end">
        <Text>Редови по страница</Text>
        <Select
          colorScheme={colorScheme}
          focusBorderColor={colorSchemeValue}
          width="80px"
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          size="sm"
        >
          {[10, 20, 40, 60, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
        <Flex alignItems="center" gap="1">
          <Text>Страница</Text>
          <Text fontWeight="bold">
            {table.getState().pagination.pageIndex + 1} од {table.getPageCount()}
          </Text>
        </Flex>
        <IconButton
          size="sm"
          aria-label="<<"
          onClick={() => table.setPageIndex(0)}
          isDisabled={!table.getCanPreviousPage()}
          icon={<FontAwesomeIcon icon={faChevronsLeft} />}
        />
        <IconButton
          size="sm"
          aria-label="<"
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          onClick={() => table.previousPage()}
          isDisabled={!table.getCanPreviousPage()}
        />
        <IconButton
          size="sm"
          aria-label=">"
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => table.nextPage()}
          isDisabled={!table.getCanNextPage()}
        />
        <IconButton
          size="sm"
          aria-label=">>"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          isDisabled={!table.getCanNextPage()}
          icon={<FontAwesomeIcon icon={faChevronsRight} />}
        />
      </Flex>
    </Box>
  );
};
