import { Text } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateClientMutation, useGetAllClientsQuery } from '../api/clients.api';
import { makeClient } from '../api/clients.model';
import { ClientsList } from './ClientsList';

export const ClientsMasterView: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetAllClientsQuery();
  const [createClient, { isLoading: isCreating }] = useCreateClientMutation();

  const onCreateClient = async () => {
    const client = makeClient();
    const result = await createClient(client);

    if ('error' in result) {
      throw new Error('Failed to create client');
    }
    navigate(`/clients/${result.data.id}/edit?new=true`);
  };

  const onSuccessfulDelete = () => {
    return navigate(`/clients`);
  };

  return (
    <>
      <MasterViewToolbar entity="Client">
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Клиенти
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          <ToolbarAction isLoading={isLoading || isCreating} isCreator label="Нов Клиент" onClick={onCreateClient} />
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton onClick={onCreateClient} />
      </ShowOnMobile>
      <ClientsList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};
ClientsMasterView.displayName = 'ClientsMasterView';
