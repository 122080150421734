import { Box, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import { Currency } from '@/Treasuries/store/treasuries.utils';
import { Select as ChakraSelect } from 'chakra-react-select';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import Select, { components } from 'react-select';
import { customStyle } from './customSelectorStyle';

const Option = (props: TODO) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <Text width="100%" textAlign="left">
        {data?.label}
      </Text>
    </components.Option>
  );
};
const options = [
  { value: Currency.MKD, label: Currency.MKD },
  { value: Currency.EUR, label: Currency.EUR },
];

interface Props {
  defaultValue?: string;
  onCurrencySelected?: (currency: Currency) => void;
  control?: TODO;
  size?: 'sm' | 'md';
}

export const CurrencySelector: React.FC<Props> = ({ control, onCurrencySelected, defaultValue, size = 'md' }) => {
  const form = useForm();
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ defaultValue: Currency.MKD, control: control ?? form.control, name: 'currency' });

  const handleChange = (selected: TODO) => {
    onCurrencySelected?.(selected?.value);
    onChange(selected?.value);
  };

  const Component: TODO = size === 'sm' ? ChakraSelect : Select;

  return (
    <Box width="100%">
      <Component
        ref={ref}
        options={options}
        value={options.find((o) => o.value === value)}
        defaultValue={options.find((o) => o.value === defaultValue)}
        onChange={handleChange}
        onBlur={onBlur}
        focusBorderColor={inputFocusBorderColor}
        selectedOptionColorScheme={colorScheme}
        colorScheme={colorScheme}
        styles={customStyle as TODO}
        noOptionsMessage={(option: TODO) => `Нема опции за "${option.inputValue}"`}
        components={{ Option }}
      />
    </Box>
  );
};

CurrencySelector.displayName = 'CurrencySelector';
