import { Box } from '@/core-components';
import { DetailView } from '@/Core/components/SplitView/DetailView';
import { MasterView } from '@/Core/components/SplitView/MasterView';
import splitViewStyles from '@/Core/components/SplitView/SplitView.module.scss';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { InvoiceLoadingState } from './InvoiceLoadingState';
import { MasterViewLoader } from './MasterViewLoader';

export const SplitViewLoadingState = React.memo(() => {
  return (
    <Box className={isMobile ? splitViewStyles.containerMobile : splitViewStyles.container}>
      <MasterView>
        <MasterViewLoader />
      </MasterView>
      <DetailView>
        <InvoiceLoadingState />
      </DetailView>
    </Box>
  );
});
