import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { getFormattedDate } from '../../Core/utils/dateTime';
import { Project } from './projects.types';

export const makeProject = (project?: Partial<Project>): Project => {
  return {
    id: v4(),
    status: DraftableStatus.Draft,
    invoices: [],
    estimates: [],
    payments: [],
    expenses: [],
    ...project,
    createdAt: project?.createdAt ? getFormattedDate(project.createdAt) : getFormattedDate(new Date()),
    startedAt: project?.startedAt ? getFormattedDate(project.startedAt) : getFormattedDate(new Date()),
    finishedAt: project?.finishedAt ? getFormattedDate(project.finishedAt) : null,
  };
};
