import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import { useGetAllEstimatesQuery } from '@/Estimates/api/estimates.api';
import { EstimateCreatorDataLoader } from '@/Treasuries/components/TreasuryDataLoader';
import * as React from 'react';
import { EstimatePageLoader } from './EstimateLoader';
import { EstimatesMasterView } from './EstimatesMasterView';

export const Estimates: React.FC = () => {
  const { isLoading } = useGetAllEstimatesQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.Estimate}
      masterView={<EstimatesMasterView />}
      detailView={<EstimatePageLoader />}
      creatorView={<EstimateCreatorDataLoader />}
    />
  );
};

Estimates.displayName = 'EstimatesMobile';
export default Estimates;
