import { Flex, IconButton, Select, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@tanstack/react-table';
import * as React from 'react';

interface TableFooterProps {
  table: Table<TODO>;
}
export const TableFooter: React.FC<TableFooterProps> = ({ table }) => {
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  return (
    <Flex alignItems="center" gap="2" mt="4" justifyContent="flex-end">
      <Text>Редови по страница</Text>
      <Select
        colorScheme={colorScheme}
        focusBorderColor={inputFocusBorderColor}
        width="80px"
        value={table.getState().pagination.pageSize}
        onChange={(e) => table.setPageSize(Number(e.target.value))}
        size="sm"
      >
        {[10, 20, 40, 60, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </Select>
      <Flex alignItems="center" gap="1">
        <Text>Страница</Text>
        <Text fontWeight="bold">
          {table.getState().pagination.pageIndex + 1} од {table.getPageCount()}
        </Text>
      </Flex>
      <IconButton
        size="sm"
        aria-label="<<"
        onClick={() => table.setPageIndex(0)}
        isDisabled={!table.getCanPreviousPage()}
        icon={<FontAwesomeIcon icon={faChevronsLeft} />}
      />
      <IconButton
        size="sm"
        aria-label="<"
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={() => table.previousPage()}
        isDisabled={!table.getCanPreviousPage()}
      />
      <IconButton
        size="sm"
        aria-label=">"
        icon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={() => table.nextPage()}
        isDisabled={!table.getCanNextPage()}
      />
      <IconButton
        size="sm"
        aria-label=">>"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        isDisabled={!table.getCanNextPage()}
        icon={<FontAwesomeIcon icon={faChevronsRight} />}
      />
    </Flex>
  );
};
