import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { useDeleteExpenseMutation } from '@/Expenses/api/expenses.api';
import { Expense } from '@/Expenses/api/expenses.types';
import { notifyExpenseDeleted } from '@/Expenses/api/expenses.utils';
import { useExpenseData } from '@/Expenses/hooks/useExpenseData';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const ExpenseMobilePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ExpenseMobilePage" */ './ExpenseMobilePage'), 'ExpenseMobilePage'),
);
const ExpensePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ExpensePage" */ './ExpensePage'), 'ExpensePage'),
);

export const ExpensePageLoader: React.FC = () => {
  const navigate = useNavigate();
  const { expense } = useExpenseData();
  const [deleteExpense] = useDeleteExpenseMutation();

  const onDeleteExpense = async (expenseToDelete: Expense) => {
    const result = await deleteExpense(expenseToDelete.id);
    const deleted = notifyExpenseDeleted(expenseToDelete, result);
    if (!deleted) {
      return;
    }

    navigate(`/expenses`);
  };

  return expense ? (
    isMobile ? (
      <ExpenseMobilePage expense={expense} onDelete={onDeleteExpense} />
    ) : (
      <ExpensePage expense={expense} onDelete={onDeleteExpense} />
    )
  ) : (
    <DocumentNotFound />
  );
};
