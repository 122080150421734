import { Box } from '@/core-components';
import { ResourceEntity } from '@/Core/core.types';
import { useIsLoadingNavigation } from '@/Core/hooks/useIsLoading';
import { useMergeRefs } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import * as React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import styles from './ListItemAction.module.scss';

const itemStyles = {
  display: 'block',
  height: 'auto',
  width: '100%',
  borderTop: 'none',
};

interface Props {
  actions?: React.ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
  item: {
    entity: ResourceEntity;
    path: string;
  };
  id: string;
  forwardRef?: React.Ref<HTMLDivElement>;
}

export const ListItemAction: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  item,
  children,
  actions,
  disabled = false,
  style,
  forwardRef,
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isLoading] = useIsLoadingNavigation();
  const matchEdit = useMatch(`/${item.path}/:id/edit`);
  const isEditing = React.useMemo(
    () => matchEdit != null && item?.entity.id === matchEdit?.params?.id,
    [matchEdit, item?.entity.id],
  );
  const navigateTo = React.useMemo(() => `/${item.path}/${item?.entity.id}`, [item]);

  const classes = `${styles['default-theme']} ${styles.action} ${disabled ? styles.disabled : ''}`;

  return (
    <motion.div
      ref={useMergeRefs(ref, forwardRef)}
      style={{ ...itemStyles, ...style }}
      data-id={`row-${id}`}
      transition={{
        type: 'spring',
        damping: 10,
        stiffness: 100,
      }}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.25,
        },
      }}
      exit={{
        opacity: 0.6,
        x: -280,
        transition: {
          duration: 0.25,
        },
      }}
      {...rest}
    >
      <NavLink
        to={navigateTo}
        role="button"
        className={({ isActive }) =>
          `${classes} ${isActive || isEditing ? styles.active : ''} ${isLoading ? styles.isLoading : ''}`
        }
      >
        <Box display="flex" flexDirection="column" w="100%" h="100%" justifyContent="space-around">
          {children}
          <Box display="flex" justifyContent="flex-start" alignItems="center" w="100%" mt={2}>
            {actions}
          </Box>
        </Box>
      </NavLink>
    </motion.div>
  );
};
