import { Box, Text } from '@/core-components';
import { getYear } from 'date-fns';
import range from 'lodash-es/range';
import * as React from 'react';
import Select, { OptionProps, SingleValue, StylesConfig, components } from 'react-select';
import { customStyle } from './customSelectorStyle';

type SelectOption = { label: string; value: number };
const Option = (props: OptionProps<SelectOption>) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <Text width="100%" textAlign="left">
        {data?.label}
      </Text>
    </components.Option>
  );
};

interface Props {
  onYearSelected?: (year: number) => void;
}

const options: SelectOption[] = range(2018, getYear(new Date()) + 1)
  .map((year) => ({
    value: year,
    label: year.toString(),
  }))
  .reverse();
export const YearSelector: React.FC<React.PropsWithChildren<Props>> = ({ onYearSelected }) => {
  const defaultValue = React.useMemo(() => options.find((o) => o.value === getYear(new Date())), []);

  return (
    <Box width="100%" maxWidth="120px">
      <Select
        options={options}
        styles={customStyle as StylesConfig<SelectOption>}
        defaultValue={defaultValue}
        noOptionsMessage={(option) => `Нема опции за "${option.inputValue}"`}
        onChange={(selected) => {
          const option = selected as SingleValue<SelectOption>;
          if (option) {
            onYearSelected?.(option.value);
          }
        }}
        components={{ Option }}
      />
    </Box>
  );
};
