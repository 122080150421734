import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Alert as ChakraAlert,
  type AlertDescriptionProps,
  type AlertIconProps,
  type AlertProps,
  type AlertStatus,
  type AlertTitleProps,
} from '@chakra-ui/react';
import * as React from 'react';

export const Alert: React.FC<AlertProps> = (props) => {
  return <ChakraAlert {...props} />;
};

export { AlertDescription, AlertIcon, AlertTitle };
export type { AlertDescriptionProps, AlertIconProps, AlertProps, AlertStatus, AlertTitleProps };
