import { useAppSelector } from '@/Core/AppStore';
import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/clients.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { Box } from '@/core-components';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDeleteClientMutation, useGetAllClientsQuery, useUpdateClientMutation } from '../api/clients.api';
import { Client } from '../api/clients.types';
import { notifyClientDeleted, notifyClientUpdated } from '../api/clients.utils';
import { ClientItem } from './ClientItem';

const EmptyText: React.FC = () => {
  const colorScheme = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      Клик на
      <Box
        borderRadius="sm"
        mx={1}
        backgroundColor={colorScheme}
        color="white"
        boxSize="20px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Box>{' '}
      да креирате нов клиент.
    </Box>
  );
};

interface Props {
  onSuccessfulDelete: (client: Client) => void;
}

export const ClientsList: React.FC<React.PropsWithChildren<Props>> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const { query, isSearching } = useSearchQuery();
  const { data, isLoading, isFetching } = useGetAllClientsQuery();
  const clients = getSortedEntityItems<Client>(data);
  const [deleteClient] = useDeleteClientMutation();
  const [updateClient] = useUpdateClientMutation();
  const match = useMatch('/clients/:id/edit');

  const items = React.useMemo(() => {
    if (!isSearching) {
      return clients;
    }

    return clients.filter((client) => {
      const foundClient =
        client?.fullName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase()) ||
        client?.businessName?.toLocaleLowerCase().includes(query?.toLocaleLowerCase());

      return foundClient;
    });
  }, [clients, query, isSearching]);

  return (
    <MasterList isLoading={isLoading || isFetching} data-test-id="clients-master-list">
      {isEmpty(items) ? (
        <EmptyMasterViewList
          isSearching={isSearching}
          title={isSearching ? `Немате клиенти со вашето пребарување "${query}"` : 'Немате Клиенти'}
          text={isSearching ? null : <EmptyText />}
        >
          <EmptyState />
        </EmptyMasterViewList>
      ) : (
        <AnimatePresence initial={false}>
          {items.map((client) =>
            client ? (
              <ClientItem
                key={client.id}
                isLoading={isLoading || isFetching}
                client={client}
                onSave={async (client) => {
                  const result = await updateClient(client);
                  const saved = notifyClientUpdated(client, result);
                  if (saved) {
                    if (isMobile) {
                      return navigate('/clients');
                    }
                    return navigate(`/clients/${saved.id}/`);
                  }
                }}
                onEdit={(client) => {
                  const isEditing = match != null && client.id === match?.params?.id;
                  navigate(isEditing ? `/clients/${client.id}/` : `/clients/${client.id}/edit`);
                }}
                onDelete={async (client) => {
                  const result = await deleteClient(client.id);
                  const deleted = notifyClientDeleted(client, result);
                  if (deleted) {
                    return onSuccessfulDelete(client);
                  }
                }}
              />
            ) : null,
          )}
        </AnimatePresence>
      )}
    </MasterList>
  );
};
ClientsList.displayName = 'ClientsList';
