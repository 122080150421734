import { CashPayment } from '../../CashPayments/api/payments.types';
import { Client } from '../../Clients/api/clients.types';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { Estimate } from '../../Estimates/api/estimates.types';
import { Expense } from '../../Expenses/api/expenses.types';
import { Invoice } from '../../Invoices/api/invoices.types';
import { Product } from '../../Products/api/products.types';
import { Project } from '../../Projects/api/projects.types';
import { TrashableEntities } from './trash.types';

const { TrashUrl } = ApiEndpoints;

export const trashApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTrash: build.query<TrashableEntities, void>({
      query: () => ({ method: 'GET', url: TrashUrl }),
      providesTags: () => [{ type: TagType.Trash }],
    }),

    restoreInvoice: build.mutation<Invoice, Invoice>({
      query: (invoice) => ({ method: 'POST', url: `${TrashUrl}/invoices/${invoice.id}/restore`, data: invoice }),
      invalidatesTags: () => [{ type: TagType.Invoices }],
    }),
    destroyInvoice: build.mutation<Invoice, Invoice>({
      query: (invoice) => ({ method: 'DELETE', url: `${TrashUrl}/invoices/${invoice.id}` }),
    }),

    restoreEstimate: build.mutation<Estimate, Estimate>({
      query: (estimate) => ({ method: 'POST', url: `${TrashUrl}/estimates/${estimate.id}/restore`, data: estimate }),
      invalidatesTags: () => [{ type: TagType.Estimates }],
    }),
    destroyEstimate: build.mutation<Estimate, Estimate>({
      query: (estimate) => ({ method: 'DELETE', url: `${TrashUrl}/estimates/${estimate.id}` }),
    }),

    restoreExpense: build.mutation<Expense, Expense>({
      query: (expense) => ({ method: 'POST', url: `${TrashUrl}/expenses/${expense.id}/restore`, data: expense }),
      invalidatesTags: () => [{ type: TagType.Expenses }],
    }),
    destroyExpense: build.mutation<Expense, Expense>({
      query: (expense) => ({ method: 'DELETE', url: `${TrashUrl}/expenses/${expense.id}` }),
    }),

    restoreCashPayment: build.mutation<CashPayment, CashPayment>({
      query: (cashPayment) => ({
        method: 'POST',
        url: `${TrashUrl}/payments/${cashPayment.id}/restore`,
        data: cashPayment,
      }),
      invalidatesTags: () => [{ type: TagType.CashPayments }],
    }),
    destroyCashPayment: build.mutation<CashPayment, CashPayment>({
      query: (cashPayment) => ({ method: 'DELETE', url: `${TrashUrl}/payments/${cashPayment.id}` }),
    }),

    restoreClient: build.mutation<Client, Client>({
      query: (client) => ({
        method: 'POST',
        url: `${TrashUrl}/clients/${client.id}/restore`,
        data: client,
      }),
      invalidatesTags: () => [{ type: TagType.Clients }],
    }),
    destroyClient: build.mutation<Client, Client>({
      query: (client) => ({ method: 'DELETE', url: `${TrashUrl}/clients/${client.id}` }),
    }),

    restoreProject: build.mutation<Project, Project>({
      query: (project) => ({
        method: 'POST',
        url: `${TrashUrl}/projects/${project.id}/restore`,
        data: project,
      }),
      invalidatesTags: () => [{ type: TagType.Projects }],
    }),
    destroyProject: build.mutation<Project, Project>({
      query: (project) => ({ method: 'DELETE', url: `${TrashUrl}/projects/${project.id}` }),
    }),

    restoreProduct: build.mutation<Product, Product>({
      query: (product) => ({
        method: 'POST',
        url: `${TrashUrl}/products/${product.id}/restore`,
        data: product,
      }),
      invalidatesTags: () => [{ type: TagType.Products }],
    }),
    destroyProduct: build.mutation<Product, Product>({
      query: (product) => ({ method: 'DELETE', url: `${TrashUrl}/products/${product.id}` }),
    }),
  }),
});

export const {
  useDestroyCashPaymentMutation,
  useDestroyEstimateMutation,
  useDestroyExpenseMutation,
  useDestroyInvoiceMutation,
  useDestroyProductMutation,
  useDestroyProjectMutation,
  useDestroyClientMutation,
  useGetAllTrashQuery,
  useRestoreCashPaymentMutation,
  useRestoreClientMutation,
  useRestoreEstimateMutation,
  useRestoreExpenseMutation,
  useRestoreInvoiceMutation,
  useRestoreProductMutation,
  useRestoreProjectMutation,
} = trashApi;
