import toFinite from 'lodash-es/toFinite';
import { getCurrentYear, yearRange } from '../../Core/utils/dateTime';
import { SummaryValue, TotalSummary } from './dashboard.types';

export const getDashboardSummary = (totalSummary?: TotalSummary[], inYear = getCurrentYear()): SummaryValue[] => {
  return yearRange(inYear)
    .reduce((summary: TotalSummary[], date) => {
      const foundSummary = totalSummary?.find((i) => i.date === date);

      summary.push(
        foundSummary ?? {
          date,
          totalAmount: '0',
        },
      );

      return summary;
    }, [])
    .map((s) => ({
      amount: toFinite(s.totalAmount),
      date: s.date,
    }));
};
