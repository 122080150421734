import { formatISO } from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';
import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { getFormattedDate } from '../../Core/utils/dateTime';
import { TreasuryTheme, TreasuryType } from '../../Treasuries/store/treasuries.types';
import { Currency } from '../../Treasuries/store/treasuries.utils';
import { Estimate, EstimateSettings } from './estimates.types';

export const makeEstimate = (estimate?: Partial<Estimate>): Estimate => {
  return {
    id: v4(),
    type: TreasuryType.Estimate,
    status: DraftableStatus.Draft,
    currency: Currency.MKD,
    totalAmount: 0,
    ...estimate,
    lineItems: cloneDeep(estimate?.lineItems ?? []),
    settings: makeEstimateSettings(estimate?.settings),
    estimatedAt: estimate?.estimatedAt ? getFormattedDate(estimate?.estimatedAt) : getFormattedDate(new Date()),
    dueDate: estimate?.dueDate ? getFormattedDate(estimate?.dueDate) : null,
    createdAt: formatISO(new Date()),
  };
};

export const makeEstimateSettings = (settings?: Partial<EstimateSettings> | null): EstimateSettings => {
  return {
    id: v4(),
    showSignaturesLine: true,
    showDiscountOnLineItems: false,
    showLineNumber: false,
    theme: TreasuryTheme.Simple,
    signatureLineOneLabel: 'Примил',
    signatureLineTwoLabel: '',
    signatureLineThreeLabel: 'Одобрил',
    language: 'mkd',
    ...settings,
  };
};
