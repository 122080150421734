import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { DetailSingleContainer } from '@/Core/components/SplitView/DetailContainer';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { useGetDashboardCountersQuery } from '@/Dashboard/api/dashboard.api';
import { DashboardCharts } from '@/Dashboard/components/DashboardCharts';
import { DashboardCounters } from '@/Dashboard/components/DashboardCounters';
import { DashboardHeader } from '@/Dashboard/components/DashboardHeader';
import { DashboardTopCharts } from '@/Dashboard/components/DashboardTopCharts';

interface SuccessDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

function SuccessDialog({ isOpen, onClose }: SuccessDialogProps) {
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Потврдена е-пошта</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg">Вашата е-пошта е успешно потврдена!</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={colorScheme} mr={3} onClick={onClose}>
              Затвори
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export const Dashboard: React.FC = () => {
  const { data: counters, isLoading, isFetching } = useGetDashboardCountersQuery();
  const { searchParams, setSearchParams } = useSearchParams();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const verified = searchParams.get('verified') as '1' | '0' | null;
  const isVerified = verified === '1';

  const onDialogClose = () => {
    searchParams.delete('verified');
    setSearchParams(searchParams);
    onClose();
  };

  return (
    <DetailSingleContainer>
      <Box mt="0.5rem" p={4}>
        <Box className="container-limited">
          <VStack width="100%" align="stretch" spacing={4}>
            <DashboardHeader />
            <DashboardCounters counters={counters} isLoading={isLoading || isFetching} />
            <DashboardCharts />
            <DashboardTopCharts />
          </VStack>
        </Box>
      </Box>
      {isVerified ? <SuccessDialog isOpen={isOpen} onClose={onDialogClose} /> : null}
    </DetailSingleContainer>
  );
};
Dashboard.displayName = 'Dashboard';
export default Dashboard;
