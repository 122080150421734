import { Client } from '@/Clients/api/clients.types';
import { Flex } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { DashboardChartLoader } from '@/Core/components/Loaders/DashboardChartLoader';
import { ClientSelector } from '@/Core/components/Selectors/ClientSelector';
import { CurrencySelector } from '@/Core/components/Selectors/CurrencySelector';
import { YearSelector } from '@/Core/components/Selectors/YearSelector';
import { Feature, useShouldEnableFeature } from '@/Core/utils/shouldEnableFeature';
import { useGetDashboardPaymentsQuery } from '@/Dashboard/api/dashboard.api';
import { getDashboardSummary } from '@/Dashboard/api/dashboard.utils';
import { AreaChart } from '@/Dashboard/components/AreaChart';
import { BarChart } from '@/Dashboard/components/BarChart';
import { DashboardChartHeader } from '@/Dashboard/components/DashboardChartHeader';
import { ChartCardWrapper, ChartWrapper, DashboardFilters } from '@/Dashboard/components/DashboardCharts';
import { SwitchChartButton } from '@/Dashboard/components/SwitchChartButton';
import { Currency, roundAmount } from '@/Treasuries/store/treasuries.utils';
import { toFinite } from 'lodash-es';
import React, { useState } from 'react';

const calculateTotal = (total: number, next: number) => {
  const amount = total + next;
  return roundAmount(amount);
};
const currentYear = new Date().getFullYear();

export const PaymentsChart: React.FC = () => {
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(Currency.MKD);
  const [selectedClient, setSelectedClient] = useState<Partial<Client> | null>(null);
  const [chartType, setChartType] = useState('AreaChart');

  const [paymentsFilter, setPaymentsFilter] = useState<DashboardFilters & { currency?: Currency }>({
    year: new Date().getFullYear(),
    currency: Currency.MKD,
  });
  const {
    data: payments,
    isLoading: isLoadingPayments,
    isFetching: isFetchingPayments,
  } = useGetDashboardPaymentsQuery(paymentsFilter, { skip: !shouldEnablePayments });

  const isLoading = isLoadingPayments || isFetchingPayments;
  const summary = getDashboardSummary(payments, toFinite(paymentsFilter?.year ?? currentYear));
  const totalAmount = summary?.reduce((total, next) => calculateTotal(total, next.amount), 0);

  const changeClientFilter = (client: Partial<Client> | null) => {
    setSelectedClient(client);
    setPaymentsFilter({ client: client?.id, year: selectedYear, currency: selectedCurrency });
  };

  const changeYearFilter = (year: number) => {
    setSelectedYear(year);
    setPaymentsFilter({ year, client: selectedClient?.id, currency: selectedCurrency });
  };

  const changeCurrencyFilter = (currency: Currency) => {
    setSelectedCurrency(currency);
    setPaymentsFilter({ currency, year: selectedYear, client: selectedClient?.id });
  };

  const toggleChart = () => {
    const type = chartType === 'AreaChart' ? 'BarChart' : 'AreaChart';
    setChartType(type);
  };

  return (
    <ChartCardWrapper>
      <DashboardChartHeader
        amount={totalAmount}
        selectedYear={selectedYear}
        selectedCurrency={selectedCurrency}
        selectedClient={selectedClient}
      >
        <Flex align="center" justify="flex-end" direction="row" width="100%">
          <HideOnMobile>
            <Flex width="220px" mr={2}>
              <ClientSelector placeholder="Од Клиент" onClientSelected={changeClientFilter} />
            </Flex>
          </HideOnMobile>
          <Flex width="120px" mr={2}>
            <CurrencySelector defaultValue={selectedCurrency} onCurrencySelected={changeCurrencyFilter} />
          </Flex>
          <Flex width="120px">
            <YearSelector onYearSelected={changeYearFilter} />
          </Flex>
        </Flex>
      </DashboardChartHeader>
      <SwitchChartButton onClick={toggleChart} type={chartType === 'AreaChart' ? 'BarChart' : 'AreaChart'} />
      <ChartWrapper>
        {isLoading ? (
          <DashboardChartLoader />
        ) : chartType === 'AreaChart' ? (
          <AreaChart name="payments" data={summary} color="#22cf04" currency={selectedCurrency} />
        ) : (
          <BarChart data={summary} color="#22cf04" currency={selectedCurrency} />
        )}
      </ChartWrapper>
    </ChartCardWrapper>
  );
};

PaymentsChart.displayName = 'PaymentsChart';
