import { MenuItem, Text, useToast } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar';
import { after15days } from '@/Core/utils/dateTime';
import { useCreateEstimateMutation, useGetAllEstimatesQuery } from '@/Estimates/api/estimates.api';
import { makeEstimate } from '@/Estimates/api/estimates.model';
import { Estimate } from '@/Estimates/api/estimates.types';
import { redirectEstimateAfterDeletion } from '@/Estimates/api/estimates.utils';
import { EstimatesList } from '@/Estimates/components/EstimatesList';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { TreasuryType } from '@/Treasuries/store/treasuries.types';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

export const EstimatesMasterView: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data, isLoading } = useGetAllEstimatesQuery();
  const [createEstimate, { isLoading: isCreating }] = useCreateEstimateMutation();

  const onCreateEstimate = async (type: TreasuryType) => {
    const estimate = makeEstimate({ type, dueDate: after15days() });
    const result = await createEstimate(estimate);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Пресметка' }));
      return;
    }
    return navigate(`/estimates/${result.data.id}/edit?new=true`);
  };

  const onSuccessfulDelete = (estimate: Estimate) => {
    const redirectTo = redirectEstimateAfterDeletion(estimate, data);
    if (redirectTo) {
      if (isMobile) {
        return navigate(`/clients`);
      }
      return navigate(`/estimates/${redirectTo.id}/`);
    }
    return navigate(`/estimates`);
  };

  return (
    <>
      <MasterViewToolbar entity="Estimate" isDisabled={isLoading}>
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Пресметки
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          {/* <ToolbarAction
            isActive={isFiltering}
            icon={<FontAwesomeIcon icon={faRadar} color={isFiltering ? '#0ca5e9' : 'inherit'} size="sm" />}
            label="Прикажи све од овој клиент"
            onClick={() => {
              if (isFiltering) {
                searchParams.delete('filter');
                setSearchParams(searchParams);
                setIsFiltering(false);
              } else {
                searchParams.set('filter', 'client');
                setSearchParams(searchParams);
                setIsFiltering(true);
              }
            }}
          /> */}
          <ToolbarAction isCreator label="Нова Пресметка" isLoading={isCreating}>
            <MenuItem minH="33px" fontSize="md" onClick={() => onCreateEstimate(TreasuryType.Estimate)}>
              Нова Про Фактура
            </MenuItem>
            <MenuItem minH="33px" fontSize="md" onClick={() => onCreateEstimate(TreasuryType.Offer)}>
              Нова Понуда
            </MenuItem>
          </ToolbarAction>
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton>
          <MenuItem minH="33px" fontSize="md" onClick={() => onCreateEstimate(TreasuryType.Estimate)}>
            Нова Про Фактура
          </MenuItem>
          <MenuItem minH="33px" fontSize="md" onClick={() => onCreateEstimate(TreasuryType.Offer)}>
            Нова Понуда
          </MenuItem>
        </FloatingActionButton>
      </ShowOnMobile>
      <EstimatesList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};

EstimatesMasterView.displayName = 'EstimatesMasterView';
