import { Link as ChakraLink, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@/core-components';
import {
  faCopy,
  faEllipsisStroke,
  faEnvelope,
  faFilePdf,
  faSquareCheck,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { isClientCompany } from '../../Clients/api/clients.utils';
import { useSearchParams } from '../../Core/hooks/useSearchParams';
import { toFormattedDate } from '../../Core/utils/dateTime';
import { formatMoney } from '../../Treasuries/store/treasuries.utils';
import { Invoice } from '../api/invoices.types';
import { PaymentStatusLabel } from '../components/PaymentStatusLabel.tsx';

const columnHelper = createColumnHelper<Invoice>();

// Extracted memoized component for the actions menu
const ActionsMenu = React.memo(
  ({
    invoice,
    openPaymentModal,
    openTreasuryModal,
    onCopy,
    onDownload,
    onDelete,
    setSearchParams,
  }: {
    invoice: Invoice;
    openPaymentModal: () => void;
    openTreasuryModal: () => void;
    onCopy: (invoice: Invoice) => void;
    onDownload: (invoice: Invoice) => void;
    onDelete: (invoice: Invoice) => void;
    setSearchParams: ReturnType<typeof useSearchParams>['setSearchParams'];
  }) => {
    // Memoize handlers to prevent recreating functions on each render
    const handlePaymentClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setSearchParams((prev) => [...prev.entries(), ['id', invoice.id]]);
        openPaymentModal();
      },
      [invoice.id, openPaymentModal, setSearchParams],
    );

    const handleCopyClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        onCopy(invoice);
      },
      [invoice, onCopy],
    );

    const handleEmailClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setSearchParams((prev) => [...prev.entries(), ['id', invoice.id], ['type', 'invoice']]);
        openTreasuryModal();
      },
      [invoice.id, openTreasuryModal, setSearchParams],
    );

    const handleDownloadClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        onDownload(invoice);
      },
      [invoice, onDownload],
    );

    const handleDeleteClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        onDelete(invoice);
      },
      [invoice, onDelete],
    );

    const handleMenuButtonClick = React.useCallback((e: React.MouseEvent) => {
      e.stopPropagation();
    }, []);

    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
          variant="ghost"
          size="sm"
          fontSize={18}
          onClick={handleMenuButtonClick}
        />
        <MenuList>
          <MenuItem onClick={handlePaymentClick} icon={<FontAwesomeIcon icon={faSquareCheck} />}>
            Наплати
          </MenuItem>
          <MenuItem onClick={handleCopyClick} icon={<FontAwesomeIcon icon={faCopy} />}>
            Копирај
          </MenuItem>
          <MenuItem onClick={handleEmailClick} icon={<FontAwesomeIcon icon={faEnvelope} />}>
            Испрати по емаил
          </MenuItem>
          <MenuItem onClick={handleDownloadClick} icon={<FontAwesomeIcon icon={faFilePdf} />}>
            Превземи PDF
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleDeleteClick} icon={<FontAwesomeIcon icon={faTrashCan} />}>
            Додади во корпа
          </MenuItem>
        </MenuList>
      </Menu>
    );
  },
);

// For optimization, add display name
ActionsMenu.displayName = 'ActionsMenu';

// Memoized client accessor function
const getClientName = (client: Invoice['client']) => {
  if (!client) return '';
  return isClientCompany(client) ? client.businessName : client.fullName;
};

interface Props {
  openPaymentModal: () => void;
  openTreasuryModal: () => void;
  onCopy: (invoice: Invoice) => void;
  onDownload: (invoice: Invoice) => void;
  onDelete: (invoice: Invoice) => void;
}
export const useInvoicesTableColumns = ({
  openPaymentModal,
  openTreasuryModal,
  onCopy,
  onDownload,
  onDelete,
}: Props) => {
  const { setSearchParams } = useSearchParams();

  return React.useMemo(
    () => [
      columnHelper.accessor('invoiceNumber', {
        header: () => 'Фактура Бр.',
        size: 15,
        cell: (info) => (
          <ChakraLink as={Link} fontWeight="semibold" to={`/invoices/${info.row.original.id}`}>
            {info.getValue()}
          </ChakraLink>
        ),
      }),
      columnHelper.accessor((row) => getClientName(row.client), {
        id: 'client',
        header: () => 'Клиент',
        size: 20,
        filterFn: 'fuzzy',
        cell: (info) => {
          const clientId = info.row.original.client?.id;
          return clientId ? (
            <ChakraLink as={Link} to={`/clients/${clientId}`} onClick={(e) => e.stopPropagation()}>
              {info.getValue()}
            </ChakraLink>
          ) : null;
        },
      }),
      columnHelper.accessor('invoicedDate', {
        header: () => 'Издадена',
        size: 10,
        cell: (info) => (info.getValue() ? toFormattedDate(info.getValue()) : null),
      }),
      columnHelper.accessor('dueDate', {
        header: () => 'Краен рок',
        size: 10,
        cell: (info) => (info.getValue() ? toFormattedDate(info.getValue()) : null),
      }),
      columnHelper.accessor('totalAmount', {
        header: () => 'Износ',
        size: 25,
        cell: (info) => (info.getValue() ? formatMoney(info.getValue(), info.row.original.currency) : null),
      }),
      columnHelper.accessor('paymentStatus', {
        header: () => 'Статус',
        size: 10,
        cell: (info) => <PaymentStatusLabel paymentStatus={info.getValue()} />,
      }),
      columnHelper.display({
        id: 'actions',
        size: 10,
        maxSize: 10,
        header: () => '',
        cell: (info) => (
          <ActionsMenu
            invoice={info.row.original}
            openPaymentModal={openPaymentModal}
            openTreasuryModal={openTreasuryModal}
            onCopy={onCopy}
            onDownload={onDownload}
            onDelete={onDelete}
            setSearchParams={setSearchParams}
          />
        ),
      }),
    ],
    [onCopy, onDelete, onDownload, openPaymentModal, openTreasuryModal, setSearchParams],
  );
};
