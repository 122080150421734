import { authService } from '@/Core/auth/authService';
import throttle from 'lodash-es/throttle';
import { useCallback, useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const THIRTY_MINUTES = 30 * 60 * 1000;

export const AppLogout: React.FC = () => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [remember] = useLocalStorage('brojki:remember', false);

  const logoutAction = useCallback(() => {
    authService.logout();
  }, []);

  const resetTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  const handleLogoutTimer = useCallback(() => {
    if (remember) {
      return;
    }
    timer.current = setTimeout(() => {
      resetTimer();
      logoutAction();
    }, THIRTY_MINUTES);
  }, [resetTimer, logoutAction, remember]);

  const throttledResetAndHandle = useCallback(
    throttle(() => {
      resetTimer();
      handleLogoutTimer();
    }, 1000),
    [],
  );

  useEffect(() => {
    if (remember) {
      return;
    }

    events.forEach((item) => {
      window.addEventListener(item, throttledResetAndHandle);
    });

    return () => {
      events.forEach((item) => {
        window.removeEventListener(item, throttledResetAndHandle);
      });
    };
  }, [throttledResetAndHandle, remember]);

  return null;
};
