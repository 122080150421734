import { getFirstObject } from '@/Core/utils/getFirstObject';
import { lazyRetry } from '@/lazyRetry';
import { useGetAllProductsQuery } from '@/Products/api/products.api';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

const EmptyProducts = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmptyProduct" */ '../../Core/components/EmptyStates/EmptyProducts'),
    'EmptyProduct',
  ),
);

export const ProductsHome: React.FC = () => {
  const { data: products } = useGetAllProductsQuery();
  const product = getFirstObject(products);

  return product ? <Navigate to={`${product?.id}`} state={{ product }} replace /> : <EmptyProducts />;
};
export default ProductsHome;
ProductsHome.displayName = 'ProductHome';
