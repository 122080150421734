import { useAppSelector } from '@/Core/AppStore';
import { TableFooter } from '@/Core/components/Tables/TableFooter.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { Box, Flex, Input, SkeletonText, Table, Tbody, Td, Th, Thead, Tr } from '@/core-components';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { initiatePaymentRequest } from '../api/payments.api';
import { CashPayment } from '../api/payments.types';
import { useCashPaymentsTableColumns } from '../hooks/useCashPaymentsTableColumns';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  cashPayments: CashPayment[];
  onDelete: (cashPayment: CashPayment) => void;
  onDownload: (cashPayment: CashPayment) => void;
  processingCashPayment: CashPayment | null;
}

export const CashPaymentsTable: React.FC<Props> = ({ cashPayments, processingCashPayment, onDelete, onDownload }) => {
  const navigate = useNavigate();
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  const [search, setSearch] = React.useState('');
  const { searchParams, setSearchParams } = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const data = React.useMemo(() => {
    return cashPayments;
  }, [cashPayments]);

  const columns = useCashPaymentsTableColumns({ onDownload, onDelete });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '',
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    setSearchParams({ page: (pageIndex + 1).toString() });
  }, [pageIndex, setSearchParams]);

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Сметки"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={colorSchemeValue}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
      </Flex>
      <Box data-testid="cash-payments-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} width={`${header.getSize()}%`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.50' }}
                onMouseEnter={() => initiatePaymentRequest(row.original.id)}
                onClick={() => navigate(`/cash-payments/${row.original.id}`)}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} maxW="100px" whiteSpace="normal">
                    {row.original.id === processingCashPayment?.id ? (
                      <SkeletonText noOfLines={1} skeletonHeight="8" />
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <TableFooter table={table} />
    </Box>
  );
};
