import { Box, Flex, Input, SkeletonText, Table, Tbody, Td, Th, Thead, Tr } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TableEmptyState } from '@/Core/components/Tables/TableEmptyState.tsx';
import { TableFooter } from '@/Core/components/Tables/TableFooter.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { initiateProjectRequest } from '@/Projects/api/projects.api';
import { Project } from '@/Projects/api/projects.types';
import { useProjectsTableColumns } from '@/Projects/hooks/useProjectsTableColumns';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  projects: Project[];
  onDelete: (project: Project) => void;
  processingProject: Project | null;
}

export const ProjectsTable: React.FC<Props> = ({ projects, processingProject, onDelete }) => {
  const navigate = useNavigate();
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [search, setSearch] = React.useState('');
  const { searchParams, setSearchParams } = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const data = React.useMemo(() => {
    return projects;
  }, [projects]);

  const columns = useProjectsTableColumns({ onDelete });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '',
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    setSearchParams({ page: (pageIndex + 1).toString() });
  }, [pageIndex, setSearchParams]);

  const rows = table.getRowModel().rows;

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Проекти"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={inputFocusBorderColor}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
      </Flex>
      <Box data-testid="projects-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isEmpty(rows) ? (
              <TableEmptyState icon={faFolderOpen} title="Нема Проекти" />
            ) : (
              rows?.map((row) => (
                <Tr
                  key={row.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'gray.50' }}
                  onMouseEnter={() => {
                    initiateProjectRequest(row.original.id);
                  }}
                  onClick={() => navigate(`/projects/${row.original.id}`)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {row.original.id === processingProject?.id ? (
                        <SkeletonText noOfLines={1} skeletonHeight="8" />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <TableFooter table={table} />
    </Box>
  );
};
