import { Box, Divider, Heading, Text } from '@/core-components';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { Estimate } from '@/Estimates/api/estimates.types';
import { getTreasuryType } from '@/Treasuries/store/treasuries.types';
import {
  formatMoney,
  getLineTotalAmount,
  getTreasurySubTotal,
  getTreasuryTotalAmount,
  getTreasuryTotalTaxAmount,
} from '@/Treasuries/store/treasuries.utils';
import * as React from 'react';

interface Props {
  estimate: Estimate;
  query?: string;
}

export const SpotlightEstimateDetail: React.FC<React.PropsWithChildren<Props>> = ({ estimate }) => {
  return estimate ? (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Heading as="h6" fontSize="xl" textAlign="center">
        {getTreasuryType(estimate)} Бр. {estimate.estimateNumber}
      </Heading>
      <Box display="flex" mt={4} justifyContent="space-between">
        <Box width="50%" display="flex" flexDir="column">
          <Text color="gray.500" mb={1} fontSize="md">
            Клиент
          </Text>
          <Text mb={0}>{estimate.client && estimate.client.fullName}</Text>
          <Text mb={1} fontWeight="light">
            {estimate.client && estimate.client.businessName}
          </Text>
          <Text>T: {estimate.client && estimate.client.contactPhone} </Text>
        </Box>
        <Divider orientation="vertical" />
        <Box width="50%" display="flex" flexDir="column" alignItems="flex-end">
          <Text color="gray.500" mb={1} fontSize="md">
            Издадена
          </Text>
          <Text>{estimate.estimatedAt ? toFormattedDate(estimate.estimatedAt) : null}</Text>
        </Box>
      </Box>
      <Box mt={4}>
        <Divider bgColor="gray.300" />
        {estimate.lineItems.map((item) => (
          <Box key={item.id} display="flex" justifyContent="space-between" my={2}>
            <Text fontSize="sm">{item.product?.name}</Text>
            <Text>{formatMoney(getLineTotalAmount(item), estimate.currency)}</Text>
          </Box>
        ))}
      </Box>
      <Divider bgColor="gray.300" my={2} />
      <Box mt={2}>
        <Text display="flex" justifyContent="space-between" mb={1}>
          <strong>Износ без ДДВ</strong>
          <span>{formatMoney(getTreasurySubTotal(estimate))}</span>
        </Text>
        <Text display="flex" justifyContent="space-between" mb={1}>
          <strong>Износ на ДДВ</strong>
          <span>{formatMoney(getTreasuryTotalTaxAmount(estimate))}</span>
        </Text>
        <Text display="flex" justifyContent="space-between" mb={1}>
          <strong>Вкупно</strong>
          <span>{formatMoney(getTreasuryTotalAmount(estimate))}</span>
        </Text>
      </Box>
    </Box>
  ) : null;
};
SpotlightEstimateDetail.displayName = 'SpotlightEstimateDetail';
