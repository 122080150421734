import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton.tsx';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction.tsx';
import { useIsEditing } from '@/Core/hooks/useIsEditing';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { highlightTerm } from '@/Core/utils/highlighter';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import { Box, ButtonGroup, Text } from '@/core-components';
import { faPenToSquare, faSave, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { CashPayment } from '../api/payments.types';

interface ActionsProps {
  cashPayment: CashPayment;
  isSaving: boolean;
  isEditing: boolean;
  onSave?: (payment: CashPayment) => CashPayment | unknown;
  onEdit?: (payment: CashPayment) => CashPayment | unknown;
  onDelete?: (payment: CashPayment) => CashPayment | unknown;
}

const Actions: React.FC<React.PropsWithChildren<ActionsProps>> = ({
  cashPayment,
  onSave,
  onEdit,
  onDelete,
  isSaving = false,
  isEditing = false,
}) => (
  <ButtonGroup spacing={3}>
    <ActionButton
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      isEditing={isEditing}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(cashPayment)}
      disabled={isSaving}
    />
    {isEditing ? (
      <ActionButton
        icon={<FontAwesomeIcon icon={faSave} />}
        label="Зачувај"
        onClick={() => onSave?.(cashPayment)}
        disabled={isSaving}
      />
    ) : null}
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label="Додади во корпа"
      tooltipBg="red.600"
      onClick={() => onDelete?.(cashPayment)}
      disabled={isSaving}
    />
  </ButtonGroup>
);

interface Props {
  cashPayment: CashPayment;
  isLoading: boolean;
  onEdit: (cashPayment: CashPayment) => CashPayment | unknown;
  onDelete: (cashPayment: CashPayment) => CashPayment | unknown;
}

export const CashPaymentItem: React.FC<React.PropsWithChildren<Props>> = ({
  cashPayment,
  onEdit,
  onDelete,
  isLoading,
}) => {
  const { query } = useSearchQuery();
  const [isEditing] = useIsEditing({ pattern: '/cash-payments/:id/edit', id: cashPayment.id });

  const { referenceNumber, paymentDate, client, amount, currency } = cashPayment;

  const highlightedNumber = query ? highlightTerm(referenceNumber, query) : referenceNumber;
  // const highlightedPaymentFor = query ? highlightTerm(paymentFor, query) : paymentFor;
  const highlightedClientFullName = query ? highlightTerm(client?.fullName, query) : client?.fullName;
  const highlightedClientBusinessName = query ? highlightTerm(client?.businessName, query) : client?.businessName;

  return (
    <ListItemAction
      id={cashPayment.id}
      item={{ entity: cashPayment, path: 'cash-payments' }}
      disabled={isLoading}
      actions={
        <Actions
          cashPayment={cashPayment}
          isSaving={isLoading}
          isEditing={isEditing}
          onEdit={() => onEdit(cashPayment)}
          onDelete={onDelete}
        />
      }
    >
      <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

      <Box display="flex" width="100%" marginBottom={2} justifyContent="space-between">
        <Text
          fontSize="sm"
          color="gray.600"
          dangerouslySetInnerHTML={{ __html: highlightedNumber ?? `${referenceNumber ?? ''}` }}
        />
        <Text color="gray.600">{paymentDate ? toFormattedDate(paymentDate) : null}</Text>
      </Box>

      <Box display="flex" width="100%" flexDirection="column" marginY={2}>
        <Text
          marginBottom={0}
          dangerouslySetInnerHTML={{ __html: highlightedClientBusinessName ?? `${client?.businessName ?? ''}` }}
        />
        <Text
          fontWeight="light"
          dangerouslySetInnerHTML={{ __html: highlightedClientFullName ?? `${client?.fullName ?? ''}` }}
        />
      </Box>

      {/* <Box className="d-flex w-100 mb-2 mt-2 flex-column">
          <Text fontSize="sm" dangerouslySetInnerHTML={{ __html: highlightedPaymentFor ?? `${paymentFor ?? ''}` }} />
        </Box> */}

      <Box display="flex" width="100%" marginBottom={1} justifyContent="flex-end">
        <Text fontSize="md" fontWeight="bold">
          {amount ? formatMoney(parseFloat(amount), currency) : null}
        </Text>
      </Box>
    </ListItemAction>
  );
};

CashPaymentItem.displayName = 'CashPaymentItem';
