import { Audit, useGetAuditsQuery } from '@/Authentication/store/authenticatedUser.api';
import { Box, IconButton, Skeleton, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@/core-components';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import React from 'react';

const columnHelper = createColumnHelper<Audit>();

const columns = [
  columnHelper.accessor('createdAt', {
    header: 'Датум',
    cell: (info) => format(new Date(info.getValue()), 'dd/MM/yyyy HH:mm'),
  }),
  columnHelper.accessor('event', {
    header: 'Настан',
    cell: (info) => getEvent(info.getValue(), info.row.original.auditableType),
  }),
  columnHelper.accessor('user', {
    header: 'Корисник',
    cell: (info) => info.getValue(),
  }),
];

const matchType = (type: string) => {
  const types = {
    user: 'Корисник',
    company: 'Компанија',
    invoice: 'Фактура',
    estimate: 'Пресметка',
    client: 'Клиент',
    expense: 'Трошок',
    cash_payment: 'Каса Прими',
    invoice_line: 'Линија на фактура',
    estimate_line: 'Линија на пресметка',
    invoice_payment: 'Плаќање на фактура',
    project: 'Проект',
    product: 'Производ',
    invoice_setting: 'Подесувања на фактура',
    estimate_setting: 'Подесувања на пресметка',
    media: 'Документ или медиум',
  } as const;

  return types[type as keyof typeof types] || type;
};

const matchEvent = (event: string) => {
  const events = {
    created: 'Креирано',
    updated: 'Ажурирано',
    deleted: 'Избришано',
    restored: 'Обновено',
  } as const;

  return events[event as keyof typeof events] || event;
};

const getEvent = (auditEvent: Audit['event'], auditType: Audit['auditableType']) => {
  const event = matchEvent(auditEvent);
  const type = matchType(auditType);

  return `${event} - ${type}`;
};

const filterOutAudits = (audits: Audit[]) => {
  return audits.filter((audit) => audit.auditableType !== 'generated_number');
};

export const CompanyAudits: React.FC = () => {
  const { data, isLoading } = useGetAuditsQuery();
  const audits = React.useMemo(() => filterOutAudits(data || []), [data]);

  const table = useReactTable({
    data: audits,
    columns,
    initialState: {
      pagination: { pageSize: DEFAULT_PAGE_SIZE },
    },
    filterFns: { fuzzy: fuzzyFilter },
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  return (
    <Box>
      <TableContainer>
        <Table size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoading ? (
              <>
                <Tr>
                  <Td colSpan={3}>
                    <Skeleton height="14px" />
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Skeleton height="14px" />
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Skeleton height="14px" />
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Skeleton height="14px" />
                  </Td>
                </Tr>
              </>
            ) : null}
            {isEmpty(audits) && !isLoading ? (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  <Text>Нема податоци за ревизија</Text>
                </Td>
              </Tr>
            ) : null}
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {isEmpty(audits) && audits.length <= 26 ? null : (
        <Box display="flex" gap="1rem" mt="4">
          <IconButton
            size="sm"
            aria-label="<<"
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
            icon={<FontAwesomeIcon icon={faChevronsLeft} />}
          />
          <IconButton
            size="sm"
            aria-label="<"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          />

          <IconButton
            size="sm"
            aria-label=">"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          />
          <IconButton
            size="sm"
            aria-label=">>"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
            icon={<FontAwesomeIcon icon={faChevronsRight} />}
          />
          <Box display="flex" alignItems="center" gap="1rem">
            <div>Страница</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} од {table.getPageCount()}
            </strong>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default CompanyAudits;
