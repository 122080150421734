import { Box, Skeleton, SkeletonText, Text } from '@/core-components';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage.tsx';
import { useGetNotificationQuery } from '@/Notifications/api/notifications.api';
import React from 'react';
import { useParams } from 'react-router';

const SkeletonLoader = () => {
  return (
    <GridPage title="">
      <GridCard title="">
        <Box p={4} overflow="hidden">
          <Skeleton height="160px" />

          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </Box>
      </GridCard>
    </GridPage>
  );
};
export const NotificationPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: notification, isLoading } = useGetNotificationQuery(id!);

  return notification ? (
    <GridPage title={notification.title}>
      <GridCard title="" key={notification.id}>
        <Box sx={{ '*': { all: 'revert' } }}>
          <Text fontSize="md" dangerouslySetInnerHTML={{ __html: notification.content }} />
        </Box>
      </GridCard>
    </GridPage>
  ) : (
    isLoading && <SkeletonLoader />
  );
};
