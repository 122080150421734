import { useGetAllClientsQuery } from '@/Clients/api/clients.api';
import { Client, ClientType } from '@/Clients/api/clients.types';
import { Box, Flex, Input, SkeletonText, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TableEmptyState } from '@/Core/components/Tables/TableEmptyState.tsx';
import { TableFooter } from '@/Core/components/Tables/TableFooter.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { initiateEstimateRequest } from '@/Estimates/api/estimates.api';
import { Estimate } from '@/Estimates/api/estimates.types';
import { useEstimatesTableColumns } from '@/Estimates/hooks/useEstimatesTableColumns';
import { MailTreasuryModal } from '@/Treasuries/components/MailTreasuryModal';
import { TreasuryType } from '@/Treasuries/store/treasuries.types';
import { faCalculatorSimple } from '@fortawesome/pro-light-svg-icons';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Select as ChakraSelect } from 'chakra-react-select';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  estimates: Estimate[];
  onDelete: (estimate: Estimate) => void;
  onCopy: (estimate: Estimate) => void;
  onTransform: (estimate: Estimate) => void;
  onDownload: (estimate: Estimate) => void;
  processingEstimate: Estimate | null;
}

type TypeOption = {
  label: string;
  value: string;
};

type ClientOption = {
  id: string;
  label?: string;
  value: string;
};

const types: TypeOption[] = [
  { label: 'Про Фактури', value: TreasuryType.Estimate },
  { label: 'Понуди', value: TreasuryType.Offer },
];

export const EstimatesTable: React.FC<Props> = ({
  estimates,
  processingEstimate,
  onCopy,
  onTransform,
  onDelete,
  onDownload,
}) => {
  const navigate = useNavigate();
  const { data: allClients } = useGetAllClientsQuery();
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const clients = React.useMemo(() => getSortedEntityItems<Client>(allClients), [allClients]);
  const clientOptions: ClientOption[] = React.useMemo(
    () =>
      clients.map((client) => ({
        id: client.id,
        value: client.id,
        label: client.type === ClientType.Company ? client.businessName : client.fullName,
      })),
    [clients],
  );
  const [search, setSearch] = React.useState('');
  const { searchParams, updateSearchParams } = useSearchParams();

  const defaultClient = React.useMemo(
    () => clientOptions.find((client) => client.value == searchParams.get('client')),
    [clientOptions, searchParams],
  );

  const defaultTreasuryType = React.useMemo(
    () => types.find((type) => type.value === searchParams.get('type')),
    [searchParams],
  );

  const [treasuryTypeFilter, setTreasuryTypeFilter] = React.useState<TypeOption | null>(defaultTreasuryType ?? null);
  const [clientFilter, setClientFilter] = React.useState<ClientOption | null>(defaultClient ?? null);
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const {
    isOpen: isOpenMailTreasuryModal,
    onOpen: openMailTreasuryModal,
    onClose: onCloseMailTreasuryModal,
  } = useDisclosure();

  const data = React.useMemo(() => {
    return estimates.filter((estimate) => {
      const hasMatchingStatus = treasuryTypeFilter == null || estimate.type === treasuryTypeFilter.value;
      const hasMatchingClient = clientFilter == null || estimate.client?.id === clientFilter.value;

      return hasMatchingStatus && hasMatchingClient;
    });
  }, [clientFilter, estimates, treasuryTypeFilter]);

  const columns = useEstimatesTableColumns({
    openMailTreasuryModal,
    onCopy,
    onTransform,
    onDownload,
    onDelete,
  });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '' || treasuryTypeFilter !== null,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    updateSearchParams({ page: (pageIndex + 1).toString() });
    if (defaultClient) {
      setClientFilter(defaultClient);
    }
  }, [pageIndex, defaultClient, updateSearchParams]);

  const rows = table.getRowModel().rows;

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Пресметки"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={inputFocusBorderColor}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
        <Flex gap="2">
          <Box minWidth="250px">
            <ChakraSelect
              isClearable
              name="client"
              isLoading={!clients}
              tagColorScheme={colorScheme}
              focusBorderColor={inputFocusBorderColor}
              selectedOptionColorScheme={colorScheme}
              size="sm"
              placeholder="Клиент"
              value={clientFilter}
              options={clientOptions}
              onChange={(option) => {
                setClientFilter(option);
                updateSearchParams({ client: option?.value ?? '' });
              }}
            />
          </Box>
          <Box minWidth="250px">
            <ChakraSelect
              isClearable
              name="type"
              tagColorScheme={colorScheme}
              focusBorderColor={inputFocusBorderColor}
              selectedOptionColorScheme={colorScheme}
              size="sm"
              placeholder="Тип"
              options={types}
              value={treasuryTypeFilter}
              onChange={(option) => {
                setTreasuryTypeFilter(option);
                updateSearchParams({ type: option?.value ?? '' });
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Box data-testid="estimates-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} width={`${header.getSize()}%`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isEmpty(rows) ? (
              <TableEmptyState icon={faCalculatorSimple} title="Нема Пресметки" />
            ) : (
              rows?.map((row) => (
                <Tr
                  key={row.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'gray.50' }}
                  onMouseEnter={() => {
                    initiateEstimateRequest(row.original.id);
                  }}
                  onClick={() => {
                    navigate(`/estimates/${row.original.id}`);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} whiteSpace="normal">
                      {row.original.id === processingEstimate?.id ? (
                        <SkeletonText noOfLines={1} skeletonHeight="8" />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <TableFooter table={table} />
      {isOpenMailTreasuryModal ? (
        <MailTreasuryModal isOpen={isOpenMailTreasuryModal} onClose={onCloseMailTreasuryModal} />
      ) : null}
    </Box>
  );
};
