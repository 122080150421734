import { Box, Text } from '@/core-components';
import { useIsOnMobile } from '@/Core/hooks/useIsOnMobile';
import { Currency, formatMoney } from '@/Treasuries/store/treasuries.utils';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { Cell, Legend, Pie, PieChart as RePieChart, ResponsiveContainer } from 'recharts';

interface Props {
  data?: { name: string; value: number }[];
  currency?: Currency;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0C4A6E'];
export const PieChart: React.FC<React.PropsWithChildren<Props>> = ({ data }) => {
  const chartData = React.useMemo(() => (isEmpty(data) ? [] : data), [data]);
  const isOnMobile = useIsOnMobile();

  return (
    <Box
      minHeight="300px"
      minWidth="300px"
      height="300px"
      maxHeight="300px"
      width="100%"
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      p={2}
    >
      {chartData ? (
        <ResponsiveContainer>
          <RePieChart>
            <Pie
              data={data}
              innerRadius={isOnMobile ? '76%' : 72}
              outerRadius={isOnMobile ? '90%' : 86}
              paddingAngle={4}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData?.map((_entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
            </Pie>
            <Legend
              style={{ textAlign: 'left' }}
              payload={chartData?.map((item, index) => ({
                id: item.name,
                type: 'rect',
                value: `${item.name} - ${formatMoney(item.value)}`,
                color: COLORS[index % COLORS.length],
              }))}
              layout={isOnMobile ? 'horizontal' : 'vertical'}
              verticalAlign={isOnMobile ? 'bottom' : 'middle'}
              align={isOnMobile ? 'left' : 'right'}
            />
          </RePieChart>
        </ResponsiveContainer>
      ) : (
        <Text>Нема доволно податоци за прикажување на графиконот</Text>
      )}
    </Box>
  );
};
