import { SerializedError } from '@reduxjs/toolkit';
import isEmpty from 'lodash-es/isEmpty';
import toFinite from 'lodash-es/toFinite';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { PaymentStatus } from '../../Treasuries/store/treasuries.types';
import { Invoice, InvoicePayment } from './invoices.types';

export const isPaid = (invoice: Invoice): boolean => invoice.paymentStatus === PaymentStatus.Paid;
export const isPending = (invoice: Invoice): boolean => invoice.paymentStatus === PaymentStatus.Pending;
export const isOverdue = (invoice: Invoice): boolean => invoice.paymentStatus === PaymentStatus.Overdue;
export const isPartiallyPaid = (invoice: Invoice): boolean => invoice.paymentStatus === PaymentStatus.PartiallyPaid;

export const getInvoiceTotalPaidAmount = (invoice: Invoice): number => {
  if (isEmpty(invoice.payments)) {
    return 0;
  }
  return invoice.payments.reduce((total: number, next: InvoicePayment) => (total += next.amount), 0);
};

export const amountLeftToPay = (invoice: Invoice): number => {
  return toFinite(invoice.totalAmount) - toFinite(invoice.paidAmount);
};

type Error =
  | {
      status: number;
      data: {
        message?: string | undefined;
        error?: string | undefined;
        errorCode?: string | undefined;
      };
    }
  | SerializedError;

type Result =
  | {
      data: Invoice;
    }
  | {
      error: Error;
    };

export const notifyInvoiceDeleted = (invoice: Invoice, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${invoice.id}-error`,
      title: 'Проблем со бришење на фактурата',
    });
    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === invoice.id) {
    toast = createInfoToast({
      id: `${invoice.id}-success`,
      title: 'Успешно',
      description: `Фактурата "${invoice?.invoiceNumber ?? ''}" е додадена во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyInvoiceUpdated = (invoice: Invoice, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${invoice.id}-error`,
      title: 'Проблем со ажурирање на фактурата',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === invoice.id) {
    toast = createInfoToast({
      id: `${invoice.id}-success`,
      title: 'Успешно',
      description: `Фактурата "${invoice?.invoiceNumber ?? ''}" e зачувана!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyInvoiceSettingsUpdated = (invoice: Invoice, result: Result) => {
  if ('error' in result) {
    const toast = createErrorToast({
      id: `${invoice.id}-error`,
      title: 'Проблем со ажурирање на фактурата',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === invoice.id) {
    return result.data;
  }

  return false;
};

export const notifyInvoiceCopied = (invoice: Invoice, result: Result) => {
  if ('error' in result) {
    const toast = createErrorToast({
      id: `${invoice.id}-error`,
      title: 'Проблеми со копирање на фактурата',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result) {
    const toast = createInfoToast({
      id: `${invoice.id}-success`,
      title: 'Успешно',
      description: `Фактурата "${invoice?.invoiceNumber ?? ''}" е копирана!`,
    });

    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyEstimateConvertedToInvoice = (result: Result) => {
  if ('error' in result) {
    const toast = createErrorToast({
      id: `converstion-error`,
      title: 'Проблеми со конвертирање во фактура',
    });
    toaster(toast);

    return false;
  }

  if ('data' in result) {
    const toast = createInfoToast({
      id: `converstion-success`,
      title: ' Пресметката е претворена во фактура!',
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const redirectInvoiceAfterDeletion = (invoice: Invoice, invoices?: Record<string, Invoice>) => {
  if (!invoices) {
    return null;
  }
  const { [invoice.id]: _, ...withoutRemoved } = invoices;
  const deletedIndex = Object.keys(invoices).findIndex((i) => invoices[i]?.id === invoice.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
