import { isClientCompany } from '@/Clients/api/clients.utils';
import {
  Link as ChakraLink,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@/core-components';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import { faEllipsisStroke, faEnvelope, faFilePdf, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CashPayment } from '../api/payments.types';

const columnHelper = createColumnHelper<CashPayment>();

interface Props {
  onDownload: (cashPayment: CashPayment) => void;
  onDelete: (cashPayment: CashPayment) => void;
}

export const useCashPaymentsTableColumns = ({ onDownload, onDelete }: Props) => {
  const { setSearchParams } = useSearchParams();

  return React.useMemo(
    () => [
      columnHelper.accessor('referenceNumber', {
        header: () => 'Сметка Бр.',
        size: 10,
        cell: (info) => (
          <ChakraLink as={Link} fontWeight="semibold" to={`/cash-payments/${info.row.original.id}`}>
            {info.getValue()}
          </ChakraLink>
        ),
      }),
      columnHelper.accessor(
        (row) => {
          const client = row.client;
          return isClientCompany(client) ? client?.businessName : client?.fullName;
        },
        {
          id: 'client',
          header: () => 'Трговец',
          size: 20,
          filterFn: 'fuzzy',
          cell: (info) => {
            return (
              <ChakraLink
                as={Link}
                to={`/clients/${info.row.original.client?.id}`}
                onClick={(e) => e.stopPropagation()}
              >
                {info.getValue()}
              </ChakraLink>
            );
          },
        },
      ),
      columnHelper.accessor('paymentFor', {
        header: () => 'Цел на уплата',
        size: 25,
        cell: (info) => <Text noOfLines={1}>{info.getValue()}</Text>,
      }),
      columnHelper.accessor('paymentDate', {
        header: () => 'Издадена',
        size: 10,
        cell: (info) => (info.getValue() ? toFormattedDate(info.getValue()) : null),
      }),
      columnHelper.accessor('amount', {
        header: () => 'Износ',
        size: 25,
        cell: (info) => {
          const amount = info.getValue() ? parseFloat(info.getValue()) : null;
          return amount ? formatMoney(amount, info.row.original.currency) : null;
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: () => '',
        size: 10,
        maxSize: 10,
        cell: (info) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
              variant="ghost"
              size="sm"
              fontSize={18}
              onClick={(e) => e.stopPropagation()}
            />
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchParams((prev) => [...prev.entries(), ['id', info.row.original.id], ['type', 'cashPayment']]);
                }}
                icon={<FontAwesomeIcon icon={faEnvelope} />}
              >
                Испрати по емаил
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload(info.row.original);
                }}
                icon={<FontAwesomeIcon icon={faFilePdf} />}
              >
                Превземи PDF
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (info.row.original) {
                    onDelete(info.row.original);
                  }
                }}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
              >
                Додади во корпа
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      }),
    ],
    [setSearchParams, onDownload, onDelete],
  );
};
