import { Stat, StatGroup, StatLabel, StatNumber } from '@/core-components';
import { Invoice } from '@/Invoices/api/invoices.types';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import * as React from 'react';

interface Props {
  invoices: Invoice[];
}
export const InvoicesStats: React.FC<Props> = ({ invoices }) => {
  const totalAmount = React.useMemo(() => invoices.reduce((acc, invoice) => acc + invoice?.totalAmount, 0), [invoices]);
  const paidAmount = React.useMemo(() => invoices.reduce((acc, invoice) => acc + invoice?.paidAmount, 0), [invoices]);
  const remainingAmount = React.useMemo(() => totalAmount - paidAmount, [totalAmount, paidAmount]);

  return (
    <StatGroup textAlign="center" data-testid="invoices-stats">
      <Stat data-testid="invoices-stats-total">
        <StatLabel>Вкупно</StatLabel>
        <StatNumber>{formatMoney(totalAmount)}</StatNumber>
      </Stat>
      <Stat data-testid="invoices-stats-paid">
        <StatLabel>Платенo</StatLabel>
        <StatNumber color="green.600">{formatMoney(paidAmount)}</StatNumber>
      </Stat>
      <Stat data-testid="invoices-stats-remaining">
        <StatLabel>Преостанато</StatLabel>
        <StatNumber color="red.600">{formatMoney(remainingAmount)}</StatNumber>
      </Stat>
    </StatGroup>
  );
};
