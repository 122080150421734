import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import { useGetAllExpensesQuery } from '@/Expenses/api/expenses.api';
import { ExpenseCreatorLoader } from '@/Expenses/components/ExpenseCreatorLoader';
import { ExpensePageLoader } from '@/Expenses/components/ExpenseLoader';
import { ExpensesMasterView } from '@/Expenses/components/ExpensesMasterView';
import * as React from 'react';

export const Expenses: React.FC = () => {
  const { isLoading } = useGetAllExpensesQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.Expense}
      masterView={<ExpensesMasterView />}
      detailView={<ExpensePageLoader />}
      creatorView={<ExpenseCreatorLoader />}
    />
  );
};

Expenses.displayName = 'ExpensesMobile';
export default Expenses;
