import { useGetAllClientsQuery } from '@/Clients/api/clients.api';
import { Client, ClientType } from '@/Clients/api/clients.types';
import { Box, Flex, Input, SkeletonText, Table, Tbody, Td, Th, Thead, Tr } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TableEmptyState } from '@/Core/components/Tables/TableEmptyState.tsx';
import { TableFooter } from '@/Core/components/Tables/TableFooter.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { useGetAllExpenseCategoriesQuery } from '@/ExpenseCategories/api/expenseCategories.api';
import { initiateExpenseRequest } from '@/Expenses/api/expenses.api';
import { Expense } from '@/Expenses/api/expenses.types';
import { useExpensesTableColumns } from '@/Expenses/hooks/useExpensesTableColumns';
import { faMoneyCheckDollarPen } from '@fortawesome/pro-light-svg-icons';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Select as ChakraSelect } from 'chakra-react-select';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  expenses: Expense[];
  onDelete: (expense: Expense) => void;
  onDownload: (expense: Expense) => void;
  processingExpense: Expense | null;
}

type CategoryOption = {
  id: string;
  value: string;
  label: string;
};

type ClientOption = {
  id: string;
  label?: string;
  value: string;
};

export const ExpensesTable: React.FC<Props> = ({ expenses, processingExpense, onDelete, onDownload }) => {
  const navigate = useNavigate();
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const { data: allClients } = useGetAllClientsQuery();
  const { data: categories, isLoading, isFetching } = useGetAllExpenseCategoriesQuery();
  const clients = React.useMemo(() => getSortedEntityItems<Client>(allClients), [allClients]);
  const clientOptions: ClientOption[] = React.useMemo(
    () =>
      clients.map((client) => ({
        id: client.id,
        value: client.id,
        label: client.type === ClientType.Company ? client.businessName : client.fullName,
      })),
    [clients],
  );
  const categoryOptions: CategoryOption[] | undefined = React.useMemo(
    () =>
      categories?.map((category) => ({
        id: category.id,
        value: category.id,
        label: category.name,
      })),
    [categories],
  );

  const [search, setSearch] = React.useState('');
  const { searchParams, updateSearchParams } = useSearchParams();

  const defaultClient = React.useMemo(
    () => clientOptions.find((client) => client.value === searchParams.get('client')),
    [clientOptions, searchParams],
  );

  const defaultCategory = React.useMemo(
    () => categoryOptions?.find((category) => category.id === searchParams.get('category')),
    [categoryOptions, searchParams],
  );

  const [categoryFilter, setCategoryFilter] = React.useState<CategoryOption | null>(defaultCategory ?? null);
  const [clientFilter, setClientFilter] = React.useState<ClientOption | null>(defaultClient ?? null);

  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const data = React.useMemo(() => {
    return expenses.filter((expense) => {
      const hasMatchingClient = clientFilter == null || expense.client?.id === clientFilter.value;
      const hasMatchingCategory = categoryFilter == null || expense.category?.id === categoryFilter.value;

      return hasMatchingClient && hasMatchingCategory;
    });
  }, [expenses, clientFilter, categoryFilter]);

  const columns = useExpensesTableColumns({ onDownload, onDelete });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '' || categoryFilter !== null || clientFilter !== null,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    updateSearchParams({ page: (pageIndex + 1).toString() });
  }, [pageIndex, updateSearchParams]);

  const rows = table.getRowModel().rows;

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Сметки"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={inputFocusBorderColor}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
        <Flex gap="2">
          <Box minWidth="250px">
            <ChakraSelect
              isClearable
              name="client"
              isLoading={!clients}
              tagColorScheme={colorScheme}
              focusBorderColor={inputFocusBorderColor}
              selectedOptionColorScheme={colorScheme}
              size="sm"
              placeholder="Клиент"
              value={clientFilter}
              options={clientOptions}
              onChange={(option) => {
                setClientFilter(option);
                updateSearchParams({ client: option?.value ?? '' });
              }}
            />
          </Box>
          <Box minWidth="250px">
            <ChakraSelect
              name="category"
              tagColorScheme={colorScheme}
              isClearable
              isLoading={isLoading || isFetching}
              focusBorderColor={inputFocusBorderColor}
              selectedOptionColorScheme={colorScheme}
              size="sm"
              value={categoryFilter}
              placeholder="Категорија"
              options={categoryOptions}
              onChange={(option) => setCategoryFilter(option)}
            />
          </Box>
        </Flex>
      </Flex>
      <Box data-testid="expenses-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} width={`${header.getSize()}%`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isEmpty(rows) ? <TableEmptyState icon={faMoneyCheckDollarPen} title="Нема Трошоци" /> : null}
            {rows?.map((row) => (
              <Tr
                key={row.id}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.50' }}
                onMouseEnter={() => initiateExpenseRequest(row.original.id)}
                onClick={() => navigate(`/expenses/${row.original.id}`)}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} maxW="100px" whiteSpace="normal">
                    {row.original.id === processingExpense?.id ? (
                      <SkeletonText noOfLines={1} skeletonHeight="8" />
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <TableFooter table={table} />
    </Box>
  );
};
