import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@/core-components';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { paymentMethods } from '@/Core/utils/payment-methods';
import { Invoice, InvoicePayment } from '@/Invoices/api/invoices.types';
import { getInvoiceTotalPaidAmount } from '@/Invoices/api/invoices.utils';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import * as React from 'react';

const getPaymentMethodName = (payment: InvoicePayment) =>
  paymentMethods.find((p) => p.value === payment.paymentMethod)?.name;

interface Props {
  invoice: Invoice;
}

export const PaymentsHistoryList: React.FC<Props> = ({ invoice }) => {
  return (
    <div className="row mt-4">
      <div className="col">
        <Text fontSize="md">Регистрирани плаќања</Text>

        <TableContainer my="4">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th scope="col">Датум</Th>
                <Th scope="col">Начин</Th>
                <Th scope="col" isNumeric>
                  Износ
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoice.payments?.map((payment) => (
                <Tr key={payment.id}>
                  <Td>{payment.paymentDate ? toFormattedDate(payment.paymentDate) : null}</Td>
                  <Td>{getPaymentMethodName(payment)}</Td>
                  <Td isNumeric>
                    {formatMoney(payment.amount, invoice.currency)} <br />
                    <Text color="gray.500">{payment.notes}</Text>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td></Td>
                <Td>
                  <strong>Вкупно</strong>
                </Td>
                <Td isNumeric>{formatMoney(getInvoiceTotalPaidAmount(invoice), invoice.currency)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
