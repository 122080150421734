import { Text } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar';
import { useCreateProjectMutation, useGetAllProjectsQuery } from '@/Projects/api/projects.api';
import { makeProject } from '@/Projects/api/projects.model';
import { ProjectsList } from '@/Projects/components/ProjectsList';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const ProjectsMasterView: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetAllProjectsQuery();
  const [createProject, { isLoading: isCreating }] = useCreateProjectMutation();

  const onCreateProject = async () => {
    const project = makeProject();
    const result = await createProject(project);

    if ('error' in result) {
      throw new Error('Failed to create project');
    }
    navigate(`/projects/${result.data.id}/edit?new=true`);
  };

  const onSuccessfulDelete = () => {
    return navigate(`/projects`);
  };

  return (
    <>
      <MasterViewToolbar entity="Project">
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Проекти
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          <ToolbarAction isCreator isLoading={isLoading || isCreating} label="Нов Проект" onClick={onCreateProject} />
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton onClick={onCreateProject} />
      </ShowOnMobile>
      <ProjectsList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};
ProjectsMasterView.displayName = 'ProjectsMasterView';
