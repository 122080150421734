import { Box, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
interface Props {
  isSearching?: boolean;
  title?: string;
  text?: React.ReactNode;
}

export const EmptyMasterViewList: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'Немате Сметки',
  isSearching,
  children,
  text,
}) => {
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  return isMobile ? (
    <Box
      maxW="280px"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      wordBreak="break-word"
    >
      {children}
      <Text fontSize="xl" textAlign="center" color="gray.600" my={4} maxW="280px">
        {title}
      </Text>
    </Box>
  ) : (
    <div style={{ position: 'fixed', zIndex: 999, marginTop: '-1rem', marginLeft: '1.2rem', pointerEvents: 'none' }}>
      <Text
        fontSize="15px"
        textAlign="center"
        color="gray.500"
        marginBottom="0.5rem"
        marginTop="2rem"
        maxW="300px"
        wordBreak="break-word"
      >
        {title}
      </Text>
      {text ??
        (isSearching ? null : (
          <Text fontSize="14px" textAlign="center" color="gray.500">
            Клик на{' '}
            <Box
              borderRadius="sm"
              mx={1}
              backgroundColor={colorSchemeValue}
              color="white"
              boxSize="20px"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Box>{' '}
            да креирате нова сметка.
          </Text>
        ))}
    </div>
  );
};

EmptyMasterViewList.displayName = 'EmptyMasterViewList';
