import DocumentNotFound from '@/Core/components/EmptyStates/DocumentNotFound';
import { lazyRetry } from '@/lazyRetry';
import { useDeleteProjectMutation } from '@/Projects/api/projects.api';
import { Project } from '@/Projects/api/projects.types';
import { notifyProjectDeleted } from '@/Projects/api/projects.utils';
import { useProjectData } from '@/Projects/hooks/useProjectData';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const ProjectMobilePage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProjectMobilePage" */ './ProjectMobilePage'), 'ProjectMobilePage'),
);
const ProjectPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProjectPage" */ './ProjectPage'), 'ProjectPage'),
);

export const ProjectPageLoader: React.FC = () => {
  const navigate = useNavigate();
  const { project } = useProjectData();
  const [deleteProject] = useDeleteProjectMutation();

  const onDeleteProject = async (projectToDelete: Project) => {
    const result = await deleteProject(projectToDelete.id);
    const deleted = notifyProjectDeleted(projectToDelete, result);
    if (!deleted) {
      return;
    }

    return navigate(`/projects`);
  };

  return project ? (
    isMobile ? (
      <ProjectMobilePage project={project} onDelete={onDeleteProject} />
    ) : (
      <ProjectPage project={project} onDelete={onDeleteProject} />
    )
  ) : (
    <DocumentNotFound />
  );
};
