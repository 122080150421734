import { getFirstObject } from '@/Core/utils/getFirstObject';
import { lazyRetry } from '@/lazyRetry';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetAllCashPaymentsQuery } from '../api/payments.api';

const EmptyCashPayments = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmptyCashPayments" */ '../../Core/components/EmptyStates/EmptyCashPayments'),
    'EmptyCashPayments',
  ),
);

export const CashPaymentsHome: React.FC = () => {
  const { data: cashPayments } = useGetAllCashPaymentsQuery();
  const cashPayment = getFirstObject(cashPayments);

  return cashPayment ? <Navigate to={`${cashPayment?.id}`} state={{ cashPayment }} replace /> : <EmptyCashPayments />;
};
export default CashPaymentsHome;
CashPaymentsHome.displayName = 'CashPaymentHome';
