import * as React from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce, useMount } from 'react-use';

export const useScrollToItem = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const scrollToItem = document.querySelector(`[data-id="row-${params.id}"]`);

  const shouldScroll = React.useMemo(() => {
    return searchParams.has('copy') || searchParams.has('new') || searchParams.has('light');
  }, [searchParams]);

  useMount(() => {
    if (scrollToItem) {
      scrollToItem.scrollIntoView({ behavior: 'smooth' });
    }
  });

  useEffectOnce(() => {
    if (shouldScroll && scrollToItem) {
      scrollToItem.scrollIntoView({ behavior: 'smooth' });
    }
  });
};
