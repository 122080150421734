import { Box, Text } from '@/core-components';
import { Feature, useShouldEnableFeature } from '@/Core/utils/shouldEnableFeature';
import { When } from '@/Core/utils/When.tsx';
import { ExpensesChart } from '@/Dashboard/components/ExpensesChart';
import { InvoicesChart } from '@/Dashboard/components/InvoicesChart';
import { PaymentsChart } from '@/Dashboard/components/PaymentsChart';
import React from 'react';

export const ChartCardWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box display="flex" height="100%" minHeight="520px" flexDirection="column" alignItems="center">
    {children}
  </Box>
);
export const ChartWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box display="flex" width="100%" height="100%" justifyContent="center">
    {children}
  </Box>
);

export interface DashboardFilters {
  client?: string;
  year: number;
}

export const DashboardCharts: React.FC = () => {
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);

  return (
    <Box w="100%" h="100%">
      <Box bg="white" boxShadow="sm" borderRadius={6} p={3}>
        <Text fontSize="lg" fontWeight="bold">
          Фактури
        </Text>
        <InvoicesChart />
      </Box>
      <Box bg="white" mt={5} boxShadow="sm" borderRadius={6} p={3}>
        <Text fontSize="lg" fontWeight="bold">
          Расходи
        </Text>
        <ExpensesChart />
      </Box>
      <When truthy={shouldEnablePayments}>
        <Box bg="white" mt={5} boxShadow="sm" borderRadius={6} p={3}>
          <Text fontSize="lg" fontWeight="bold">
            Каса Прими
          </Text>
          <PaymentsChart />
        </Box>
      </When>
    </Box>
  );
};
DashboardCharts.displayName = 'DashboardCharts';
