import { useGetAllClientsQuery } from '@/Clients/api/clients.api';
import { Client, ClientType } from '@/Clients/api/clients.types';
import { Box, Flex, Input, SkeletonText, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TableEmptyState } from '@/Core/components/Tables/TableEmptyState.tsx';
import { TableFooter } from '@/Core/components/Tables/TableFooter.tsx';
import { useSearchParams } from '@/Core/hooks/useSearchParams';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import { DEFAULT_PAGE_SIZE, fuzzyFilter } from '@/Core/utils/tableDefaultOptions';
import { initiateInvoiceRequest } from '@/Invoices/api/invoices.api';
import { Invoice } from '@/Invoices/api/invoices.types';
import { InvoicePaymentModal } from '@/Invoices/components/InvoicePaymentModal';
import { useInvoicesTableColumns } from '@/Invoices/hooks/useInvoicesTableColumns';
import { MailTreasuryModal } from '@/Treasuries/components/MailTreasuryModal';
import { PaymentStatus } from '@/Treasuries/store/treasuries.types';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Select as ChakraSelect } from 'chakra-react-select';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface Props {
  invoices: Invoice[];
  onDelete: (invoice: Invoice) => void;
  onCopy: (invoice: Invoice) => void;
  onDownload: (invoice: Invoice) => void;
  processingInvoice: Invoice | null;
}

type StatusOption = {
  label: string;
  value: string;
};

const statuses: StatusOption[] = [
  { label: 'Платени', value: PaymentStatus.Paid },
  { label: 'Неплатени', value: PaymentStatus.Overdue },
  { label: 'Во тек', value: PaymentStatus.Pending },
  { label: 'Делумно платени', value: PaymentStatus.PartiallyPaid },
];

type ClientOption = {
  id: string;
  label?: string;
  value: string;
};
export const InvoicesTable: React.FC<Props> = ({ invoices, processingInvoice, onCopy, onDelete, onDownload }) => {
  const navigate = useNavigate();
  const { data: allClients } = useGetAllClientsQuery();
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const clients = React.useMemo(() => getSortedEntityItems<Client>(allClients), [allClients]);
  const clientOptions: ClientOption[] = React.useMemo(
    () =>
      clients.map((client) => ({
        id: client.id,
        value: client.id,
        label: client.type === ClientType.Company ? client.businessName : client.fullName,
      })),
    [clients],
  );
  const { searchParams, updateSearchParams } = useSearchParams();
  const defaultStatus = React.useMemo(
    () => statuses.find((status) => status.value === searchParams.get('status')),
    [searchParams],
  );
  const defaultClient = React.useMemo(
    () => clientOptions.find((client) => client.value == searchParams.get('client')),
    [clientOptions, searchParams],
  );
  const [search, setSearch] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState<StatusOption | null>(defaultStatus ?? null);
  const [clientFilter, setClientFilter] = React.useState<ClientOption | null>(defaultClient ?? null);

  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const { isOpen: isOpenPaymentModal, onOpen: openPaymentModal, onClose: onClosePaymentModal } = useDisclosure();

  const {
    isOpen: isOpenMailTreasuryModal,
    onOpen: openTreasuryModal,
    onClose: onCloseMailTreasuryModal,
  } = useDisclosure();

  const data = React.useMemo(() => {
    return invoices.filter((invoice) => {
      const hasMatchingStatus = statusFilter == null || invoice.paymentStatus === statusFilter.value;
      const hasMatchingClient = clientFilter == null || invoice.client?.id === clientFilter.value;

      return hasMatchingStatus && hasMatchingClient;
    });
  }, [invoices, statusFilter, clientFilter]);

  const columns = useInvoicesTableColumns({
    openPaymentModal,
    openTreasuryModal,
    onCopy,
    onDownload,
    onDelete,
  });

  const table = useReactTable({
    data,
    initialState: {
      pagination: { pageIndex: page - 1, pageSize: DEFAULT_PAGE_SIZE },
    },
    state: { globalFilter: search },
    filterFns: { fuzzy: fuzzyFilter },
    columns,
    autoResetPageIndex: search !== '' || statusFilter?.value !== null,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const pageIndex = table.getState().pagination.pageIndex;

  React.useEffect(() => {
    updateSearchParams({ page: (pageIndex + 1).toString() });
    if (defaultClient) {
      setClientFilter(defaultClient);
    }
  }, [pageIndex, defaultClient, updateSearchParams]);

  const rows = table.getRowModel().rows;

  return (
    <Box pb={2}>
      <Flex gap="2" mb="8" justifyContent="space-between">
        <Input
          placeholder="Барај Фактури"
          size="sm"
          value={search}
          colorScheme={colorScheme}
          focusBorderColor={inputFocusBorderColor}
          onChange={(e) => setSearch(e.target.value)}
          minW="20rem"
          maxW="25rem"
        />
        <Box display="flex" gap={2}>
          <Box minWidth="250px">
            <ChakraSelect
              isClearable
              name="client"
              isLoading={!clients}
              tagColorScheme={colorScheme}
              focusBorderColor={inputFocusBorderColor}
              selectedOptionColorScheme={colorScheme}
              size="sm"
              placeholder="Клиент"
              value={clientFilter}
              options={clientOptions}
              onChange={(option) => {
                setClientFilter(option);
                updateSearchParams({ client: option?.value ?? '' });
              }}
            />
          </Box>
          <Box minWidth="250px">
            <ChakraSelect
              isClearable
              name="status"
              tagColorScheme={colorScheme}
              focusBorderColor={inputFocusBorderColor}
              selectedOptionColorScheme={colorScheme}
              size="sm"
              placeholder="Статус"
              value={statusFilter}
              options={statuses}
              onChange={(option) => {
                setStatusFilter(option);
                updateSearchParams({ status: option?.value ?? '' });
              }}
            />
          </Box>
        </Box>
      </Flex>
      <Box data-testid="invoices-table">
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} width={`${header.getSize()}%`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isEmpty(rows) ? (
              <TableEmptyState icon={faFileInvoiceDollar} title="Нема Фактури" />
            ) : (
              rows?.map((row) => (
                <Tr
                  key={row.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'gray.50' }}
                  onMouseEnter={() => {
                    initiateInvoiceRequest(row.original.id);
                  }}
                  onClick={() => navigate(`/invoices/${row.original.id}`)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {row.original.id === processingInvoice?.id ? (
                        <SkeletonText noOfLines={1} skeletonHeight="8" />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <TableFooter table={table} />
      {isOpenPaymentModal ? <InvoicePaymentModal isOpen={isOpenPaymentModal} onClose={onClosePaymentModal} /> : null}
      {isOpenMailTreasuryModal ? (
        <MailTreasuryModal isOpen={isOpenMailTreasuryModal} onClose={onCloseMailTreasuryModal} />
      ) : null}
    </Box>
  );
};
