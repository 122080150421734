import { Box } from '@/core-components';
import clsx from 'clsx';
import * as React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export const LoaderContainer: React.FC<React.PropsWithChildren<Props>> = ({ children, className, style }) => {
  return (
    <Box
      p={4}
      className={clsx('container', className)}
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        borderRadius: '4px',
        maxWidth: '1152px',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};
