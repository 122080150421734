import { Box, Flex, Heading, List, ListItem, Text } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { BrojkiLogo } from '@/Core/components/BrojkiLogo/BrojkiLogo';
import { TODO } from '@/Core/core.types';
import * as React from 'react';
import styles from './AuthenticationPage.module.scss';
import { LogoText } from './LogoText';
import { TermsConditionsDialog } from './TermsConditions';

const displayErrorMessage = (failures: TODO) => {
  if (Object.keys(failures?.errors).length > 0) {
    return Object.keys(failures?.errors)
      .map((key) => failures?.errors[key])
      .flat();
  }

  return [failures?.errors?.message];
};

export const AuthenticationPage: React.FC<React.PropsWithChildren<{ showProgress?: boolean }>> = ({ children }) => {
  const failures = useAppSelector((state) => state.authenticated.failures);

  const tagline = React.useMemo(() => {
    const taglines: string[] = [
      'Кажете им збогум на бизнис проблемите со Brojki.',
      'Brojki – вашата тајна за поефикасен бизнис.',
      'Помалку проблеми, повеќе успех – со Brojki.',
      'Оптимизирајте го вашиот бизнис со Brojki.',
    ];
    const randomIndex = Math.floor(Math.random() * taglines.length);
    return taglines[randomIndex];
  }, []);

  return (
    <Box
      className={styles.host}
      overflowY={{ base: 'auto', md: 'hidden' }}
      flexFlow={{ base: 'initial', md: 'nowrap' }}
    >
      <Box display={{ base: 'none', md: 'flex' }} className={styles.forsta} flexBasis={{ base: '0', md: '50%' }}>
        <Box m={4} mb={8} display="flex" flexDirection="column">
          <Heading color="white" maxW="90%" textShadow="1px 1px 2px #333">
            {tagline}
          </Heading>
          <Text color="white" maxW="90%" fontStyle="italic" textShadow="1px 1px 2px #333">
            Преземете ја контролата над вашите финансии со Brojki. Едноставно и лесно.
          </Text>
        </Box>
      </Box>
      <Box flexBasis={{ base: '100%', md: '50%' }} overflowY="auto">
        <Box className={styles.authenticationCard}>
          <Box>
            {/* {showProgress ? <Progress colorScheme={colorScheme} size="xs" isIndeterminate mb={4} /> : null} */}
            <Flex direction="column" align="center" width="100%">
              <BrojkiLogo showLogoText={false} />
              <LogoText />
            </Flex>
            {failures?.errors ? (
              <Flex direction="column" align="start" justify="center" my={4}>
                <List ml={6} whiteSpace="pre-line">
                  {displayErrorMessage(failures)?.map((error, i) => (
                    <ListItem color="red.500" fontSize="sm" key={i}>
                      {error}
                    </ListItem>
                  ))}
                </List>
              </Flex>
            ) : null}
            {children}
          </Box>
          <Flex justify="center" align="center" mt={3} mb={3}>
            <TermsConditionsDialog />
          </Flex>
          <Box textAlign="center" alignSelf="flex-end" mt="auto">
            <Text color="gray.500" mb={1}>
              &copy; Copyright 2018 - {new Date().getFullYear()} Brojki. All rights reserved.
            </Text>
            <Text color="gray.500">
              <a href="mailto:info@brojki.com">info@brojki.com</a>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
