import { initiateEmailVerification, updateUserProfile } from '@/Authentication/store/authenticatedUser.actions';
import { useGetAuthenticatedUserQuery } from '@/Authentication/store/authenticatedUser.api';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  useToast,
} from '@/core-components';
import { useAppDispatch, useAppSelector } from '@/Core/AppStore';
import { UserProfileLoader } from '@/Core/components/Loaders/UserProfileLoader';
import { ProfileAvatarUploader } from '@/Core/components/Profile/ProfileAvatarUploader';
import { DetailSingleContainer } from '@/Core/components/SplitView/DetailContainer';
import { TODO } from '@/Core/core.types';
import { validate } from '@/Core/utils/validate';
import { createSuccessToast } from '@/Toaster/store/toaster.utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';

const validationSchema = validate.object().shape({
  name: validate.string().required('Потребно е Име и Презиме'),
  email: validate.string().required('Потребна е емаил адреса').email('Неважечка емаил адреса'),
  password: validate.string().min(8, 'Лозинката треба да биде минимум 8 карактери.'),
  password_confirmation: validate.string().oneOf([validate.ref('password'), ''], 'Лозинките мора да се совпаднат'),
});

interface FormValues {
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
}

const resolver = yupResolver(validationSchema);

export const Profile: React.FC = () => {
  const { data: user, isLoading } = useGetAuthenticatedUserQuery();
  const { colorScheme } = useAppSelector((state) => state.ui.theme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const [isRequestingEmailVerification, setIsRequestingEmailVerification] = React.useState(false);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const form = useForm<FormValues>({
    resolver,
    defaultValues: { name: user?.name ?? '', email: user?.email ?? '', password: '', password_confirmation: '' },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = form;

  const isFullNameInvalid = !!(errors.name && touchedFields.name);
  const isPasswordInvalid = !!(errors.password && touchedFields.password);
  const isPasswordConfirmationInvalid = !!(errors.password_confirmation && touchedFields.password_confirmation);

  const onSubmit = async ({ name, password, password_confirmation }: TODO) => {
    const user: TODO = await dispatch(updateUserProfile({ name, password, password_confirmation } as TODO));

    if (user && user.data) {
      toast(
        createSuccessToast({
          position: 'top',
          title: 'Успешно',
          description: 'Промените се зачувани',
          duration: 3210,
        }),
      );
    }
  };

  const requestEmailVerification = async () => {
    setIsRequestingEmailVerification(true);
    const { data } = await initiateEmailVerification();
    setIsRequestingEmailVerification(false);
    if (data.status === 'sent') {
      toast(
        createSuccessToast({
          position: 'top',
          title: 'Успешно',
          description: 'Е-маилот е успешно испратен.',
          duration: 3210,
        }),
      );
    }

    if (data.status === 'verified') {
      toast(
        createSuccessToast({
          position: 'top',
          title: 'Успешно',
          description: 'Е-маилот е успешно потврден.',
          duration: 3210,
        }),
      );
    }
  };

  return (
    <DetailSingleContainer>
      <Box mt="1rem" p={4}>
        <Box maxW="container.xl" mx="auto">
          {isLoading ? (
            <UserProfileLoader />
          ) : (
            <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Text fontSize="2xl">Мој Профил</Text>
                <Grid templateColumns="repeat(12, 1fr)" gap={6} mt={5}>
                  <GridItem colSpan={3} display="flex" alignItems="center">
                    <Text fontWeight="bold">Аватар</Text>
                  </GridItem>
                  <GridItem colSpan={8} display="flex" alignItems="center">
                    <ProfileAvatarUploader />
                  </GridItem>
                </Grid>

                <FormControl isInvalid={isFullNameInvalid} mb={3} mt={3}>
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    <GridItem colSpan={3}>
                      <FormLabel htmlFor="name">Име Презиме</FormLabel>
                    </GridItem>
                    <GridItem colSpan={8}>
                      <Input
                        id="name"
                        type="text"
                        focusBorderColor={inputFocusBorderColor}
                        placeholder="Име Презиме"
                        {...register('name')}
                      />
                      <FormErrorMessage>
                        <>{errors.name?.message}</>
                      </FormErrorMessage>
                    </GridItem>
                  </Grid>
                </FormControl>

                <FormControl isInvalid={!user?.hasVerifiedEmail} mb={user?.hasVerifiedEmail ? 0 : 3}>
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    <GridItem colSpan={3}>
                      <FormLabel htmlFor="email" color={!user?.hasVerifiedEmail ? 'red.500' : 'inherit'}>
                        {!user?.hasVerifiedEmail ? 'Емаил адресата не е потврдена' : 'Емаил'}
                      </FormLabel>
                    </GridItem>
                    <GridItem colSpan={8}>
                      <Input
                        id="email"
                        type="text"
                        name="email"
                        focusBorderColor={inputFocusBorderColor}
                        placeholder="Емаил"
                        value={user?.email}
                        autoComplete="off"
                        isDisabled
                        isReadOnly
                      />
                    </GridItem>
                  </Grid>
                </FormControl>

                {!user?.hasVerifiedEmail ? (
                  <FormControl mb={3}>
                    <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                      <GridItem colSpan={8} />
                      <GridItem colSpan={3}>
                        <Text fontSize="sm" color="red.500">
                          <Button
                            type="button"
                            onClick={requestEmailVerification}
                            isLoading={isRequestingEmailVerification}
                          >
                            Испрати е-маил за потврда
                          </Button>
                        </Text>
                      </GridItem>
                    </Grid>
                  </FormControl>
                ) : null}

                <FormControl isInvalid={isPasswordInvalid} mb={3}>
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    <GridItem colSpan={3}>
                      <FormLabel htmlFor="password">Лозинка</FormLabel>
                    </GridItem>
                    <GridItem colSpan={8}>
                      <Input
                        id="password"
                        type="password"
                        focusBorderColor={inputFocusBorderColor}
                        placeholder="Лозинка"
                        autoComplete="current-password"
                        {...register('password')}
                      />
                      <FormErrorMessage>
                        <>{errors.password?.message}</>
                      </FormErrorMessage>
                    </GridItem>
                  </Grid>
                </FormControl>

                <FormControl isInvalid={isPasswordConfirmationInvalid} mb={3}>
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    <GridItem colSpan={3}>
                      <FormLabel htmlFor="password_confirmation">Повтори Лозинка</FormLabel>
                    </GridItem>
                    <GridItem colSpan={8}>
                      <Input
                        id="password_confirmation"
                        type="password"
                        focusBorderColor={inputFocusBorderColor}
                        placeholder="Повтори Лозинка"
                        autoComplete="new-password"
                        {...register('password_confirmation')}
                      />
                      <FormErrorMessage>
                        <>{errors.password_confirmation?.message}</>
                      </FormErrorMessage>
                    </GridItem>
                  </Grid>
                </FormControl>

                <Box display="flex" justifyContent="flex-end">
                  <Button mt={4} colorScheme={colorScheme} isLoading={isLoading} type="submit">
                    Зачувај
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </DetailSingleContainer>
  );
};
Profile.displayName = 'Profile';
export default Profile;
