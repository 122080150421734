import * as React from 'react';
import { useMatch } from 'react-router-dom';

export const useIsEditing = ({ pattern, id }: { pattern: string; id?: string }) => {
  const match = useMatch(pattern);

  const isEditing = React.useMemo(() => match != null && id === match?.params?.id, [match, id]);

  return [isEditing, match] as const;
};
