import { useIsAuthenticated } from '@/Core/hooks/useIsAuthenticated';
import { Feature, useShouldEnableFeature } from '@/Core/utils/shouldEnableFeature';
import * as React from 'react';
import { Navigate } from 'react-router';

export const PrivateRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isAuthenticated] = useIsAuthenticated();

  return isAuthenticated ? <>{children}</> : <Navigate to={{ pathname: '/login' }} replace />;
};

export const ShouldEnablePayments: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);

  return shouldEnablePayments ? <>{children}</> : null;
};
