import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
interface Props {
  /**
   * Data to validate for truthiness.
   */
  truthy: unknown;

  /**
   * A component that should be rendered when the condition evaluates to false
   */
  fallback?: React.ReactNode;
}

/**
 * Render conditional blocks with truthy condition and fallback option.
 *
 * @example
 * <When truthy={evaluateSomething()}> ...children... </When>
 */
export const When: React.FC<React.PropsWithChildren<Props>> = ({ truthy, fallback = null, children }) => {
  if (truthy == null) {
    return <>{fallback}</>;
  }

  if (Array.isArray(truthy) || typeof truthy === 'string') {
    return truthy.length > 0 ? <>{children}</> : <>{fallback}</>;
  }

  if (typeof truthy === 'object' && typeof (truthy as Iterable<unknown>)[Symbol.iterator] === 'function') {
    return !isEmpty(truthy as Iterable<unknown>) ? <>{children}</> : <>{fallback}</>;
  }

  return truthy ? <>{children}</> : <>{fallback}</>;
};
