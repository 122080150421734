import { Avatar, Badge, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@/core-components';
import { getTranslatedClientType } from '@/Core/utils/translatableTypes';
import { faEllipsisStroke, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { Client, ClientType } from '../api/clients.types';

const columnHelper = createColumnHelper<Client>();

interface Props {
  onDelete: (client: Client) => void;
}

export const useClientsTableColumns = ({ onDelete }: Props) => {
  return React.useMemo(
    () => [
      columnHelper.accessor('avatarUrl', {
        header: () => '',
        size: 5,
        maxSize: 5,
        cell: (info) => {
          const isCompany = info.row.original.type === ClientType.Company;
          const name = isCompany ? info.row.original.businessName : info.row.original.fullName;
          return <Avatar name={name} src={info.getValue()} size="sm" />;
        },
      }),
      columnHelper.accessor('businessName', {
        header: () => 'Компанија',
        size: 40,
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),
      columnHelper.accessor('fullName', {
        header: () => 'Име Презиме (Контакт)',
        size: 35,
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
      }),
      columnHelper.accessor('type', {
        header: () => 'Вид',
        size: 10,
        cell: (info) => {
          const type = getTranslatedClientType(info.getValue());
          const isCompany = info.row.original.type === ClientType.Company;
          const color = isCompany ? 'sky' : 'green';
          return (
            <Badge textTransform="capitalize" colorScheme={color}>
              {type}
            </Badge>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        size: 10,
        maxSize: 10,
        header: () => '',
        cell: (info) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FontAwesomeIcon icon={faEllipsisStroke} />}
              variant="ghost"
              size="sm"
              fontSize={18}
              onClick={(e) => e.stopPropagation()}
            />
            <MenuList>
              {/* <MenuDivider /> */}
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (info.row.original) {
                    onDelete(info.row.original);
                  }
                }}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
              >
                Додади во корпа
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      }),
    ],
    [onDelete],
  );
};
