import { SerializedError } from '@reduxjs/toolkit';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { Project } from './projects.types';

type Error =
  | {
      status: number;
      data: { message?: string | undefined; error?: string | undefined; errorCode?: string | undefined };
    }
  | SerializedError;

type Result = { data: Project } | { error: Error };

export const notifyProjectDeleted = (project: Project, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${project.id}-error`,
      title: 'Failed to delete project',
    });
    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === project.id) {
    toast = createInfoToast({
      id: `${project.id}-success`,
      title: 'Успешно',
      description: `Проектот "${project.name ?? ''}" е додаден во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyProjectUpdated = (project: Project, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${project.id}-error`,
      title: 'Failed to update project',
    });

    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === project.id) {
    toast = createInfoToast({
      id: `${project.id}-success`,
      title: 'Успешно',
      description: `Проектот "${project?.name ?? ''}" e зачуван!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const redirectProjectAfterDeletion = (project: Project, projects?: Record<string, Project>) => {
  if (!projects) {
    return null;
  }
  const { [project.id]: _, ...withoutRemoved } = projects;
  const deletedIndex = Object.keys(projects).findIndex((i) => projects[i]?.id === project.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
