import { Box, useDisclosure } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { EmptyMasterViewList } from '@/Core/components/EmptyStates/EmptyMasterViewList';
import EmptyState from '@/Core/components/EmptyStates/invoices.svg?react';
import { MasterList } from '@/Core/components/SplitView/MasterList';
import { useIsLoadingNavigation } from '@/Core/hooks/useIsLoading';
import { useFilterQuery, useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import {
  useDeleteInvoiceMutation,
  useGetAllInvoicesQuery,
  useGetInvoiceQuery,
  useReplicateInvoiceMutation,
} from '@/Invoices/api/invoices.api';
import { Invoice } from '@/Invoices/api/invoices.types';
import { notifyInvoiceCopied, notifyInvoiceDeleted } from '@/Invoices/api/invoices.utils';
import { InvoiceItem } from '@/Invoices/components/InvoiceItem';
import { InvoicePaymentModal } from '@/Invoices/components/InvoicePaymentModal';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

const EmptyText: React.FC = () => {
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      Клик на
      <Box
        borderRadius="sm"
        mx={1}
        backgroundColor={colorSchemeValue}
        color="white"
        boxSize="20px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Box>
      да креирате нова фактура.
    </Box>
  );
};

interface Props {
  onSuccessfulDelete: (invoice: Invoice) => void;
}

export const InvoicesList: React.FC<Props> = ({ onSuccessfulDelete }) => {
  const navigate = useNavigate();
  const [isNavigating] = useIsLoadingNavigation();
  const { id } = useParams<{ id: string }>();
  const { query, isSearching } = useSearchQuery();
  const { isFiltering } = useFilterQuery();

  const match = useMatch('/invoices/:id/edit');

  const { data, isLoading, isFetching } = useGetAllInvoicesQuery();
  const invoices = getSortedEntityItems<Invoice>(data);
  const { data: selectedInvoice } = useGetInvoiceQuery(id!, { skip: !isFiltering });

  const [deleteInvoice, { isLoading: isDeleting }] = useDeleteInvoiceMutation();
  const [copyInvoice, { isLoading: isCopying }] = useReplicateInvoiceMutation();

  const shouldDisableActions = React.useMemo(
    () => isLoading || isFetching || isDeleting || isCopying,
    [isLoading, isFetching, isDeleting, isCopying],
  );

  const items = React.useMemo(() => {
    if (!isSearching) {
      return invoices;
    }

    return invoices
      .filter((invoice) => {
        return isFiltering ? invoice?.client?.id === selectedInvoice?.client?.id : invoice;
      })
      .filter((invoice) => {
        const foundNumber = invoice?.invoiceNumber?.includes(query);
        const foundClient =
          invoice?.client?.fullName?.toLocaleLowerCase()?.includes(query?.toLocaleLowerCase()) ||
          invoice?.client?.businessName?.toLocaleLowerCase().includes(query?.toLocaleLowerCase());

        return foundNumber || foundClient;
      });
  }, [invoices, query, isSearching, isFiltering, selectedInvoice?.client?.id]);

  // Register payments on an invoice modal
  const { isOpen: isOpenPaymentModal, onOpen: openPaymentModal, onClose: closeOpenPaymentModal } = useDisclosure();

  const payInvoice = async (invoice: Invoice) => {
    if (!isMobile) {
      navigate(`/invoices/${invoice.id}/`);
    }
    openPaymentModal();
  };

  return (
    <>
      <MasterList isLoading={isLoading || isFetching} data-test-id="invoices-master-list">
        {isEmpty(items) ? (
          <EmptyMasterViewList
            isSearching={isSearching}
            title={isSearching ? `Немате фактури со вашето пребарување "${query}"` : 'Немате Фактури'}
            text={isSearching ? null : <EmptyText />}
          >
            <EmptyState />
          </EmptyMasterViewList>
        ) : (
          <AnimatePresence initial={false}>
            {items.map((invoice) =>
              invoice ? (
                <InvoiceItem
                  key={invoice.id}
                  invoice={invoice}
                  isLoading={shouldDisableActions}
                  onEdit={(invoice) => {
                    const isEditing = match != null && invoice.id === match?.params?.id;
                    navigate(isEditing ? `/invoices/${invoice.id}` : `/invoices/${invoice.id}/edit`);
                  }}
                  onDelete={async (invoice) => {
                    const result = await deleteInvoice(invoice.id);
                    const deleted = notifyInvoiceDeleted(invoice, result);
                    if (deleted) {
                      onSuccessfulDelete(invoice);
                    }
                  }}
                  onCopy={async (invoice) => {
                    const result = await copyInvoice(invoice);
                    const saved = notifyInvoiceCopied(invoice, result);
                    if (saved) {
                      return navigate(`/invoices/${saved.id}/?copy=true`);
                    }
                  }}
                  onPay={payInvoice}
                />
              ) : null,
            )}
          </AnimatePresence>
        )}
      </MasterList>
      {isOpenPaymentModal && !isNavigating ? (
        <InvoicePaymentModal isOpen={isOpenPaymentModal} onClose={closeOpenPaymentModal} />
      ) : null}
    </>
  );
};
InvoicesList.displayName = 'InvoicesList';
