import { v4 } from 'uuid';
import { DraftableStatus } from '../../Core/core.types';
import { validate } from '../../Core/utils/validate';
import { Product, ProductType } from './products.types';

export const makeProduct = (product?: Partial<Product>): Product => {
  return {
    id: v4(),
    status: DraftableStatus.Draft,
    type: ProductType.Product,
    amountInStock: '0.00',
    unitCost: '0.00',
    unitPrice: 0,
    ...product,
  };
};

export const productValidationSchema = validate.object().shape({
  unitPrice: validate.number().typeError('Цената мора да биде бројка').required('Недостасува цена на производ/услуга'),
});
