import { Skeleton, Stack } from '@/core-components';
import * as React from 'react';

export const DashboardHeaderLoader = React.memo(() => {
  return (
    <Stack height="100px">
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="20px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="20px" width="95%" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="20px" width="90%" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="20px" width="85%" />
    </Stack>
  );
});
