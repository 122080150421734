import { Box } from '@/core-components';
import { useScrollToItem } from '@/Core/hooks/useScrollToItem';
import { motion } from 'framer-motion';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styles from './MasterList.module.scss';

const transition = {
  duration: 0.2,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const variants = {
  initial: { x: -300, opacity: 0.4, transition },
  animate: { x: 0, opacity: 1, transition },
  exit: { x: 300, opacity: 0.4, transition },
};

interface Props {
  isLoading?: boolean;
}

export const MasterList = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ children, isLoading = false, ...rest }, ref) => {
    useScrollToItem();
    return isMobile ? (
      <Box
        as={motion.div}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        height="100%"
        className={styles.container}
        opacity={isLoading ? 0.8 : 1}
        {...rest}
      >
        <div ref={ref} className={styles.list}>
          {children}
        </div>
      </Box>
    ) : (
      <Box as={motion.div} height="100%" className={styles.container} {...rest}>
        <div ref={ref} className={styles.list}>
          {children}
        </div>
      </Box>
    );
  },
);
