import { SplitView } from '@/Core/components/SplitView/SplitView';
import { SplitViewType } from '@/Core/components/SplitView/SplitViewType';
import * as React from 'react';
import { useGetAllClientsQuery } from '../api/clients.api';
import { ClientCreatorLoader } from './ClientCreatorLoader';
import { ClientPageLoader } from './ClientLoader';
import { ClientsMasterView } from './ClientsMasterView';
export const Clients: React.FC = () => {
  const { isLoading } = useGetAllClientsQuery();

  return (
    <SplitView
      isLoading={isLoading}
      type={SplitViewType.Client}
      masterView={<ClientsMasterView />}
      detailView={<ClientPageLoader />}
      creatorView={<ClientCreatorLoader />}
    />
  );
};

Clients.displayName = 'Clients';

export default Clients;
