import { useAppSelector } from '@/Core/AppStore';
import { GridCard, GridPage } from '@/Core/components/ContentContainers/GridPage';
import { getSortedEntityItems } from '@/Core/utils/getSortedEntityItems';
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetAllProductsQuery,
} from '@/Products/api/products.api';
import { makeProduct } from '@/Products/api/products.model';
import { Product, ProductType } from '@/Products/api/products.types';
import { notifyProductDeleted } from '@/Products/api/products.utils';
import { ProductsTable } from '@/Products/components/ProductsTable';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { Button, Menu, MenuButton, MenuItem, MenuList, useToast } from '@/core-components';
import { captureEvent } from '@/setupPostHog';
import { faChevronDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const Products: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data } = useGetAllProductsQuery();
  const products = React.useMemo(() => getSortedEntityItems<Product>(data), [data]);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const [processingProduct, setProcessingProduct] = React.useState<Product | null>(null);

  const [createProduct, { isLoading: isCreating }] = useCreateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();

  const onCreateProduct = async (type: ProductType) => {
    captureEvent(`create_product_${type}`);
    const product = makeProduct({ type });
    const result = await createProduct(product);

    if ('error' in result) {
      toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
      return;
    }

    navigate(`/products/${result.data.id}/edit`);
  };

  const onDeleteProduct = React.useCallback(
    async (product: Product) => {
      captureEvent('delete_product');
      setProcessingProduct(product);

      try {
        const result = await deleteProduct(product.id);
        notifyProductDeleted(product, result);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessingProduct(null);
      }
    },
    [deleteProduct],
  );

  return (
    <GridPage
      title="Производи и Услуги"
      actions={
        <Menu>
          <MenuButton
            isLoading={isCreating}
            colorScheme={colorScheme}
            as={Button}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            Нов Производ
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => onCreateProduct(ProductType.Product)}>Нов Производ</MenuItem>
            <MenuItem onClick={() => onCreateProduct(ProductType.Service)}>Нова Услуга</MenuItem>
          </MenuList>
        </Menu>
      }
    >
      <GridCard title="">
        <ProductsTable products={products} processingProduct={processingProduct} onDelete={onDeleteProduct} />
      </GridCard>
    </GridPage>
  );
};
Products.displayName = 'Products';

export default Products;
