import { uploadUserAvatar } from '@/Authentication/store/authenticatedUser.actions';
import { authUserApi, useGetAuthenticatedUserQuery } from '@/Authentication/store/authenticatedUser.api';
import { Avatar, Box, Progress, Skeleton, Text } from '@/core-components';
import { useAppDispatch } from '@/Core/AppStore';
import { useDropzoneAvatarUpload } from '@/Core/hooks/useDropzoneUpload';
import isEmpty from 'lodash-es/isEmpty';
import React, { useCallback, useState } from 'react';

export const ProfileAvatarUploader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useGetAuthenticatedUserQuery();
  const [isUploadingAvatar, setUploadingAvatar] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }

      setUploadingAvatar(true);
      const formData = new FormData();
      formData.append('avatar', acceptedFiles[0]);

      uploadUserAvatar(formData, {
        onUploadProgress: (event) => {
          if (event.total) {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(percentCompleted);
          }
        },
      })
        .then(() => {
          dispatch(authUserApi.endpoints.getAuthenticatedUser.initiate(undefined, { forceRefetch: true }));
          setUploadingAvatar(false);
          setUploadProgress(0);
        })
        .catch(() => {
          setUploadingAvatar(false);
          setUploadProgress(0);
        });
    },
    [dispatch],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzoneAvatarUpload({ onDrop });

  return (
    <Box
      {...getRootProps()}
      p={2}
      display="flex"
      width="97%"
      cursor="pointer"
      borderWidth="1px"
      borderStyle="dashed"
      minHeight="140px"
      bg={isDragReject ? 'red.500' : 'gray.50'}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <input {...getInputProps()} disabled={isUploadingAvatar} />
      {isUploadingAvatar ? (
        <Skeleton startColor="sky.500" endColor="teal.500" height="120px" width="260px" />
      ) : (
        <Avatar name={user?.name} size="xl" src={user?.avatarUrl} />
      )}

      <Box mt={2}>
        {isDragReject && <Text color="white">Датотеката не е поддржана. Само "png" и "jpg"</Text>}
        {isDragActive && !isDragReject ? (
          <Text color="sky.600">Спушти аватар овде ...</Text>
        ) : isUploadingAvatar ? (
          <Box>
            <Text color="sky.600">Поставување аватар. Ве молиме почекајте.</Text>
            <Progress size="xs" value={uploadProgress} />
          </Box>
        ) : (
          !isDragReject && <Text color="sky.600">Повлечи па спушти аватар овде или кликнете за да изберете.</Text>
        )}
      </Box>
    </Box>
  );
};
