import { Box, ButtonGroup, Text } from '@/core-components';
import { UnsavedChangesBadge } from '@/Core/components/Helpers/UnsavedChangesBadge';
import { ActionButton } from '@/Core/components/ListItemAction/ActionButton';
import { ListItemAction } from '@/Core/components/ListItemAction/ListItemAction';
import { useIsEditing } from '@/Core/hooks/useIsEditing';
import { useSearchQuery } from '@/Core/hooks/useSearchQuery';
import { toFormattedDate } from '@/Core/utils/dateTime';
import { highlightTerm } from '@/Core/utils/highlighter';
import { getTranslatedPaymentMethod } from '@/Core/utils/translatableTypes';
import { Expense } from '@/Expenses/api/expenses.types';
import { formatMoney } from '@/Treasuries/store/treasuries.utils';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ActionsProps {
  expense: Expense;
  isSaving: boolean;
  isEditing: boolean;
  onEdit?: (expense: Expense) => Expense | unknown;
  onDelete?: (expense: Expense) => Expense | unknown;
}

const Actions: React.FC<ActionsProps> = ({ expense, onEdit, onDelete, isSaving = false, isEditing = false }) => (
  <ButtonGroup spacing={3}>
    <ActionButton
      icon={<FontAwesomeIcon icon={faPenToSquare} />}
      isEditing={isEditing}
      label={isEditing ? 'Откажи' : 'Измени'}
      onClick={() => onEdit?.(expense)}
      disabled={isSaving}
    />
    <ActionButton
      icon={<FontAwesomeIcon icon={faTrashCan} />}
      label="Додади во корпа"
      tooltipBg="red.600"
      onClick={() => onDelete?.(expense)}
      disabled={isSaving}
    />
  </ButtonGroup>
);

interface Props {
  expense: Expense;
  isLoading: boolean;
  onSave: (expense: Expense) => Expense | unknown;
  onEdit: (expense: Expense) => Expense | unknown;
  onDelete: (expense: Expense) => Expense | unknown;
}

export const ExpenseItem: React.FC<Props> = ({ expense, onEdit, onDelete, isLoading }) => {
  const { query } = useSearchQuery();
  const [isEditing] = useIsEditing({ pattern: '/expenses/:id/edit', id: expense.id });

  const shouldDisableActions = React.useMemo(() => {
    return isEditing ?? false;
  }, [isEditing]);

  const { client, expenseNumber, expenseDate, amount, paymentMethod, currency } = expense;

  const highlightedNumber = query ? highlightTerm(expenseNumber, query) : expenseNumber;
  const highlightedClientFullName = query ? highlightTerm(client?.fullName, query) : client?.fullName;
  const highlightedClientBusinessName = query ? highlightTerm(client?.businessName, query) : client?.businessName;

  return (
    <ListItemAction
      disabled={isLoading || shouldDisableActions}
      id={expense.id}
      item={{ entity: expense, path: 'expenses' }}
      actions={
        <Actions
          expense={expense}
          isSaving={isLoading}
          isEditing={isEditing}
          onEdit={() => {
            onEdit(expense);
          }}
          onDelete={onDelete}
        />
      }
    >
      <UnsavedChangesBadge isEditing={isEditing} hasUnsavedChanges={false} />

      <Box display="flex" width="100%" justifyContent="space-between" marginBottom={2}>
        <Text
          fontSize="sm"
          color="gray.600"
          dangerouslySetInnerHTML={{ __html: highlightedNumber ?? `${expenseNumber ?? ''}` }}
        />
        <Text fontSize="sm" color="gray.600">
          {expenseDate ? toFormattedDate(expenseDate) : null}
        </Text>
      </Box>

      <Box display="flex" width="100%" flexDirection="column" marginY={1}>
        <Box display="flex" width="100%" flexDirection="column" marginY={2}>
          <Text
            marginBottom={0}
            dangerouslySetInnerHTML={{ __html: highlightedClientBusinessName ?? `${client?.businessName ?? ''}` }}
          />
          <Text
            fontWeight="light"
            dangerouslySetInnerHTML={{ __html: highlightedClientFullName ?? `${client?.fullName ?? ''}` }}
          />
        </Box>

        <Box display="flex" width="100%" justifyContent="flex-end">
          <Text marginLeft="auto" fontSize="xs" color="sky.500">
            {paymentMethod ? getTranslatedPaymentMethod(paymentMethod) : null}
          </Text>
        </Box>
      </Box>

      <Box display="flex" width="100%" marginBottom={1} justifyContent="flex-end">
        <Text marginLeft="auto" fontSize="md" fontWeight="bold">
          {amount ? formatMoney(parseFloat(amount), currency) : null}
        </Text>
      </Box>
    </ListItemAction>
  );
};

ExpenseItem.displayName = 'ExpenseItem';
