import { Button, IconButton, ThemeTypings, Tooltip } from '@/core-components';
import * as React from 'react';
import { useAppSelector } from '../../AppStore';

type BarIconType = {
  type?: 'button' | 'submit';
  buttonType?: 'icon';
  label: string;
};

type BarButtonType = {
  type?: 'button' | 'submit';
  buttonType?: 'button';
  children: React.ReactNode;
};

type ToolbarButtonProps = {
  icon: React.ReactElement;
  colorScheme?: ThemeTypings['colorSchemes'];
  tooltipBg?: string;
  label?: string;
  type?: string;
  ml?: number;
  mr?: number;
  variant?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  form?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

// Currently if the view is mobile always use icon buttons otherwise decide based on type provided
type ToolbarButtonTypes = BarIconType | BarButtonType;
export const ToolbarButton: React.FC<React.PropsWithChildren<ToolbarButtonProps & ToolbarButtonTypes>> = ({
  onClick = () => console.log('not implemented'),
  form,
  icon,
  children,
  label = '',
  buttonType = 'icon',
  variant = 'ghost',
  type = 'button',
  tooltipBg = 'gray.700',
  ml = 1,
  mr = 1,
  isLoading = false,
  isDisabled = false,
  colorScheme,
  placement = 'top',
}) => {
  const buttonColorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  return buttonType === 'icon' ? (
    <Tooltip hasArrow aria-label={label} label={label} placement={placement} bg={tooltipBg} zIndex={1000} color="white">
      <IconButton
        isRound
        type={type}
        onClick={type === 'submit' ? undefined : onClick}
        form={form}
        colorScheme={colorScheme ?? buttonColorScheme}
        color="gray.600"
        variant={variant}
        isDisabled={isDisabled}
        isLoading={isLoading}
        disabled={isDisabled}
        ml={ml}
        mr={mr}
        size="md"
        aria-label={label}
        fontSize="18px"
        icon={icon}
      />
    </Tooltip>
  ) : (
    <Button
      type={type}
      onClick={type === 'submit' ? undefined : onClick}
      colorScheme={colorScheme ?? buttonColorScheme}
      variant={variant}
      isDisabled={isDisabled}
      isLoading={isLoading}
      disabled={isDisabled}
      size="md"
      ml={1.5}
      mr={1.5}
      leftIcon={icon}
    >
      {children}
    </Button>
  );
};

ToolbarButton.displayName = 'ToolbarButton';
