import LoginPage from '@/Authentication/components/AuthenticationPage/LoginPage.tsx';
import SignupPage from '@/Authentication/components/AuthenticationPage/SignupPage.tsx';
import TermsConditions from '@/Authentication/components/AuthenticationPage/TermsConditions.tsx';
import CompanyAudits from '@/Company/components/CompanyAudits.tsx';
import CompanyPage from '@/Company/components/CompanyPage.tsx';
import CompanyProfile from '@/Company/components/CompanyProfile.tsx';
import CompanySettings from '@/Company/components/CompanySettings.tsx';
import CompanyTrash from '@/Company/components/CompanyTrash.tsx';
import { PublicRoute } from '@/Core/components/PrivateRoutes/PublicRoute.tsx';
import Profile from '@/Core/components/Profile/Profile.tsx';
import { dashboardCountersLoader } from '@/Dashboard/api/dashboard.api';
import Dashboard from '@/Dashboard/components/Dashboard';
import { NotificationPage } from '@/Notifications/components/NotificationPage.tsx';
import { Notifications } from '@/Notifications/components/Notifications.tsx';
import { RouteObject } from 'react-router';

export const commonPublicRoutes: RouteObject[] = [
  {
    index: true,
    Component: () => (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/login',
    Component: () => (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/signup',
    Component: () => (
      <PublicRoute>
        <SignupPage />
      </PublicRoute>
    ),
  },
  {
    path: '/terms-and-conditions',
    Component: () => (
      <PublicRoute>
        <TermsConditions />
      </PublicRoute>
    ),
  },
];

export const commonPrivateRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    loader: dashboardCountersLoader,
    Component: Dashboard,
  },
  {
    path: '/notifications',
    Component: Notifications,
  },
  {
    path: '/notifications/:id',
    Component: NotificationPage,
  },
  {
    path: '/profile',
    Component: Profile,
  },
  {
    path: '/company',
    Component: CompanyPage,
    children: [
      {
        index: true,
        Component: CompanyProfile,
      },
      {
        path: 'profile',
        Component: CompanyProfile,
      },
      {
        path: 'settings',
        Component: CompanySettings,
      },
      {
        path: 'trash',
        Component: CompanyTrash,
      },
      {
        path: 'audits',
        Component: CompanyAudits,
      },
    ],
  },
];
