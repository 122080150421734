import { Box } from '@/core-components';
import { useAppSelector } from '@/Core/AppStore';
import { TODO } from '@/Core/core.types';
import { PaymentMethod } from '@/Core/utils/payment-methods';
import { Select } from 'chakra-react-select';
import * as React from 'react';
import { useController } from 'react-hook-form';

interface PaymentOption {
  value: PaymentMethod;
  label: string;
}

const options: PaymentOption[] = [
  {
    value: PaymentMethod.Cash,
    label: 'Кеш',
  },
  {
    value: PaymentMethod.Invoice,
    label: 'Фактура',
  },
  {
    value: PaymentMethod.BankTransfer,
    label: 'Банкарски трансфер',
  },
  {
    value: PaymentMethod.CreditCard,
    label: 'Кредитна Картичка',
  },
  {
    value: PaymentMethod.DebitCard,
    label: 'Дебитна Картичка',
  },
  {
    value: PaymentMethod.Cheque,
    label: 'Чек',
  },
  {
    value: PaymentMethod.PayPal,
    label: 'PayPal',
  },
  {
    value: PaymentMethod.MasterCard,
    label: 'Master Card',
  },
  {
    value: PaymentMethod.Visa,
    label: 'Visa',
  },
  {
    value: PaymentMethod.DinersClub,
    label: 'Diners Club',
  },
  {
    value: PaymentMethod.AMEX,
    label: 'AMEX',
  },
];

interface Props {
  name?: string;
  control?: TODO;
}

export const PaymentMethodSelector: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  name = 'paymentMethod',
}) => {
  const colorSchemeValue = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);
  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ defaultValue: PaymentMethod.Invoice, control, name });

  const Component: TODO = Select;

  const selectedValue = React.useMemo(() => options.find((option) => option?.value === value), [value]);

  return (
    <Box width="100%">
      <Component
        ref={ref}
        name={name}
        options={options}
        focusBorderColor={colorSchemeValue}
        selectedOptionColorScheme={colorScheme}
        colorScheme={colorScheme}
        value={selectedValue}
        onChange={(c: { value: string }) => onChange(c?.value)}
        noOptionsMessage={(option: { inputValue: string }) => `Нема опции за "${option.inputValue}"`}
        onBlur={onBlur}
      />
    </Box>
  );
};
