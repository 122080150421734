import { authUserLoader } from '@/Authentication/store/authenticatedUser.api';
import { cashPaymentLoader, cashPaymentsLoader } from '@/CashPayments/api/payments.api';
import { clientLoader, clientsLoader } from '@/Clients/api/clients.api';
import { AppLogout } from '@/Core/components/AutoLogout.tsx';
import { LoadingBrojki } from '@/Core/components/BrojkiLogo/LoadingBrojki.tsx';
import { Navbar } from '@/Core/components/Navbar/Navbar.tsx';
import { PrivateRoute } from '@/Core/components/PrivateRoutes/PrivateRoute.tsx';
import { estimateLoader, estimatesLoader } from '@/Estimates/api/estimates.api';
import { expenseLoader, expensesLoader } from '@/Expenses/api/expenses.api';
import { invoiceLoader, invoicesLoader } from '@/Invoices/api/invoices.api';
import { productLoader, productsLoader } from '@/Products/api/products.api';
import { projectLoader, projectsLoader } from '@/Projects/api/projects.api';
import { AppContainer, Root, RouteNotFound } from '@/Root.tsx';
import { EstimateCreatorDataLoader, InvoiceCreatorDataLoader } from '@/Treasuries/components/TreasuryDataLoader.tsx';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router';
import { CashPaymentCreatorLoader } from '../CashPayments/components/CashPaymentCreatorLoader.tsx';
import { CashPaymentPageLoader } from '../CashPayments/components/CashPaymentLoader.tsx';
import { CashPaymentsHome } from '../CashPayments/components/CashPaymentsHome.tsx';
import { CashPayments } from '../CashPayments/components/CashPaymentsMobile.tsx';
import { ClientCreatorLoader } from '../Clients/components/ClientCreatorLoader.tsx';
import { ClientPageLoader } from '../Clients/components/ClientLoader.tsx';
import { ClientsHome } from '../Clients/components/ClientsHome.tsx';
import { Clients } from '../Clients/components/ClientsMobile.tsx';
import Error500Boundary from '../Core/ErrorBoundary/Error500Boundary.tsx';
import { EstimatePageLoader } from '../Estimates/components/EstimateLoader.tsx';
import { EstimatesHome } from '../Estimates/components/EstimatesHome.tsx';
import { Estimates } from '../Estimates/components/EstimatesMobile.tsx';
import { ExpenseCreatorLoader } from '../Expenses/components/ExpenseCreatorLoader.tsx';
import { ExpensePageLoader } from '../Expenses/components/ExpenseLoader.tsx';
import { ExpensesHome } from '../Expenses/components/ExpensesHome.tsx';
import { Expenses } from '../Expenses/components/ExpensesMobile.tsx';
import { InvoicePageLoader } from '../Invoices/components/InvoiceLoader.tsx';
import { Invoices } from '../Invoices/components/InvoicesMobile.tsx';
import { ProductCreatorLoader } from '../Products/components/ProductCreatorLoader.tsx';
import { ProductPageLoader } from '../Products/components/ProductLoader.tsx';
import { ProductsHome } from '../Products/components/ProductsHome.tsx';
import { Products } from '../Products/components/ProductsMobile.tsx';
import { ProjectCreatorLoader } from '../Projects/components/ProjectCreatorLoader.tsx';
import { ProjectPageLoader } from '../Projects/components/ProjectLoader.tsx';
import { ProjectsHome } from '../Projects/components/ProjectsHome.tsx';
import { Projects } from '../Projects/components/ProjectsMobile.tsx';
import { commonPrivateRoutes, commonPublicRoutes } from './commonRoutes.tsx';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const mobileRouter = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      Component: Root,
      HydrateFallback: LoadingBrojki,
      ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
      children: [
        ...commonPublicRoutes,
        {
          ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
          loader: authUserLoader,
          Component: () => (
            <PrivateRoute>
              <AppLogout />
              <Navbar />
              <AppContainer />
            </PrivateRoute>
          ),
          children: [
            ...commonPrivateRoutes,
            {
              path: '/invoices/*',
              loader: invoicesLoader,
              Component: Invoices,
            },
            {
              path: '/invoices/:id',
              loader: invoiceLoader,
              Component: InvoicePageLoader,
            },
            {
              path: '/invoices/:id/edit',
              loader: invoiceLoader,
              Component: InvoiceCreatorDataLoader,
            },
            {
              path: '/estimates/*',
              loader: estimatesLoader,
              Component: Estimates,
              children: [
                {
                  index: true,
                  Component: EstimatesHome,
                },
                {
                  path: ':id',
                  loader: estimateLoader,
                  children: [
                    {
                      index: true,
                      loader: estimateLoader,
                      Component: EstimatePageLoader,
                    },
                    {
                      path: 'edit',
                      loader: estimateLoader,
                      Component: EstimateCreatorDataLoader,
                    },
                  ],
                },
              ],
            },
            {
              path: '/expenses/*',
              Component: Expenses,
              loader: expensesLoader,
              children: [
                {
                  index: true,
                  Component: ExpensesHome,
                },
                {
                  path: ':id',
                  loader: expenseLoader,
                  children: [
                    {
                      index: true,
                      loader: expenseLoader,
                      Component: ExpensePageLoader,
                    },
                    {
                      path: 'edit',
                      loader: expenseLoader,
                      Component: ExpenseCreatorLoader,
                    },
                  ],
                },
              ],
            },
            {
              path: '/cash-payments/*',
              loader: cashPaymentsLoader,
              Component: CashPayments,
              children: [
                {
                  index: true,
                  Component: CashPaymentsHome,
                },
                {
                  path: ':id',
                  loader: cashPaymentLoader,
                  children: [
                    {
                      index: true,
                      loader: cashPaymentLoader,
                      Component: CashPaymentPageLoader,
                    },
                    {
                      path: 'edit',
                      loader: cashPaymentLoader,
                      Component: CashPaymentCreatorLoader,
                    },
                  ],
                },
              ],
            },
            {
              path: '/clients/*',
              Component: Clients,
              loader: clientsLoader,
              children: [
                {
                  index: true,
                  Component: ClientsHome,
                },
                {
                  path: ':id',
                  loader: clientLoader,
                  children: [
                    {
                      index: true,
                      loader: clientLoader,
                      Component: ClientPageLoader,
                    },
                    {
                      path: 'edit',
                      loader: clientLoader,
                      Component: ClientCreatorLoader,
                    },
                  ],
                },
              ],
            },
            {
              path: '/projects/*',
              Component: Projects,
              loader: projectsLoader,
              children: [
                {
                  index: true,
                  Component: ProjectsHome,
                },
                {
                  path: ':id',
                  loader: projectLoader,
                  children: [
                    {
                      index: true,
                      loader: projectLoader,
                      Component: ProjectPageLoader,
                    },
                    {
                      path: 'edit',
                      loader: projectLoader,
                      Component: ProjectCreatorLoader,
                    },
                  ],
                },
              ],
            },
            {
              path: '/products/*',
              loader: productsLoader,
              Component: Products,
              children: [
                {
                  index: true,
                  Component: ProductsHome,
                },
                {
                  path: ':id',
                  loader: productLoader,
                  children: [
                    {
                      index: true,
                      loader: productLoader,
                      Component: ProductPageLoader,
                    },
                    {
                      path: 'edit',
                      loader: productLoader,
                      Component: ProductCreatorLoader,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      Component: RouteNotFound,
    },
  ],
  {
    future: {
      v7_partialHydration: true,
    },
  },
);
