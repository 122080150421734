import { Client } from '@/Clients/api/clients.types';
import { isClientCompany, isClientIndividual } from '@/Clients/api/clients.utils';
import { Avatar, Flex, Heading, Link, Text } from '@/core-components';
import * as React from 'react';

interface Props {
  client: Client;
  query?: string;
}

export const SpotlightClientDetail: React.FC<Props> = ({ client }) => {
  const {
    fullName,
    avatarUrl,
    contactAddress,
    contactPhone,
    contactEmail,
    website,
    businessName,
    businessAddress,
    businessBankAccount,
    businessId,
    businessVatId,
    businessEmail,
  } = client;

  const name = isClientCompany(client) ? businessName : fullName;

  return client ? (
    <Flex direction="column" justify="center">
      <Flex mb={4} align="center">
        <Avatar name={name} src={avatarUrl} size="md" />
        <Heading as="h5" size="md" mb={0} pl={2}>
          {name}
        </Heading>
      </Flex>
      {isClientIndividual(client) ? (
        <Flex direction="column">
          <Heading as="h4" size="md" mb={2}>
            {client.fullName}
          </Heading>
          <Text color="gray.500">Адреса</Text>
          <Text mt={0} mb={1}>
            {contactAddress}
          </Text>
          <Text color="gray.500">Телефон</Text>
          <Text mt={0} mb={1}>
            {contactPhone}
          </Text>
          <Text color="gray.500">Емаил</Text>
          <Text mt={0} mb={1}>
            <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
          </Text>
          <Text color="gray.500">Wеб</Text>
          {website ? (
            <Text mt={0}>
              <Link href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </Link>
            </Text>
          ) : null}
        </Flex>
      ) : null}

      {isClientCompany(client) && (
        <Flex direction="column">
          <Heading as="h4" size="md" mb={2}>
            {businessName}
          </Heading>
          <Text color="gray.500">Адреса</Text>
          <Text mt={0} mb={2}>
            {businessAddress}
          </Text>
          <Text color="gray.500">ЕМБС (Единствен Матичен Број на Субјектот)</Text>
          <Text mb={2}>{businessId}</Text>
          <Text color="gray.500">ЕДБ (Единствен Даночен Број)</Text>
          <Text mb={2}>{businessVatId}</Text>
          <Text color="gray.500">Ж.Сметка</Text>
          <Text mb={2}>{businessBankAccount}</Text>
          <Text mt={0}>
            <Link href={`mailto:${businessEmail}`}>{businessEmail}</Link>
          </Text>

          <Text my={2} mt={4} color="gray.500">
            Контакт лице
          </Text>
          <Heading as="h4" size="md" mb={2}>
            {client.fullName}
          </Heading>
          <Text color="gray.500">Адреса</Text>
          <Text mt={0} mb={2}>
            {contactAddress}
          </Text>
          <Text color="gray.500">Телефон</Text>
          <Text mt={0}>{contactPhone}</Text>
        </Flex>
      )}
    </Flex>
  ) : null;
};
SpotlightClientDetail.displayName = 'SpotlightClientDetail';
