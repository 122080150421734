import { Text } from '@/core-components';
import { HideOnMobile } from '@/Core/components/Helpers/HideOnMobile';
import { ShowOnMobile } from '@/Core/components/Helpers/ShowOnMobile';
import { FloatingActionButton } from '@/Core/components/SplitView/FloatingActionButton';
import { MasterViewToolbar, ToolbarAction } from '@/Core/components/SplitView/MasterViewToolbar';
import { useCreateExpenseMutation, useGetAllExpensesQuery } from '@/Expenses/api/expenses.api';
import { makeExpense } from '@/Expenses/api/expenses.model';
import { ExpensesList } from '@/Expenses/components/ExpensesList';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const ExpensesMasterView: React.FC = () => {
  const navigate = useNavigate();

  const { isLoading } = useGetAllExpensesQuery();
  const [createExpense, { isLoading: isCreating }] = useCreateExpenseMutation();

  const onSuccessfulDelete = () => {
    return navigate(`/expenses`);
  };

  const onCreateExpense = async () => {
    const expense = makeExpense();
    const result = await createExpense(expense);

    if ('error' in result) {
      throw new Error('Failed to create expense');
    }
    navigate(`/expenses/${result.data.id}/edit?new=true`);
  };

  return (
    <>
      <MasterViewToolbar isDisabled={isLoading || isCreating} entity="Expense">
        <ShowOnMobile>
          <Text fontWeight="bold" color="gray.600" textAlign="center" fontSize="2xl" width="100%">
            Расходи
          </Text>
        </ShowOnMobile>
        <HideOnMobile>
          {/* <ToolbarAction
            isDisabled={isLoading || isCreating}
            isActive={isFiltering}
            icon={<FontAwesomeIcon icon={faRadar} color={isFiltering ? '#0ca5e9' : 'inherit'} size="sm" />}
            label="Прикажи све од овој клиент"
            onClick={() => {
              if (isFiltering) {
                searchParams.delete('filter');
                setSearchParams(searchParams);
                setIsFiltering(false);
              } else {
                searchParams.set('filter', 'client');
                setSearchParams(searchParams);
                setIsFiltering(true);
              }
            }}
          /> */}
          <ToolbarAction
            isCreator
            label="Нова Трансакција"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onCreateExpense}
          />
        </HideOnMobile>
      </MasterViewToolbar>
      <ShowOnMobile>
        <FloatingActionButton onClick={onCreateExpense} />
      </ShowOnMobile>
      <ExpensesList onSuccessfulDelete={onSuccessfulDelete} />
    </>
  );
};
ExpensesMasterView.displayName = 'ExpensesMasterView';
