import { Box } from '@/core-components';
import { SummaryValue } from '@/Dashboard/api/dashboard.types';
import { Currency, formatMoney } from '@/Treasuries/store/treasuries.utils';
import { format } from 'date-fns';
import { mk } from 'date-fns/locale';
import * as React from 'react';
import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as ReBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
interface Props {
  data: SummaryValue[];
  color: string;
  currency?: Currency;
}
export const BarChart: React.FC<React.PropsWithChildren<Props>> = ({ data, color, currency }) => {
  const chartData = data.map((d) => ({
    ...d,
    date: format(new Date(d.date!), 'MMM-yy', { locale: mk }),
  }));
  return (
    <Box height="420px" width="100%" display="flex" alignItems="center" justifyContent="center" p={2}>
      {data ? (
        <ResponsiveContainer>
          <ReBarChart margin={{ top: 12, right: 12, bottom: 12, left: 45 }} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              dataKey="amount"
              tickFormatter={(value) => formatMoney(value, currency, 'decimal')}
              label={{ value: 'Приходи', position: 'insideLeft', angle: -90 }}
            />
            <XAxis style={{ textTransform: 'capitalize' }} dataKey="date" name="Датум" />
            <Tooltip
              cursor={{ fill: '#e2e8f0', opacity: 0.5 }}
              formatter={(value: string | number) => (typeof value === 'number' ? formatMoney(value, currency) : value)}
            />
            <Legend />
            <Bar
              dataKey="amount"
              barSize={160}
              fill={color}
              name="Износ"
              radius={[3, 3, 0, 0] as const}
              opacity={0.7}
            />
          </ReBarChart>
        </ResponsiveContainer>
      ) : null}
    </Box>
  );
};
