import { Box, IconButton, Menu, MenuButton, MenuList, Tooltip } from '@/core-components';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
interface Props {
  // Optional click event when there are no menu items
  onClick?: () => void;
  label?: string;
  isLoading?: boolean;
}

const defaultProps = {
  boxShadow: '14px 16px 10px -10px rgba(0,0,0,0.4)',
  fontSize: '36px',
  size: 'lg',
  zIndex: '800',
  boxSize: '60px',
  colorScheme: 'sky',
  'aria-label': 'Креирај',
};

export const FloatingActionButton: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onClick,
  isLoading = false,
  label = 'Креирај',
}) => {
  const hasChildren = React.Children.count(children) > 0;

  return (
    <Box boxSize="50px" bottom="5%" position="absolute" right="48px">
      {hasChildren ? (
        <Menu>
          <Tooltip label={label} placement="left" color="white">
            <MenuButton
              isRound
              isLoading={isLoading}
              as={IconButton}
              {...defaultProps}
              icon={<FontAwesomeIcon icon={faPlus} />}
            />
          </Tooltip>
          <MenuList>{children}</MenuList>
        </Menu>
      ) : (
        <Tooltip label={label} placement="left">
          <IconButton
            isLoading={isLoading}
            isRound
            {...defaultProps}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={onClick}
          />
        </Tooltip>
      )}
    </Box>
  );
};

FloatingActionButton.displayName = 'FloatingActionButton';
