import { Box, Grid, Stat, StatArrow, StatHelpText, StatLabel, StatNumber } from '@/core-components';
import { DashboardCounterLoader } from '@/Core/components/Loaders/DashboardCounterLoader';
import { isCurrentMonthInShortFormat } from '@/Core/utils/dateTime';
import { DashboardStats, GrowthRate } from '@/Dashboard/api/dashboard.types';
import isEmpty from 'lodash-es/isEmpty';
import last from 'lodash-es/last';
import nth from 'lodash-es/nth';
import size from 'lodash-es/size';
import * as React from 'react';

const getGrowthFor = (list: GrowthRate[] = []) => {
  const current = last(list);
  if (!current) {
    return null;
  }

  return isCurrentMonthInShortFormat(current.date) ? current.growth : null;
};

const hasGrowth = (list: GrowthRate[] = []): boolean => {
  return !isEmpty(list) && size(list) >= 2 && getGrowthFor(list) !== null;
};

const growthGoingUp = (list: GrowthRate[] = []): boolean => {
  const previous = nth(list, -2);
  const current = last(list);

  if (!previous || !current) {
    return false;
  }

  return current.count > previous.count;
};

const growthIsSame = (list: GrowthRate[] = []): boolean => {
  const previous = nth(list, -2);
  const current = last(list);

  if (!current || !previous) {
    return false;
  }

  return current.count === previous.count;
};

interface CounterBoxProps {
  type: 'Invoices' | 'Estimates' | 'Clients' | 'Products' | 'Expenses' | 'Payments' | 'Projects';
  label: string;
  counter: number;
  growth: GrowthRate[];
  isLoading: boolean;
}

const CounterBox: React.FC<CounterBoxProps> = ({ label, counter, growth, isLoading, type }) => {
  const getArrowType = () => {
    if (type === 'Expenses') {
      return growthGoingUp(growth) ? 'decrease' : 'increase';
    }

    return growthGoingUp(growth) ? 'increase' : 'decrease';
  };

  return (
    <Box w="100%" bg="white" textAlign="center" p={4} boxShadow="sm" borderRadius={6} data-testid={`counter-${type}`}>
      <Stat>
        <StatLabel mb={4}>{label}</StatLabel>
        {isLoading ? (
          <DashboardCounterLoader />
        ) : (
          <>
            <StatNumber>{counter}</StatNumber>
            {hasGrowth(growth) ? (
              <StatHelpText>
                {!growthIsSame(growth) ? <StatArrow type={getArrowType()} /> : null}
                {getGrowthFor(growth)} <br />
                од минатиот месец
              </StatHelpText>
            ) : null}
          </>
        )}
      </Stat>
    </Box>
  );
};

interface CountersProps {
  counters?: DashboardStats;
  isLoading: boolean;
}

export const DashboardCounters: React.FC<CountersProps> = ({ counters, isLoading }) => {
  const { invoicesCount, estimatesCount, expensesCount, clientsCount, projectsCount, productsCount } = counters || {
    invoicesCount: 0,
    estimatesCount: 0,
    expensesCount: 0,
    clientsCount: 0,
    projectsCount: 0,
    productsCount: 0,
  };

  const { invoicesGrowth, estimatesGrowth, expensesGrowth, clientsGrowth, projectsGrowth, productsGrowth } =
    counters || {
      invoicesGrowth: [{ date: '', count: 0, growth: null }],
      estimatesGrowth: [{ date: '', count: 0, growth: null }],
      expensesGrowth: [{ date: '', count: 0, growth: null }],
      clientsGrowth: [{ date: '', count: 0, growth: null }],
      projectsGrowth: [{ date: '', count: 0, growth: null }],
      productsGrowth: [{ date: '', count: 0, growth: null }],
    };

  return (
    <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4} data-testid="dashboard-counters">
      <CounterBox
        type="Invoices"
        label="Фактури"
        counter={invoicesCount}
        growth={invoicesGrowth}
        isLoading={isLoading}
      />
      <CounterBox
        type="Estimates"
        label="Пресметки"
        counter={estimatesCount}
        growth={estimatesGrowth}
        isLoading={isLoading}
      />
      <CounterBox
        type="Expenses"
        label="Трошоци"
        counter={expensesCount}
        growth={expensesGrowth}
        isLoading={isLoading}
      />
      <CounterBox type="Clients" label="Клиенти" counter={clientsCount} growth={clientsGrowth} isLoading={isLoading} />
      <CounterBox
        type="Projects"
        label="Проекти"
        counter={projectsCount}
        growth={projectsGrowth}
        isLoading={isLoading}
      />
      <CounterBox
        type="Products"
        label="Производи"
        counter={productsCount}
        growth={productsGrowth}
        isLoading={isLoading}
      />
    </Grid>
  );
};
