import { IconButton } from '@/core-components';
import { faChartArea, faChartBar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
interface Props {
  type: 'BarChart' | 'AreaChart';
  onClick: () => any;
}
export const SwitchChartButton: React.FC<React.PropsWithChildren<Props>> = ({ type = 'AreaChart', onClick }) => {
  return (
    <div style={{ position: 'relative', height: '40px', width: '100%', top: 0, right: 0 }}>
      <IconButton
        size="sm"
        borderRadius={0}
        aria-label="Switch Chart"
        fontSize={18}
        icon={type === 'AreaChart' ? <FontAwesomeIcon icon={faChartArea} /> : <FontAwesomeIcon icon={faChartBar} />}
        style={{ position: 'absolute', top: 0, right: 0 }}
        onClick={onClick}
      />
    </div>
  );
};
