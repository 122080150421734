import { Box } from '@/core-components';
import { DetailsViewLoader } from '@/Core/components/Loaders/DetailsLoader';
import { InvoiceLoadingState } from '@/Core/components/Loaders/InvoiceLoadingState';
import { MasterViewLoader } from '@/Core/components/Loaders/MasterViewLoader';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SplitViewType } from './SplitViewType';

import styles from './SplitView.module.scss';

interface SplitViewProps {
  type?: SplitViewType;
  masterView: React.ReactNode;
  detailView: React.ReactNode;
  creatorView: React.ReactNode;
  isLoading?: boolean;
}

const LoadingSection = React.memo(InvoiceLoadingState);

const loaders: Record<SplitViewType, React.ReactElement> = {
  [SplitViewType.Invoice]: <LoadingSection />,
  [SplitViewType.Estimate]: <LoadingSection />,
  [SplitViewType.Expense]: <DetailsViewLoader />,
  [SplitViewType.Client]: <DetailsViewLoader />,
  [SplitViewType.CashPayment]: <DetailsViewLoader />,
  [SplitViewType.Project]: <DetailsViewLoader />,
  [SplitViewType.Product]: <DetailsViewLoader />,
};

export const SplitView: React.FC<React.PropsWithChildren<SplitViewProps>> = ({
  type,
  isLoading = false,
  masterView,
  detailView,
  creatorView,
}) => {
  const loader = React.useMemo(() => (type ? loaders[type] : <DetailsViewLoader />), [type]);

  return (
    <Box className={styles.containerMobile}>
      <Routes>
        <Route index element={isLoading ? <MasterViewLoader /> : masterView} />
        <Route path=":id">
          <Route index element={isLoading ? loader : detailView} />
          <Route path="edit" element={isLoading ? loader : creatorView} />
        </Route>
      </Routes>
    </Box>
  );
};

SplitView.displayName = 'SplitView';
